import { API_URI, REPORT_API_URI, ACCOUNT_API_URI } from "utils/config/env"

//locales
export const LOCALES_URL = `${REPORT_API_URI}/i18n/`

//email
export const SENDMAIL_URL = `${REPORT_API_URI}/email/send?locale={lang}`

//file
export const DOWNLOAD_FILE_NAME_URL = `${REPORT_API_URI}/file/download/file-name/{fileName}?locale={lang}&ext={ext}&fileType=TEMPLATES&createdFromSystem=true`
export const DOWNLOAD_FILE_BY_ID_URL = `${REPORT_API_URI}/sustainability-report/download-file/{fileId}`
export const DOWNLOAD_GENERATED_FILE_BY_ID_URL = `${REPORT_API_URI}/reports/company/{companyId}/final-report`
export const DOWNLOAD_GENERATED_SUSTAINABILITY = `${REPORT_API_URI}/reports/company/{companyId}/generate-report`
//user
export const GET_USERS_URL = `${ACCOUNT_API_URI}/admin/users?pageNumber={pageNumber}&pageSize={pageSize}`
export const GET_Roles_URL = `${ACCOUNT_API_URI}/admin/roles?pageNumber={pageNumber}&pageSize={pageSize}`
export const ADD_USER_ROLES = `${ACCOUNT_API_URI}/admin/users/{userId}/roles`
export const GET_USER_ROLES_BY_ID_URL = `${ACCOUNT_API_URI}/user/{userId}/roles?pageNumber=0&pageSize=10`
export const GET_USER_ROLES_AND_PERMISSION_BY_ID_URL = `${ACCOUNT_API_URI}/admin/users/{userId}/roles-permissions`
export const UPDATE_USER_FIRST_LOGIN_URL = `${ACCOUNT_API_URI}/user/{userId}/cancel-first-login`
export const ADD_USER_FIRST_LOGIN_URL = `${ACCOUNT_API_URI}/user/users/{userId}/add-first-login`
export const GET_USER_BY_ID_URL = `${ACCOUNT_API_URI}/user/users/{userId}`
export const CHANGE_USER_DATA_BY_ID_URL = `${ACCOUNT_API_URI}/user/users/{userId}`
//sustainability
export const GENERATE_REPORT_URL = `${REPORT_API_URI}/sustainability-report/generate-report`
// steps
export const GET_STEPS_URL = `${REPORT_API_URI}/steps/?locale=no`
export const UPDATE_STEPS_URL = `${REPORT_API_URI}/reports/company/{companyId}/steps/{stepNumber}`
export const UPDATE_CLIMATE_STEPS_URL = `${REPORT_API_URI}/reports/company/{companyId}/steps/{stepNumber}/climate-module`
export const UPDATE_STEPS_FILE_URL = `${REPORT_API_URI}/reports/company/{companyId}/steps/{stepNumber}/upload-file`
export const GET_COMPANY_STEPS_URL = `${REPORT_API_URI}/reports/company/{companyId}/steps`
export const SEND_CLIMATE_NOTIFICATION_URL = `${REPORT_API_URI}/companies/{companyId}/send-notification`
export const RESET_TRANSPARANCY_ACT_STEPS_URL = `${REPORT_API_URI}/reports/company/{companyId}/transparency-act/steps/reset`
export const DELETE_STEPS_FILE_URL = `${REPORT_API_URI}/reports/company/{companyId}/steps/{stepNumber}/uploaded-file`

//companies
export const CREATE_COMPANY_URL = `${REPORT_API_URI}/companies`
export const UPDATE_COMPANY_URL = `${REPORT_API_URI}/companies/{id}`
export const GET_COMPANY_URL = `${REPORT_API_URI}/companies/{id}`
export const DELETE_COMPANY_URL = `${REPORT_API_URI}/companies/{id}/soft-delete`
export const GET_ACTIVE_MODULES_LIST_URL = `${REPORT_API_URI}/companies/list-active-modules`
export const GET_ALL_COMPANIES_URL = `${REPORT_API_URI}/companies/`
export const GET_ALL_COMPANIES_LIST_URL = `${REPORT_API_URI}/companies/list-companies`
export const GET_ALL_COMPANIES_STATUS_LIST_URL = `${REPORT_API_URI}/companies/list-status`
export const UPDATE_COMPANY_VISIBILITY = `${REPORT_API_URI}/companies/{companyId}/update-visibility`
export const SEND_COMPLETE_STATEMENT_NOTIFICATION = `${REPORT_API_URI}/companies/{companyId}/climate-statement/send-notification`
export const UPLOAD_CLIMATE_EMISSION = `${REPORT_API_URI}/climate/charts/upload-climate-emission`
export const UPLOAD_CLIMATE_GOALS = `${REPORT_API_URI}/climate/charts/upload-climate-goals`
export const GET_UPLOAD_CLIMATE_DATE = `${REPORT_API_URI}/climate/charts/uploaded-date`
//users
export const CREATE_USER_URL = `${REPORT_API_URI}/user/add`
export const GET_ALL_USERS_URL = `${REPORT_API_URI}/user/`
export const GET_USERS_BY_COMPANY_URL = `${REPORT_API_URI}/user/{companyId}/company`
export const GET_ROLES_PER_USER_URL = `${ACCOUNT_API_URI}/user/roles-per-user`
export const DELETE_USER_URL = `${REPORT_API_URI}/user/{userId}/soft-delete`
export const DELETE_USER_COMPANY_URL = `${REPORT_API_URI}/user/{userId}/company/{companyId}/soft-delete`
export const UPDATE_USER_URL = `${REPORT_API_URI}/user/{userId}/update`
export const UPDATE_USER_ADMIN_URL = `${REPORT_API_URI}/user/{userId}/update-admin`
export const SEND_USER_INVITATION_URL = `${ACCOUNT_API_URI}/user/{userId}/verified-email`
export const GET_USERS_LIST_URL = `${REPORT_API_URI}/user/list-users`

//logs
export const GET_HISTORY_LOGS_URL = `${REPORT_API_URI}/logs`

//climate charts
export const GET_TOTAL_SCOPES_IN_LAST_TWO_YEARS_FOR_ACTIVITY_GROUPS_URL = `${REPORT_API_URI}/climate-analysis/companies/{companyId}/emissions/activity-groups/last-two-years`
export const GET_COMPANY_CAPRO_URL = `${REPORT_API_URI}/climate-analysis/companies/{companyId}/capro`
export const GET_GOAL_PERFOMRANCE_TREND_URL = `${REPORT_API_URI}/climate-analysis/companies/{companyId}/goal-performance-trend`
export const GET_CLIMATE_IMPACT_PER_PERSON_URL = `${REPORT_API_URI}/climate-analysis/companies/{companyId}/climate-impact-per-person`
export const GET_LGENEDS_URL = `${REPORT_API_URI}/climate-analysis/companies/{companyId}/activity-groups`
export const GET_TOTAL_ACTIVITY_GROUP_CURRENT_YEAR_URL = `${REPORT_API_URI}/climate-analysis/companies/{companyId}/total-activity-group-current-year`
export const GET_TOTAL_ACTIVITY_GROUP_PER_QUARTER_URL = `${REPORT_API_URI}/climate-analysis/companies/{companyId}/total-activity-group-per-quarter`
export const GET_TOTAL_SCOPES_IN_LAST_TWO_YEARS_URL = `${REPORT_API_URI}/climate-analysis/companies/{companyId}/total-scopes-in-last-two-years`
export const GET_TOTAL_SCOPES_PER_QUARTER_URL = `${REPORT_API_URI}/climate-analysis/companies/{companyId}/total-scopes-per-quarter`
export const GET_TOTAL_SCOPES_PER_YEAR_URL = `${REPORT_API_URI}/climate-analysis/companies/{companyId}/total-scopes-per-year`
export const GET_EMISSIONS_TOTAL_SCOPES_YEARLY_URL = `${REPORT_API_URI}/climate-analysis/companies/{companyId}/total-scopes-yearly`

import { Div, FootprintFileFieldFormik } from "modules/shared"
import FootprintFileField from "modules/shared/components/FootprintFileField/FootprintFileFIeld"
import { FunctionComponent, useEffect, useState } from "react"
import styles from "./Adminstration.style.module.scss"
import { useAdminstriationFilesApi } from "modules/admin/services/useAdminstriationFilesApi"
import SnackbarUtils from "utils/SnackbarUtils"
import moment from "moment"
interface AdminstrationProps {}

const Adminstration: FunctionComponent<AdminstrationProps> = () => {
  const { getUploadClimateDate, uploadClimateEmission, uploadClimateGoal } =
    useAdminstriationFilesApi()

  const [emissionUploadDate, setEmissionUploadDate] = useState<string>()
  const [goalUploadDate, setGoalUploadDate] = useState<string>()

  const onUploadClimateEmission = async (file: File) => {
    try {
      await uploadClimateEmission(file)
      SnackbarUtils.success("Climate emission uploaded successfully", {
        autoHideDuration: 3000,
      })
      updateUploadDates()
    } catch (e) {}
  }

  const onUploadClimateGoal = async (file: File) => {
    try {
      await uploadClimateGoal(file)
      SnackbarUtils.success("Climate goal uploaded successfully", {
        autoHideDuration: 3000,
      })
      updateUploadDates()
    } catch (e) {}
  }

  const updateUploadDates = () => {
    getUploadClimateDate().then((data) => {
      console.log("data", data)
      const dates = data.data
      const emissionDate = dates[0].date
        ? moment(dates[0].date).format("YYYY/MM/DD hh:mm A")
        : "___"
      const UploadDate = dates[1].date
        ? moment(dates[1].date).format("YYYY/MM/DD hh:mm A")
        : "___"
      setEmissionUploadDate(emissionDate)
      setGoalUploadDate(UploadDate)
    })
  }

  useEffect(() => {
    updateUploadDates()
  }, [])

  return (
    <Div className={styles.host}>
      <Div className={styles.fileInput}>
        <span className={styles.label}>LAST OPP 1 FIL</span>
        <span className={styles.subLabel}>CO2 emission CG</span>
        <FootprintFileField
          // name={"fieldData.name"}
          //   accept={{ [fieldData.allowedMimeType ?? "*"]: [] }}
          sx={{ width: "fit-content", margin: "auto" }}
          onFilesUpload={(files) => {
            onUploadClimateEmission(files[0])
          }}
        />
        <span className={styles.date}>Uploaded at: {emissionUploadDate}</span>
      </Div>

      <Div className={styles.fileInput}>
        <span className={styles.label}>LAST OPP 2 FIL</span>
        <span className={styles.subLabel}>Common Data Excel</span>
        <FootprintFileField
          // name={"fieldData.name"}
          // accept={{ [fieldData.allowedMimeType ?? "*"]: [] }}
          accept={{}}
          sx={{ width: "fit-content", margin: "auto" }}
          onFilesUpload={(files) => {
            onUploadClimateGoal(files[0])
          }}
        />
        <span className={styles.date}>Uploaded at: {goalUploadDate}</span>
      </Div>
    </Div>
  )
}

export default Adminstration

import { SIDEBAR_VARIANTS } from "modules/layout/data/layout"
const layoutConfig = {
  sidebar: {
    hide: true,
    variant: SIDEBAR_VARIANTS.TEMPORARY,
  },
  content: {
    sx: {
      p: 0,
      px: { lg: 0, xs: 0 },
    },
  },
  header: {
    hide: true,
  },
  footer: {
    hide: true,
  },
}

export default layoutConfig

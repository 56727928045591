import React from "react"
import { Typography } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import { Div } from "modules/shared"
import { Logo } from "modules/shared"
import Stack from "@mui/material/Stack"
import Divider from "@mui/material/Divider"
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import * as yup from "yup"
import { Form, Formik } from "formik"
import FootprintContactTextFieldFormik from "modules/shared/components/FootprintFormik/FootprintContactTextFieldFormik"
import LoadingButton from "@mui/lab/LoadingButton"
import useLocalesState from "utils/Localization/useLocalesState"

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
})

const Footer = () => {
  const [locales] = useLocalesState()

  //temp
  const mutation = { isError: false }
  return (
    <Div
      sx={{
        height: "256px",
        bgcolor: "background.blackPaper",
        pt: "80px",
        px: "172px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography
            variant="h5"
            color="text.primary"
            fontWeight={500}
            fontSize="17px"
            letterSpacing={"2px"}
            mb={"13px"}
          >
            Hovedkontor:
          </Typography>
          <Typography
            variant="h6"
            color="text.primary"
            fontWeight={300}
            fontSize="14px"
            lineHeight={"17px"}
          >
            {locales["pages_footer_contact_us_switchboard"] ?? "Sentralbord"}
            : + 47 922 03 214 <br />{" "}
            {locales["pages_footer_contact_us_address"] ?? "Besøksadresse"}
            : Smeltedigelen 1, 0195 Oslo <br />{" "}
            {locales["pages_footer_contact_us_postal"] ?? "Postadresse"}:
            Postboks 6395 Etterstad, NO 0604 Oslo
          </Typography>
          {/* <Stack direction={"row"} color={"text.primary"}>
                <IconButton
                  color="inherit"
                  aria-label="LinkedIn"
                  sx={{ padding: "0px" }}
                >
                  <LinkedInIcon fontSize={"medium"} />
                </IconButton>
                <IconButton color="inherit" aria-label="Facebook">
                  <FacebookRoundedIcon fontSize={"medium"} />
                </IconButton>
              </Stack> */}
        </Grid>
        <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Logo mode="dark" />
        </Grid>
        {/* <Grid item xs={12} lg={2}>
              <Typography
                variant="h5"
                color="text.primary"
                fontWeight={500}
                fontSize="17px"
              >
                {locales["pages_footer_service"] ?? "VÅRE TJENESTER:"}
              </Typography>
              <Typography variant="h6" color="text.primary" fontWeight={300}>
                {locales["pages_footer_service_transparency"] ??
                  "Åpenhetsloven"}{" "}
                <br />
                {locales["pages_footer_service_climate"] ?? "Klimaregnskap"}
                <br />{" "}
                {locales["pages_footer_service_reporting"] ??
                  "Bærekraftsrapportering"}
                <br />
                {locales["pages_footer_service_strategy"] ??
                  "Bærekraftig Strategi"}{" "}
                <br />&{" "}
                {locales["pages_footer_service_business"] ?? "Næringsutvikling"}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={2}>
              <Typography
                variant="h5"
                color="text.primary"
                fontWeight={500}
                fontSize="17px"
              >
                {locales["pages_footer_links"] ?? "NYTTIGE LENKER:"}
              </Typography>
              <Typography variant="h6" color="text.primary" fontWeight={300}>
                {locales["pages_footer_links_about"] ?? "Om Oss"} <br />
                {locales["pages_footer_links_blog"] ?? "Blogg"} <br />
                {locales["pages_footer_links_Privacy"] ??
                  "Personvernerklæring"}{" "}
                <br />
                {locales["pages_footer_links_center"] ??
                  "Amesto Trust Center"}{" "}
                <br />
                {locales["pages_footer_links_gdpr"] ?? "GDPR sikkerhetsavvik"}
              </Typography>
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                display: { xs: "none", lg: "block" },
              }}
            />
            <Grid item xs={12} lg={3} sx={{ marginLeft: "auto" }}>
              <Typography
                variant="h5"
                color="text.primary"
                fontWeight={500}
                fontSize="17px"
              >
                {locales["pages_footer_newsletter"] ?? "Nyhetsmail"}
              </Typography>
              <Formik
                validateOnChange={true}
                initialValues={{
                  email: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting }) => {
                  setSubmitting(true);
                  setSubmitting(false);
                }}
              >
                {({ isSubmitting }) => (
                  <Form
                    style={{ textAlign: "left" }}
                    noValidate
                    autoComplete="off"
                  >
                    <Div sx={{ mb: 3, mt: 1 }}>
                      <FootprintTextField
                        fullWidth
                        name="email"
                        label={
                          locales["pages_footer_newsletter_email"] ??
                          "E-post adresse*"
                        }
                        sx={{
                          fieldset: { borderColor: "white" },
                          label: { color: "white" },
                        }}
                      />
                    </Div>

                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      color="action"
                      sx={{ mb: 3 }}
                      loading={isSubmitting || mutation.isLoading}
                    >
                      {locales["pages_footer_newsletter_email_action_button"] ??
                        "Send"}
                    </LoadingButton>
                  </Form>
                )}
              </Formik>
            </Grid> */}
      </Grid>
    </Div>
  )
}

export default Footer

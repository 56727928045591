import { Div } from "modules/shared"
import { SectionContainer } from "modules/shared"
import React from "react"
import { HintTitle, SectionSixSideMenus } from "../ManualStyles"
import { Heading2, Paragraph } from "modules/shared"
import { FootprintScrollArrow } from "modules/shared"
export const Section6: React.FC<{
  locales?: any
  large?: boolean
  medium?: boolean
  small?: boolean
}> = ({ locales = {}, large, medium, small }) => {
  return (
    <Div>
      <SectionContainer
        id="page_manual_section6"
        sx={{
          p:
            (large && "100px 336px 0px") ||
            (medium && "81px 14.322vw 0px") ||
            (small && "81px  3.77vw 0px ") ||
            "100px 336px 0px",
        }}
      >
        <Div
          sx={{
            textAlign: (small && "center") || "none",
          }}
        >
          <HintTitle sx={{ fontSize: (small && "15px") || "17px" }}>
            {locales["page_manual_section6_hint"] ??
              "HVORDAN FÅR JEG EN FERDIG RAPPORT?"}
          </HintTitle>
          <Heading2 sx={{ m: 0, fontSize: (small && "26px") || "32px" }}>
            {locales["page_manual_section6_title"] ??
              "Vi konverterer rapporten for deg."}
          </Heading2>
          <Paragraph sx={{ m: "0px", fontSize: (small && "15px") || "17px" }}>
            {locales["page_manual_section6_paragraph"] ??
              `Når du har fylt ut alle rapporteringskravene, så trykker du på "konverter rapport".\r\nSvarene dine konvertes automatisk til en ferdig rapport og lastes ned til din datamaskin som et word-dokument.\r\nDu kan endre og justere i den ferdige rapporten i word lokalt på din datamaskin.\r\n`}
          </Paragraph>
          <SectionSixSideMenus
            sx={{
              width: (medium && "648px") || (small && "719.787px") || "648px",
              height: (medium && "388px") || (small && "430.984px") || "388px",
            }}
          />
        </Div>
        <FootprintScrollArrow
          nextSelector="#page_manual_section7"
          style={{
            margin:
              (small && "60px auto") || (medium && "60px auto") || "80px auto",
          }}
        />
      </SectionContainer>
    </Div>
  )
}

export const idGenerator = () => {
  return Math.floor(Math.random() * 100000)
}

export const getArrayElementFromKey = (
  variable: { [x: string]: any },
  keyString: string,
) => {
  if (variable && keyString) {
    const levels = keyString.split(".")
    let value: { [x: string]: any } | null = null
    levels.forEach((level: string | number) => {
      if (value === null) value = variable[level]
      else value = value[level]
    })
    return value
  }
  return null
}

export const sleep = (milliseconds: number) => {
  const date = Date.now()
  let currentDate = null
  do {
    currentDate = Date.now()
  } while (currentDate - date < milliseconds)
}

export const createFootprintTheme = (
  mainTheme: {
    breakpoints: {
      values: { xs: number; sm: number; md: number; lg: number; xl: number }
    }
    palette: {
      primary: {
        main: string
        light: string
        dark: string
        black: string
        white: string
        transparent: string
        contrastText: string
      }
      secondary: {
        main: string
        light: string
        dark: string
        black: string
        white: string
        transparent: string
        contrastText: string
      }
      black: {
        main: string
        light: string
        dark: string
        black: string
        transparent: string
        contrastText: string
      }
      nav: { main: string }
      error: {
        main: string
        light: string
        dark: string
        contrastText: string
      }
      warning: {
        main: string
        light: string
        dark: string
        contrastText: string
      }
      info: { main: string; light: string; dark: string; contrastText: string }
      success: {
        main: string
        light: string
        dark: string
        contrastText: string
      }
      text: { primary: string; secondary: string; disabled: string }
      divider: string
      background: { paper: string; default: string }
      action: { active: string; hover: string }
    }
    typography: {
      fontFamily: string
      fontSize: number
      h1: {
        fontSize: string
        lineHeight: number
        fontWeight: number
        color: string
        margin: string
      }
      h2: {
        fontSize: string
        lineHeight: number
        fontWeight: number
        color: string
        margin: string
      }
      h3: {
        fontSize: string
        lineHeight: number
        fontWeight: number
        color: string
        margin: string
      }
      h4: {
        fontSize: string
        lineHeight: number
        fontWeight: number
        color: string
        margin: string
      }
      h5: {
        fontSize: string
        lineHeight: number
        fontWeight: number
        color: string
        margin: string
      }
      h6: {
        fontSize: string
        lineHeight: number
        fontWeight: number
        color: string
        margin: string
      }
      body1: { fontSize: string }
    }
    components: {
      MuiTableCell: { styleOverrides: { root: { borderColor: string } } }
      MuiMenuItem: { styleOverrides: { root: { minHeight: string } } }
      MuiCard: { styleOverrides: { root: { borderRadius: number } } }
      MuiCardContent: { styleOverrides: { root: { padding: number } } }
      MuiCardHeader: {
        styleOverrides: {
          root: { padding: string }
          title: { fontSize: string; marginBottom: number }
          subheader: { margin: string }
          action: { margin: number }
        }
      }
      MuiCardActions: { styleOverrides: { root: { padding: string } } }
      MuiChip: {
        styleOverrides: { sizeSmall: { height: number; fontSize: number } }
      }
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: number
            borderRadius: number
            letterSpacing: string
            fontSize: string
            boxShadow: number
            backgroundColor: string
            shadows: string[]
            "&:hover": { backgroundColor: string }
          }
          sizeSmall: { fontSize: string }
        }
      }
      MuiPopover: { styleOverrides: { paper: { borderRadius: number } } }
      MuiDialogTitle: { styleOverrides: { root: { fontSize: number } } }
      MuiDialogActions: { styleOverrides: { root: { padding: string } } }
      MuiAvatarGroup: {
        styleOverrides: {
          avatar: { backgroundColor: string; fontSize: number }
        }
      }
    }
    footprintComponents: { FootprintSearch: { background: string } }
  },
  headerTheme: {
    type: string
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            color: string
            background: string
            fontWeight: number
            letterSpacing: string
            textTransform: string
            fontSize: string
          }
        }
      }
      MuiInputBase: {
        styleOverrides: { root: { background: string; fontWeight: number } }
      }
      MuiIconButton: {
        styleOverrides: {
          root: {
            fontWeight: number
            color: string
            background: string
            "&:hover": { color: string; background: string }
          }
        }
      }
    }
    palette: {
      primary: {
        main: string
        light: string
        dark: string
        contrastText: string
      }
      secondary: {
        main: string
        light: string
        dark: string
        contrastText: string
      }
      error: {
        main: string
        light: string
        dark: string
        contrastText: string
      }
      warning: {
        main: string
        light: string
        dark: string
        contrastText: string
      }
      info: { main: string; light: string; dark: string; contrastText: string }
      success: {
        main: string
        light: string
        dark: string
        contrastText: string
      }
      text: { primary: string; secondary: string; disabled: string }
      divider: string
      background: { paper: string; default: string }
      action: { active: string; hover: string }
    }
  },
  sidebarTheme: {
    type: string
    palette: {
      primary: {
        main: string
        light: string
        dark: string
        contrastText: string
      }
      secondary: {
        main: string
        light: string
        dark: string
        contrastText: string
      }
      error: {
        main: string
        light: string
        dark: string
        contrastText: string
      }
      warning: {
        main: string
        light: string
        dark: string
        contrastText: string
      }
      info: { main: string; light: string; dark: string; contrastText: string }
      success: {
        main: string
        light: string
        dark: string
        contrastText: string
      }
      text: { primary: string; secondary: string; disabled: string }
      nav: {
        action: { active: string; hover: string }
        background: { active: string; hover: string }
        tick: { active: string; hover: string }
      }
      divider: string
      background: { paper: string; default: string }
      action: { active: string; hover: string }
    }
  },
  footerTheme: {
    palette: {
      primary: {
        main: string
        light: string
        dark: string
        contrastText: string
      }
      secondary: {
        main: string
        light: string
        dark: string
        contrastText: string
      }
      error: {
        main: string
        light: string
        dark: string
        contrastText: string
      }
      warning: {
        main: string
        light: string
        dark: string
        contrastText: string
      }
      info: { main: string; light: string; dark: string; contrastText: string }
      success: {
        main: string
        light: string
        dark: string
        contrastText: string
      }
      text: { primary: string; secondary: string; disabled: string }
      divider: string
      background: { paper: string; blackPaper: string; default: string }
      action: {
        main: string
        light: string
        dark: string
        contrastText: string
      }
    }
  },
) => {
  return {
    main: mainTheme,
    header: {
      ...mainTheme,
      ...(headerTheme ?? {}),
    },
    sidebar: {
      ...mainTheme,
      ...(sidebarTheme ?? {}),
    },
    footer: {
      ...mainTheme,
      ...(footerTheme ?? {}),
    },
  }
}

import jwt_decode from "jwt-decode"
export const getUserDataFromToken = () => {
  const storedToken = localStorage.getItem("token")
  if (storedToken) {
    const userData = jwt_decode(storedToken)
    return userData as any
  } else {
    return null
  }
}

import { Column, Row } from "modules/shared"
import styled from "@mui/material/styles/styled"
import { ASSET_IMAGES } from "utils/constants/paths"

export const TextContainer = styled(Column)({
  width: "100%",
  height: "100%",
  backgroundColor: "#F1F3F3",
  position: "relative",
})
export const VideoContainer = styled("div")({
  paddingTop: "100%",
  position: "relative",
  height: "30.34vw",
  border: "none",
})

export const Video = styled("img")({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  width: "100%",
  height: "100%",
  border: "none",
})

export const InnerTextContainer = styled("div")({
  width: "648px",
  padding: " 0 112px",
})
export const HintTitle = styled("span")({
  fontSize: "17px",
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: "21px" /* 123.529% */,
  letterSpacing: "2px",
  textTransform: "uppercase",
})
export const StepsContainer = styled(Column)({
  color: "#233E4C",
  backgroundColor: "#CDE6D2",
  width: "472px",
  height: "180px",
  position: "absolute",
  left: "17.28%",
  right: "17.28%",

  bottom: "-90px",
  // height: "33%",
  alignItems: "center",
  justifyContent: "center",
  padding: "15px 0",
})

export const Step = styled(Row)({
  textTransform: "uppercase",
  width: "80%",
  alignItems: "center",
  gap: "0.546vw",
})

export const StepNumber = styled("div")({
  fontSize: "1.667vw",
  marginRight: "0.3472vw",
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: 500,
})

export const StepText = styled("div")({
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "17px",
  lineHeight: "24px",
  letterSpacing: "2px",
})

export const Intro = styled("p")({
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: "1.18vw",
  lineHeight: "1.458vw",
  marginTop: "132px",
  /* identical to box height, or 124% */
  textAlign: "center",
  letterSpacing: "0.139vw",
  textTransform: "uppercase",
})

export const CrownImage = styled("div")({
  width: "50px",
  height: "90px",
  position: "absolute",
  left: "-25px",
  top: "45px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundImage: `url("${ASSET_IMAGES}/pages/manual/img Coat.svg")`,
})
export const SectionTwoDiagram = styled("div")({
  width: "1096px",
  height: "826px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundImage: `url("${ASSET_IMAGES}/pages/manual/section_2.png")`,
})
export const SectionFourDiagram = styled("div")({
  width: "852px",
  height: "513px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundImage: `url("${ASSET_IMAGES}/pages/manual/section_4.png")`,
})
export const LastDiagram = styled("div")({
  width: "978px",
  height: "630px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundImage: `url("${ASSET_IMAGES}/pages/manual/last_section.png")`,
})
export const SectionThreeDiagramOne = styled("div")({
  height: "22.7vw",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundImage: `url("${ASSET_IMAGES}/pages/manual/Section 3 Diagram 1.png")`,
})
export const SectionThreeDiagramTwo = styled("div")({
  height: "22.7vw",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundImage: `url("${ASSET_IMAGES}/pages/manual/Section 3 Diagram 2.png")`,
  marginLeft: "28vw",
})
export const SectionFiveSideMenus = styled("div")({
  width: "648px",
  height: "500px",
  flexShrink: 0,
  margin: "auto",
  marginRight: "24px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundImage: `url("${ASSET_IMAGES}/pages/manual/input.png")`,
})
export const SectionNineSideMenus = styled("div")({
  width: " 536px",
  height: " 536px",
  flexShrink: 0,
  margin: "auto",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundImage: `url("${ASSET_IMAGES}/pages/manual/section9.png")`,
})
export const SectionEightSideMenus = styled("div")({
  width: "542px",
  height: "542px",
  flexShrink: 0,
  margin: "auto",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundImage: `url("${ASSET_IMAGES}/pages/manual/section8.png")`,
})
export const SectionSixSideMenus = styled("div")({
  width: "648px",
  height: "388px",
  flexShrink: 0,
  margin: "auto",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundImage: `url("${ASSET_IMAGES}/pages/manual/section6.png")`,
})
export const SectionSevenSideMenus = styled("div")({
  width: "154px",
  height: "111.607px",
  flexShrink: 0,
  margin: "auto",
  marginRight: "24px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundImage: `url("${ASSET_IMAGES}/pages/manual/Frame_136.png")`,
})

export const AccordionTwoDetailsContainer = styled(Column)({
  padding: "5.35vw 7.77vw",
  gap: "1.67vw",
})

export const AccordionOneDetailsContainer = styled(Row)({
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
})
export const KlikkBox = styled(Row)({
  alignItems: "center",
  padding: "3.28% 6.5%",
  width: "760px",
  height: "103px",
  gap: "4.47%",
  backgroundColor: "#EDF7EF",
})

export const TouchIcon = styled("div")({
  background: `url(${ASSET_IMAGES}/pages/manual/touchIcon.svg)`,
  width: "2.77vw",
  height: "3.75vw",
  backgroundSize: "contain",
})

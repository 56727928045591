import * as React from "react"
import { useField, FieldAttributes } from "formik"
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete"
import TextField, { TextFieldProps } from "@mui/material/TextField"
interface Props {
  labelTopText: string
}
const FootprintAutoCompleteFormi: React.FC<
  FieldAttributes<
    AutocompleteProps<any, boolean, boolean, boolean, any> & TextFieldProps
  > &
    Props
> = (props: any) => {
  const [field, meta, helpers] = useField(props)
  const errorText = meta.error && meta.touched ? meta.error : ""
  const helperText = props?.helperText ?? ""
  return (
    <div
      style={{
        ...props.style,
        marginBottom:
          errorText || helperText ? "20px" : props.style?.marginBottom,
      }}
    >
      <div style={{ fontWeight: 300 }}>{props.labelTopText}</div>
      <Autocomplete
        multiple
        {...props}
        {...field}
        sx={{
          ...props.sx,
          "& fieldset": {
            borderRadius: "0px",
            boxShadow: "none",
            borderColor: "#DFE3E6",
            minHeight: "48px",
          },
          "& .MuiChip-root": {
            borderRadius: "0px",
          },
          "& .MuiChip-label": {
            color: "black",
            fontSize: "13px",
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "normal",
            letterSpacing: "0.26px",
            background: "var(--F1F3F3, #F1F3F3)",
          },
          "& .MuiFormHelperText-root": {
            ml: 0,
          },
        }}
        id="tags-outlined"
        value={field.value}
        onChange={(event, value) => helpers.setValue(value)}
        onBlur={() => helpers.setTouched(true)}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={errorText || helperText}
            error={!!errorText}
          />
        )}
      />
    </div>
  )
}
export default FootprintAutoCompleteFormi

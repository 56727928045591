import { Div } from "modules/shared"
import { FunctionComponent } from "react"
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined"
import { Button } from "@mui/material"
import { useMyfilesApi } from "modules/myFiles/services/useMyFilesApi"
import useLocalesState from "utils/Localization/useLocalesState"
import { downloadFileHelper } from "utils/DownloadFilesHelper"
import { closeSweetAlert, LoadingFileSweetAlert } from "utils/sweetAlert"
import CloseIcon from "@mui/icons-material/Close"
import { FootprintTertiaryButton } from "modules/shared"
import "./MyFilesFile.style.css"

interface MyFilesFileProps {
  sx?: React.CSSProperties
  file: any
  companyId?: any
  onDelete?: VoidFunction
  hideDeleteIcon?: boolean
}
const MyFilesFile: React.FC<MyFilesFileProps> = (props) => {
  const file = props.file
  const [locales] = useLocalesState()
  const { getDownloadLink, deleteFile } = useMyfilesApi()

  const getFormattedDate = () => {
    const date = new Date(file.createdAt)
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    // Replace slashes with dots
    return formattedDate.replace(/\//g, ".")
  }

  function trimString(str: string) {
    if (str.length > 30) {
      const firstWord = str.split(" ")[0]
      const restOfStr = str.slice(firstWord.length)
      if (restOfStr.length > 26) {
        return `${firstWord}${restOfStr.slice(0, 22)}...`
      } else {
        return str
      }
    } else {
      return str
    }
  }

  const downloadFile = async () => {
    LoadingFileSweetAlert()
    getDownloadLink(file.id, props.companyId)
      .then((response) => {
        downloadFileHelper(
          response?.data.blobDownloadUrl,
          response?.data.fileName,
          response?.data.fileExtension,
        )
          .then(() => {
            closeSweetAlert()
          })
          .catch(() => {
            closeSweetAlert()
          })
      })
      .catch(() => {
        closeSweetAlert()
      })
  }
  return (
    <Div
      className="myFilesFileContainer"
      sx={{
        ...props.sx,
      }}
    >
      {!props.hideDeleteIcon && (
        <Div title="Delete file" className="closeIcon">
          <CloseIcon
            onClick={() => {
              const result = deleteFile(file.id, props?.companyId)
              result.then((response) => {
                const isSuccess =
                  response?.status >= 200 && response?.status < 300
                if (isSuccess && props.onDelete) {
                  props.onDelete()
                }
              })
            }}
          />
        </Div>
      )}
      <Div className="date">
        <Div>{getFormattedDate()}</Div>
      </Div>
      <Div className="descContainer">
        <DescriptionOutlinedIcon className="descIcon" />
        <Div className="title" title={`${file.fileName}.${file.fileExtension}`}>
          {trimString(`${file.fileName}.${file.fileExtension}`)}
        </Div>

        <FootprintTertiaryButton onClick={downloadFile} className="downloadBtn">
          {locales["page.files.download"] ?? "LAST NED"}
        </FootprintTertiaryButton>
      </Div>
    </Div>
  )
}

export default MyFilesFile

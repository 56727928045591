export interface CompanyModuleDto {
  moduleId: number
  startDate?: Date
  endDate?: Date
}

export interface CompanyCreationDto {
  activeModule: CompanyModuleDto[]
  companyName: string
  invoiceInfo: string
  organizationNumber: string
  status: string
  subscription: number
}
export interface Pagination<T> {
  count: number
  data: T
}
export interface CompanyDto {
  activeModules: string[]
  companyName: string
  id: string
  invoiceInfo: string
  organizationNumber: string
  status: string
  subscription: number
}
export interface UserDto {
  userId: string
  userName: null | string
  businessRole: null | string
  email: null | string
  startDate: Date | null
  endDate: Date | null
  createdAt: Date | null
  updatedAt: Date | null
  deletedAt: Date | null
  companyEntity: CompanyEntity[]
  roleName?: string
  roleId?: string
  id?: string
}

export interface CompanyEntity {
  createdAt: Date
  updatedAt: Date
  deletedAt: null
  id: string
  companyName: string
  organizationNumber: string
  invoiceInfo: string
  subscription: number
  status: string
  activeModules: ActiveModule[]
}

export interface ActiveModule {
  id: number
  companyId: string
  startDate: Date
  endDate: Date
  isVisible: boolean
  module: Module
}

export interface Module {
  id: number
  name: string
}
export interface CreateUserDto {
  companyId: string[]
  email: string
  endDate: string
  roleBusiness: string
  roleId: string
  roleName: string
  startDate: string
  userName: string
}
export interface UpdateUserAdminDto {
  companyId: string[]
  email: string
  endDate: Date
  invitation: boolean
  name: string
  role: string
  roleId: string
  roleInBusiness: string
  startDate: Date
}
export interface UpdateUserDto {
  email: string
  invitation: boolean
  name: string
  role: string
  roleId: string
  roleInBusiness: string
}
export enum AdminPage {
  listBusinesses,
  users,
  history,
  updateBusiness,
  createBusiness,
  readBusiness,
  adminstration,
}

export interface HistoryLogDTO {
  id: number
  createdAt: Date
  message: string
}

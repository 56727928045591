import {
  Column,
  SectionContainer,
  Heading1,
  Subtitle,
  BodyText,
  useFootprintModule,
  Div,
  Heading4,
  FootprintTertiaryButton,
} from "modules/shared"
import React, { useState } from "react"
import {
  SimpleText,
  SimpleBodyText,
  UploadBox,
} from "./TransparencyFinishPageStyles"
import { ASSET_IMAGES } from "utils/constants/paths"
import { useLocation, useParams } from "react-router-dom"
import { downloadReportById } from "../../services/generateReport"
import useLocalesState from "utils/Localization/useLocalesState"
import { ActiveModule } from "modules/admin/types/adminApiTypes"
import { useStepsAPI } from "modules/steps/services/useStepsAPI"
import TransparencyResetConfirmationModal from "modules/transparencyAct/components/TransparencyResetCofirmationModal/TransparencyResetConfirmationModal"
import { useSelector } from "react-redux"
import { SystemRole } from "../../../authentication"

const TransparencyFinishPage = () => {
  const { companyId } = useParams()
  const [locales]: any = useLocalesState()
  const { activeModule } = useFootprintModule()
  const [isResetting, setisResetting] = useState(false)
  const { permissions } = useSelector((state: any) => state.roles)
  return (
    <>
      <SectionContainer
        sx={{
          paddingBottom: "120px",
          marginBottom: "80px",
        }}
      >
        <Column
          style={{
            justifyContent: "center",
            alignItems: "center",
            gap: "0px",
          }}
        >
          <SimpleText>
            {locales[`${activeModule()}_finishPage_preTitle`]}
          </SimpleText>
          <Heading1 sx={{ margin: "0px", textAlign: "center" }}>
            {locales[`${activeModule()}_finishPage_title`]}
          </Heading1>
          <BodyText sx={{ width: "648px", textAlign: "center" }}>
            {locales[`${activeModule()}_finishPage_description`]}
          </BodyText>
          <Subtitle>
            {" "}
            {locales[`${activeModule()}_finishPage_subtitle`]}
          </Subtitle>
        </Column>

        <Column
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <UploadBox style={{ marginTop: "10px" }}>
            <Column
              sx={{
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                padding: "0 10%",
              }}
              onClick={() => companyId && downloadReportById(companyId)}
            >
              <img src={`${ASSET_IMAGES}/pages/steps/imgCoat.png`}></img>
              <p style={{ textAlign: "center" }}>
                {" "}
                {locales[`${activeModule()}_finishPage_download`]}
              </p>
            </Column>
          </UploadBox>
          <div
            style={{
              width: "464px",
              fontFamily: "'Rubik'",
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: "13px",
              lineHeight: "15px",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            <strong style={{ fontWeight: "600" }}>
              {locales[`${activeModule()}_finishPage_note`]}:
            </strong>{" "}
            {locales[`${activeModule()}_finishPage_noteText`]}
          </div>
        </Column>
      </SectionContainer>
      <Div sx={{ height: "80px", backgroundColor: "#f1f3f3" }}></Div>
      <SectionContainer
        sx={{
          paddingBottom: "100px",
        }}
      >
        <Column
          style={{
            justifyContent: "center",
            alignItems: "center",
            gap: "0px",
          }}
        >
          {permissions.userRole === SystemRole.Admin && ( // Condition added here
            <>
              <Heading4>
                {locales[`${activeModule()}_finishPage_reset_preTitle`] ??
                  `Start på nytt`}
              </Heading4>
              <Heading1 sx={{ margin: "0px", textAlign: "center" }}>
                {locales[`${activeModule()}_finishPage_reset_title`] ??
                  `If you are willing to have a fresh start.`}
              </Heading1>
              <BodyText sx={{ width: "648px", textAlign: "center" }}>
                {locales[`${activeModule()}_finishPage_reset_title_desc`] ??
                  `You can remove
            all the filled data in order to re-write your answers to the report.
            this button will reset the earlier submitted data, but you will save
            your completed reports.`}
              </BodyText>
              <FootprintTertiaryButton
                style={{ color: "#78B785" }}
                onClick={() => setisResetting(true)}
              >
                {locales[`${activeModule()}_finishPage_reset_title_btn`] ??
                  "Reset information"}
              </FootprintTertiaryButton>
            </>
          )}
        </Column>
      </SectionContainer>
      <TransparencyResetConfirmationModal
        open={isResetting}
        onClose={() => {
          setisResetting(false)
        }}
      />
    </>
  )
}

export default TransparencyFinishPage

import { UPDATE_ROLES, UPDATE_PERMISSIONS } from "redux/constants/roles"
import { getUserPermissionsAndRolesById } from "modules/shared"
import { getUserDataFromToken } from "modules/authentication"
import { onFirstTime } from "./dashboard"
export const getRoles =
  (userId: any) =>
  (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    getUserPermissionsAndRolesById(userId)
      .then((response) => {
        if (response?.data?.roles) {
          dispatch(updateRoles(response?.data?.roles))
        } else {
          const userData: any = getUserDataFromToken()
          if (userData?.permissions) {
            dispatch(updateRoles(userData.permissions))
          }
        }
        if (response?.data?.permissions) {
          let permissions = response?.data?.permissions
          dispatch(updatePermissions(response?.data?.permissions))
          if (permissions.length > 0) {
            let firstLogin = permissions.some(
              (permission: { permission_name: string }) =>
                permission?.permission_name == "first_login",
            )
            if (firstLogin) {
              dispatch(onFirstTime())
            }
          }
        }
      })
      .catch(() => {
        const userData: any = getUserDataFromToken()
        if (userData?.permissions) {
          dispatch(updateRoles(userData.permissions))
        }
      })
  }

export const updateRoles = (roles: any) => ({
  type: UPDATE_ROLES,
  payload: roles,
})

export const updatePermissions = (permissions: any) => ({
  type: UPDATE_PERMISSIONS,
  payload: permissions,
})

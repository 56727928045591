import React from "react"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import { Link } from "react-router-dom"

interface Breadcrumb {
  name: string
  url: string
}

interface MyBreadcrumbsProps {
  breadcrumbs: Breadcrumb[]
}

const MyBreadcrumbs: React.FC<MyBreadcrumbsProps> = ({ breadcrumbs }) => {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{
        position: "relative",
        top: "23px",
        left: "112px",
        textDecoration: "none",
        color: "var(--BABABA, #BABABA)",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "normal",
      }}
    >
      {breadcrumbs.map((breadcrumb, index) => (
        <Link
          key={index}
          color="inherit"
          to={breadcrumb.url}
          style={{
            textDecoration: "none",
            color: "var(--BABABA, #BABABA)",
            fontSize: "13px",
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "normal",
          }}
        >
          {breadcrumb.name}
        </Link>
      ))}
    </Breadcrumbs>
  )
}

export default MyBreadcrumbs

import { Div } from "modules/shared"
import React from "react"
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags"
import { FootprintTertiaryButton } from "modules/shared"
import { FootprintPrimaryButton } from "modules/shared"
import { SimpleLoader } from "modules/shared"
import FlagIcon from "@mui/icons-material/Flag"
import useLocalesState from "utils/Localization/useLocalesState"
/**
 *
 * state: flagged | default | finished
 * onFlag: func
 * onFinish: func
 * isLoading: bool
 */
interface FlagButtonsProps {
  state: "flagged" | "default" | "finished"
  onFlag: VoidFunction
  onSubmit: VoidFunction
  isLoading?: boolean
  sx?: React.CSSProperties
  isSubmitDisabled?: boolean
}

const FlagButtons: React.FC<FlagButtonsProps> = (props) => {
  const { state, onFlag, onSubmit, isLoading } = props
  const [locales] = useLocalesState()
  if (isLoading) return <SimpleLoader></SimpleLoader>
  return (
    <Div
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignContent: "top",
        alignItems: "top",
        ...props.sx,
      }}
    >
      <Div
        sx={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          marginBottom: "12px",
        }}
      >
        {state == "flagged" ? (
          <FlagIcon sx={{ color: "red", marginRight: "18px" }} />
        ) : (
          <EmojiFlagsIcon sx={{ color: "#E6E6E6", marginRight: "18px" }} />
        )}
        <FootprintTertiaryButton
          style={{
            color: "red",
            cursor: state === "flagged" ? "not-allowed" : "pointer",
          }}
          onClick={state === "flagged" ? () => {} : onFlag}
        >
          {state == "flagged"
            ? locales["pages_steps_unflagButton"]
            : locales["pages_steps_flagButton"]}
        </FootprintTertiaryButton>
      </Div>

      <Div
        sx={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          cursor: props.isSubmitDisabled ? "not-allowed" : "pointer",
        }}
      >
        <FootprintPrimaryButton
          onClick={onSubmit}
          disabled={props.isSubmitDisabled}
          style={{ cursor: props.isSubmitDisabled ? "not-allowed" : "pointer" }}
          // textColor={state === "finished" ? "white !important" : undefined}
        >
          Lagre
        </FootprintPrimaryButton>
        {/* {state == "finished" ? (
            <CheckIcon sx={{ color: "#78B785", marginLeft: "10px" }} />
          ) : (
            <></>
          )} */}
      </Div>
    </Div>
  )
}

export default FlagButtons

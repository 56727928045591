import React from "react"
import Swal from "sweetalert2"
import useLocalesState from "./Localization/useLocalesState"

const { label_confirmation_ok, label_cancel } = useLocalesState as any

const textStyle = {
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: "17px",
  lineHeight: "23px",
  textAlign: "center",
  color: "#000000",
}

const buttonContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}
export function confirmVerifyAlert(title = "", desc = "", callback: Function) {
  Swal.fire({
    title: title,
    html: `<div style='${textStyle}'>${desc}</div>`,
    icon: "info",
    iconColor: "#78B785",
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonColor: "#000",
    cancelButtonColor: "#78B785",
    focusConfirm: false,
    confirmButtonText: label_confirmation_ok ?? "I got it",
    confirmButtonAriaLabel: "great!",
    cancelButtonText: label_cancel ?? "Cancel",
    cancelButtonAriaLabel: "cancel",
  }).then((result) => (callback ? callback(result) : null))
}
export const LoadingFileSweetAlert = () => {
  Swal.fire({
    title: "Vennligst vent mens filen laster ned.",
    text: "",
    icon: "info",
    iconColor: "#78B785",
    showConfirmButton: false,
    showCancelButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    didOpen: () => {
      Swal.showLoading()
    },
  })
}
export const closeSweetAlert = () => {
  Swal.close()
}

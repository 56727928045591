import { Div } from "modules/shared"
import { SplitView } from "modules/layout"
import { SectionContainer } from "modules/shared"
import React from "react"
import { HintTitle, SectionEightSideMenus } from "../IntroductionStyles"
import { Heading2, Paragraph } from "modules/shared"
import { FootprintScrollArrow } from "modules/shared"
export const Section5: React.FC<{
  locales?: any
  large?: boolean
  medium?: boolean
  small?: boolean
}> = ({ locales = {}, large, medium, small }) => {
  return (
    <SectionContainer
      id="page_manual_section5"
      sx={{
        p:
          (large && "84px 112px 0px") ||
          (medium && "81px 2.86vw 0px") ||
          (small && "81px 2.604vw 0px ") ||
          "84px 112px 0px",
      }}
    >
      <SplitView
        left={(medium && "500px") || (small && "360px") || "535px"}
        right={(medium && "504px") || (small && "368px") || "424px"}
        gap={(small && "16px") || "123px"}
      >
        <SectionEightSideMenus
          sx={{
            width: (medium && "416px") || (small && "304px") || "535px",
            height: (medium && "420px") || (small && "360px") || "420px",
          }}
        />
        <Div sx={{ margin: "auto" }}>
          <HintTitle sx={{ fontSize: (small && "15px") || "17px" }}>
            {locales["climate_page_intro_section5_hint"] ??
              "Hvordan få et klimaregnskap?"}
          </HintTitle>
          <Heading2
            sx={{
              m: 0,
              fontSize: (small && "26px") || "32px",
              lineHeight: "normal",
            }}
          >
            {locales["climate_page_intro_section5_title"] ??
              `Vi konverterer regnskapetfor deg.`}
          </Heading2>
          <Paragraph sx={{ m: "0px", fontSize: (small && "15px") || "17px" }}>
            {locales["climate_page_intro_section5_paragraph"] ??
              `Når du har fylt ut alle rapporteringskravene, så begynner å vi konvertere regnskapsdaten din til et klimaregnskap.\n\nNår klimaregnskapet ditt er ferdig laget, så får du et varsel til e-postadressen din.\n\nDu kan så logge deg inn på portalen og laste ned klimaregnskapet ditt som en PDF.\nHvis ønskelig kan vi også koble opp det dynamiske og mer omstendelige klimaregnskapet vi har laget for din virksomhet opp mot deres Power BI-konto.`}
          </Paragraph>
        </Div>
      </SplitView>
      <FootprintScrollArrow
        nextSelector="#page_manual_section6"
        style={{
          margin:
            (small && "60px auto") || (medium && "60px auto") || "80px auto",
        }}
      />
    </SectionContainer>
  )
}

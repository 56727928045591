export interface Permissions {
  canAccessAdminPage: boolean
  canCreateBusiness: boolean
  canCreateUser: boolean
  canDeleteBusiness: boolean
  canDeleteUser: boolean
  isFirstLogin: boolean
  canReadBusiness: boolean
  canReadSustainability: boolean
  canReadUser: boolean
  canUpdateBusiness: boolean
  canUpdateUser: boolean
  userRole: SystemRole
}

export enum SystemRole {
  Admin,
  Advisor,
  CompanyUser,
  CompanyAdmin,
  UNKNOWN,
}

import React from "react"
import useLocalesState from "utils/Localization/useLocalesState"
import { Spacing } from "modules/shared"
import useMediaQuery from "@mui/material/useMediaQuery"

import { Section1 } from "./components/Section1"
import { Section2 } from "./components/Section2"
import { Section3 } from "./components/Section3"
import { Section7 } from "./components/Section7"
import { Section4 } from "./components/Section4"
import { Section5 } from "./components/Section5"
import { Section6 } from "./components/Section6"
import { Section10 } from "./components/Section10"
import { Section8 } from "./components/Section8"
import { Section9 } from "./components/Section9"

const Manual = () => {
  const mediumScreenSize = useMediaQuery(
    "(max-width: 1440px) and (min-width: 1240px)",
  )
  const smallScreenSize = useMediaQuery("(max-width: 904px)")
  const LargeScreenSize = useMediaQuery("(min-width: 1440px)")

  const [locales] = useLocalesState()

  return (
    <>
      {/* // start Section one */}
      <Section1
        large={LargeScreenSize}
        medium={mediumScreenSize}
        small={smallScreenSize}
        locales={locales}
      />
      {/* // end Section one */}
      {/* //Split sections */}
      <Spacing />
      {/* //End split sections */}
      <Section2
        large={LargeScreenSize}
        medium={mediumScreenSize}
        small={smallScreenSize}
        locales={locales}
      />

      {/* // start Section two */}

      {/* // end Section two */}
      {/* ////////////////////////////////////////////////////////// */}
      <Spacing />
      {/* ////////////////////////////////////////////////////////// */}

      {/* // start Section three */}
      <Section3
        large={LargeScreenSize}
        medium={mediumScreenSize}
        small={smallScreenSize}
        locales={locales}
      />
      {/* // end Section three */}

      <Spacing />
      {/* // start Section four */}
      <Section4
        large={LargeScreenSize}
        medium={mediumScreenSize}
        small={smallScreenSize}
        locales={locales}
      />

      <Spacing />

      {/* // end Section four */}
      {/* // start Section Five */}
      <Section5
        large={LargeScreenSize}
        medium={mediumScreenSize}
        small={smallScreenSize}
        locales={locales}
      />
      {/* // end Section Five */}
      <Spacing />
      {/* // start Section 6 */}

      <Section6
        large={LargeScreenSize}
        medium={mediumScreenSize}
        small={smallScreenSize}
        locales={locales}
      />
      {/* // end Section 6*/}
      <Spacing />

      {/* // start Section 7 */}
      <Section7
        large={LargeScreenSize}
        medium={mediumScreenSize}
        small={smallScreenSize}
        locales={locales}
      />
      {/* // end Section 7 */}
      <Spacing />
      {/* // start Section 8 */}
      <Section8
        large={LargeScreenSize}
        medium={mediumScreenSize}
        small={smallScreenSize}
        locales={locales}
      />
      {/* // end Section 8 */}
      <Spacing />
      {/* // start Section 8 */}
      <Section9
        large={LargeScreenSize}
        medium={mediumScreenSize}
        small={smallScreenSize}
        locales={locales}
      />
      {/* // end Section 8 */}

      <Spacing />
      <Section10
        large={LargeScreenSize}
        medium={mediumScreenSize}
        small={smallScreenSize}
        locales={locales}
      />
      <Spacing />
    </>
  )
}

export default Manual

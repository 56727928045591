import React, { FunctionComponent } from "react"
import { Div } from "modules/shared"
import { ASSET_IMAGES } from "utils/constants/paths"

interface LogoProps {
  mode?: "light" | "dark"
  sx?: any
  insideApp?: boolean
  handleClick?: VoidFunction
}

const Logo: FunctionComponent<LogoProps> = (props) => {
  const { mode, sx, insideApp, handleClick } = props
  return (
    <Div
      sx={{ display: "inline-flex", ...sx }}
      onClick={() => (insideApp && handleClick ? handleClick() : null)}
    >
      <img
        src={
          mode === "light"
            ? `${ASSET_IMAGES}/logos/logo.svg`
            : `${ASSET_IMAGES}/logos/logo-light.svg`
        }
        alt="Amesto Footprint "
        data-testid="logo-img"
      />
    </Div>
  )
}

Logo.defaultProps = {
  mode: "light",
}

export default Logo

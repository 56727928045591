import styled from "@emotion/styled"
import { Column } from "modules/shared"
export const AccordionDetailsContainer = styled(Column)({
  padding: "5.35vw 7.77vw",
  gap: "1.67vw",
})

export const SimpleText = styled("div")({
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: "17px",
  lineHeight: "21px",
  /* identical to box height, or 124% */
  textAlign: "center",
  letterSpacing: "2px",
  textTransform: "uppercase",
})

export const SimpleBodyText = styled("div")({
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: "17px",
  lineHeight: "21px",
  /* identical to box height, or 124% */
  textAlign: "center",
  letterSpacing: "2px",
})
export const Stepper = styled("div")({
  display: "flex",
  position: "relative",
  alignItems: "center",
  alignContent: "center",
})

export const Step = styled("div")({
  height: "2px",
  width: "100px",
  background: "#233E4C",
  position: "relative",
  display: "flex",
  alignItems: "center",
  alignContent: "center",
})

export const StepBall = styled("div")({
  background: "#1C1C1C",
  borderRadius: "30px",
  position: "absolute",
  height: "10px",
  width: "10px",
  zIndex: 3,
})

export const DownloadButton = styled(Column)({
  backgroundColor: "#F1F3F3",
  width: "14.28vw",
  height: "14.28vw",
  cursor: "pointer",
  justifyContent: "center",
  alignItems: "center",
})
export const UploadBox = styled("div")({
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "17px",
  lineHeight: "24px",
  letterSpacing: "2px",
  color: "#78B785",
  textTransform: "uppercase",
  width: "312px",
  height: "312px",
  background: "#F1F3F3",
  cursor: "pointer",
})

import React, { useEffect, useState } from "react"
import { Div } from "modules/shared"
import useLocalesState from "utils/Localization/useLocalesState"
import { Span } from "modules/shared"
import * as yup from "yup"
import { Form, Formik } from "formik"
import { FootprintTextFiledProfileStyle } from "modules/shared"
import { Button, Grid, TextField } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import { TextContainerTitle } from "./ProfilePageStyle"
import { getUserDataById } from "modules/shared"
import { changeUSerProfileDataById } from "./services/user"
import { getUserDataFromToken } from "modules/authentication"
import { useDispatch, useSelector } from "react-redux"
import { SystemRole } from "modules/authentication"
import { resetCompanyReduxData } from "redux/actions/company"
import { useFootprintAuth } from "modules/authentication"

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  name: yup.string().required("Name is required"),
})
const mutation = { isError: false }

const Profile = () => {
  const [locales] = useLocalesState()
  const { setLogout } = useFootprintAuth() as any
  const [formData, setFormData] = useState<any>()
  const [userPrivateId, setUserId] = useState()
  const { permissions } = useSelector((state: any) => state.roles)
  const dispatch = useDispatch()
  const medium = useMediaQuery("(max-width: 1440px) and (min-width: 1240px)")
  const small = useMediaQuery("(max-width: 904px)")
  const large = useMediaQuery("(min-width: 1440px)")
  useEffect(() => {
    const userData = getUserDataFromToken()
    if (userData?.sub) {
      setUserId(userData.sub.split("|")[1])
    }
    getUserData(userData.sub.split("|")[1])
  }, [])
  const getUserData = (userId: any) => {
    getUserDataById(userId)
      .then((response) => {
        response?.data && setFormData(response.data)
      })
      .catch((e) => {})
  }
  const getUserRole = () => {
    if (permissions?.userRole === SystemRole.Admin) {
      return "SYSTEMADMINISTRATOR"
    } else if (permissions?.userRole === SystemRole.Advisor) {
      return "ADVISOR"
    } else if (permissions?.userRole === SystemRole.CompanyAdmin) {
      return "ADMINISTRATOR"
    } else {
      return "BRUKER"
    }
  }
  return (
    <Div
      sx={
        large
          ? { p: "100px 224px 156px" }
          : medium
            ? { p: "80px 132px 100px" }
            : small
              ? { p: "60px 40px 80px" }
              : { p: "2%" }
      }
    >
      {/* //header title */}
      <Div
        sx={{
          textAlign: "center",
          fontWeight: 800,
          lineHeight: "normal",
          fontSize: (small && "38px") || "45px",
        }}
      >
        {locales["pages_profile_main_section_title"] ?? "Min profil"}
      </Div>
      <Div
        sx={{
          textAlign: "end",
          marginBottom: "20px",
        }}
      >
        <Span
          sx={{
            color: "#78B785",
            fontSize: "14px",
            fontWeight: 400,
            textAlign: "right",
            textTransform: "uppercase",
            textDecoration: "underline",
            textUnderlineOffset: "8px",
            textDecorationThickness: "1px",
            cursor: "pointer",
            ":hover": {
              textDecorationColor: "#CDE6D2",
            },
          }}
          onClick={() => {
            dispatch(resetCompanyReduxData())
            setLogout("profileLogout")
          }}
        >
          {locales["pages_profile_logout"] ?? "logg ut"}
        </Span>
      </Div>
      <Div
        sx={{
          background: "#F1F3F3",
          p:
            (large && "10px 112px 120px") ||
            (medium && "10px 88px 100px") ||
            (small && "10px 64px 80px") ||
            "10px 112px 120px",
        }}
      >
        <Div id="form-group">
          <Formik
            enableReinitialize
            validateOnChange={true}
            initialValues={{
              name: formData?.name ?? "",
              email: formData?.email ?? "",
              phone: formData?.user_metadata?.phoneNumber ?? "",
              businessWork: formData?.user_metadata?.businessWork ?? "",
              companyHub: formData?.user_metadata?.CompanyHub ?? "",
              organizationNumber:
                formData?.user_metadata?.organizationNumber ?? "",
              invoiceNumber: formData?.user_metadata?.invoiceNumber ?? "",
              activeModule: "",
              role: formData?.user_metadata?.role ?? "",
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => {
              changeUSerProfileDataById(userPrivateId, data)
                .then(() => {
                  setSubmitting(false)
                  getUserData(userPrivateId)
                })
                .catch(() => setSubmitting(false))
            }}
          >
            {({ isSubmitting, resetForm, isValid, dirty }) => (
              <Form noValidate autoComplete="off">
                <Div>
                  {(permissions?.userRole == SystemRole.CompanyAdmin ||
                    permissions?.userRole == SystemRole.CompanyUser) && (
                    <>
                      <Div
                        id="title-two"
                        sx={{
                          mt:
                            (large && "80px") ||
                            (medium && "60px") ||
                            (small && "36") ||
                            "60px",
                          mb: "20px",
                          fontSize: (small && "32px") || "26px",
                        }}
                      >
                        <TextContainerTitle>
                          {locales["pages_profile_title_form_second_title"] ??
                            "Om virksomheten du jobber for"}
                        </TextContainerTitle>
                      </Div>
                      {/* //title with button */}
                      <Span
                        sx={{
                          fontWeight: 500,
                          fontSize: "17px",
                          lineHeight: "23px",
                        }}
                      >
                        {locales["pages_profile_title_active_module"] ??
                          "Aktive moduler"}
                      </Span>
                      <Div
                        sx={{
                          display: "flex",
                          textAlign: "center",
                          alignItems: "center",
                          mb: "20px",
                        }}
                      >
                        <Div
                          sx={{
                            width: "140px",
                            height: "31px",
                            background: "#DFE3E6",
                            fontWeight: 300,
                          }}
                        >
                          <Span sx={{ verticalAlign: "sub" }}>
                            {locales["pages_profile_title_Transparency"] ??
                              "Åpenhetsloven"}
                          </Span>
                        </Div>
                        <Div
                          sx={{
                            ml: "15px",
                            width: "140px",
                            height: "31px",
                            background: "#DFE3E6",
                            fontWeight: 300,
                          }}
                        >
                          <Span sx={{ verticalAlign: "sub" }}>
                            {locales[
                              "pages_profile_title_climate_accounting"
                            ] ?? "Klimaregnskap"}
                          </Span>
                        </Div>
                      </Div>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FootprintTextFiledProfileStyle
                            fullWidth
                            name="companyHub"
                            label={
                              locales[
                                "pages_profile_title_form_second_companyName"
                              ] ?? "Virksomhetens navn"
                            }
                          />
                        </Grid>

                        {permissions?.userRole == SystemRole.CompanyAdmin && (
                          <Grid item xs={12}>
                            <FootprintTextFiledProfileStyle
                              fullWidth
                              name="organizationNumber"
                              label={
                                locales[
                                  "pages_profile_title_form_second_organizationNumber"
                                ] ?? "Organisasjonsnummer"
                              }
                            />
                          </Grid>
                        )}
                        {permissions?.userRole == SystemRole.CompanyAdmin && (
                          <Grid item xs={12}>
                            <FootprintTextFiledProfileStyle
                              fullWidth
                              name="invoiceNumber"
                              label={
                                locales[
                                  "pages_profile_title_form_second_billingAddress"
                                ] ?? "Fakturaadresse"
                              }
                            />
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}
                  <Div
                    id="title-one"
                    sx={{
                      mt: "68px",
                      mb: "20px",
                      fontSize: (small && "32px") || "26px",
                    }}
                  >
                    <TextContainerTitle>
                      {locales["pages_profile_title_form_first_title"] ??
                        "Om deg"}
                    </TextContainerTitle>
                  </Div>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Span
                        sx={{
                          fontWeight: 300,
                          fontSize: "17px",
                          lineHeight: "23px",
                        }}
                      >
                        {"Systemtilgang"}
                      </Span>

                      <TextField
                        fullWidth
                        size="small"
                        sx={{
                          "& .MuiInputBase-root": {
                            borderRadius: "0px",
                          },
                          "& input": {
                            textTransform: "uppercase",
                          },
                        }}
                        value={getUserRole()}
                        disabled
                        label=""
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FootprintTextFiledProfileStyle
                        fullWidth
                        name="name"
                        label={
                          locales["pages_profile_title_form_first_name"] ??
                          "Ditt navn"
                        }
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FootprintTextFiledProfileStyle
                        fullWidth
                        disabled
                        name="email"
                        label={
                          locales["pages_profile_title_form_first_email"] ??
                          "E-postadresse"
                        }
                        sx={{
                          "& input": {
                            background: "transparent",
                            textTransform: "uppercase",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FootprintTextFiledProfileStyle
                        fullWidth
                        name="phone"
                        label={
                          locales["pages_profile_title_form_first_phone"] ??
                          "Telefonnummer"
                        }
                      />
                    </Grid>
                    {permissions?.userRole == SystemRole.CompanyAdmin && (
                      <Grid item xs={12}>
                        <FootprintTextFiledProfileStyle
                          fullWidth
                          name="businessWork"
                          label={
                            locales["pages_profile_title_form_first_workFor"] ??
                            "Virksomheten du jobber for"
                          }
                        />
                      </Grid>
                    )}
                    {permissions?.userRole == SystemRole.CompanyAdmin && (
                      <Grid item xs={12}>
                        <FootprintTextFiledProfileStyle
                          fullWidth
                          name="role"
                          label={
                            locales[
                              "pages_profile_title_form_first_jobTitle"
                            ] ?? "Din rolle i virksomheten"
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Div
                    sx={{
                      mt:
                        (large && "80px") ||
                        (medium && "60px") ||
                        (small && "36") ||
                        "60px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant={"contained"}
                      disableElevation
                      disableRipple
                      disableFocusRipple
                      type="submit"
                      sx={{
                        // background: "#000000" /* default color */,
                        mb: "12px",
                        width: "215px",
                        height: "48px",
                        fontWeight: 400,
                        fontSize: "14px",
                        letterSpacing: "2px",
                        background:
                          "linear-gradient(to right, #233E4C 50%, #ffffff 50%) left",
                        backgroundSize: "200%",
                        transition: ".5s ease-out",
                        "&:hover": {
                          backgroundPosition: "right",
                          color: "#000000",
                          backgroundColor: "transparent",
                          border: "1px solid #000000",
                        },
                        "&.Mui-disabled": {
                          background: "#EFECE9",
                          color: "#ffffff",
                        },
                      }}
                      disabled={!(isValid && dirty)}
                      size="large"
                    >
                      {locales["pages_profile_title_form_button_submit"] ??
                        "Lagre endringer"}
                    </Button>

                    <Span
                      sx={{
                        color: "#78B785",
                        fontSize: "14px",
                        fontWeight: 400,
                        textTransform: "uppercase",
                        textDecoration: "underline",
                        textUnderlineOffset: "8px",
                        textDecorationThickness: "1px",
                        cursor: "pointer",
                      }}
                      onClick={() => resetForm()}
                    >
                      {locales["pages_profile_title_form_button_cancel"] ??
                        "Avbryt"}
                    </Span>
                  </Div>
                </Div>
              </Form>
            )}
          </Formik>
        </Div>
      </Div>
    </Div>
  )
}

export default Profile

import { styled } from "@mui/system"

export const Heading1 = styled("h1")({
  fontStyle: "normal",
  fontWeight: "800",
  fontSize: "45px",
  lineHeight: "normal",
  margin: 0,
})

export const Heading2 = styled("h2")({
  fontStyle: "normal",
  fontWeight: "800",
  fontSize: "32px",
  lineHeight: "34px",
  margin: 0,
})

export const Heading3 = styled("h3")({
  fontFamily: "'Rubik'",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "17px",
  lineHeight: "24px",
  letterSpacing: "2px",
  margin: 0,
})

export const Heading4 = styled("h4")({
  fontFamily: "'Rubik'",
  fontStyle: "normal",
  fontWeight: "300",
  fontSize: "17px",
  lineHeight: "21px",
  letterSpacing: "2px",
  margin: 0,
})

export const Subtitle = styled("span")({
  fontFamily: "'Rubik'",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "17px",
  lineHeight: "23px",
})

export const BodyText = styled("p")({
  fontFamily: "'Rubik'",
  fontStyle: "normal",
  fontWeight: "300",
  fontSize: "17px",
  lineHeight: "23px",
})

export const BodyText2 = styled("p")({
  fontFamily: "'Rubik'",
  fontStyle: "normal",
  fontWeight: "300",
  fontSize: "14px",
  lineHeight: "17px",
})

export const Caption = styled("p")({
  fontFamily: "'Rubik'",
  fontStyle: "normal",
  fontWeight: "300",
  fontSize: "13px",
  lineHeight: "15px",
  letterSpacing: "0.02em",
})

export const Paragraph = styled("pre")({
  width: "100%",
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: "300",
  fontSize: "17px",
  lineHeight: "23px",
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  overflowWrap: "break-word",
})

import { Div, Heading2 } from "modules/shared"
import React from "react"
import { SplitView } from "modules/layout"
import { FootprintScrollArrow, Paragraph } from "modules/shared"
import { ASSET_IMAGES } from "utils/constants/paths"
import { Stack } from "@mui/material"
import { HintTitle } from "../RoadHeadStyles"
export const Section3: React.FC<{
  locales?: any
  large?: boolean
  medium?: boolean
  small?: boolean
}> = ({ locales = {}, large, medium, small }) => {
  return (
    <Div id="page_road_head_section3">
      {/* //Section three */}
      <Div
        sx={{
          p:
            (large && "100px 112px 0px") ||
            (medium && "113px 40px 0px") ||
            (small && "83px 40px 0px ") ||
            "83px 40px 0px",
        }}
      >
        <SplitView
          left={(medium && "504px") || (small && "368px") || "536px"}
          right={(medium && "416px") || (small && "368px") || "424px"}
          gap={(small && "16px") || (medium && "123px") || "136px"}
        >
          <Div>
            <img
              width={
                (large && "536px") ||
                (medium && "504px") ||
                (small && "368px") ||
                "368px"
              }
              height={
                (large && "480px") ||
                (medium && "480px") ||
                (small && "350px") ||
                "350px"
              }
              src={`${ASSET_IMAGES}/pages/climate/roadHead/roadHeadSection3-img.png`}
              alt="climate_report_roadHead_section3"
            />
          </Div>
          <Div sx={{ margin: "auto" }}>
            <HintTitle>
              {locales["climate_page_roadHead_section3_hint"] ??
                "Praktisk Informasjon"}
            </HintTitle>
            <Heading2 sx={{ m: 0, fontSize: (small && "26px") || "32px" }}>
              {locales["climate_page_roadHead_section3_title"] ??
                "Hvordan bruke klimaregnskapet?"}
            </Heading2>
            <Paragraph
              sx={{
                m: "0px",
                fontSize: (small && "15px") || "17px",
                display: "flex",
                alignItems: "baseline",
              }}
              dangerouslySetInnerHTML={{
                __html:
                  locales["climate_page_roadHead_section3_item1_paragraph"] ??
                  `<span>&bull;</span><span>Til å identifisere de viktigste kildene til utslipp i sin virksomhet, og prioritere reduksjonstiltak.</span>`,
              }}
            ></Paragraph>
            <Paragraph
              sx={{
                m: "0px",
                fontSize: (small && "15px") || "17px",
                display: "flex",
                alignItems: "baseline",
                mt: "5px",
              }}
              dangerouslySetInnerHTML={{
                __html:
                  locales["climate_page_roadHead_section3_item2_paragraph"] ??
                  `\n<span>&bull;</span><span>Til å sette mål for å redusere sine utslipp i tråd med nasjonale og internasjonale klimamål, som Parisavtalen.</span>`,
              }}
            ></Paragraph>
            <Paragraph
              sx={{
                m: "0px",
                fontSize: (small && "15px") || "17px",
                display: "flex",
                alignItems: "baseline",
                mt: "5px",
              }}
              dangerouslySetInnerHTML={{
                __html:
                  locales["climate_page_roadHead_section3_item3_paragraph"] ??
                  `\n<span>&bull;</span><span>Til å sette mål for å redusere sine utslipp i tråd med nasjonale og internasjonale klimamål, som Parisavtalen</span>`,
              }}
            ></Paragraph>
            <Paragraph
              sx={{
                m: "0px",
                fontSize: (small && "15px") || "17px",
                display: "flex",
                alignItems: "baseline",
                mt: "5px",
              }}
              dangerouslySetInnerHTML={{
                __html:
                  locales["climate_page_roadHead_section3_item4_paragraph"] ??
                  `\n<span>&bull;</span><span>Til å innovere løsninger for å redusere sine utslipp og skape nye muligheter for vekst og verdiskaping.</span>`,
              }}
            ></Paragraph>
            <Paragraph
              sx={{
                m: "0px",
                fontSize: (small && "15px") || "17px",
                display: "flex",
                alignItems: "baseline",
                mt: "5px",
              }}
              dangerouslySetInnerHTML={{
                __html:
                  locales["climate_page_roadHead_section3_item5_paragraph"] ??
                  `\n<span>&bull;</span><span>Til å kommunisere resultater og klimapåvirkning til interessenter, som kunder og investorer.</span>`,
              }}
            ></Paragraph>

            <Paragraph
              sx={{
                m: "0px",
                fontSize: (small && "15px") || "17px",
                display: "flex",
                alignItems: "baseline",
                mt: "5px",
              }}
              dangerouslySetInnerHTML={{
                __html:
                  locales["climate_page_roadHead_section3_item6_paragraph"] ??
                  `\n<span>&bull;</span><span>Til å sammenligne klimaprestasjon med andre virksomheter.</span>`,
              }}
            ></Paragraph>
          </Div>
        </SplitView>
        <Stack
          alignItems="center"
          sx={{ my: (large && "80px") || "60px", p: 0 }}
        >
          <FootprintScrollArrow nextSelector="#page_road_head_section4" />
        </Stack>
      </Div>
    </Div>
  )
}

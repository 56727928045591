import * as React from "react"
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid"
import LinearProgress from "@mui/material/LinearProgress"
import { Div } from "modules/shared"
import { SxProps } from "@mui/system"
interface DataTableProps {
  rows: any[]
  columns: GridColDef[]
  isLoading?: boolean
  sx?: SxProps
  setPaginationModel?: any
  rowCount?: number
  getCellClassName?: any
}
export default function DataTable(props: DataTableProps) {
  return (
    <Div sx={{ height: 650, width: "100%", ...props.sx }}>
      <DataGrid
        rows={props.rows}
        columns={props.columns}
        paginationMode="server"
        disableColumnMenu
        disableSelectionOnClick
        getCellClassName={props.getCellClassName}
        pagination={true}
        sx={{
          "& .MuiDataGrid-cell:focus": {
            outline: " none",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: " none",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "inherit", // Or 'transparent' or whatever color you'd like
          },
        }}
        onPageChange={props.setPaginationModel}
        pageSize={10}
        components={{
          LoadingOverlay: LinearProgress,
        }}
        loading={props.isLoading}
        rowCount={props.rowCount}
      />
    </Div>
  )
}

import {
  Caption,
  Div,
  FootprintPrimaryButton,
  Heading3,
  ReactQueryKeys,
  SimpleLoader,
} from "modules/shared"
import React, { useContext, useEffect, useState } from "react"
import useLocalesState from "utils/Localization/useLocalesState"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js"
import { Line } from "react-chartjs-2"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
import ChartsContext from "../ChartsContext"
import { useClimateBudgetApi } from "../../../../../services/useClimateBudgetApi"
import { Else, If, Then, When } from "react-if"
import { useSelector } from "react-redux"
import { ChartsColorGenerator } from "modules/climateModule/services/ChartsColorGenerator"
import { useQuery } from "react-query"
import NoDataAvailable from "./NoDataAvailable"
import { useParams } from "react-router-dom"

type TableRow = {
  name: string
  curYear: number
  prevYear: number
  goal: number
  usage: number
}

function createData(
  name: string,
  curYear: number,
  prevYear: number,
  goal: number,
  usage: number,
) {
  return { name, curYear, prevYear, goal, usage }
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
)
interface BudgetProps {
  notShowDownloadButton?: boolean
}
const Budget = (props: BudgetProps) => {
  const chartsContext = useContext(ChartsContext)
  const [locales]: any = useLocalesState()
  const {
    getTotalScopesInLastTwoYears,
    getTotalScopesInLastTwoYearsByActivityGroups,
  } = useClimateBudgetApi()
  const pdfRef = React.useRef<any>()
  const [chart1Data, setchart1Data] = useState<any | null>()
  const [rows, setRows] = useState<TableRow[] | null>()
  const [tableTotalsRow, settableTotalsRow] = useState<TableRow | null>()
  const { companyId } = useParams()

  const {
    isLoading: ischart1DataLoading,
    error: chart1DataError,
    data: chart1DataResponse,
  } = useQuery(
    [
      ReactQueryKeys.climateBudgetChart1,
      companyId,
      chartsContext.reportingYear,
    ],
    () => getTotalScopesInLastTwoYears(chartsContext.reportingYear),
    { refetchOnMount: false },
  )

  const {
    isLoading: istableDataLoading,
    error: tableDataError,
    data: tableDataResponse,
  } = useQuery(
    [ReactQueryKeys.climateBudgetTable, companyId, chartsContext.reportingYear],
    () =>
      getTotalScopesInLastTwoYearsByActivityGroups(chartsContext.reportingYear),
    { refetchOnMount: false },
  )

  const lineOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      scales: {
        y: {
          display: true,
          title: {
            display: true,
            text: "Co\u2082 Emission",
          },
        },
      },
      legend: {
        position: "top" as const,
      },
    },
  }

  const fillChart1Data = () => {
    if (chart1DataResponse?.status == 204) {
      setchart1Data(null)
      return
    }
    const data = chart1DataResponse!.data
    const lastYear = data[0]
    const curYear = data[1] //nullable

    const lineLabels = [
      locales["climate_charts_budget_chart1_label1"] ?? "Previous year",
      locales["climate_charts_budget_chart1_label2"] ?? "Current year",
    ]

    const dataSets: any[] = lastYear.scopes.map((scope, idx) => {
      return {
        fill: true,
        label: scope.scope,
        data: [scope.total, curYear?.scopes[idx]?.total ?? null],
        borderColor: ChartsColorGenerator.instance.getColor(scope.scope)
          .mainColor,
        backgroundColor: ChartsColorGenerator.instance.getColor(scope.scope)
          .backgroundColor,
      }
    })

    dataSets.push({
      fill: false,
      label: "Goal trend",
      data: [lastYear.totalGoals, curYear?.totalGoals ?? null],
      borderColor: "#78B785",
    })

    const chartData = {
      labels: lineLabels,
      datasets: dataSets,
    }
    console.log("CliamteBudget chart1Data", chartData)

    setchart1Data(chartData)
  }

  const fillTableData = () => {
    if (tableDataResponse?.status == 204) {
      setRows(null)
      settableTotalsRow(null)
      console.log("TableData", "null")
      return
    }
    const data = tableDataResponse!.data
    const emissions = data.emissions
    const emissionRows = emissions.map((emission) =>
      createData(
        emission.activityGroup,
        emission.activityGroupCurrentYearTotal,
        emission.activityGroupPrevYearTotal,
        emission.activityGroupGoal,
        emission.activityGroupEmissionUsagePercentage,
      ),
    )
    setRows(emissionRows)
    settableTotalsRow(
      createData(
        "Total",
        data.currentYearTotalEmission,
        data.prevYearTotalEmission,
        data.totalGoal,
        data.totalEmissionUsagePercentage,
      ),
    )
  }

  const downloadPdf = async () => {
    const pdf = new jsPDF({
      format: "a4", // Use A4 format
    })

    const pdfElement = pdfRef.current

    // Use html2canvas to capture the HTML content as an image
    const canvas = await html2canvas(pdfElement)

    // Get the dimensions of the A4 page
    const a4Width = 210
    const a4Height = 297

    // Calculate the scaling factor for the captured image
    const scaleFactor = Math.min(
      a4Width / canvas.width,
      a4Height / canvas.height,
    )

    // Calculate the new dimensions of the captured image
    const scaledWidth = canvas.width * scaleFactor
    const scaledHeight = canvas.height * scaleFactor

    // Calculate the center position on the A4 page
    const xPosition = (a4Width - scaledWidth) / 2
    const yPosition = (a4Height - scaledHeight) / 2

    // Add the captured image to the PDF
    pdf.addImage(
      canvas.toDataURL("image/png"),
      "PNG",
      xPosition,
      yPosition,
      scaledWidth,
      scaledHeight,
    )

    // Generate PDF from the HTML content
    pdf.save("klimabudsjett.pdf")
  }

  useEffect(() => {
    if (!ischart1DataLoading) fillChart1Data()
    if (!istableDataLoading) fillTableData()
  }, [
    ischart1DataLoading,
    chart1DataResponse,
    istableDataLoading,
    tableDataResponse,
  ])

  return (
    <Div>
      <Div ref={pdfRef} sx={{ canvas: { background: "white" } }}>
        <Div sx={{ textAlign: "center", py: "18px" }}>
          <Heading3
            sx={{ color: "var(--B0B9C0, #B0B9C0)", textTransform: "uppercase" }}
          >
            {locales["climate_charts_reportingYear"] ?? "Rapporteringsår"}{" "}
            {chartsContext.reportingYear}
          </Heading3>
        </Div>

        <Div
          sx={{
            py: "24px",
            backgroundColor: "var(--F1F3F3, #F1F3F3)",
            display: "grid",
            gridTemplateColumns: "auto auto ",
            gap: "24px",
          }}
        >
          <Div
            sx={{
              background: "white",
              width: "638px",
              height: "460px",
              padding: "20px",
            }}
          >
            <Heading3 sx={{ textAlign: "center" }}>
              {locales["climate_charts_budget_chart1_title"]}
            </Heading3>
            <If condition={!ischart1DataLoading}>
              <Then>
                <When condition={chart1Data}>
                  <Line
                    options={{
                      ...lineOptions,
                      scales: {
                        y: {
                          display: true,
                          title: {
                            display: true,
                            text: "Co\u2082 Emission",
                          },
                        },
                      },
                    }}
                    data={chart1Data}
                    height="355px"
                    width="583px"
                    style={{ marginTop: "39px" }}
                  />
                </When>
                <When condition={chart1Data == null}>
                  <Div sx={{ textAlign: "center", mt: "150px" }}>
                    <NoDataAvailable></NoDataAvailable>
                  </Div>
                </When>
              </Then>
              <Else>
                <SimpleLoader></SimpleLoader>
              </Else>
            </If>
          </Div>
          <Div
            sx={{
              background: "white",
              width: "638px",
              height: "460px",
            }}
          >
            <TableContainer
              sx={{
                padding: " 24px",
                // paddingBottom: "44px",
                width: "100%",
                overflow: "hidden",
              }}
            >
              <Heading3 sx={{ textAlign: "center" }}>
                {locales["climate_charts_budget_chart2_title"]}
              </Heading3>

              <If condition={typeof rows != undefined}>
                <Then>
                  <When condition={rows != null}>
                    <Table
                      sx={{
                        minWidth: "568px",
                        p: "0px",
                        m: "0px",
                        mt: "14px",
                        "& td": { border: 0 },
                        "& .MuiTableCell-root": {
                          padding: "2px",
                          borderBottom: "none", // Remove bottom border for all table cells
                        },
                        "th,td": {
                          borderLeft: "1px solid var(--F1F3F3, #F1F3F3)",
                          textAlign: "start",
                        },
                      }}
                      aria-label="simple table"
                    >
                      <TableHead
                        sx={{
                          borderBottom: "1px solid var(--F1F3F3, #F1F3F3)",
                          fontSize: "11px",
                          fontStyle: "normal",
                          fontWeight: 700,
                          letterSpacing: "0.22px",
                          lineHeight: "normal",
                        }}
                      >
                        <TableRow
                          sx={{
                            "th:first-child": {
                              borderLeft: "0",
                            },
                            "th:last-child": {
                              width: "151px",
                            },
                          }}
                        >
                          <TableCell>Activity Group</TableCell>
                          <TableCell align="right">Current year</TableCell>
                          <TableCell align="right">Previous Year</TableCell>
                          <TableCell align="right">Goal</TableCell>
                          <TableCell align="right">
                            % Usage of Emission Budget
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody
                        sx={{
                          fontSize: "12px",
                          fontWeight: 300,
                          letterSpacing: "0.24px",
                        }}
                      >
                        {rows?.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              "th:first-child": {
                                borderLeft: "0",
                              },
                              "td , th": {
                                fontFamily: "Rubik",
                                fontsize: "12px",
                                fontStyle: "normal",
                                fontWeight: "300",
                                lineHeight: "normal",
                                letterSpacing: "0.24px",
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="right">
                              {row.curYear.toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              {row.prevYear.toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              {row.goal.toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              {row.usage.toFixed(2)}%
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow
                          sx={{
                            borderTop: "1px solid var(--F1F3F3, #F1F3F3)",
                            "th:first-child": {
                              borderLeft: "0",
                            },
                            td: {
                              fontFamily: "Rubik",
                              fontsize: "12px",
                              fontStyle: "normal",
                              fontWeight: "300",
                              lineHeight: "normal",
                              letterSpacing: "0.24px",
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {tableTotalsRow?.name}
                          </TableCell>
                          <TableCell align="right">
                            {tableTotalsRow?.curYear.toFixed(2)}
                          </TableCell>
                          <TableCell align="right">
                            {tableTotalsRow?.prevYear.toFixed(2)}
                          </TableCell>
                          <TableCell align="right">
                            {tableTotalsRow?.goal.toFixed(2)}
                          </TableCell>
                          <TableCell align="right">
                            {tableTotalsRow?.usage.toFixed(2)}%
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </When>
                  <When condition={rows === null}>
                    <Div sx={{ textAlign: "center", mt: "150px" }}>
                      <NoDataAvailable></NoDataAvailable>
                    </Div>
                  </When>
                </Then>
                <Else>
                  <SimpleLoader></SimpleLoader>
                </Else>
              </If>
            </TableContainer>
          </Div>
        </Div>
      </Div>
      {!props.notShowDownloadButton && (
        <Div sx={{ background: "var(--F1F3F3, #F1F3F3)", textAlign: "center" }}>
          <FootprintPrimaryButton
            overrideColor="#78B785"
            style={{
              color: "#78B785",
              fontWeight: 500,
              fontSize: "14px",
              backgroundImage: `linear-gradient(to right, #ffffff 50%, #78B785 50%)`,
            }}
            onClick={() => downloadPdf()}
          >
            {locales["climate_charts_downloadTab"] ?? "Last ned utvalg"}
          </FootprintPrimaryButton>
        </Div>
      )}
    </Div>
  )
}
export default Budget

import React, { useState } from "react"
import { Div } from "modules/shared"
import useLocalesState from "utils/Localization/useLocalesState"
import Box from "@mui/material/Box"
import TabContext from "@mui/lab/TabContext"
import TabPanel from "@mui/lab/TabPanel"
import { ASSET_IMAGES } from "utils/constants/paths"
import {
  TabHeader,
  TabItem,
} from "modules/admin/components/TabStyles/TabHeaderStyle"
import { Heading4 } from "modules/shared"
import Companies from "modules/admin/components/Companies/Companies"
import { Users } from "modules/admin/components/Users/Users"
import History from "modules/admin/components/History/History"
import ViewCompany from "modules/admin/components/ViewCompany/ViewCompany"
import { AdminPage } from "modules/admin/types/adminApiTypes"
import Adminstration from "./components/Adminstration/Adminstration"
import { useSelector } from "react-redux"
import { SystemRole } from "modules/authentication"

export const AdminContext = React.createContext({
  activeTab: "0",
  activePage: AdminPage.listBusinesses,
  changeActivePage: (page: AdminPage, params?: any) => {},
  params: {},
})

const Admin = () => {
  const [locales]: any = useLocalesState()
  const [activeTab, setActiveTab] = React.useState("0")
  const [activePage, setActivePage] = useState<AdminPage>(
    AdminPage.listBusinesses,
  )
  const [pageParams, setpageParams] = useState({})
  const { permissions } = useSelector((state: any) => state.roles)

  const handleChange = (event: any, newValue: string) => {
    setActiveTab(newValue)
    switch (newValue) {
      case "0":
        setActivePage(AdminPage.listBusinesses)
        break
      case "1":
        setActivePage(AdminPage.users)
        break
      case "2":
        setActivePage(AdminPage.history)
        break
      case "4":
        setActivePage(AdminPage.adminstration)
        break
      default:
        setActivePage(AdminPage.listBusinesses)
        break
    }
  }

  const getPage = () => {
    switch (activePage) {
      case AdminPage.createBusiness:
        return <ViewCompany />
      case AdminPage.listBusinesses:
        return <Companies />
      case AdminPage.users:
        return <Users />
      case AdminPage.history:
        return <History />
      case AdminPage.adminstration:
        return <Adminstration />
      default:
        return <ViewCompany />
    }
  }

  return (
    <AdminContext.Provider
      value={{
        activeTab: activeTab,
        activePage: activePage,
        params: pageParams,
        changeActivePage: (page: AdminPage, params?: any) => {
          setActivePage(page)
          setpageParams(params)
          switch (page) {
            case AdminPage.createBusiness:
            case AdminPage.listBusinesses:
            case AdminPage.updateBusiness:
            case AdminPage.readBusiness:
              setActiveTab("0")
              break
            case AdminPage.users:
              setActiveTab("1")
              break
            case AdminPage.history:
              setActiveTab("3")
              break
            case AdminPage.adminstration:
              setActiveTab("4")
              break
            default:
              setActiveTab("0")
              break
          }
        },
      }}
    >
      <Div>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={activeTab}>
            <Box>
              <TabHeader
                onChange={handleChange}
                TabIndicatorProps={{ style: { display: "none" } }}
              >
                <TabItem
                  label={
                    <Heading4>
                      {" "}
                      <img
                        src={`${ASSET_IMAGES}/pages/admin/companiesIcon.svg`}
                        alt=""
                      />
                      {locales["pages_admin_home_business_title"]}
                    </Heading4>
                  }
                  value={"0"}
                  disableRipple
                />
                <TabItem
                  label={
                    <Heading4>
                      {" "}
                      <img
                        src={`${ASSET_IMAGES}/pages/admin/usersIcon.svg`}
                        alt=""
                      />
                      brukere
                    </Heading4>
                  }
                  value={"1"}
                  disableRipple
                />
                <TabItem
                  label={
                    <Heading4>
                      {" "}
                      <img
                        src={`${ASSET_IMAGES}/pages/admin/historyIcon.svg`}
                        alt=""
                      />
                      historie
                    </Heading4>
                  }
                  value={"2"}
                  disableRipple
                />
                {permissions.userRole === SystemRole.Admin && (
                  <TabItem
                    label={
                      <Heading4>
                        {" "}
                        <img
                          src={`${ASSET_IMAGES}/pages/admin/settings_applications.svg`}
                          alt=""
                        />
                        ADMINISTRERE
                      </Heading4>
                    }
                    value={"4"}
                    disableRipple
                  />
                )}
              </TabHeader>
            </Box>
            <TabPanel value={"0"}>{getPage()}</TabPanel>
            <TabPanel value={"1"}>{getPage()}</TabPanel>
            <TabPanel value={"2"}>{getPage()}</TabPanel>
            {permissions.userRole === SystemRole.Admin && (
              <TabPanel value={"4"}>{getPage()}</TabPanel>
            )}
          </TabContext>
        </Box>
      </Div>
    </AdminContext.Provider>
  )
}

export default Admin

import useFootprintLayoutFooter from "modules/layout/services/useFootprintLayoutFooter"
import useFootprintLayoutHeader from "modules/layout/services/useFootprintLayoutHeader"
import useFootprintLayoutRoot from "modules/layout/services/useFootprintLayoutRoot"
import useFootprintLayoutContent from "modules/layout/services/useFootprintLayoutContent"

const useFootprintLayout = () => {
  const { setHeaderOptions }: any = useFootprintLayoutHeader()
  const { setFooterOptions }: any = useFootprintLayoutFooter()
  const { setRootOptions }: any = useFootprintLayoutRoot()
  const { setContentOptions }: any = useFootprintLayoutContent()

  const setFootprintLayoutOptions = (layoutOptions: any) => {
    setHeaderOptions(layoutOptions?.header)
    setFooterOptions(layoutOptions?.footer)
    setRootOptions(layoutOptions?.root)
    setContentOptions(layoutOptions?.content)
  }

  return { setFootprintLayoutOptions }
}

export default useFootprintLayout

export class ChartsColorGenerator {
  constructor() {
    if (ChartsColorGenerator._instance) {
      return ChartsColorGenerator._instance
    }
    const initialColors = [
      "#213642",
      "#DFE3E6",
      "#6F8B9E",
      "#9FABC8",
      "#BACCD5",
      "#78B785",
      "#4B6371",
      "#9FABC8",
      "#233E4C",
      "#B2D6BA",
      "#B0B9C0",
      "#D0D7ED",
      "#89A6B1",
      "#334A75",
      "#43877B",
      "#3C5752",
      "#588A5C",
      "#3E6A5A",
      "#6FA1C1",
      "#1B5876",
      "#4E7499",
      "#B9DCEE",
      "#95CAD0",
      "#A1B88E",
      "#899760",
      "#D8F2E3",
    ]
    this.colorPool = initialColors.map((c) => {
      return {
        mainColor: c,
        backgroundColor: `${c}33`, //33 at end is 0.2 opacity
        isUsed: false,
      }
    })
    return ChartsColorGenerator._instance
  }

  private static _instance = new ChartsColorGenerator()
  static get instance() {
    return ChartsColorGenerator._instance
  }

  private colorPool: chartInternalColor[] = []
  private get mainColorsOnly() {
    return this.colorPool.map((c) => c.mainColor)
  }
  private colorMatchTable = new Map<string, chartColor>()

  getColor = (name: string): chartColor => {
    let theName: string = name
    if (name) {
      theName = name.toLowerCase().trim()
    }

    if (this.colorMatchTable.has(theName))
      return this.colorMatchTable.get(theName)!
    const unusedColor = this.findUnusedColor()
    this.colorMatchTable.set(theName, unusedColor)
    unusedColor.isUsed = true
    return unusedColor
  }

  private findUnusedColor = () => {
    for (const clr of this.colorPool) {
      if (!clr.isUsed) return clr
    }
    //generate color and return it
    const newClr = this.generateRandomColor()
    return newClr
  }

  private generateRandomColor = () => {
    let mainClrHex: string
    let randomColor: any
    do {
      mainClrHex = Math.floor(Math.random() * 16777215).toString(16)
      const paddingHex = Math.floor(Math.random() * 1048575).toString(16) // Generate a random hex number up to 5 characters
      mainClrHex = mainClrHex.padStart(6, paddingHex) // Pad the string with the random hex number
      randomColor = {
        mainColor: `#${mainClrHex}`,
        backgroundColor: `#${mainClrHex}33`, //33 at end is 0.2 opacity
        isUsed: false,
      }
    } while (
      this.mainColorsOnly.find((c) => c === `#${mainClrHex}`) != undefined
    )

    this.colorPool.push(randomColor)
    return randomColor
  }
}

type chartColor = {
  mainColor: string
  backgroundColor: string
}
type chartInternalColor = {
  mainColor: string
  backgroundColor: string
  isUsed: boolean
}

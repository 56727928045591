import React from "react"
import Page from "./Page"
import { MyFiles } from "modules/myFiles"
import { AdminContext as Admin } from "modules/admin"
import ContactUs from "modules/transparencyAct/components/ContactUs/ContactUs"

import {
  Companies,
  Welcome,
  Manual,
  Profile,
  Dictionary,
  LoaderPage,
  Dashboard,
  TransparencySteps,
} from "modules/transparencyAct"
import { ClimateDashboard, Introduction, RoadHead } from "modules/climateModule"
import { Steps, FlaggedSteps } from "modules/steps"
import MainPage from "modules/mainPage/MainPage"
import { FootprintModule } from "modules/shared"
import ClimateSteps from "modules/climateModule/pages/climateSteps/ClimateSteps"
import ClimateFinishPage from "modules/climateModule/pages/climateSteps/components/ClimateFinishPage"
import TransparencyFinishPage from "modules/transparencyAct/pages/TransparencyFinishPage/TransparencyFinishPage"

const last = {
  path: "/last",
  element: (
    <Page
      component={() => <div>Mon, 17 Jun 06:24</div>}
      layout={"white-page"}
    />
  ),
}

/**
 * admin page
 *
 * 
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic: any = [last]

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
  last,
  {
    path: `/companies`,
    element: <Page component={Companies} layout={"vertical-default"} />,
  },
  {
    path: `/:companyId/main-page`,
    element: <Page component={MainPage} layout={"vertical-default"} />,
  },
  {
    path: `/:companyId/${FootprintModule.Transparency_act}/dashboard`,
    element: <Page component={Dashboard} layout={"vertical-default"} />,
  },
  {
    path: `/:companyId/${FootprintModule.Transparency_act}/manual`,
    element: <Page component={Manual} layout={"vertical-default"} />,
  },
  {
    path: `/admin`,
    element: <Page component={Admin} layout={"vertical-default"} />,
  },
  {
    path: `/:companyId/:activeModule/files`,
    element: <Page component={MyFiles} layout={"vertical-default"} />,
  },
  {
    path: `/:companyId/${FootprintModule.Transparency_act}/steps/:stepNumber`,
    element: <Page component={TransparencySteps} layout={"vertical-default"} />,
  },
  {
    path: `/:companyId/${FootprintModule.Transparency_act}/steps`,
    element: <Page component={TransparencySteps} layout={"vertical-default"} />,
  },
  {
    path: `/:companyId/${FootprintModule.Climate_accounting}/steps/:stepNumber`,
    element: <Page component={ClimateSteps} layout={"vertical-default"} />,
  },
  {
    path: `/:companyId/${FootprintModule.Climate_accounting}/steps`,
    element: <Page component={ClimateSteps} layout={"vertical-default"} />,
  },
  {
    path: `/:companyId/${FootprintModule.Climate_accounting}/steps/finishPage`,
    element: <Page component={ClimateFinishPage} layout={"vertical-default"} />,
  },
  {
    path: `/:companyId/${FootprintModule.Transparency_act}/finishPage`,
    element: (
      <Page component={TransparencyFinishPage} layout={"vertical-default"} />
    ),
  },
  {
    path: `/:companyId/${FootprintModule.Transparency_act}/flaggedSteps`,
    element: <Page component={FlaggedSteps} layout={"vertical-default"} />,
  },
  {
    path: `/:companyId/${FootprintModule.Climate_accounting}/flaggedSteps`,
    element: <Page component={FlaggedSteps} layout={"vertical-default"} />,
  },
  {
    path: `/profile`,
    element: <Page component={Profile} layout={"vertical-default"} />,
  },
  {
    path: `/dictionary/${FootprintModule.Climate_accounting}`,
    element: <Page component={Dictionary} layout={"vertical-default"} />,
  },
  {
    path: `/dictionary/${FootprintModule.Transparency_act}`,
    element: <Page component={Dictionary} layout={"vertical-default"} />,
  },
  {
    path: `/contact`,
    element: <Page component={ContactUs} layout={"vertical-default"} />,
  },
  {
    path: `/:companyId/${FootprintModule.Climate_accounting}/dashboard`,
    element: <Page component={ClimateDashboard} layout={"vertical-default"} />,
  },
  {
    path: `/:companyId/${FootprintModule.Climate_accounting}/introduction`,
    element: <Page component={Introduction} layout="vertical-default" />,
  },
  {
    path: `/:companyId/${FootprintModule.Climate_accounting}/roadHead`,
    element: <Page component={RoadHead} layout="vertical-default" />,
  },
]

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
  {
    path: `/welcome`,
    element: <Page component={Welcome} layout={"white-page"} />,
  },
  {
    path: `/`,
    element: <Page component={LoaderPage} layout={"white-page"} />,
  },
]

const routes = [
  ...routesForPublic,
  ...routesForAuthenticatedOnly,
  ...routesForNotAuthenticatedOnly,
]

export {
  routes as default,
  routesForPublic,
  routesForNotAuthenticatedOnly,
  routesForAuthenticatedOnly,
}

import { AxiosResponse } from "axios"
import { useParams } from "react-router-dom"
import { GoalPerformanceTrend, TotalCO2Goals } from "../data/chartsApiTypes"
import axiosHelper from "utils/axiosHelper"
import { GET_GOAL_PERFOMRANCE_TREND_URL } from "utils/constants/URLs"

export const useClimateTrendApi = () => {
  const { companyId } = useParams()
  return {
    getGoalPerformanceTrend: (
      year: number,
    ): Promise<AxiosResponse<GoalPerformanceTrend>> => {
      return axiosHelper.get<GoalPerformanceTrend>(
        GET_GOAL_PERFOMRANCE_TREND_URL.replace("{companyId}", companyId!),
        {
          params: {
            year: year,
          },
        },
      )
    },
  }
}

import {
  Heading2,
  Paragraph,
  Subtitle,
  ExampleBox,
  TipsBox,
  Column,
  SectionContainer,
  FootprintAccordion,
  useFootprintModule,
} from "modules/shared"
import useLocalesState from "utils/Localization/useLocalesState"
import { FunctionComponent } from "react"
import {
  AccordionDetailsContainer,
  DownloadButton,
  DownloadImage,
  DownloadText,
} from "./StepsStyles"
import { FootprintStepInput, FootprintStepSection } from "../types/stepsTypes"
import "../styles/Steps.styles.scss"
import { If } from "react-if"
import StepForm from "./StepForm"
import { onDownloadFileFromUrl } from "utils/DownloadFilesHelper"

import { useSnackbar } from "notistack"
import SnackbarUtils from "utils/SnackbarUtils"
interface StepsSectionProps {
  section: FootprintStepSection
  onUpdateStep: (stepNumber: string, stepData: any) => void
  className?: string
}

const StepsSection: FunctionComponent<StepsSectionProps> = (props) => {
  const { section } = props
  const { activeModule } = useFootprintModule()
  const [locales]: any = useLocalesState()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const stateColor = {
    "default-bg": "#F1F3F3",
    "finished-bg": "#EDF7EF",
    "flagged-bg": "#FFF4F4",

    "default-number-bg": "#DFE3E6",
    "finished-number-bg": "#CDE6D2",
    "flagged-number-bg": "#FFC7C7",

    "default-icon": "#233E4C",
    "finished-icon": "#78B785",
    "flagged-icon": "#FF0000",
  }

  const onUpdateStep = (stepNumber: string, stepData: any) => {
    SnackbarUtils.setSnackBar(enqueueSnackbar as any, closeSnackbar)
    SnackbarUtils.success("Form updated successfully", {
      autoHideDuration: 5000,
    })
    props.onUpdateStep(stepNumber, stepData)
  }

  return (
    <SectionContainer className={`stage-section ${props.className}`}>
      <Heading2>
        {`${section?.number} ` +
          (locales[
            `${activeModule()}_pages_steps_step${section?.number.replace(
              /\./gim,
              "_",
            )}_title`
          ] ?? `${section?.name}`)}
      </Heading2>

      {section?.steps?.map((step) => (
        <FootprintAccordion
          iconStyle={{
            color: stateColor[`${step.status}-icon` as keyof typeof stateColor],
          }}
          accordionStyle={{
            background:
              stateColor[`${step.status}-bg` as keyof typeof stateColor],
          }}
          AccordionDetailsStyle={{
            borderColor:
              stateColor[`${step.status}-bg` as keyof typeof stateColor],
          }}
          numberSectionStyle={{
            background: ` ${
              stateColor[`${step.status}-number-bg` as keyof typeof stateColor]
            }`,
          }}
          key={step?.number}
          withNumbering
          number={step?.number}
          title={
            locales[
              `${activeModule()}_pages_steps_step${step?.number.replace(
                /\./gim,
                "_",
              )}_reportingRequirement`
            ] ?? step?.name
          }
        >
          <AccordionDetailsContainer>
            <div>
              <Subtitle>
                {locales["page_manual_section5_step1b_section1_title"] ??
                  "Forklaring:"}
              </Subtitle>
              <Paragraph>
                {locales[
                  `${activeModule()}_pages_steps_step${step?.number.replace(
                    /\./gim,
                    "_",
                  )}_forklaring`
                ] ?? step?.explanation}
              </Paragraph>
            </div>

            <If condition={step.example}>
              <ExampleBox className="exampleBox">
                <Subtitle className="subtitle">
                  {locales["page_manual_section5_step1b_section2_title"] ??
                    "Eksempel:"}
                </Subtitle>
                <Paragraph className="exampleBox-text">
                  {locales[
                    `${activeModule()}_pages_steps_step${step?.number.replace(
                      /\./gim,
                      "_",
                    )}_eksemple`
                  ] ?? step.example}
                </Paragraph>
              </ExampleBox>
            </If>

            <If condition={step.tips}>
              <TipsBox>
                <Subtitle sx={{ marginTop: "1px" }}>
                  {locales["page_manual_section5_step1_section3_title"] ??
                    "Tips:"}
                </Subtitle>
                <Paragraph className="tipsBox-text">
                  {locales[
                    `${activeModule()}_pages_steps_step${step?.number.replace(
                      /\./gim,
                      "_",
                    )}_tips`
                  ] ?? step.tips}
                </Paragraph>
              </TipsBox>
            </If>

            <If condition={(step as any).templateName}>
              <Column sx={{ marginBottom: "20px" }}>
                <Subtitle>{locales["steps_download_title"]}:</Subtitle>
                <DownloadButton
                  sx={{
                    width: "200px",
                    height: "200px",
                  }}
                  onClick={() => {
                    onDownloadFileFromUrl(
                      (step as any).templateDownloadUrl,
                      (step as any).templateName,
                    )
                  }}
                >
                  <DownloadImage />

                  <DownloadText style={{ fontSize: "11px" }}>
                    {locales["download"]}
                  </DownloadText>
                </DownloadButton>
              </Column>
            </If>

            <If condition={step.inputs.length > 0}>
              <StepForm
                inputs={step.inputs as FootprintStepInput[]}
                stepState={step.status as any}
                stepNumber={step.number}
                onUpdateStep={onUpdateStep}
              />
            </If>
          </AccordionDetailsContainer>
        </FootprintAccordion>
      ))}
    </SectionContainer>
  )
}

export default StepsSection

import React from "react"
import Stack from "@mui/material/Stack"
import {
  Div,
  FootprintModule,
  Logo,
  Span,
  useFootprintHeaderTheme,
} from "modules/shared"
import useLocalesState from "utils/Localization/useLocalesState"
import FootprintNavHeaderItem from "modules/layout/components/vertical-default/FootprintNavItem"
import { useSelector, useDispatch } from "react-redux"
import { onLogoClick, onReportCase } from "redux/actions/dashboard"
import { useLocation, useNavigate } from "react-router-dom"
import { ListItemButton, useMediaQuery } from "@mui/material"
import { ASSET_IMAGES } from "utils/constants/paths"
import ListItemIcon from "@mui/material/ListItemIcon"
import {
  addCompanyReduxData,
  resetCompanyReduxData,
} from "redux/actions/company"
import LangButton from "./LangButton"

const Header = () => {
  const { headerTheme } = useFootprintHeaderTheme()
  const [locales] = useLocalesState()
  const { roles, permissions } = useSelector((state: any) => state.roles)
  // const { isAdmin } = getRolesTypes(roles);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const medium = useMediaQuery("(max-width: 1440px) and (min-width: 1240px)")
  const small = useMediaQuery("(max-width: 904px)")
  const large = useMediaQuery("(min-width: 1440px)")
  const { id, companyName } = useSelector((state: any) => state.company)
  const { firstTimeLogin, logoClick, reportCreation, reportDone } = useSelector(
    (state: any) => state.dashboard,
  )
  const location = useLocation()
  const hasClimate = location.pathname.includes("/climate/")
  const hasTransparency = location.pathname.includes("/transparency/")
  const activeModule = () => {
    if (hasClimate) {
      return "climate"
    } else {
      return "transparency"
    }
  }
  const menus = [
    {
      label: locales["header_label_dashboard"] ?? "Dashboard",
      uri: `/${id}/${activeModule()}/dashboard`,
      type: "nav-item",
      role: true && id && (hasClimate || hasTransparency),
    },
    {
      label: locales["header_label_files"] ?? "Mine Filer",
      uri: `/${id}/${activeModule()}/files`,
      type: "nav-item",
      role: true && id && (hasClimate || hasTransparency),
    },
    {
      label: locales["header_label_help"] ?? "Hjelp",
      uri: "/contact",
      type: "nav-item",
      role: true,
      children: [
        {
          label: locales["header_label_contact"]?.toUpperCase() ?? "KONTAKT",
          uri: "/contact",
        },
        {
          label: locales["header_label_dicitionary"]?.toUpperCase() ?? "ORDBOK",
          uri: `/dictionary/${
            activeModule() ?? FootprintModule.Transparency_act
          }`,
        },
      ],
    },
    {
      label: locales["header_label_admin"] ?? "Administrator",
      uri: "/admin",
      type: "nav-item",
      role: permissions?.canAccessAdminPage ?? false,
    },
    {
      label: locales["header_label_profile"] ?? "Min Profil",
      uri: "/profile",
      type: "nav-item",
      role: true,
    },
  ]
  return (
    <React.Fragment>
      <Logo
        mode={headerTheme.type ?? "light"}
        insideApp={true}
        handleClick={() => {
          dispatch(resetCompanyReduxData())
          window.scrollTo(0, 0)
          navigate("/companies")
        }}
      />
      <Stack
        direction="row"
        alignItems="center"
        spacing={0}
        sx={{ ml: "auto" }}
      >
        {menus.map((item) => (
          <FootprintNavHeaderItem
            item={item}
            key={item.uri}
            firstTimeLogin={firstTimeLogin}
            logoClick={logoClick}
            handleClick={() => {
              dispatch(onLogoClick(false))
              if (!reportCreation && !reportDone) {
                dispatch(onReportCase())
              }
              window.scrollTo(0, 0)
            }}
          />
        ))}
        <Div
          sx={{
            pl:
              (large && "60px") ||
              (small && "32px") ||
              (medium && "32px") ||
              "32px",
          }}
        >
          <LangButton />
        </Div>
      </Stack>
    </React.Fragment>
  )
}

export default Header

import React, { FunctionComponent, PropsWithChildren } from "react"

import Button from "@mui/material/Button"
/**
 * version: "primary" | "secondary"
 * primary is the black button
 * secondary is the blue button
 * overrideColor: string
 */
interface FootprintPrimaryButtonProps extends React.PropsWithChildren {
  version?: "primary" | "secondary"
  overrideColor?: string
  textColor?: string
  style?: React.CSSProperties
  onClick?: VoidFunction
  disabled?: boolean
}

const FootprintPrimaryButton: FunctionComponent<FootprintPrimaryButtonProps> = (
  props,
) => {
  const color = () => {
    if (props.overrideColor) return props.overrideColor
    return props.version == "primary" ? "#000000" : "#233E4C"
  }
  return (
    <Button
      variant={"contained"}
      disableElevation
      disableRipple
      disableFocusRipple
      type="submit"
      sx={{
        marginBottom: "12px",
        width: "215px",
        height: "48px",
        fontWeight: 400,
        fontSize: "14px",
        letterSpacing: "2px",
        background: props.disabled
          ? "#f1f3f3"
          : `linear-gradient(to right, ${color()} 50%, #ffffff 50%) left`,
        backgroundSize: "200%",
        transition: ".5s ease-out",
        border: props.disabled ? "unset" : `1px solid ${color()}`,
        cursor: props.disabled ? "not-allowed" : "pointer",
        color: props.disabled ? "white" : props.textColor,
        "&:hover": {
          backgroundPosition: "right",
          border: `1px solid ${color()}`,
          color: props.overrideColor ? "#ffffff !important" : color(),
        },
        ...props.style,
      }}
      size="large"
      {...props}
    >
      {props.children}
    </Button>
  )
}

export default FootprintPrimaryButton

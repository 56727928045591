import { BodyText, Div, Heading3, SimpleLoader, Span } from "modules/shared"
import MyFilesFile from "../File/MyFilesFile"
import InfiniteScroll from "react-infinite-scroll-component"
import useLocalesState from "utils/Localization/useLocalesState"
import React, { useRef } from "react"
import { useDropzone } from "react-dropzone"
import "./MyFilesTabBody.style.css"
import { FootprintPrimaryButton } from "modules/shared"
interface MyFilesTabBodyProps {
  files: any[]
  tabNames: string[]
  isLoading: boolean
  fetchNext: VoidFunction
  hideDownloadBtn?: boolean
  onUpload: (file: any) => void
  filesCount: number
  companyId?: any
  onDelete: (file: any) => void
  onChange: (selectedIdx: number) => void
  curTab?: number
}

const MyFilesTabBody: React.FC<MyFilesTabBodyProps> = (props) => {
  const [locales] = useLocalesState()
  const files = props.files
  const fileInputRef = useRef<any>(null)

  const { getRootProps, getInputProps } = useDropzone({
    noClick: true,
    onDrop: (acceptedFiles) => {
      props.onUpload(acceptedFiles[0])
    },
  })
  return (
    <Div id="myFilesBodyContainer">
      <Div
        className="btnContainer"
        sx={{
          display: "flex",
        }}
      >
        <Div sx={{ display: "flex", alignItems: "center", gap: "24px" }}>
          {props.tabNames.map((tab, idx) => (
            <Span
              onClick={() => {
                props.onChange(idx)
              }}
              key={tab}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer",
              }}
            >
              <BodyText
                sx={{
                  borderBottom:
                    props.curTab === idx
                      ? "1px solid var(--78B785, #78B785)"
                      : "none",
                }}
              >
                {tab}
              </BodyText>
            </Span>
          ))}
        </Div>
        {!props.hideDownloadBtn && (
          <FootprintPrimaryButton
            //@ts-ignore
            className="uploadBtn"
            onClick={() => {
              fileInputRef?.current?.click()
            }}
          >
            {locales["page_files_upload"] ?? "Last opp ny fil"}
            <input
              type="file"
              onChange={(ev) => {
                if (props.onUpload) props.onUpload((ev.target.files as any)[0])
              }}
              hidden
              ref={fileInputRef}
            />
          </FootprintPrimaryButton>
        )}
      </Div>
      <Div {...getRootProps({ className: "dropzone" })}>
        <InfiniteScroll
          className="inifnite-scroll"
          dataLength={props.filesCount} //This is important field to render the next data
          next={props.fetchNext}
          hasMore={files.length !== props.filesCount && files.length !== 0} //check swagger, then add the next result to this
          loader={<SimpleLoader />}
          endMessage={<></>}
        >
          {files.length
            ? files.map((f, id) => (
                <MyFilesFile
                  file={f}
                  companyId={props.companyId}
                  sx={{ width: "49%" }}
                  key={f.id}
                  hideDeleteIcon={props.hideDownloadBtn}
                  onDelete={() => {
                    if (props.onDelete) props.onDelete(f)
                  }}
                />
              ))
            : locales["page.files.noFiles"] ?? ""}
        </InfiniteScroll>
      </Div>
    </Div>
  )
}

export default MyFilesTabBody

import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Locale } from "redux/types/localeTypes"

const useLocalesState = () => {
  const [locales, setLocales] = useState({})
  const { lang, messages } = useSelector(
    (state) => (state as any).locales as Locale,
  )

  useEffect(() => {
    const newLocales: any = {}
    for (const key in messages) {
      const parsedKey = key.split(".").join("_")
      newLocales[parsedKey] = messages[key]
    }
    setLocales(newLocales)
  }, [lang, messages])

  return [locales] as any
}
export default useLocalesState

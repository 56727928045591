import {
  FIRSTTIMELOGIN,
  REPORTCREATION,
  REPORTDONE,
  SETREPORTID,
  LOGOCLICK,
} from "redux/constants/dashboard"

export const onFirstTime = () => ({
  type: FIRSTTIMELOGIN,
})

export const onReportCase = () => ({
  type: REPORTCREATION,
})

export const onReportDone = () => ({ type: REPORTDONE })

export const onLogoClick = (type: boolean) => ({
  type: LOGOCLICK,
  payload: type,
})
export const updateGeneratedReportId = (fileId: any) => ({
  type: SETREPORTID,
  payload: fileId,
})

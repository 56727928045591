import { BodyText, Div, Heading2, Heading3 } from "modules/shared"
import React from "react"
import { SplitView } from "modules/layout"
import { FootprintScrollArrow, Paragraph } from "modules/shared"
import { ASSET_IMAGES } from "utils/constants/paths"
import { Grid } from "@mui/material"
import { HintTitle } from "../RoadHeadStyles"
export const Section6: React.FC<{
  locales?: any
  large?: boolean
  medium?: boolean
  small?: boolean
}> = ({ locales = {}, large, medium, small }) => {
  return (
    <Div
      id="page_road_head_section6"
      sx={{ justifyContent: "center", textAlign: "center" }}
    >
      {/* //Section one */}
      <Div
        sx={{
          justifyContent: "center",
          p:
            (large && "100px 112px 100px") ||
            (medium && "80px 21px 80px") ||
            (small && "80px 40px 0px ") ||
            "80px 40px 0px",
        }}
      >
        <Div sx={{ margin: "auto" }}>
          <HintTitle>
            {locales["climate_page_roadHead_section6_hint"] ?? "FEM AV DEM"}
          </HintTitle>
          <Heading2 sx={{ m: 0, fontSize: (small && "26px") || "32px" }}>
            {locales["climate_page_roadHead_section6_title"] ??
              "Prinsipper for god rapportering."}
          </Heading2>
          <Paragraph
            sx={{ m: "0px", mt: "12px", fontSize: (small && "15px") || "17px" }}
          >
            {locales["climate_page_roadHead_section6_paragraph"] ??
              "Disse prinsippene sikrer at klimagassrapporteringen er pålitelig, transparent og sammenlignbar, og bidrar til å fremme ansvarlighet og bærekraft i næringslivet."}
          </Paragraph>
        </Div>
        <Div sx={{ mt: "60px" }}>
          <Grid container spacing={"24px"} justifyContent="center">
            <Grid xs={4} item>
              <Div>
                <img
                  src={`${ASSET_IMAGES}/pages/climate/roadHead/icons/extension.svg`}
                  alt="item1"
                />
                <Heading3>
                  {locales["climate_page_roadHead_section6_item1_title"] ??
                    "Relevans"}
                </Heading3>
                <BodyText>
                  {locales["climate_page_roadHead_section6_item1_body_text"] ??
                    "Identifiser og rapporter klimagassutslipp som er vesentlige for organisasjonens aktiviteter og klimapåvirkning."}
                </BodyText>
              </Div>
            </Grid>
            <Grid xs={4} item>
              <Div>
                <img
                  src={`${ASSET_IMAGES}/pages/climate/roadHead/icons/library_add_check.svg`}
                  alt="item1"
                />
                <Heading3>
                  {locales["climate_page_roadHead_section6_item2_title"] ??
                    "Fullstendighet"}
                </Heading3>
                <BodyText>
                  {locales["climate_page_roadHead_section6_item2_body_text"] ??
                    "Tar hensyn til og inkluderer alle relevante utslippskilder for å gi en helhetlig oversikt over klimagassutslippene. Ekskluderte utslipp må gjøres rede for."}
                </BodyText>
              </Div>
            </Grid>
            <Grid xs={4} item>
              <Div>
                <img
                  src={`${ASSET_IMAGES}/pages/climate/roadHead/icons/linear_scale.svg`}
                  alt="item1"
                />
                <Heading3>
                  {locales["climate_page_roadHead_section6_item3_title"] ??
                    "Konsistent"}
                </Heading3>
                <BodyText>
                  {locales["climate_page_roadHead_section6_item3_body_text"] ??
                    "For å muliggjøre sammenligning av utslippsdata mellom organisasjoner og over tid, må rapporteringen følge standardiserte metoder og retningslinjer."}
                </BodyText>
              </Div>
            </Grid>
            <Grid xs={4} item>
              <Div>
                <img
                  src={`${ASSET_IMAGES}/pages/climate/roadHead/icons/zoom_in.svg`}
                  alt="item1"
                />
                <Heading3>
                  {locales["climate_page_roadHead_section6_item4_title"] ??
                    "Åpenhet"}
                </Heading3>
                <BodyText>
                  {locales["climate_page_roadHead_section6_item4_body_text"] ??
                    "Gi et tydelig revisjonsspor for datakilder, referanser, antagelser og andre informasjonskilder."}
                </BodyText>
              </Div>
            </Grid>
            <Grid xs={4} item>
              <Div>
                <img
                  src={`${ASSET_IMAGES}/pages/climate/roadHead/icons/calculate.svg`}
                  alt="item1"
                />
                <Heading3>
                  {locales["climate_page_roadHead_section6_item5_title"] ??
                    "Nøyaktighet"}
                </Heading3>
                <BodyText>
                  {locales["climate_page_roadHead_section6_item5_body_text"] ??
                    "Det er avgjørende at organisasjoner bruker pålitelige kilder og metoder for å sikre nøyaktighet og pålitelighet i rapportene om klimagassutslipp."}
                </BodyText>
              </Div>
            </Grid>
          </Grid>
        </Div>
      </Div>
    </Div>
  )
}

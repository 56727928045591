import React, { FunctionComponent, useState, useEffect } from "react"
import { FootprintPrimaryButton } from "modules/shared"
import { FootprintTextField } from "modules/shared"
import SearchIcon from "@mui/icons-material/Search"
import CompaniesTable from "./CompaniesTable"
import { AdminPage } from "modules/admin/types/adminApiTypes"
import { AdminContext } from "../../Admin"
import { debounce } from "lodash"
import useLocalesState from "utils/Localization/useLocalesState"
import { Permissions } from "modules/authentication/types/permissionType"
import { useSelector } from "react-redux"

interface Props {}
///TODO: folder shared should contain these components?
const Companies: FunctionComponent<Props> = (props) => {
  const [locales]: any = useLocalesState()
  const adminContext = React.useContext(AdminContext)
  const [searchText, setsearchText] = useState("")
  const [searchResult, setsearchResult] = useState("")
  const { permissions } = useSelector((state: any) => state.roles) as {
    permissions: Permissions
  }
  let debounceTimeout: NodeJS.Timeout
  useEffect(() => {
    return () => {
      clearTimeout(debounceTimeout)
    }
  }, [])

  return (
    <>
      <div style={{ display: "flex", gap: "10px" }}>
        <FootprintTextField
          placeholder={locales["pages_admin_home_business_search"]}
          style={{ flexGrow: "1" }}
          value={searchText}
          onChange={(e) => {
            setsearchText(e.target.value)
            clearTimeout(debounceTimeout)
            debounceTimeout = setTimeout(() => {
              setsearchResult(e.target.value) // Trigger the search function after the delay
            }, 300)
          }}
          suffixIcon={<SearchIcon />}
        ></FootprintTextField>
        <FootprintPrimaryButton
          overrideColor={permissions.canCreateBusiness ? "#78B785" : "#E0E0E0"}
          style={{
            display: permissions.canCreateBusiness ? "" : "none",
            backgroundImage: `linear-gradient(to right, #ffffff 50%, #78B785 50%)`,
            color: permissions.canCreateBusiness ? "#78B785" : "#E0E0E0",
            fontWeight: 500,
            fontSize: "14px",
          }}
          disabled={!permissions.canCreateBusiness}
          onClick={() =>
            adminContext.changeActivePage(AdminPage.createBusiness)
          }
        >
          {locales["pages_admin_home_business_create"]}
        </FootprintPrimaryButton>
      </div>
      <CompaniesTable searchText={searchResult}></CompaniesTable>
    </>
  )
}
export default Companies

import { AxiosResponse } from "axios"
import { useParams } from "react-router-dom"
import axiosHelper from "utils/axiosHelper"
import {
  GET_LGENEDS_URL,
  GET_TOTAL_ACTIVITY_GROUP_CURRENT_YEAR_URL,
  GET_TOTAL_ACTIVITY_GROUP_PER_QUARTER_URL,
} from "utils/constants/URLs"
import { ChartData } from "../data/chartsApiTypes"

export const useClimateDetailedOverviewApi = () => {
  const { companyId } = useParams()
  return {
    getTotalActivityGroupCurrentYear: (
      year: number,
    ): Promise<AxiosResponse<ChartData>> => {
      return axiosHelper.get<ChartData>(
        GET_TOTAL_ACTIVITY_GROUP_CURRENT_YEAR_URL.replace(
          "{companyId}",
          companyId!,
        ),
        {
          params: {
            year: year,
          },
        },
      )
    },
    getTotalActivityGroupPerQuarter: (
      year: number,
    ): Promise<AxiosResponse<ChartData>> => {
      return axiosHelper.get<ChartData>(
        GET_TOTAL_ACTIVITY_GROUP_PER_QUARTER_URL.replace(
          "{companyId}",
          companyId!,
        ),
        {
          params: {
            year: year,
          },
        },
      )
    },
    //queryKey climateDetailedOverviewLegends
    getLegends: (year: number): Promise<AxiosResponse<any>> => {
      return axiosHelper.get<any>(
        GET_LGENEDS_URL.replace("{companyId}", companyId!),
        { params: { year: year } },
      )
    },
  }
}

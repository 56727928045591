import React, { Suspense } from "react"
import { BrowserRouter } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "react-query"
import FootprintApp from "./services/FootprintApp"
import AppLayout from "./services/AppLayout"
import AppRoutes from "./services/AppRoutes"
import { SnackbarProvider, useSnackbar } from "notistack"
import { Div, FootprintTheme } from "modules/shared"
import { CircularProgress } from "@mui/material"
import config from "utils/config"
import AppProvider from "./services/AppProvider"
import configureStore, { history } from "redux/store/store"
import { Provider } from "react-redux"
import { FootprintAuthProvider } from "modules/authentication"
import { IconButton } from "@mui/material"
import { PersistGate } from "redux-persist/integration/react"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})
function SnackbarCloseButton({ snackbarKey }: any) {
  const { closeSnackbar } = useSnackbar()

  return (
    <IconButton
      onClick={() => closeSnackbar(snackbarKey)}
      sx={{ position: "absolute", left: "5px", width: "30px", height: "30px" }}
    ></IconButton>
  )
}
const { store, persistor } = configureStore()
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <AppProvider>
              <FootprintApp activeLayout={config.defaultLayout}>
                <FootprintTheme init={config.theme}>
                  <SnackbarProvider
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    action={(snackbarKey) => (
                      <SnackbarCloseButton snackbarKey={snackbarKey} />
                    )}
                    maxSnack={5}
                    autoHideDuration={50000}
                  >
                    <FootprintAuthProvider>
                      <AppLayout>
                        <Suspense
                          fallback={
                            <Div
                              sx={{
                                display: "flex",
                                minWidth: 0,
                                alignItems: "center",
                                alignContent: "center",
                                height: "100%",
                              }}
                            >
                              <CircularProgress sx={{ m: "-40px auto 0" }} />
                            </Div>
                          }
                        >
                          <AppRoutes />
                        </Suspense>
                      </AppLayout>
                    </FootprintAuthProvider>
                  </SnackbarProvider>
                </FootprintTheme>
              </FootprintApp>
            </AppProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  )
}

export default App

import * as React from "react"
import Typography from "@mui/material/Typography"
import { Div } from "modules/shared"
import NewsLetterForm from "modules/transparencyAct/components/NewsLetterForm/NewsLetterForm"
import useLocalesState from "utils/Localization/useLocalesState"
import { useMediaQuery } from "@mui/material"

const DashboardNewsletter = () => {
  const is1240OrLess = useMediaQuery("(max-width:1240px)")
  const is1440OrLess = useMediaQuery("(max-width:1440px)")
  const isOver1440 = useMediaQuery("(min-width:1441px)")
  const [locales] = useLocalesState()

  return (
    <Div
      sx={{
        flexGrow: 1,
        background: "#f1f3f3",
      }}
    >
      <Div
        sx={{
          px: (is1240OrLess && "128px") || (is1440OrLess && "320px") || "336px",
          height: "auto",
          margin: "auto",
          py: (is1240OrLess && "20px") || "60px",
        }}
      >
        <Typography
          variant="h2"
          textAlign={"start"}
          fontWeight={800}
          fontSize={{ xs: "18px", md: "32px" }}
        >
          {locales["pages_dashboard_newsletter_section_title_part1"] ??
            "Vi lanserer nye moduler fortløpende. Meld deg"}
        </Typography>
        <Typography
          variant="h2"
          textAlign={"start"}
          fontWeight={800}
          fontSize={{ xs: "18px", md: "32px" }}
        >
          {locales["pages_dashboard_newsletter_section_title_part2"] ??
            "på vårt nyhetsbrev for å holde deg i loopen."}
        </Typography>
        <Div
          sx={{
            "& form": {
              textAlign: "center !important",
            },
          }}
        >
          <NewsLetterForm />
        </Div>
      </Div>
    </Div>
  )
}
export default DashboardNewsletter

import React from "react"
import { HintTitle } from "../ManualStyles"
import {
  Heading2,
  Paragraph,
  Subtitle,
  SectionContainer,
  Div,
  FootprintScrollArrow,
} from "modules/shared"
import { Grid, Stack } from "@mui/material"
import { ASSET_IMAGES } from "utils/constants/paths"
export const Section3: React.FC<{
  locales?: any
  large?: boolean
  medium?: boolean
  small?: boolean
}> = ({ locales = {}, large, medium, small }) => {
  return (
    <Div>
      <SectionContainer
        id="page_manual_section3"
        sx={{
          p:
            (large && "100px 7.29vw 80px") ||
            (medium && "81px 2.86vw 60px") ||
            (small && "81px 2.604vw 60px ") ||
            "100px 7.29vw 80px",
        }}
      >
        <Stack justifyContent="center">
          <HintTitle
            sx={{ textAlign: "center", fontSize: (small && "15px") || "17px" }}
          >
            {locales["page_manual_section3_title_hint"] ??
              "HVA BØR JEG TENKE PÅ FØR JEG STARTER?"}
          </HintTitle>
          <Heading2
            sx={{
              marginBottom: "0px",
              textAlign: "center",
              fontSize: (small && "26px") || "45px",
            }}
          >
            {locales["page_manual_section3_title"] ??
              "3 veiledende prinsipp når du skal lage en rapport."}
          </Heading2>
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            textAlign={"center"}
            sx={{ mt: (small && "40px") || "80px" }}
          >
            <Grid item xs={4}>
              <img
                src={`${ASSET_IMAGES}/pages/manual/balance.svg`}
                alt="blanc-img"
                style={{ margin: "auto", display: "block" }}
              />
              <Subtitle sx={{ fontSize: (small && "15px") || "17px" }}>
                {locales["page_manual_section3_subtitle1"] ??
                  "Proporsjonalitet"}
              </Subtitle>
              <Paragraph sx={{ fontSize: (small && "15px") || "17px" }}>
                {locales["page_manual_section3_paragraph1"] ??
                  `Ta hensyn til tilgjengelige ressurser. Dere trenger ikke være best i klassen fra start av. Det er akseptabelt at noen rapporteringskrav står tomme. Myndighetene er tydelige på at viktigste er å komme i gang.`}
              </Paragraph>
            </Grid>
            <Grid item xs={4}>
              <img
                src={`${ASSET_IMAGES}/pages/manual/timeline.svg`}
                alt="timeline-img"
                style={{ margin: "auto", display: "block" }}
              />
              <Subtitle sx={{ fontSize: (small && "15px") || "17px" }}>
                {locales["page_manual_section3_subtitle2"] ??
                  "Gradvis forbedringover tid"}
              </Subtitle>
              <Paragraph sx={{ fontSize: (small && "15px") || "17px" }}>
                {locales["page_manual_section3_paragraph2"] ??
                  `Arbeidet med Åpenhetsloven er en reise og krever at aktsomhetsvurderingen gjennomføres kontinuerlig. Gjør noen tiltak grundig, ikke mange tiltak halvveis samtidig.`}
              </Paragraph>
            </Grid>
            <Grid item xs={4}>
              <img
                src={`${ASSET_IMAGES}/pages/manual/help_outline.svg`}
                alt="help-img"
                style={{ margin: "auto", display: "block" }}
              />
              <Subtitle sx={{ fontSize: (small && "15px") || "17px" }}>
                {locales["page_manual_section3_subtitle3"] ?? "Spør om hjelp"}
              </Subtitle>
              <Paragraph sx={{ fontSize: (small && "15px") || "17px" }}>
                {locales["page_manual_section3_paragraph3"] ??
                  "Våre bærekraftsrådgivere står klare til å hjelpe deg hvis det trengs. Ta kontakt ved behov."}
              </Paragraph>
            </Grid>
          </Grid>
        </Stack>
        <FootprintScrollArrow
          nextSelector="#page_manual_section4"
          style={{ marginTop: "0px" }}
        />
      </SectionContainer>
    </Div>
  )
}

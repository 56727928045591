import { Div } from "modules/shared"
import React from "react"
import { HintTitle } from "../IntroductionStyles"
import {
  FootprintScrollArrow,
  SectionContainer,
  Heading1,
  Paragraph,
  FootprintAccordion,
} from "modules/shared"
import { Stack } from "@mui/material"
import { StepStyle } from "./Step"
export const Section3: React.FC<{
  locales?: any
  large?: boolean
  medium?: boolean
  small?: boolean
}> = ({ locales = {}, large, medium, small }) => {
  return (
    <Div>
      {/* //Section four */}
      <SectionContainer
        id="page_manual_section3"
        sx={{
          padding: "100px 7.29vw 0px",
          alignItems: "start",
          gap: "0px",
          p:
            (large && "100px 7.29vw 0px") ||
            (medium && "81px 2.86vw 0px") ||
            (small && "81px 2.604vw 0px ") ||
            "100px 7.29vw 0px",
        }}
      >
        <Stack sx={{ p: (small && "0px 128px") || "0px 224px" }}>
          <HintTitle
            sx={{
              textAlign: "left",
              p: 0,
              m: 0,
              width: "100%",
              fontSize: (small && "15px") || "17px",
            }}
          >
            {locales["page_manual_section4_hint"] ?? "HVOR FÅR JEG VEILEDNING?"}
          </HintTitle>
          <Heading1
            sx={{
              textAlign: "left",
              p: 0,
              m: 0,
              fontSize: (small && "26px") || "45px",
            }}
          >
            {locales["page_manual_section4_title"] ??
              "Veilederen vår hjelper deg med hvert rapporteringskrav."}
          </Heading1>
          <Paragraph>
            {locales["climate_page_intro_section3_paragraph"] ??
              `For at vi lettest mulig skal kunne opprette ditt klimaregnskaps dashboard har vi laget en veileder bestående av 2 steg og 8 rapporteringskrav.\nHver av disse har en egen nedtrekksboks med en beskrivelse av hva slags type dokumentasjon, tall eller beskrivelse som forventes av deg.\nKlikk på boksen i eksempelet under for å se et hvordan veilederen ser ut.`}
          </Paragraph>
        </Stack>
        <FootprintAccordion
          AccordionDetailsStyle={{}}
          withNumbering
          iconStyle={{}}
          numberSectionStyle={{ background: "#DFE3E6" }}
          number="1.1.a"
          accordionStyle={{
            background: "var(--F1F3F3, #F1F3F3)",
            fontWeight: 500,
            fontSize: "17px",
            marginTop: "14px",
            width: (small && "700px") || "none",
            height: (small && "60px") || "none",
            textAlign: "center",
          }}
          title={
            locales["climate_page_intro_section3_step1"] ??
            "Navn på virksomheten"
          }
        >
          <StepStyle locales={locales} />
        </FootprintAccordion>
        <FootprintScrollArrow
          nextSelector="#page_manual_section4"
          style={{
            margin:
              (small && "60px auto") || (medium && "60px auto") || "80px auto",
          }}
        />
      </SectionContainer>
    </Div>
  )
}

import { Div, Heading2 } from "modules/shared"
import React from "react"
import { SplitView } from "modules/layout"
import { FootprintScrollArrow, Paragraph } from "modules/shared"
import { ASSET_IMAGES } from "utils/constants/paths"
import { Stack } from "@mui/material"
import { HintTitle } from "../RoadHeadStyles"
export const Section5: React.FC<{
  locales?: any
  large?: boolean
  medium?: boolean
  small?: boolean
}> = ({ locales = {}, large, medium, small }) => {
  return (
    <Div id="page_road_head_section5">
      {/* //Section one */}
      <Div
        sx={{
          justifyContent: "center",
          p:
            (large && "100px 112px 0px") ||
            (medium && "80px 21px 0px") ||
            (small && "80px 40px 0px ") ||
            "80px 40px 0px",
        }}
      >
        <Div sx={{ margin: "auto", px: "64px" }}>
          <HintTitle>
            {locales["climate_page_roadHead_section5_hint"] ?? "metoden vår"}
          </HintTitle>
          <Heading2 sx={{ m: 0, fontSize: (small && "26px") || "32px" }}>
            {locales["climate_page_roadHead_section5_title"] ??
              "GHG-protokollen."}
          </Heading2>
          <Paragraph
            sx={{ m: "0px", fontSize: (small && "15px") || "17px" }}
            dangerouslySetInnerHTML={{
              __html:
                locales["climate_page_roadHead_section5_paragraph"] ??
                `Vårt verktøy tar utgangspunkt i Greenhouse Gas Protocol (GHG-protokollen). GHG-protokollen er en internasjonalt anerkjent og mye brukt standard for måling og rapportering av klimagassutslipp (GHG) fra virksomheter, deres forsyningskjede og aktiviteter. Virksomheter anvender denne standarden for å sikre at de følger en enhetlig og pålitelig rapportering av klimagassutslipp.\nI dette rammeverket presenterer man utslippet i tre nivåer (scopes), som beskrevet nedenfor.`,
            }}
          ></Paragraph>
        </Div>
        <Div>
          <img
            // width={
            //   (large && "536px") ||
            //   (medium && "504px") ||
            //   (small && "368px") ||
            //   "368px"
            // }
            // height={
            //   (large && "440px") ||
            //   (medium && "440px") ||
            //   (small && "360px") ||
            //   "360px"
            // }
            src={`${ASSET_IMAGES}/pages/climate/roadHead/roadHeadSection5-img.svg`}
            alt="climate_report_roadHead_section5"
          />
        </Div>
        <Stack
          alignItems="center"
          sx={{ my: (large && "80px") || "60px", p: 0 }}
        >
          <FootprintScrollArrow nextSelector="#page_road_head_section6" />
        </Stack>
      </Div>
    </Div>
  )
}

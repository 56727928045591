import React, { useRef } from "react"
import { matchRoutes, useNavigate, useLocation } from "react-router-dom"
import { removeToken, storeToken } from "./authHelpers"
import { AuthContext } from "modules/authentication"
import { useAuth0 } from "@auth0/auth0-react"
import { confirmVerifyAlert } from "utils/sweetAlert"
import useLocalesState from "utils/Localization/useLocalesState"
import { getRoles } from "redux/actions/roles"
import { useDispatch, useSelector } from "react-redux"
import { getUserDataFromToken } from "modules/authentication"
import { routesForAuthenticatedOnly } from "modules/app"
import config from "utils/config"
const authReducer = (state: any, action: any) => {
  switch (action.type) {
    case "set-auth-data":
      return {
        ...state,
        ...action.payload,
      }

    case "start-loading":
      return {
        ...state,
        isLoading: true,
      }

    case "stop-loading":
      return {
        ...state,
        isLoading: false,
      }
  }
}

const FootprintAuthProvider = ({ children, ...restProps }: any) => {
  const location = useLocation()
  const storedToken = localStorage.getItem("token")
  const { label_sweet_alert_verify_account }: any = useLocalesState
  const {
    user,
    isAuthenticated,
    isLoading,
    logout,
    loginWithRedirect,
    error,
    getAccessTokenSilently,
  } = useAuth0()
  const [authOptions, setAuthOptions] = React.useReducer(authReducer, restProps)
  const [systemLogout, setSystemLogout] = React.useState<any>(false)
  const firstUpdate = useRef(true)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { permissions } = useSelector((state: any) => state.roles)
  const { roles } = useSelector((state: any) => state.roles)
  const isAdmin = permissions?.canAccessAdminPage ?? false
  const isSustainReport = permissions?.canReadSustainability ?? false
  // const { isAdmin, isSustainReport } = getRolesTypes(roles);

  const { reportCreation, reportDone } = useSelector(
    (state: any) => state.dashboard,
  )

  const isAuthenticatedRouteOnly = matchRoutes(
    routesForAuthenticatedOnly,
    location.pathname,
  )
  const getRoleDataFromToken = React.useCallback(() => {
    const userData = getUserDataFromToken()
    if (firstUpdate.current && userData && userData.sub) {
      let tokenExp: any = new Date(userData?.exp)
      if (Date.now() >= tokenExp * 1000) {
        setSystemLogout(true)
      } else {
        // setAuthOptions({ type: "start-loading" });
        // @ts-ignore
        dispatch(getRoles(userData?.sub))
      }
      firstUpdate.current = false
    }
  }, [])
  React.useEffect(() => {
    getRoleDataFromToken()
  }, [getRoleDataFromToken])
  React.useEffect(() => {
    if (systemLogout) {
      removeToken()
      setAuthOptions({
        type: "set-auth-data",
        payload: { authToken: null, authUser: null, isLoading: false },
      })
      setSystemLogout(false)
      systemLogout == "profileLogout"
        ? logout({
            logoutParams: {
              returnTo: "https://www.amestoaccounthouse.no/amesto-footprint",
            },
          })
        : logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          })
    }
  }, [systemLogout])
  React.useEffect(() => {
    if (error) {
      const { name, stack, message } = error
      navigate(config.authSetting.fallbackPath)
      confirmVerifyAlert(
        "Verify your account to continue.",
        "We’ve sent you a message with verification link to your mail address.",
        (result: any) => {
          if (result.isConfirmed === true) {
            loginWithRedirect()
          }
        },
      )
      // enqueueSnackbar("Vennligst bekreft e-posten din");
    }
  }, [error])
  React.useEffect(() => {
    if (isLoading && !storedToken) {
      setAuthOptions({ type: "start-loading" })
    } else {
      setAuthOptions({ type: "stop-loading" })
    }
  }, [isLoading])
  React.useEffect(() => {
    if (storedToken && roles?.length > 0) {
      if (isAuthenticatedRouteOnly && (isAdmin || isSustainReport)) {
        if (
          location.pathname == "/admin" ||
          location.pathname == "/admin/users"
        ) {
          isAdmin
            ? navigate(location.pathname)
            : navigate(config.authSetting.redirectNotAuthenticatedPath)
        } else {
          navigate(location.pathname)
        }
      } else {
        if (isAdmin || isSustainReport) {
          navigate(
            config.authSetting.redirectNotAuthenticatedPath ?? "/companies",
          )
        } else {
          navigate(config.authSetting.fallbackPath ?? "/")
        }
      }
      return
    } else if (storedToken && roles?.length == 0) {
      navigate(config.authSetting.fallbackPath ?? "/")
      return
    } else if (user && isAuthenticated && !storedToken) {
      setAuthToken(user, isAuthenticated)
    } else {
      if (
        !location?.search.includes("?code=") &&
        !location?.search.includes("?error=") &&
        !error &&
        !storedToken
      ) {
        loginWithRedirect()
      }
    }
  }, [user, isAuthenticated, roles])

  const setLogout = (val = true) => {
    setSystemLogout(val)
  }
  const setAuthToken = React.useCallback(
    async (user: any, isAuthenticated: any) => {
      const token = await getAccessTokenSilently()
      setAuthOptions({ type: "start-loading" })
      if (!token || !user?.email_verified) {
        setSystemLogout(true)
        return
      }
      storeToken(token)
      const userData = getUserDataFromToken()
      // alert(JSON.stringify(userData))
      //  Data i'm getting on localhost
      // {"iss":"https://footprint-prod.eu.auth0.com/","sub":"auth0|6659c808090e416e0cabb7f9","aud":["https://footprint/api/v1","https://footprint-prod.eu.auth0.com/userinfo"],"iat":1719475815,"exp":1719562215,"scope":"openid profile email","azp":"sx93erdIZPoNTj5S4Qjk7GPYTkuSheoO","permissions":["admin","
      //   create:business","create:user","delete:business","delete:user","first_login","re
      //   ad:business","read:sustainability","read:user","send:notification","update:busin
      //   ess","update:user"]}
      // Data from prod
      // {"iss":"https://footprint-prod.eu.auth0.com/","sub":"auth0|6659c808090e416e0cabb7f9","aud":["https://footprint/api/v1","https://footprint-prod.eu.auth0.com/userinfo"],"iat":1719475909,"exp":1719562309,"scope":"openid profile email","azp":"sx93erdIZPoNTj5S4Qjk7GPYTkuSheoO","permissions":["admin","
      //   create:business","create:user","delete:business","delete:user","first_login","re
      //   ad:business","read:sustainability","read:user","send:notification","update:busin
      //   ess","update:user"]}
      //dispatch(updateRoles(userData.permissions));
      //@ts-ignore
      dispatch(getRoles(userData?.sub)) // sub looks like auth0|6659c808090e416e0cabb7f9

      // if (userData?.permissions) {
      //   let isLoginFirstTime = userData?.permissions.some(
      //     (permission) => permission == "first_login"
      //   );
      //   if (isLoginFirstTime) {
      //     dispatch(onFirstTime());
      //   } else if (reportCreation) {
      //     dispatch(onReportCase());
      //   } else if (reportDone) {
      //     dispatch(onReportDone());
      //   } else {
      //     dispatch(onReportCase());
      //   }
      // }
      try {
        if (user && isAuthenticated && token) {
          setAuthOptions({
            type: "set-auth-data",
            payload: {
              authToken: token,
              authUser: user,
              isLoading: false,
            },
          })
          navigate(config.authSetting.redirectNotAuthenticatedPath ?? "/")
          return
        }
        setSystemLogout(true)
      } catch (error) {
        setSystemLogout(true)
      }
    },
    [],
  )

  const setRedirectPath = React.useCallback((redirectPath: any) => {
    setAuthOptions({ type: "set-redirect-path", payload: { redirectPath } })
  }, [])

  const setAuthData = React.useCallback((data: any) => {
    setAuthOptions({ type: "set-auth-data", payload: data })
  }, [])

  const contextValue = React.useMemo(() => {
    return {
      ...authOptions,
      setAuthData,
      setRedirectPath,
      setAuthToken,
      setAuthOptions,
      setLogout,
    }
  }, [authOptions])

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

export default FootprintAuthProvider

import {
  Div,
  FootprintPrimaryButton,
  Heading3,
  ReactQueryKeys,
  SimpleLoader,
} from "modules/shared"
import React, { useContext, useEffect, useState } from "react"
import useLocalesState from "utils/Localization/useLocalesState"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  Filler,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
import ChartsContext from "../ChartsContext"
import { useClimateDetailedOverviewApi } from "modules/climateModule/services/useClimateDetailedOverviewApi"
import { useQuery } from "react-query"
import { ChartsColorGenerator } from "modules/climateModule/services/ChartsColorGenerator"
import { Else, If, Then, When } from "react-if"
import NoDataAvailable from "./NoDataAvailable"
import { useParams } from "react-router-dom"
import "chartjs-plugin-datalabels"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { PieChart, Pie, Cell, Sector } from "recharts"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ChartDataLabels,
)
interface DetailedOverviewProps {
  downloadingFullReport: boolean
  setDownloadingFullReport: (arg: boolean) => void
  notShowDownloadButton?: boolean
}
const DetailedOverview = (props: DetailedOverviewProps) => {
  const [locales]: any = useLocalesState()
  const pdfRef = React.useRef<any>()
  const chartsContext = useContext(ChartsContext)
  const {
    getTotalActivityGroupCurrentYear,
    getTotalActivityGroupPerQuarter,
    getLegends,
  } = useClimateDetailedOverviewApi()
  const [chart1Data, setchart1Data] = useState<any>()
  const [chart2Data, setchart2Data] = useState<any>()
  const [legendsData, setlegendsData] = useState<any>()
  const [selectedShape, setSelectedShape] = useState(0)
  const [downloadingSection, setDownloadingSection] = useState(false)
  const [emissionsActive, setEmissionsActive] = useState<boolean>(false)
  const { companyId } = useParams()

  const {
    isLoading: ischart1DataLoading,
    error: chart1DataError,
    data: chart1DataResponse,
  } = useQuery(
    [
      ReactQueryKeys.climateDetailedOverviewPieChart,
      companyId,
      chartsContext.reportingYear,
    ],
    () => getTotalActivityGroupCurrentYear(chartsContext.reportingYear),
    { refetchOnMount: false },
  )

  const {
    isLoading: ischart2DataLoading,
    error: chart2DataError,
    data: chart2DataResponse,
  } = useQuery(
    [
      ReactQueryKeys.climateDetailedOverviewBarChart,
      companyId,
      chartsContext.reportingYear,
    ],
    () => getTotalActivityGroupPerQuarter(chartsContext.reportingYear),
    { refetchOnMount: false },
  )

  const {
    isLoading: islegendsDataLoading,
    error: legendsDataError,
    data: legendsDataResponse,
  } = useQuery(
    [
      ReactQueryKeys.climateDetailedOverviewLegends,
      companyId,
      chartsContext.reportingYear,
    ],
    () => getLegends(chartsContext.reportingYear),
    { refetchOnMount: false },
  )

  const options = {
    plugins: {
      datalabels: {
        display: (context: any) =>
          // Used to only display the last dataset in the chart
          context.datasetIndex === context.chart.data.datasets.length - 1,
        color: "black",
        anchor: "end" as "end",
        align: "end" as "end",

        formatter: (value: number, context: any) => {
          const dataIndex = context.dataIndex
          const datasets = context.chart.data.datasets

          const sum = datasets.reduce((acc: number, dataset: any) => {
            return acc + dataset.data[dataIndex]
          }, 0)

          return sum.toFixed(2)
        },
      },
      legend: {
        display: false,
      },
    },
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    layout: {
      padding: {
        top: 40,
      },
    },
  }

  const downloadPdf = async () => {
    const pdf = new jsPDF({
      format: "a4", // Use A4 format
    })
    const pdfElement = pdfRef.current

    // Use html2canvas to capture the HTML content as an image
    const canvas = await html2canvas(pdfElement)

    // Get the dimensions of the A4 page
    const a4Width = 210
    const a4Height = 297

    // Calculate the scaling factor for the captured image
    const scaleFactor = Math.min(
      a4Width / canvas.width,
      a4Height / canvas.height,
    )

    // Calculate the new dimensions of the captured image
    const scaledWidth = canvas.width * scaleFactor
    const scaledHeight = canvas.height * scaleFactor

    // Calculate the center position on the A4 page
    const xPosition = (a4Width - scaledWidth) / 2
    const yPosition = (a4Height - scaledHeight) / 2

    // Add the captured image to the PDF
    pdf.addImage(
      canvas.toDataURL("image/png"),
      "PNG",
      xPosition,
      yPosition,
      scaledWidth,
      scaledHeight,
    )

    // Generate PDF from the HTML content
    pdf.save("Detaljert_oversikt.pdf")
    setDownloadingSection(false)
  }
  const fillChart1Data = () => {
    if (chart1DataResponse?.status == 204) {
      setchart1Data(null)
      return
    }
    const data = chart1DataResponse!.data
    const chartData = {
      labels: data.labels,
      datasets: [
        {
          label: "",
          data: data.dataSets[0].data,
          backgroundColor: data.labels.map(
            (label) => ChartsColorGenerator.instance.getColor(label).mainColor,
          ),
          borderColor: data.labels.map(
            (label) =>
              ChartsColorGenerator.instance.getColor(label).backgroundColor,
          ),
          borderWidth: 1,
        },
      ],
    }
    console.log("chart1Data", chartData)
    setchart1Data(chartData)
  }

  const fillChart2Data = () => {
    if (chart2DataResponse?.status == 204) {
      setchart2Data(null)
      return
    }
    const data = chart2DataResponse!.data
    const chartData = {
      labels: data.labels,
      datasets: data.dataSets.map((set, idx) => {
        return {
          label: set.label,
          data: set.data,
          backgroundColor: ChartsColorGenerator.instance.getColor(set.label)
            .mainColor,
        }
      }),
    }

    console.log("chart2Data", chartData)
    setchart2Data(chartData)
  }

  const fillLegendsData = () => {
    if (legendsDataResponse?.status == 204) {
      setlegendsData(null)
      return
    }
    const data = legendsDataResponse!.data
    console.log("legendsData", data)

    setlegendsData(data)
  }

  useEffect(() => {
    if (!ischart1DataLoading) fillChart1Data()
    if (!ischart2DataLoading) fillChart2Data()
    if (!islegendsDataLoading) fillLegendsData()
  }, [
    ischart1DataLoading,
    chart1DataResponse,
    ischart2DataLoading,
    chart1DataResponse,
    islegendsDataLoading,
    legendsDataResponse,
  ])

  const onPieEnter = (_: any, index: number) => {
    setSelectedShape(index)
  }

  // Format chart1Data for piechart.
  const formattedChart1Data = chart1Data
    ? chart1Data.labels.map((label: string, index: number) => ({
        name: label,
        value: chart1Data.datasets[0].data[index],
        backgroundColor: chart1Data.datasets[0].backgroundColor[index],
      }))
    : []

  const RADIAN = Math.PI / 180

  // activeShape for piechart.
  const renderActiveShape = (props: any) => {
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      value,
    } = props
    const sin = Math.sin(-RADIAN * midAngle)
    const cos = Math.cos(-RADIAN * midAngle)
    const sx = cx + (outerRadius + 10) * cos
    const sy = cy + (outerRadius + 10) * sin
    const mx = cx + (outerRadius + 30) * cos
    const my = cy + (outerRadius + 30) * sin
    const ex = mx + (cos >= 0 ? 1 : -1) * 22
    const ey = my
    const textAnchor = cos >= 0 ? "start" : "end"

    const sum = chart1DataResponse?.data.dataSets[0].data.reduce(
      (a: any, b: any) => a + b,
      0,
    )

    let percentage = ((value / sum) * 100).toFixed(1) + "%"

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
          fontSize={12}
        >
          {payload.name}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {percentage}
          {/* {value.toFixed(0)} */}
        </text>
      </g>
    )
  }

  // Custom labels for piechart
  const renderCustomLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
  }: {
    cx: number
    cy: number
    midAngle: number
    innerRadius: number
    outerRadius: number
    value: number
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    // Total value of all the values in the array.
    const totalValue = formattedChart1Data.reduce(
      (acc: number, cur: any) => acc + cur.value,
      0,
    )

    const ratio = 0.024 // 2% of the total value
    const dynamicThreshold = totalValue * ratio

    if (value >= dynamicThreshold) {
      return (
        <text
          x={x}
          y={y}
          textAnchor="middle"
          dominantBaseline="central"
          fill="#FFFFFF"
          fontSize={12}
        >
          {value.toFixed(0)}
        </text>
      )
    } else {
      return null
    }
  }

  // Labels for downloaded pie chart - only gets displayed in downloading state.
  const renderCustomLabelsOnDownload = (props: any) => {
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      value,
    } = props
    // Outer labels
    const sin = Math.sin(-RADIAN * midAngle)
    const cos = Math.cos(-RADIAN * midAngle)
    const sx = cx + (outerRadius + 10) * cos
    const sy = cy + (outerRadius + 10) * sin
    const mx = cx + (outerRadius + 30) * cos
    const my = cy + (outerRadius + 30) * sin
    const ex = mx + (cos >= 0 ? 1 : -1) * 22
    const ey = my
    const textAnchor = cos >= 0 ? "start" : "end"

    const sum = chart1DataResponse?.data.dataSets[0].data.reduce(
      (a: any, b: any) => a + b,
      0,
    )

    let percentage = ((value / sum) * 100).toFixed(1) + "%"

    // Inner labels
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    // Total value of all the values in the array.
    const totalValue = formattedChart1Data.reduce(
      (acc: number, cur: any) => acc + cur.value,
      0,
    )

    const ratio = 0.024 // 2% of the total value
    const dynamicThreshold = totalValue * ratio

    if (value >= dynamicThreshold) {
      return (
        <g>
          {/* Outer labels */}
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
          />
          <path
            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
            stroke={fill}
            fill="none"
          />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            textAnchor={textAnchor}
            fill="#333"
            fontSize={12}
          >
            {payload.name}
          </text>

          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            dy={18}
            textAnchor={textAnchor}
            fill="#999"
          >
            {percentage}
            {/* {value.toFixed(0)} */}
          </text>
          {/* Inner labels */}
          <text
            x={x}
            y={y}
            textAnchor="middle"
            dominantBaseline="central"
            fill="#FFFFFF"
            fontSize={12}
          >
            {value.toFixed(0)}
          </text>
        </g>
      )
    } else {
      return null
    }
  }

  useEffect(() => {
    if (props.downloadingFullReport) {
      props.setDownloadingFullReport(props.downloadingFullReport)
    }
    if (downloadingSection) {
      downloadPdf()
    }
  }, [props, downloadingSection])

  return (
    <Div>
      <Div ref={pdfRef} sx={{ canvas: { background: "white" } }}>
        <Div sx={{ textAlign: "center", py: "18px" }}>
          <Heading3
            sx={{ color: "var(--B0B9C0, #B0B9C0)", textTransform: "uppercase" }}
          >
            {locales["climate_charts_reportingYear"] ?? "Rapporteringsår"}{" "}
            {chartsContext.reportingYear}
          </Heading3>
          <If condition={!islegendsDataLoading}>
            <Then>
              <When condition={legendsData == null}>
                <NoDataAvailable></NoDataAvailable>
              </When>

              <When condition={legendsData?.length > 0}>
                <Div
                  sx={{
                    margin: "auto",
                    mt: "20px",
                    display: "flex",
                    width: "80%",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    gap: "15px",
                  }}
                >
                  {legendsData?.map((legend: string) => (
                    <Div
                      key={legend}
                      sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                    >
                      <Div
                        sx={{
                          backgroundColor:
                            ChartsColorGenerator.instance.getColor(legend)
                              .mainColor,
                          width: "50px",
                          height: "16px",
                        }}
                      ></Div>
                      <Div
                        sx={{
                          fontFamily: "Rubik",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: 300,
                          lineHeight: "normal",
                          // color: "#BABABA",
                        }}
                      >
                        {legend}
                      </Div>
                    </Div>
                  ))}
                </Div>
              </When>
            </Then>
            <Else>
              <SimpleLoader></SimpleLoader>
            </Else>
          </If>
        </Div>

        <Div
          sx={{
            py: "24px",
            backgroundColor: "var(--F1F3F3, #F1F3F3)",
            display: "grid",
            gridTemplateColumns: "auto auto ",
            gap: "24px",
          }}
        >
          <Div
            sx={{
              background: "white",
              width: "648px",
              height: "360px",
              overflow: "hidden",
              textAlign: "center",
              padding: "20px",
            }}
            onMouseEnter={() => setEmissionsActive(true)}
            onMouseLeave={() => setEmissionsActive(false)}
          >
            <Heading3>
              {locales["climate_charts_detailedOverview_chart1_title"]}
            </Heading3>
            <If condition={!ischart1DataLoading}>
              <Then>
                <When condition={chart1Data == null}>
                  <NoDataAvailable></NoDataAvailable>
                </When>
                <When condition={chart1Data}>
                  <Div
                    sx={{
                      marginTop: "-100px",
                      marginLeft: "-100px",
                    }}
                  >
                    <PieChart width={800} height={500}>
                      <Pie
                        cx="50%"
                        cy="50%"
                        startAngle={360}
                        endAngle={0}
                        dataKey="value"
                        data={formattedChart1Data}
                        fill="#8884d8"
                        outerRadius={90}
                        labelLine={false}
                        label={
                          props.downloadingFullReport || downloadingSection
                            ? renderCustomLabelsOnDownload
                            : renderCustomLabel
                        }
                        onMouseEnter={onPieEnter}
                        activeIndex={
                          emissionsActive ? selectedShape : undefined
                        }
                        activeShape={renderActiveShape}
                      >
                        {formattedChart1Data.map(
                          (entry: any, index: number) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={entry.backgroundColor}
                            />
                          ),
                        )}
                      </Pie>
                    </PieChart>
                  </Div>
                </When>
              </Then>
              <Else>
                <SimpleLoader></SimpleLoader>
              </Else>
            </If>
          </Div>
          <Div
            sx={{
              background: "white",
              width: "648px",
              height: "360px",
              overflow: "hidden",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <Heading3>
              {locales["climate_charts_detailedOverview_chart2_title"]}
            </Heading3>
            <If condition={!ischart2DataLoading}>
              <Then>
                <When condition={chart2Data == null}>
                  <NoDataAvailable></NoDataAvailable>
                </When>
                <When condition={chart2Data}>
                  <Bar
                    options={{
                      ...options,
                    }}
                    data={chart2Data}
                  />
                </When>
              </Then>
              <Else>
                <SimpleLoader></SimpleLoader>
              </Else>
            </If>
          </Div>
        </Div>
      </Div>
      {!props.notShowDownloadButton && (
        <Div
          sx={{
            background: "var(--F1F3F3, #F1F3F3)",
            textAlign: "center",
          }}
        >
          <FootprintPrimaryButton
            overrideColor="#78B785"
            style={{
              color: "#78B785",
              fontWeight: 500,
              fontSize: "14px",
              backgroundImage: `linear-gradient(to right, #ffffff 50%, #78B785 50%)`,
            }}
            onClick={() => setDownloadingSection(true)}
          >
            {locales["climate_charts_downloadTab"] ?? "Last ned utvalg"}
          </FootprintPrimaryButton>
        </Div>
      )}
    </Div>
  )
}
export default DetailedOverview

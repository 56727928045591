import { AxiosResponse } from "axios"
import axiosHelper from "utils/axiosHelper"
import {
  GET_UPLOAD_CLIMATE_DATE,
  UPLOAD_CLIMATE_EMISSION,
  UPLOAD_CLIMATE_GOALS,
} from "utils/constants/URLs"

export const useAdminstriationFilesApi = () => {
  return {
    uploadClimateEmission: (file: File) => {
      const formData: FormData = new FormData()
      formData.append("file", file, file.name)

      return axiosHelper.post(UPLOAD_CLIMATE_EMISSION, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    },
    uploadClimateGoal: (file: File) => {
      const formData: FormData = new FormData()
      formData.append("file", file, file.name)

      return axiosHelper.post(UPLOAD_CLIMATE_GOALS, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    },
    getUploadClimateDate: (): Promise<
      AxiosResponse<{ date: string; type: number }[]>
    > => {
      return axiosHelper.get(GET_UPLOAD_CLIMATE_DATE)
    },
  }
}

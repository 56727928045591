import { Div, FootprintModule, FootprintPrimaryButton } from "modules/shared"
import { Steps } from "modules/steps"
import React, { FunctionComponent, useState } from "react"
import { If } from "react-if"
import styles from "./ClimateSteps.style.module.scss"
import { useClimateStepsAPI } from "modules/climateModule/services/useClimateSteps"
import SnackbarUtils from "utils/SnackbarUtils"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import useLocalesState from "utils/Localization/useLocalesState"

interface ClimateStepsProps {}

const ClimateSteps: FunctionComponent<ClimateStepsProps> = () => {
  const [curStep, setcurStep] = useState(0)
  const { sendNotification } = useClimateStepsAPI()
  const { id: companyId } = useSelector((state: any) => state.company)
  const [locales]: any = useLocalesState()
  const navigate = useNavigate()

  const onSendNotification = async () => {
    await sendNotification()
    SnackbarUtils.success("Notification sent successfully", {
      autoHideDuration: 5000,
    })
    navigate(
      `/${companyId}/${FootprintModule.Climate_accounting}/steps/finishPage`,
    )
  }

  return (
    <Div>
      <Steps
        onStepChange={(newStep) => {
          setcurStep(newStep)
        }}
      />

      <If condition={curStep == 1}>
        <Div className={styles.analysis}>
          <Div className={styles.spacing}></Div>
          <Div className={styles.title}>
            {locales["climate_steps_endSection_title"]}
          </Div>
          <Div className={styles.header}>
            {locales["climate_steps_endSection_header"]}
          </Div>
          <Div className={styles.desc}>
            {locales["climate_steps_endSection_desc"]}
          </Div>
          <FootprintPrimaryButton
            style={{ width: "auto" }}
            onClick={onSendNotification}
          >
            {locales["climate_steps_endSection_btn"]}
          </FootprintPrimaryButton>
        </Div>
      </If>
    </Div>
  )
}

export default ClimateSteps

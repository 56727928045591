import * as React from "react"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Dialog, { DialogProps } from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { Div } from "modules/shared"
import { Heading2 } from "modules/shared"

import "../ViewCompany/ViewCompany.style.css"
import { FootprintPrimaryButton } from "modules/shared"
import { FootprintTertiaryButton } from "modules/shared"
import useLocalesState from "utils/Localization/useLocalesState"

interface DeleteCompanyModalProps {
  open: boolean
  onClose: (val: boolean) => void
}

const DeleteCompanyModal: React.FunctionComponent<DeleteCompanyModalProps> = (
  props,
) => {
  const [locales]: any = useLocalesState()
  return (
    <Div>
      <Dialog
        fullWidth={true}
        open={props.open}
        onClose={(ev, reason) => {
          props.onClose(
            reason == "backdropClick" || reason == "escapeKeyDown"
              ? false
              : true,
          )
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ padding: "unset", paddingTop: "80px" }}
        >
          <Heading2 style={{ textAlign: "center" }}>
            {locales["page_admin_deleteModal_title"]}
          </Heading2>
        </DialogTitle>
        <DialogContent>
          <Box
            component={Div}
            sx={{
              paddingBottom: "60px !important",

              "& .MuiFormControl-root:not(.MuiTextField-root)": {
                mb: "12px",
                width: { xs: "100%", sm: "50%" },
              },

              "& .MuiFormControl-root.MuiFormControl-fluid": {
                width: "100%",
              },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Div style={{ textAlign: "center", marginBottom: "60px" }}>
                  {locales["page_admin_deleteModal_desc"]}
                </Div>
              </Grid>
              <Grid item xs={12}>
                <Div
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <FootprintPrimaryButton onClick={() => props.onClose(true)}>
                    {locales["page_admin_deleteModal_confirm"]}
                  </FootprintPrimaryButton>

                  <FootprintTertiaryButton
                    onClick={() => props.onClose(false)}
                    style={{ color: "#78B785" }}
                  >
                    {locales["page_admin_deleteModal_cancel"]}
                  </FootprintTertiaryButton>
                </Div>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </Div>
  )
}

export default DeleteCompanyModal

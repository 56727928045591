export const climateGlossary = [
  {
    term: "GHG-protokollen",
    description:
      'Forkortelse for "Greenhouse Gas Protocol." Dette er en anerkjent standard for måling og rapportering av klimagassutslipp fra organisasjoner og virksomheter. GHG-protokollen gir retningslinjer for hvordan man skal måle, rapportere og redusere utslipp.',
  },
  {
    term: "Hovedaktiviteter",
    description:
      "De viktigste aktivitetene eller prosessene i en organisasjon eller et prosjekt som har betydelig innvirkning på klimaet og klimagassutslipp. Identifisering av hovedaktiviteter er viktig for å utvikle målrettede tiltak for reduksjon av utslipp.",
  },
  {
    term: "Hovedbok",
    description:
      "Er et regnskapsdokument som inneholder en oversikt over alle økonomiske transaksjoner i virksomheten.",
  },
  {
    term: "CO2-ekvivalenter",
    description:
      "En måte å uttrykke den potensielle globale oppvarmingseffekten av ulike klimagasser ved å sammenligne dem med karbondioksid (CO2). Dette gjør det enklere å forstå og sammenligne utslipp av ulike gasser.",
  },
  {
    term: "Klimagassutslipp",
    description:
      "Mengden av klimagasser som slippes ut i atmosfæren fra menneskelige aktiviteter, som karbondioksid (CO2), metan (CH4) og lystgass (N2O).",
  },
  {
    term: "Klimamål",
    description:
      "Målsetninger som er satt for å begrense klimaendringer, vanligvis ved å redusere klimagassutslipp eller øke bærekraftige praksiser.",
  },
  {
    term: "Klimaprestasjon",
    description:
      "En vurdering av hvordan en enhet eller virksomhet presterer når det gjelder å redusere klimapåvirkningen og oppnå klimamålene.",
  },
  {
    term: "Klimaregnskap",
    description:
      "En oversikt over en organisasjons eller en persons klimagassutslipp og deres påvirkning på klimaet.",
  },
  {
    term: "Kontoplan",
    description:
      "Er en strukturert liste over alle kontoer som brukes i regnskapet.",
  },
]

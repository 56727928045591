import { STEPS } from "redux/constants/status"

const INIT_STATE = {
  stepsData: null,
}
const reducerFunc = (
  state = INIT_STATE,
  action: { type: any; payload: any },
) => {
  switch (action.type) {
    case STEPS: {
      return {
        ...state,
        stepsData: action.payload,
      }
    }
    default: {
      return state
    }
  }
}
export default reducerFunc

import React from "react"
import { FootprintLayout } from "modules/layout"
import useFootprintLayout from "modules/layout/services/useFootprintLayout"
import { SIDEBAR_STYLES } from "modules/layout/data/layout"
import { useApp } from "modules/app"
import useMediaQuery from "@mui/material/useMediaQuery"
import layoutConfig from "./layoutConfig"
import Header from "modules/layout/components/vertical-default/header"
import {
  headerTheme as defaultTheme,
  Footer,
  useFootprintHeaderTheme,
  useFootprintTheme,
  headerTheme as theme4,
} from "modules/shared"
import Sidebar from "modules/shared/components/Sidebar/Sidebar"
import { useSelector } from "react-redux"

const VerticalDefault = ({ children }: any) => {
  const { setFootprintLayoutOptions } = useFootprintLayout()
  const { headerTheme, setHeaderTheme } = useFootprintHeaderTheme()
  const { theme }: any = useFootprintTheme()
  const { id } = useSelector((state: any) => state.company)
  const appBarBgColor =
    headerTheme.components?.MuiAppBar?.styleOverrides?.root?.background
  const appData: any = useApp()
  const mediumScreenSize = useMediaQuery(
    "(max-width: 1440px) and (min-width: 1240px)",
  )
  const smallScreenSize = useMediaQuery("(max-width: 904px)")
  const largeScreenSize = useMediaQuery("(min-width: 1440px)")

  layoutConfig.content.sx = {
    ...layoutConfig.content.sx,
    mx:
      (largeScreenSize && "60px") ||
      (smallScreenSize && "36px") ||
      (mediumScreenSize && "60px") ||
      "60px",
  }
  React.useEffect(() => {
    setFootprintLayoutOptions(layoutConfig)
  }, [])

  React.useEffect(() => {
    if (appData.prevHeaderBgColor && appData.prevHeaderBgColor === "#F1F3F3") {
      setHeaderTheme({ ...theme, ...defaultTheme })
    }
  }, [])

  return (
    <FootprintLayout
      header={<Header />}
      footer={<Footer />}
      headerSx={{
        height: 76,
      }}
    >
      {children}
      {id && <Sidebar />}
    </FootprintLayout>
  )
}

export default VerticalDefault

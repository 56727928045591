import { useSelector } from "react-redux"
import axiosHelper from "utils/axiosHelper"
import { SEND_CLIMATE_NOTIFICATION_URL } from "utils/constants/URLs"

export const useClimateStepsAPI = () => {
  const { id: companyId } = useSelector((state: any) => state.company)
  return {
    sendNotification: async () => {
      return await axiosHelper.get(
        SEND_CLIMATE_NOTIFICATION_URL.replace("{companyId}", companyId),
      )
    },
  }
}

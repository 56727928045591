import { Div } from "modules/shared"
import { SplitView } from "modules/layout"
import { SectionContainer } from "modules/shared"
import React from "react"
import { HintTitle, SectionNineSideMenus } from "../ManualStyles"
import { Heading2, Paragraph } from "modules/shared"
import { FootprintScrollArrow } from "modules/shared"
export const Section9: React.FC<{
  locales?: any
  large?: boolean
  medium?: boolean
  small?: boolean
}> = ({ locales = {}, large, medium, small }) => {
  return (
    <Div>
      <Div
        sx={{
          position: "absolute",
          height: "100vh",
          "& .dashboard-status-menu": {
            position: "relative",
            top: 0,
            left: 0,
            marginLeft: "0px",
            height: "100vh",
            zIndex: 0,
          },
        }}
      ></Div>
      <SectionContainer
        id="page_manual_section9"
        sx={{
          p:
            (medium && "82px 48px 0px") ||
            (small && "80px 40px 0px") ||
            "82px 112px 0px",
        }}
      >
        <SplitView
          left={(medium && "504px") || (small && "368px") || "536px"}
          right={(medium && "500px") || (small && "304px") || "536px"}
          gap={(small && "16px") || "24px"}
        >
          <Div sx={{ m: "auto" }}>
            <HintTitle sx={{ fontSize: (small && "15px") || "17px" }}>
              {locales["page_manual_section9_hint"] ??
                "HVOR LAGRER JEG ALLE FILENE MINE?"}
            </HintTitle>
            <Heading2
              sx={{
                m: 0,
                fontSize: (small && "26px") || "32px",
                lineHeight: "normal",
              }}
            >
              {locales["page_manual_section9_title"] ??
                "Mine Filer er ditt skylagringssystem."}
            </Heading2>
            <Paragraph sx={{ m: "0px", fontSize: (small && "15px") || "17px" }}>
              {locales["page_manual_section9_paragraph"] ??
                `Plattformen vår tilbyr infrastruktur og trygg skylagring for alt fra leverandører til malverk og den ferdige rapporten din. Du kan enkelt laste ned, laste opp og slette dokumenter som du vil. Du finner alle filene dine under Mine Filer i menyen øverst til høyre.`}
            </Paragraph>
          </Div>
          <SectionNineSideMenus
            sx={{
              width: (medium && "416px") || (small && "304px") || "536px",
              height: (medium && "500px") || (small && "360px") || "500px",
            }}
          />
        </SplitView>
        <FootprintScrollArrow
          nextSelector="#page_manual_section10"
          style={{
            margin:
              (small && "60px auto") || (medium && "60px auto") || "80px auto",
          }}
        />
      </SectionContainer>
    </Div>
  )
}

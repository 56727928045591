import styled from "@emotion/styled"
import {
  AccordionSummary as MuiAccordionSummary,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
} from "@mui/material"

export const Accordion = styled(MuiAccordion)({
  height: "100%",
  boxShadow: "none",
  border: "none",
  "::before": {
    all: "unset",
  },
  maxHeight: "unset",
  minHeight: "unset",
})

export const AccordionSummary = styled(MuiAccordionSummary)({
  boxShadow: "none",
  width: "1096px",
  minWidth: "100%",
  alignItems: "center !important",
  padding: "0 1.46% 0 0",
  height: "60px !important",
  maxHeight: "60px !important",
  minHeight: "60px !important",
  ":disabled": {
    opacity: 1,
  },
})

export const NumberSection = styled("div")({
  height: "60px",
  flexBasis: "130px",
  flexShrink: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontStyle: "normal",
  fontWeight: 800,
  fontSize: "32px",
  lineHeight: "34px",
})

export const AccordionDetails = styled(MuiAccordionDetails)({})

import styled from "@mui/material/styles/styled"
import { ASSET_IMAGES } from "utils/constants/paths"

export const HintTitle = styled("span")({
  fontSize: "17px",
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: "21px" /* 123.529% */,
  letterSpacing: "2px",
  textTransform: "uppercase",
})

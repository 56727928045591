import React from "react"
import { Div, useFootprintFooterTheme } from "modules/shared"
import useFootprintLayoutFooter from "modules/layout/services/useFootprintLayoutFooter"
import ThemeProvider from "@mui/material/styles/ThemeProvider"

const FootprintLayoutFooter = ({ children }: any) => {
  const { footerTheme }: any = useFootprintFooterTheme()
  const { footerOptions }: any = useFootprintLayoutFooter()
  if (footerOptions?.hide) {
    return null
  }
  return (
    <ThemeProvider theme={footerTheme}>
      <Div className="CmtLayout-footer" sx={{ zIndex: 99 }}>
        {children}
      </Div>
    </ThemeProvider>
  )
}

export default FootprintLayoutFooter

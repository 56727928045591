import React from "react"
import ReactDOM from "react-dom/client"
import reportWebVitals from "./reportWebVitals"
import { Auth0Provider } from "@auth0/auth0-react"
import {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  AUDIENCE,
} from "utils/config/env"
import { App } from "modules/app"

// Suppress warnings and errors that include certain texts.
// more can be found here https://stackoverflow.com/questions/64158705/ignore-certain-console-errors-warnings-in-react
const consoleWarn = console.warn
const consoleErr = console.error
const SUPPRESSED_WARNINGS: string[] = []
const SUPPRESSED_ERRORS = ["Warning: React does not recognize the"]

// console.warn = function filterWarnings(msg, ...args) {
//   try {
//     if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
//       consoleWarn(msg, ...args);
//     }
//   } catch (e) {
//     console.warn(e);
//   }
// };

// console.error = function filterErrors(msg, ...args) {
//   try {
//     if (!SUPPRESSED_ERRORS.some((entry) => msg.includes(entry))) { consoleErr(msg, ...args);
//     }
//   } catch (e) {
//     console.error(e);
//   }
// };

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN}
      clientId={REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: AUDIENCE,
        defaultScope: "read:sustainability admin", //maybe not working
      }}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

export enum FootprintModule {
  Climate_accounting = "climate",
  Transparency_act = "transparency",
}

/***
pass any of FootprintModule enum values to this dictionary to get your data
*/
export const FootprintModuleData = {
  [FootprintModule.Transparency_act]: {
    id: 1,
    name: "Transparency_act",
    urlPath: FootprintModule.Transparency_act.toString(),
    translationKey: "transparencyModule",
  },
  [FootprintModule.Climate_accounting]: {
    id: 2,
    name: "Climate_accounting",
    urlPath: FootprintModule.Climate_accounting.toString(),
    translationKey: "climateModule",
  },
}

import axiosHelper from "utils/axiosHelper"
import { API_URI, REPORT_API_URI, ACCOUNT_API_URI } from "utils/config/env"

export const getFileListApi = async (
  companyId: any,
  type: any,
  pageNumber: any,
) => {
  const response = axiosHelper.get(
    `${REPORT_API_URI}/file/companies/${companyId}/file-type/${type}`,
    {
      params: { locale: "no", pageNumber: pageNumber || 0, pageSize: 10 },
    },
  )
  return response
}
export const useMyfilesApi = () => {
  return {
    getFileList: async (
      companyId: any,
      activeModule: any,
      type: any,
      pageNumber: any,
      reqUserId = -1,
    ) => {
      const response = axiosHelper.get(
        `${REPORT_API_URI}/file/companies/${companyId}/file-type/${type}`,
        {
          params: {
            locale: "no",
            moduleId: activeModule,
            pageNumber: pageNumber || 0,
            pageSize: 10,
            reqUserId: reqUserId,
          },
        },
      )
      return response
    },
    getDownloadLink: async (fileId: any, companyId: any) => {
      const response = axiosHelper.get(
        `${REPORT_API_URI}/file/companies/${companyId}/download/file-id/${fileId}`,
        {
          params: { locale: "no" },
        },
      )
      return response
    },
    uploadReport: async (
      companyId: any,
      activeModule: any,
      file: any,
      reportType: any,
      reqUserId: any = null,
    ) => {
      const formData = new FormData()
      formData.append("file", file, file.name)
      formData.append("locale", "no")
      formData.append("fileType", reportType)
      formData.append("moduleId", activeModule)
      reqUserId && formData.append("reqUserId", reqUserId)
      const response = axiosHelper.post(
        `${REPORT_API_URI}/file/upload/companies/${companyId}`,
        formData,
        {
          headers: {
            "content-type": `multipart/form-data`,
            "content-length": `${file.size}`,
          },
        },
      )
      return response
    },
    deleteFile: async (fileId: any, companyId: any) => {
      const response = axiosHelper.delete(
        `${REPORT_API_URI}/file/${fileId}/companies/${companyId}`,
        {
          params: { locale: "no" },
        },
      )
      return response
    },
  }
}

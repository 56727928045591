import { alpha } from "@mui/material"

export const mainTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },

  palette: {
    primary: {
      main: "#233E4C",
      light: "#B0B9C0",
      dark: "#15252d",
      black: "#000000",
      white: "#ffffff",
      transparent: "#BABABA",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#78B785",
      light: "#cde6d2",
      dark: "#486D4F",
      black: "#000000",
      white: "#ffffff",
      transparent: "#BABABA",
      contrastText: "#FFF",
    },
    black: {
      main: "#000000",
      light: "#000000",
      dark: "#4c4c4c",
      black: "#000000",
      transparent: "#000000",
      contrastText: "#FFF",
    },
    nav: {
      main: "#F1F3F3",
    },

    error: {
      main: "#E73145",
      light: "#FF6A70",
      dark: "#AD001E",
      contrastText: "#FFF",
    },
    warning: {
      main: "#F39711",
      light: "#FFC84C",
      dark: "#BB6900",
      contrastText: "#FFF",
    },
    info: {
      main: "#2EB5C9",
      light: "#6FE7FC",
      dark: "#008598",
      contrastText: "#FFF",
    },
    success: {
      main: "#3BD2A2",
      light: "#78FFD3",
      dark: "#00A073",
      contrastText: "#FFF",
    },
    text: {
      primary: "#000000",
      secondary: "#8595A6",
      disabled: "#A2B2C3",
    },
    divider: "#DEE2E6",
    background: {
      paper: "#FFFFFF",
      default: "#FFFFFF",
    },
    action: {
      active: "#475259",
      hover: "#F5F7FA",
    },
  },

  typography: {
    fontFamily: "Rubik, Times New Roman",
    fontSize: 14,
    h1: {
      fontSize: "1.5rem",
      lineHeight: 1,
      fontWeight: 400,
      color: "#000000",
      margin: "0 0 .5rem",
    },
    h2: {
      fontSize: "1.4rem",
      lineHeight: 1,
      fontWeight: 400,
      color: "#000000",
      margin: "0 0 .5rem",
    },
    h3: {
      fontSize: "1.25rem",
      lineHeight: 1,
      fontWeight: 400,
      color: "#000000",
      margin: "0 0 .5rem",
    },
    h4: {
      fontSize: "1.1rem",
      lineHeight: 1,
      fontWeight: 400,
      color: "#000000",
      margin: "0 0 .5rem",
    },
    h5: {
      fontSize: "1rem",
      lineHeight: 1,
      fontWeight: 400,
      color: "#000000",
      margin: "0 0 .5rem",
    },
    h6: {
      fontSize: ".875rem",
      lineHeight: 1,
      fontWeight: 400,
      color: "#000000",
      margin: "0 0 .5rem",
    },
    body1: {
      fontSize: ".875rem",
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: alpha("#000", 0.1),
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: "auto",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 24,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: "18px 24px",
        },
        title: {
          fontSize: "1.1rem",
          marginBottom: 0,
        },
        subheader: {
          margin: "4px 0 0",
        },
        action: {
          margin: 0,
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "8px 24px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          height: 22,
          fontSize: 12,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          borderRadius: 0,
          letterSpacing: "2px",
          fontSize: "14px",
          boxShadow: 0,
          backgroundColor: "#000",
          shadows: ["none"],
          "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#000",
          },
        },
        sizeSmall: {
          fontSize: "12px",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 18,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "16px 24px",
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          backgroundColor: "#757575",
          fontSize: 16,
        },
      },
    },
  },
  footprintComponents: {
    FootprintSearch: {
      background: "#F5F5F5",
    },
  },
}

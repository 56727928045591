import React from "react"
import config from "utils/config"
import { APP_ACTIONS } from "../data/appActions"
import { AppContext } from "./AppContext"

function init(initialValue: any) {
  return {
    customizerVisibility: initialValue.customizerVisibility,
    containerStyle: initialValue.containerStyle,
    rebuildRoutes: true,
  }
}

function appReducer(state: any, action: any) {
  switch (action.type) {
    case APP_ACTIONS.SET_CUSTOMIZER_VISIBILITY:
      return {
        ...state,
        customizerVisibility: action.payload,
      }

    case APP_ACTIONS.SET_CONTAINER_STYLE:
      return {
        ...state,
        containerStyle: action.payload,
      }

    case APP_ACTIONS.SET_APP:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}

const AppProvider = ({ children }: any) => {
  const [app, setApp] = React.useReducer(
    appReducer,
    {
      customizerVisibility: false,
      containerStyle: config.containerStyle,
    },
    init,
  )

  const setCustomizerVisibility = React.useCallback(
    (value: any) => {
      setApp({ type: APP_ACTIONS.SET_CUSTOMIZER_VISIBILITY, payload: value })
    },
    [setApp],
  )

  const setContainerStyle = React.useCallback(
    (containerStyle: any) => {
      setApp({
        type: APP_ACTIONS.SET_CONTAINER_STYLE,
        payload: containerStyle,
      })
    },
    [setApp],
  )

  const setAppState = React.useCallback(
    (stateObject: any) => {
      setApp({ type: APP_ACTIONS.SET_APP, payload: stateObject })
    },
    [setApp],
  )

  const rebuildRoutes = React.useCallback((value: any) => {}, [setApp])

  const contextValue = React.useMemo(
    () => ({
      ...app,
      setCustomizerVisibility,
      setContainerStyle,
      setAppState,
    }),
    [app],
  )

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  )
}

export default AppProvider

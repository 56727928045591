import { CompanyEntity } from "modules/admin/types/adminApiTypes"
import { GET_COMPANY_DATA, RESET_COMPANY_DATA } from "redux/constants/company"

export const addCompanyReduxData = (data: CompanyEntity) => ({
  type: GET_COMPANY_DATA,
  payload: { ...data },
})

export const resetCompanyReduxData = () => ({
  type: RESET_COMPANY_DATA,
})

import React from "react"
import { FootprintLayout } from "modules/layout"
import useFootprintLayout from "modules/layout/services/useFootprintLayout"
import layoutConfig from "./layoutConfig"
import Footer from "../../../shared/components/Footer/footer"

const SoloPage = ({ children }: any) => {
  const { setFootprintLayoutOptions } = useFootprintLayout()

  React.useEffect(() => {
    setFootprintLayoutOptions(layoutConfig)
  }, [])

  return <FootprintLayout footer={<Footer />}>{children}</FootprintLayout>
}

export default SoloPage

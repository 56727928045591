import { useParams } from "react-router-dom"
import axiosHelper from "utils/axiosHelper"
import {
  GET_TOTAL_SCOPES_IN_LAST_TWO_YEARS_FOR_ACTIVITY_GROUPS_URL,
  GET_TOTAL_SCOPES_IN_LAST_TWO_YEARS_URL,
} from "utils/constants/URLs"
import { TotalCO2Scopes, TotalScopes } from "../data/chartsApiTypes"
import { AxiosResponse } from "axios"

export const useClimateBudgetApi = () => {
  const { companyId } = useParams()
  return {
    getTotalScopesInLastTwoYears: (
      year: number,
    ): Promise<AxiosResponse<TotalCO2Scopes[]>> => {
      return axiosHelper.get<TotalCO2Scopes[]>(
        GET_TOTAL_SCOPES_IN_LAST_TWO_YEARS_URL.replace(
          "{companyId}",
          companyId!,
        ),
        {
          params: {
            year: year,
          },
        },
      )
    },
    getTotalScopesInLastTwoYearsByActivityGroups: (
      year: number,
    ): Promise<AxiosResponse<TotalScopes>> => {
      return axiosHelper.get<TotalScopes>(
        GET_TOTAL_SCOPES_IN_LAST_TWO_YEARS_FOR_ACTIVITY_GROUPS_URL.replace(
          "{companyId}",
          companyId!,
        ),
        {
          params: {
            year: year,
          },
        },
      )
    },
  }
}

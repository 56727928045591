import * as React from "react"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import MenuItem from "@mui/material/MenuItem"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme, Theme } from "@mui/material/styles"
import {
  Span,
  FootprintTertiaryButton,
  FootprintPrimaryButton,
  FootprintAutoCompleteFormik,
  Div,
  BodyText,
  Heading2,
  FootprintTextFieldFormik,
  FootprintDropDownFiledFormik,
} from "modules/shared"
import { Form, Formik, FormikProps, FormikValues } from "formik"
import * as yup from "yup"
import { DateRangePicker, IconButton } from "rsuite"
import { DateRange } from "rsuite/esm/DateRangePicker"

import { TextField } from "@mui/material"
import "../ViewCompany/ViewCompany.style.css"
import { useUserApi } from "modules/admin/services/usersApi"
import { UserDto } from "modules/admin/types/adminApiTypes"
import { useSelector } from "react-redux"
import { SystemRole } from "modules/authentication"
import CloseIcon from "@mui/icons-material/Close"
import "rsuite/dist/rsuite-no-reset.min.css"
import { getUserDataById } from "modules/shared"

const validationSchema: yup.SchemaOf<{
  userName: string
  email: string
  roleId: string
  roleBusiness: string
  companyId: any[]
  startDate: string
  endDate: string
}> = yup.object({
  userName: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  roleId: yup.string().required("Role is required"),
  roleBusiness: yup.string().required("Business role is required"),
  companyId: yup.array().when("isDisabled", {
    is: false,
    then: yup.array().min(1, "Access business is required"),
  }),
  startDate: yup.string().required("Start Date is required"),
  endDate: yup.string().required("End Date is required"),
})
const validationSchemaForCompanyAdmin: yup.SchemaOf<{
  userName: string
  email: string
  roleId: string
  roleBusiness: string
  companyId: any[]
}> = yup.object({
  userName: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  roleId: yup.string().required("Role is required"),
  roleBusiness: yup.string().required("Business role is required"),
  companyId: yup.array().min(1, "Access business is required"),
})
interface UserInfo {
  email_verified: boolean
  // other properties...
}
type UserFormProps = {
  open: boolean
  locales: any
  setOpen: (open: boolean) => void // Example function signature, adjust as needed
  roleList: { id: string; name: string }[] // Adjust the type as needed
  companiesList: { id: string; companyName: string }[] // Adjust the ty
  handleGetAllUsers: () => void
  userData: UserDto
  setUserData: any
  company?: {
    id: string
    companyName: string
  }
}
export const UserForm: React.FunctionComponent<UserFormProps> = (props) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"))
  const { createUser, sendInvitation, updateAdminUser, updateUser } =
    useUserApi()
  const formFormikRef = React.useRef<FormikProps<FormikValues>>()
  const { permissions } = useSelector((state: any) => state.roles)
  const [userInfo, setUserInfo] = React.useState<UserInfo>({
    email_verified: true,
  })
  const {
    open,
    setOpen,
    roleList,
    companiesList,
    userData,
    setUserData,
    handleGetAllUsers,
    locales,
  } = props
  const userId = userData?.id || ""
  const [dataRange, setDateRange] = React.useState<any>({
    endDate: userData?.endDate,
    startDate: userData?.startDate,
  })

  const companyIdsInitial = () => {
    if (userData?.companyEntity)
      return userData.companyEntity.map((company) => company)
    else if (props.company) return [props.company]
    else return []
  }
  React.useEffect(() => {
    userData &&
      getUserDataById(userData.id).then((res: any) => {
        setUserInfo(res.data)
      })
  }, [])

  const handleClose = () => {
    setOpen(false)
    setUserData(null)
  }

  const onDateRangeSelect = (range: DateRange | null) => {
    formFormikRef?.current?.setFieldValue(
      "startDate",
      formatDate(range?.[0] as Date),
    )
    formFormikRef?.current?.setFieldValue(
      "endDate",
      formatDate(range?.[1] as Date),
    )
    setDateRange({
      startDate: formatDate(range?.[0] as Date),
      endDate: formatDate(range?.[1] as Date),
    })
  }
  const formatDate = (date: Date) => {
    // Extract the year, month, and day components
    const year: number = date.getUTCFullYear()
    const month: number = date.getUTCMonth() + 1 // Months are zero-based, so add 1
    const day: number = date.getUTCDate()

    // Format the components into the desired format
    const formattedDate: string = `${year}-${month
      .toString()
      .padStart(2, "0")}-${day.toString().padStart(2, "0")}`

    return formattedDate
  }
  return (
    <Div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{ borderRadius: "0px", boxShadow: "none" }}
      >
        <DialogTitle id="responsive-dialog-title" sx={{ p: "60px 40px 0px" }}>
          <Heading2 sx={{ m: 0, p: 0 }}>
            {userData
              ? locales["page_admin_user_dialog_form_title_edit"] ??
                "Rediger bruker"
              : locales["page_admin_user_dialog_form_title"] ?? "Ny bruker"}
          </Heading2>
        </DialogTitle>

        <CloseIcon
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 10,
            color: "#B0B9C0",
            width: "28px",
            cursor: "pointer",
            height: "28px",
          }}
        />
        <DialogContent sx={{ p: "48px 40px 80px" }}>
          <Box
            component={Div}
            sx={{
              "& .MuiFormControl-root:not(.MuiTextField-root)": {
                mb: "12px",
                width: { xs: "100%", sm: "50%" },
              },

              "& .MuiFormControl-root.MuiFormControl-fluid": {
                width: "100%",
              },
            }}
          >
            <Formik
              enableReinitialize
              validateOnChange={true}
              innerRef={formFormikRef as any}
              initialValues={{
                userName: userData?.userName ?? "",
                email: userData?.email ?? "",
                roleBusiness: userData?.businessRole ?? "",
                roleId: userData?.roleId ?? "",
                roleName: userData?.roleName ?? "",
                companyId: companyIdsInitial(),
                startDate: userData?.startDate ?? "",
                endDate: userData?.endDate ?? "",
              }}
              validationSchema={
                permissions?.userRole === SystemRole.CompanyAdmin
                  ? validationSchemaForCompanyAdmin
                  : validationSchema
              }
              onSubmit={(data: any, { setSubmitting, resetForm }) => {
                let newCompanyId = data.companyId.map(
                  (company: { id: string; companyName: string }) => company.id,
                )
                if (permissions?.userRole === SystemRole.CompanyAdmin) {
                  if (!data.startDate && !data.endDate) {
                    data.startDate = formatDate(new Date())
                    data.endDate = formatDate(new Date())
                  }
                }
                data.roleName = roleList.find(
                  (row: { id: string; name: string }) => row.id === data.roleId,
                )?.name
                userData
                  ? permissions?.userRole == SystemRole.Admin ||
                    permissions?.userRole == SystemRole.Advisor
                    ? updateAdminUser(userId, {
                        ...data,
                        companyId: newCompanyId,
                        invitation: userInfo?.email_verified,
                        roleInBusiness: data?.roleBusiness,
                        name: data?.userName,
                        role: data?.roleName,
                      }).then(() => {
                        handleGetAllUsers()
                        resetForm()
                        handleClose()
                        setSubmitting(true)
                      })
                    : updateUser(userId, {
                        ...data,
                        companyId: newCompanyId,
                        invitation: userInfo?.email_verified,
                        roleInBusiness: data?.roleBusiness,
                        name: data?.userName,
                        role: data?.roleName,
                      }).then(() => {
                        handleGetAllUsers()
                        resetForm()
                        handleClose()
                        setSubmitting(true)
                      })
                  : createUser({ ...data, companyId: newCompanyId }).then(
                      () => {
                        handleGetAllUsers()
                        resetForm()
                        handleClose()
                        setSubmitting(true)
                      },
                    )
              }}
            >
              {({ isSubmitting, resetForm, isValid, dirty, values }) => (
                <Form
                  style={{ textAlign: "left" }}
                  noValidate
                  autoComplete="off"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FootprintTextFieldFormik
                        fullWidth
                        labelTopText={
                          locales["page_admin_user_dialog_form_name"] ?? "Navn"
                        }
                        placeholder={
                          locales["page_admin_user_dialog_form_name"] ?? "Navn"
                        }
                        name="userName"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FootprintTextFieldFormik
                        fullWidth
                        labelTopText={
                          locales["page_admin_user_dialog_form_email"] ??
                          "E-post"
                        }
                        placeholder={
                          locales["page_admin_user_dialog_form_email"] ??
                          "E-post"
                        }
                        name="email"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FootprintTextFieldFormik
                        fullWidth
                        labelTopText={
                          locales[
                            "page_admin_user_dialog_form_role_in_business"
                          ] ?? "Rolle i virksomheten"
                        }
                        placeholder={
                          locales[
                            "page_admin_user_dialog_form_role_in_business"
                          ] ?? "Rolle i virksomheten"
                        }
                        name="roleBusiness"
                        size="small"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      spacing={2}
                      container
                      alignItems={"center"}
                    >
                      <Grid item xs={userData ? 4 : 12}>
                        <FootprintDropDownFiledFormik
                          name="roleId"
                          fullWidth
                          size="small"
                          labelTopText={
                            locales["page_admin_user_dialog_form_role"] ??
                            "Rolle"
                          }
                          placeholder={
                            locales["page_admin_user_dialog_form_role"] ??
                            "Rolle"
                          }
                        >
                          {roleList.map((role, index) => (
                            <MenuItem key={index} value={role.id}>
                              {role.name}
                            </MenuItem>
                          ))}
                        </FootprintDropDownFiledFormik>
                      </Grid>
                      {userData && (
                        <Grid item xs={4}>
                          <BodyText style={{ display: "inline" }}>
                            {locales["label_invitation"] ?? "Invitasjon:"}
                          </BodyText>{" "}
                          <BodyText style={{ display: "inline" }}>
                            {userInfo?.email_verified ? (
                              <Span sx={{ color: "#78B785" }}>
                                {" "}
                                {locales["label_accepted"] ?? "Akseptert"}
                              </Span>
                            ) : (
                              <Span sx={{ color: "#BABABA" }}>
                                {" "}
                                {locales["label_overdue"] ?? "Forfalt"}
                              </Span>
                            )}
                          </BodyText>
                        </Grid>
                      )}
                      {userData && !userInfo?.email_verified && (
                        <Grid item xs={4} sx={{ textAlign: "end" }}>
                          <FootprintTertiaryButton
                            style={{ color: "#78B785" }}
                            onClick={() =>
                              sendInvitation(
                                userData?.id as string,
                                userData?.email as string,
                              )
                            }
                          >
                            {locales["label_send_invitation"] ??
                              "Send invitasjon"}
                          </FootprintTertiaryButton>
                        </Grid>
                      )}
                    </Grid>

                    {(!props.company ||
                      !userData ||
                      permissions?.userRole === SystemRole.Admin) && (
                      <Grid item xs={12}>
                        <FootprintAutoCompleteFormik
                          fullWidth
                          size="small"
                          name="companyId"
                          labelTopText={
                            locales[
                              "page_admin_user_dialog_form_access_businesses"
                            ] ?? "Tilgang til virksomheter"
                          }
                          placeholder={
                            locales[
                              "page_admin_user_dialog_form_access_businesses"
                            ] ?? "Tilgang til virksomheter"
                          }
                          options={companiesList}
                          getOptionLabel={(option) => option.companyName}
                          renderInput={(params) => <TextField {...params} />}
                          disabled={roleList.some(
                            (role) =>
                              role.id === values.roleId &&
                              role.name === "ADMIN",
                          )}
                        />
                      </Grid>
                    )}
                    {permissions?.userRole !== SystemRole.CompanyAdmin && (
                      <Grid item xs={12}>
                        <Div className="userDateTime">
                          <div style={{ fontWeight: 300 }}>
                            {locales[
                              "page_admin_user_dialog_form_access_period"
                            ] ?? "Tilgangsperiode"}
                          </div>
                          <DateRangePicker
                            onChange={(range) => onDateRangeSelect(range)}
                            value={
                              dataRange?.endDate && dataRange?.startDate
                                ? [
                                    new Date(dataRange.startDate || ""),
                                    new Date(dataRange.endDate || ""),
                                  ]
                                : null
                            }
                          />
                        </Div>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Div
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          pt: "60px",
                        }}
                      >
                        <FootprintPrimaryButton>
                          {userData
                            ? locales[
                                "pages_profile_title_form_button_submit"
                              ] ?? "Lagre endringer"
                            : locales["label_send_invitation"] ??
                              "Send invitasjon"}
                        </FootprintPrimaryButton>

                        <FootprintTertiaryButton
                          onClick={() => handleClose()}
                          style={{ color: "#78B785" }}
                        >
                          {locales["label_cancel"] ?? "Avbryt"}
                        </FootprintTertiaryButton>
                      </Div>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    </Div>
  )
}

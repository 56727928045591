import { Div } from "modules/shared"
import React from "react"
import { SplitView } from "modules/layout"
import {
  CrownImage,
  HintTitle,
  InnerTextContainer,
  Intro,
  Step,
  StepNumber,
  StepsContainer,
  StepText,
  TextContainer,
  Video,
  VideoContainer,
} from "../ManualStyles"
import {
  FootprintScrollArrow,
  Span,
  SectionContainer,
  Heading1,
  Paragraph,
} from "modules/shared"
import { ASSET_IMAGES } from "utils/constants/paths"
import { Stack } from "@mui/material"
export const Section1: React.FC<{
  locales?: any
  large?: boolean
  medium?: boolean
  small?: boolean
}> = ({ locales = {}, large, medium, small }) => {
  return (
    <Div>
      {/* //Section one */}
      <SectionContainer
        sx={{
          p:
            (large && "125px 7.29vw 0px") ||
            (medium && "81px 2.86vw 0px") ||
            (small && "81px 2.604vw 0px ") ||
            "125px 7.29vw 0px",
        }}
      >
        <SplitView
          left={(medium && "592px") || (small && "432px") || "648px"}
          right={(medium && "416px") || (small && "304px") || "424px"}
          gap={(small && "16px") || "24px"}
        >
          <TextContainer
            sx={{
              height: (medium && "428px") || (small && "314px") || "437px",
            }}
          >
            <InnerTextContainer
              sx={{
                width: (medium && "592px") || (small && "432px") || "648px",
                mt: (small && "0px") || "60px",
                p: (small && "53px 64px") || "0 112px",
              }}
            >
              <HintTitle sx={{ fontSize: (small && "15px") || "17px" }}>
                {locales["page_manual_section1_hint"] ?? "VELKOMMEN"}
              </HintTitle>
              <Heading1
                sx={{
                  height: "unset",
                  lineHeight: "normal",
                  textAlign: "start",
                  m: 0,
                  fontSize: (small && "26px") || "45px",
                }}
              >
                <Span>
                  {locales["page_manual_section1_title1"] ?? "Slik lager du en"}
                  <div style={{ height: "0px" }} />
                  <span style={{ top: "-10px", position: "relative" }}>
                    {locales["page_manual_section1_title2"] ??
                      "rapport med vårt"}
                  </span>
                  <span
                    style={{
                      top: "-15px",
                      position: "relative",
                      display: "block",
                    }}
                  >
                    {locales["page_manual_section1_title3"] ??
                      "digitale verktøy."}
                  </span>
                </Span>
              </Heading1>
              <Paragraph
                sx={{ marginTop: "0px", fontSize: (small && "15px") || "17px" }}
              >
                {locales["page_manual_section1_paragraph"] ??
                  `På denne siden forklarer vi hvordan du bruker plattformen vår for å lage en rapport som tilfredsstiller kravene i Åpenhetsloven:`}
              </Paragraph>
              <StepsContainer
                style={{
                  width:
                    (large && "472px") ||
                    (medium && "480px") ||
                    (small && "384px") ||
                    "472px",
                  height:
                    (large && "180px") ||
                    (medium && "180px") ||
                    (small && "174px") ||
                    "180px",
                  //@ts-ignore
                  left: small && "24px",
                  fontSize: (small && "15px") || "17px",
                }}
              >
                <CrownImage />

                <Step
                  sx={{
                    fontSize: (small && "15px") || "17px",
                    width: (small && "85%") || "80%",
                  }}
                >
                  <StepNumber
                    sx={{ fontSize: (small && "20px !important") || "25px" }}
                  >
                    §4
                  </StepNumber>
                  <StepText
                    sx={{ fontSize: (small && "15px !important") || "17px" }}
                  >
                    {locales["page_manual_section1_point1"] ??
                      "Forankring av ansvarlighet"}
                  </StepText>
                </Step>

                <Step
                  sx={{
                    fontSize: (small && "15px") || "17px",
                    width: (small && "85%") || "80%",
                  }}
                >
                  <StepNumber
                    sx={{ fontSize: (small && "20px !important") || "25px" }}
                  >
                    §4
                  </StepNumber>{" "}
                  <StepText
                    sx={{ fontSize: (small && "15px !important") || "17px" }}
                  >
                    {locales["page_manual_section1_point2"] ??
                      "Aktsomhetsvurderinger"}
                  </StepText>
                </Step>
                <Step
                  sx={{
                    fontSize: (small && "15px") || "17px",
                    width: (small && "85%") || "80%",
                  }}
                >
                  <StepNumber
                    sx={{ fontSize: (small && "20px !important") || "25px" }}
                  >
                    §5
                  </StepNumber>
                  <StepText
                    sx={{ fontSize: (small && "15px !important") || "17px" }}
                  >
                    {locales["page_manual_section1_point3"] ??
                      "Redegjørelsesplikten"}
                  </StepText>
                </Step>
                <Step
                  sx={{
                    fontSize: (small && "15px") || "17px",
                    width: (small && "85%") || "80%",
                  }}
                >
                  <StepNumber
                    sx={{ fontSize: (small && "20px !important") || "25px" }}
                  >
                    §6
                  </StepNumber>
                  <StepText
                    sx={{ fontSize: (small && "15px !important") || "17px" }}
                  >
                    {locales["page_manual_section1_point4"] ??
                      "Informasjonsplikten"}
                  </StepText>
                </Step>
              </StepsContainer>
            </InnerTextContainer>
          </TextContainer>
          <VideoContainer
            sx={{
              height:
                (large && "437px") ||
                (medium && "429px") ||
                (small && "313.5px") ||
                "313.5px",
            }}
          >
            <Video
              width={
                (large && "424px") ||
                (medium && "416px") ||
                (small && "304px") ||
                "304px"
              }
              height={
                (large && "437px") ||
                (medium && "429px") ||
                (small && "313.5px") ||
                "313.5px"
              }
              src={`${ASSET_IMAGES}/pages/manual/video-img.png`}
              title=""
              //@ts-ignore
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </VideoContainer>
        </SplitView>
        <Stack alignItems="center" sx={{ m: 0, p: 0 }}>
          <Intro>
            {locales["page_manual_section1_intro"] ?? "INTRODUKSJON"}
          </Intro>
          <FootprintScrollArrow nextSelector="#page_manual_section2" />
          <span style={{ marginTop: (large && "80px") || "60px" }}></span>
        </Stack>
      </SectionContainer>
    </Div>
  )
}

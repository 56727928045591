import {
  Caption,
  Div,
  FootprintPrimaryButton,
  Heading2,
  Heading3,
  ReactQueryKeys,
  SimpleLoader,
} from "modules/shared"
import React, { useContext, useEffect, useState } from "react"
import useLocalesState from "utils/Localization/useLocalesState"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js"
import { Line } from "react-chartjs-2"
import { Divider } from "@mui/material"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
import ChartsContext from "../ChartsContext"
import { useClimateTrendApi } from "modules/climateModule/services/useClimateTrendApi"
import { useQuery } from "react-query"
import { ChartsColorGenerator } from "modules/climateModule/services/ChartsColorGenerator"
import { Else, If, Then, When } from "react-if"
import { useParams } from "react-router-dom"
import NoDataAvailable from "./NoDataAvailable"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
)
interface TrendProps {
  notShowDownloadButton?: boolean
}
const Trend = (props: TrendProps) => {
  const [locales]: any = useLocalesState()
  const pdfRef = React.useRef<any>()
  const chartsContext = useContext(ChartsContext)
  const { getGoalPerformanceTrend } = useClimateTrendApi()
  const [chart1Data, setchart1Data] = useState<any | null>()
  const [percentageGoal, setpercentageGoal] = useState<string>()
  const { companyId } = useParams()
  const {
    isLoading: ischart1DataLoading,
    error: chart1DataError,
    data: chart1DataResponse,
  } = useQuery(
    [ReactQueryKeys.climateTrendChart1, companyId, chartsContext.reportingYear],
    () => getGoalPerformanceTrend(chartsContext.reportingYear),
    { refetchOnMount: false },
  )

  const fillChart1Data = () => {
    if (chart1DataResponse?.status == 204) {
      setchart1Data(null)
      return
    }
    const data = chart1DataResponse!.data
    const chartData = {
      labels: data.labels,
      datasets: data.dataSets.map((set: any) => {
        return {
          ...set,
          borderColor: ChartsColorGenerator.instance.getColor(set.label)
            .mainColor,
          fill: false,
        }
      }),
    }
    console.log("chart1Data", chartData)

    setchart1Data(chartData)
  }

  const lineTrendZeroLabels = [
    locales["baseYear"],
    "2030",
    "2035",
    "2040",
    "2045",
    "2048",
    "2050",
  ]
  const lineTrendZero = {
    labels: lineTrendZeroLabels,
    datasets: [
      {
        fill: false,
        label: locales["climate_charts_trend_chart2_title"],
        data: [1400, 1300, 1000, 400, 100, -100, -200],
        borderColor: "#78B785",
        backgroundColor: "#aad1b2",
      },
    ],
  }

  const lineTrendZeroOptions: any = {
    responsive: true,

    interaction: {
      intersect: false,
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Co\u2082 Emission",
        },

        ticks: {
          display: false,
        },
      },
    },
    elements: {
      line: {
        cubicInterpolationMode: "monotone",
      },
    },
  }

  const lineOptions: any = {
    responsive: true,
    interaction: {
      intersect: false,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Co\u2082 Emission",
        },
      },
    },
    elements: {
      line: {
        cubicInterpolationMode: "monotone",
      },
    },
  }

  const downloadPdf = async () => {
    const pdf = new jsPDF({
      format: "a4", // Use A4 format
    })

    const pdfElement = pdfRef.current

    // Use html2canvas to capture the HTML content as an image
    const canvas = await html2canvas(pdfElement)

    // Get the dimensions of the A4 page
    const a4Width = 210
    const a4Height = 297

    // Calculate the scaling factor for the captured image
    const scaleFactor = Math.min(
      a4Width / canvas.width,
      a4Height / canvas.height,
    )

    // Calculate the new dimensions of the captured image
    const scaledWidth = canvas.width * scaleFactor
    const scaledHeight = canvas.height * scaleFactor

    // Calculate the center position on the A4 page
    const xPosition = (a4Width - scaledWidth) / 2
    const yPosition = (a4Height - scaledHeight) / 2

    // Add the captured image to the PDF
    pdf.addImage(
      canvas.toDataURL("image/png"),
      "PNG",
      xPosition,
      yPosition,
      scaledWidth,
      scaledHeight,
    )

    // Generate PDF from the HTML content
    pdf.save("Trend_og_mål.pdf")
  }

  useEffect(() => {
    if (!ischart1DataLoading) {
      fillChart1Data()
      setpercentageGoal(chart1DataResponse?.data.currentYearPercentageGoal)
    }
  }, [ischart1DataLoading, chart1DataResponse])
  return (
    <Div>
      <Div ref={pdfRef} sx={{ canvas: { background: "white" } }}>
        <Div sx={{ textAlign: "center", py: "18px" }}>
          <Heading3
            sx={{ color: "var(--B0B9C0, #B0B9C0)", textTransform: "uppercase" }}
          >
            {locales["climate_charts_reportingYear"] ?? "Rapporteringsår"}{" "}
            {chartsContext.reportingYear}
          </Heading3>
        </Div>
        <Divider
          sx={{ backgroundColor: "var(--F1F3F3, #F1F3F3)", height: "24px" }}
        />
        <Div sx={{ textAlign: "center", py: "18px" }}>
          <Heading2
            sx={{
              textTransform: "uppercase",
              fontFamily: "Abhaya Libre ExtraBold",
            }}
          >
            <If condition={!ischart1DataLoading}>
              <Then>
                <When condition={chart1Data}>
                  {percentageGoal}
                  {locales["climate_charts_trend_title"]}
                </When>
                <When condition={chart1Data == null}>
                  <NoDataAvailable></NoDataAvailable>
                </When>
              </Then>
              <Else>
                <SimpleLoader></SimpleLoader>
              </Else>
            </If>
          </Heading2>
          <Caption sx={{ color: "var(--BABABA, #BABABA)" }}>
            {locales["climate_charts_trend_subTitle"]}
          </Caption>
        </Div>

        <Div
          sx={{
            py: "24px",
            backgroundColor: "var(--F1F3F3, #F1F3F3)",
            display: "grid",
            gridTemplateColumns: "auto auto ",
            gap: "24px",
          }}
        >
          <Div
            sx={{
              background: "white",
              width: "638px",
              height: "340px",
              padding: "20px",
              paddingTop: "10px",
              textAlign: "center",
            }}
          >
            <Heading3>{locales["climate_charts_trend_chart1_title"]}</Heading3>
            <If condition={!ischart1DataLoading}>
              <Then>
                <When condition={chart1Data}>
                  <Line
                    options={{
                      ...lineOptions,
                    }}
                    data={chart1Data}
                  />
                </When>
                <When condition={chart1Data == null}>
                  <NoDataAvailable></NoDataAvailable>
                </When>
              </Then>
              <Else>
                <SimpleLoader></SimpleLoader>
              </Else>
            </If>
          </Div>
          <Div
            sx={{
              background: "white",
              width: "638px",
              height: "340px",
              padding: "20px",
              paddingTop: "10px",
              textAlign: "center",
            }}
          >
            <Heading3>{locales["climate_charts_trend_chart2_title"]}</Heading3>
            <Line
              options={{
                ...lineTrendZeroOptions,
              }}
              data={lineTrendZero}
            />
          </Div>
        </Div>
      </Div>
      {!props.notShowDownloadButton && (
        <Div sx={{ background: "var(--F1F3F3, #F1F3F3)", textAlign: "center" }}>
          <FootprintPrimaryButton
            overrideColor="#78B785"
            style={{
              color: "#78B785",
              fontWeight: 500,
              fontSize: "14px",
              backgroundImage: `linear-gradient(to right, #ffffff 50%, #78B785 50%)`,
            }}
            onClick={() => downloadPdf()}
          >
            {locales["climate_charts_downloadTab"] ?? "Last ned utvalg"}
          </FootprintPrimaryButton>
        </Div>
      )}
    </Div>
  )
}
export default Trend

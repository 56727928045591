import React from "react"
import { Div, Span, Logo } from "modules/shared"
import { ASSET_IMAGES } from "utils/constants/paths"
import { Typography } from "@mui/material"
import useLocalesState from "utils/Localization/useLocalesState"
import Link from "@mui/material/Link"

import "./Welcome.style.css"
const Welcome = () => {
  const [locales] = useLocalesState()
  return (
    <Div data-testid="welcome-page" className="welcome-page">
      <Div
        className="image"
        sx={{
          background: `url(${ASSET_IMAGES}/pages/welcome/welcome.png) no-repeat`,
        }}
      ></Div>
      <Div className="content">
        <Typography
          variant="h4"
          fontWeight={300}
          sx={{ textTransform: "uppercase" }}
        >
          {locales["page_welcome_page_hint"] ?? "Hei"}
        </Typography>
        <Typography variant="h2" fontSize={"45px"} fontWeight={800}>
          {locales["page_welcome_page_title"] ?? "Velkommen til oss."}
        </Typography>
        <Typography variant="h4" fontWeight={300}>
          {locales["page_welcome_page_subtitle1"] ??
            `En av våre rådgivere vil ta kontakt med deg snarlig for å registrere selskapet du skal rapportere på vegne av. I mellomtiden kan du lese om mer om Åpenhetsloven`}{" "}
          <a
            href="https://www.amestoaccounthouse.no/apenhetsloven"
            target="_blank"
            style={{ color: "#005a9e" }}
            rel="noreferrer"
          >
            her
          </a>
          .
          <br /> <br />
          {locales["page_welcome_page_subtitle2"] ??
            `Hilsen Amesto Footprint-teamet`}
        </Typography>
        <Span sx={{ position: "absolute", bottom: "30px", right: "30px" }}>
          <Link href="/">
            <Logo />
          </Link>
        </Span>
      </Div>
    </Div>
  )
}

export default Welcome

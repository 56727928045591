import { Div, Heading2 } from "modules/shared"
import React from "react"
import { SplitView } from "modules/layout"
import { FootprintScrollArrow, Paragraph } from "modules/shared"
import { ASSET_IMAGES } from "utils/constants/paths"
import { Stack } from "@mui/material"
import { HintTitle } from "../RoadHeadStyles"
export const Section1: React.FC<{
  locales?: any
  large?: boolean
  medium?: boolean
  small?: boolean
}> = ({ locales = {}, large, medium, small }) => {
  return (
    <Div id="page_road_head_section1">
      {/* //Section one */}
      <Div
        sx={{
          p:
            (large && "100px 111px 0px") ||
            (medium && "80px 21px 0px") ||
            (small && "80px 40px 0px ") ||
            "80px 40px 0px",
        }}
      >
        <SplitView
          left={(medium && "416px") || (small && "368px") || "424px"}
          right={(medium && "504px") || (small && "368px") || "536px"}
          gap={(small && "16px") || (medium && "103px") || "137.5px"}
        >
          <Div sx={{ margin: "auto" }}>
            <HintTitle>
              {locales["climate_page_roadHead_section1_hint"] ?? "klimamål"}
            </HintTitle>
            <Heading2 sx={{ m: 0, fontSize: (small && "26px") || "32px" }}>
              {locales["climate_page_roadHead_section1_title"] ??
                "Steg 3. Veien videre."}
            </Heading2>
            <Paragraph
              sx={{ m: "0px", fontSize: (small && "15px") || "17px" }}
              dangerouslySetInnerHTML={{
                __html:
                  locales["climate_page_roadHead_section1_paragraph"] ??
                  `Hvordan bruke klimaregnskapet som et verktøy for å redusere CO<sub>2</sub>-utslipp? Vi kan hjelpe med å identifisere tiltak, lage en tiltaksplan og sette klimamål for deres virksomhet.`,
              }}
            ></Paragraph>
          </Div>
          <Div>
            <img
              width={
                (large && "536px") ||
                (medium && "504px") ||
                (small && "368px") ||
                "368px"
              }
              height={
                (large && "440px") ||
                (medium && "440px") ||
                (small && "360px") ||
                "360px"
              }
              src={`${ASSET_IMAGES}/pages/climate/roadHead/roadHeadSection1-img.png`}
              alt="climate_report_roadHead_section1"
            />
          </Div>
        </SplitView>
        <Stack
          alignItems="center"
          sx={{ my: (large && "80px") || "60px", p: 0 }}
        >
          <FootprintScrollArrow nextSelector="#page_road_head_section2" />
        </Stack>
      </Div>
    </Div>
  )
}

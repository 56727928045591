import React, { FunctionComponent } from "react"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  NumberSection,
} from "./FootprintAccordionStyles"
import { Subtitle } from "modules/shared/styles/FootprintText"
interface FootprintAccordionProps {
  isDisabled?: boolean
  title: any
  children: any
  withNumbering: any
  number: any
  accordionStyle: any
  AccordionDetailsStyle: any
  iconStyle: any
  numberSectionStyle: any
}

const FootprintAccordion: FunctionComponent<FootprintAccordionProps> = (
  props,
) => {
  const {
    isDisabled = false,
    title,
    children,
    withNumbering,
    number,
    accordionStyle,
    AccordionDetailsStyle,
    iconStyle,
    numberSectionStyle,
  } = props

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            sx={{ width: "36px", height: "36px", ...iconStyle }}
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
        className="panelHeader"
        disabled={isDisabled}
        style={{
          opacity: 1,
          width: "1096px ",
          ...accordionStyle,
        }}
      >
        {withNumbering && (
          <NumberSection style={{ ...numberSectionStyle }}>
            {number}
          </NumberSection>
        )}
        <Subtitle
          sx={{ display: "flex", alignItems: "center", padding: "0 1.67vw" }}
        >
          {title}
        </Subtitle>
      </AccordionSummary>

      <AccordionDetails
        style={{ border: "1px solid #DFE3E6", ...AccordionDetailsStyle }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default FootprintAccordion

import React from "react"
import FootprintAppContext from "modules/app/services/FootprintAppContext"
import { useLocation } from "react-router-dom"

const FootprintApp = (props: any) => {
  const [activeLayout, setActiveLayout] = React.useState(props.activeLayout)
  const [rebuildRoutes, setRebuildRoutes] = React.useState(true)
  const { pathname } = useLocation()

  React.useEffect(() => {
    if (rebuildRoutes) {
      setRebuildRoutes(false)
    }
  }, [rebuildRoutes])
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  const appContextValue = React.useMemo(() => {
    return {
      activeLayout,
      setActiveLayout,
      rebuildRoutes,
      setRebuildRoutes,
    }
  }, [activeLayout, setActiveLayout, rebuildRoutes, setRebuildRoutes])

  return (
    <FootprintAppContext.Provider value={appContextValue}>
      {props?.children}
    </FootprintAppContext.Provider>
  )
}

export default FootprintApp

import {
  DENSITIES,
  LAYOUT_CONTAINER_STYLES,
  POSITION_TYPES,
  LAYOUT_NAMES,
} from "modules/layout"
import { enUS } from "@mui/material/locale"
import {
  footerTheme,
  sidebarTheme,
  headerTheme,
  mainTheme,
} from "modules/shared"
import { createFootprintTheme } from "utils"

const config = {
  locale: {
    locale: enUS,
  },
  defaultContentLayout: {
    base: {
      density: DENSITIES.STANDARD,
    },
    root: {
      container: LAYOUT_CONTAINER_STYLES.FLUID,
      sx: {}, //for content layout wrapper
    },
    wrapper: {
      sx: {},
    },
    sidebar: {
      type: POSITION_TYPES.DEFAULT,
      width: 250,
      minWidth: 80,
      open: true,
      sx: {},
    },
    main: {
      sx: {},
    },
    header: {
      type: POSITION_TYPES.DEFAULT,
      spreadOut: true,
      sx: {},
    },
    content: {
      sx: {},
    },
    footer: {
      type: POSITION_TYPES.DEFAULT,
      spreadOut: true,
      sx: {},
    },
  },
  defaultLayout: LAYOUT_NAMES.SOLO_PAGE,
  containerStyle: LAYOUT_CONTAINER_STYLES.FLUID,
  authSetting: {
    fallbackPath: "/welcome",
    redirectNotAuthenticatedPath: "/companies",
  },
  theme: createFootprintTheme(
    mainTheme,
    headerTheme,
    sidebarTheme,
    footerTheme,
  ),
}

export default config

import React, { FunctionComponent, useEffect } from "react"
import { useField, FieldProps } from "formik"
import TextField, { TextFieldProps } from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"

interface Props {
  style?: React.CSSProperties
  suffixIcon?: React.ReactNode
  labelTopText?: string
  placeholder?: string
  maxLength?: number
}
//TODO: use FieldAttributes<TextFieldProps>
const FootprintTextFieldFormik: FunctionComponent<Props & TextFieldProps> = (
  props,
) => {
  const [field, meta] = useField(props.name as string)
  const errorText = meta.error && meta.touched ? meta.error : ""
  const helperText = props?.helperText ?? ""

  return (
    <div
      style={{
        ...props.style,
        fontWeight: 300,
        marginBottom:
          errorText || helperText ? "20px" : props.style?.marginBottom,
      }}
    >
      <div style={{ fontWeight: 300 }}>{props.labelTopText}</div>
      <TextField
        {...props}
        {...field}
        inputProps={{
          placeholder: props.placeholder,
          maxLength: props.maxLength,
          endAdornment: (
            <InputAdornment position="end">{props.suffixIcon}</InputAdornment>
          ),
        }}
        style={{}}
        helperText={errorText || helperText}
        error={!!errorText}
        sx={{
          width: "100%",
          height: "48px",
          "& .MuiInputBase-root": {
            height: "48px",
            fontWeight: 300,
          },
          "& fieldset": {
            borderRadius: "0px",
            boxShadow: "none",
            borderColor: "#DFE3E6",
            fontWeight: 300,
          },
          ...props.sx,
        }}
      />
      <div
        style={{
          display: "flex",
          visibility: props.maxLength ? "visible" : "hidden",
          fontSize: "13px",
          fontWeight: 300,
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <span>Skriv inn maks {props.maxLength} tegn</span>
        <span style={{ color: "#BABABA" }}>{`${
          field.value?.toString().length ?? 0
        }/${props.maxLength}`}</span>
      </div>
    </div>
  )
}

export default FootprintTextFieldFormik

function authConfig() {
  let AUTH_DOMAIN = ""
  let AUTH_CLIENT_ID = ""
  let AUTH_AUDIENCE = ""
  // const hostname = window && window.location && window.location.origin
  // if (hostname.indexOf("http://localhost:3000") >= 0) {
  //   AUTH_DOMAIN = "footprint-dev.eu.auth0.com"
  //   AUTH_CLIENT_ID = "Lqfi8fYFHmyEeTsqC7mNLWMu7Ef8JpA6"
  //   AUTH_AUDIENCE = "https://footprint-dev/api/v1"
  // } else if (
  //   hostname.indexOf("https://dev-footprint.amesto.no") >= 0 ||
  //   hostname.indexOf("https://42footprint.amesto.no") >= 0
  // ) {
  //   AUTH_DOMAIN = "footprint-dev.eu.auth0.com"
  //   AUTH_CLIENT_ID = "Lqfi8fYFHmyEeTsqC7mNLWMu7Ef8JpA6"
  //   AUTH_AUDIENCE = "https://footprint-dev/api/v1"
  // } else if (
  //   hostname.indexOf("https://footprint.amesto.no") >= 0 ||
  //   hostname.indexOf("https://42prod-footprint.amesto.no") >= 0
  // ) {
  //   AUTH_DOMAIN = "footprint-prod.eu.auth0.com"
  //   AUTH_CLIENT_ID = "sx93erdIZPoNTj5S4Qjk7GPYTkuSheoO"
  //   AUTH_AUDIENCE = "https://footprint/api/v1"
  // }

  AUTH_DOMAIN = "footprint-prod.eu.auth0.com"
  AUTH_CLIENT_ID = "sx93erdIZPoNTj5S4Qjk7GPYTkuSheoO"
  AUTH_AUDIENCE = "https://footprint/api/v1"
  return { AUTH_DOMAIN, AUTH_CLIENT_ID, AUTH_AUDIENCE }
}
export default authConfig

import {
  Div,
  FootprintTertiaryButton,
  useFootprintModule,
} from "modules/shared"
import React, { useState } from "react"
import { SectionSevenSideMenus } from "../ManualStyles"
import { SectionContainer } from "modules/shared"
import { Heading2, Paragraph } from "modules/shared"
import { Stack } from "@mui/material"
import { FootprintScrollArrow } from "modules/shared"
import { ASSET_IMAGES } from "utils/constants/paths"
import { SplitView } from "modules/layout"
import TransparencyResetConfirmationModal from "modules/transparencyAct/components/TransparencyResetCofirmationModal/TransparencyResetConfirmationModal"
import { useSelector } from "react-redux"
import { SystemRole } from "../../../../authentication"
export const Section7: React.FC<{
  locales?: any
  large?: boolean
  medium?: boolean
  small?: boolean
}> = ({ locales = {}, large, medium, small }) => {
  const [isResetting, setisResetting] = useState(false)
  const { activeModule } = useFootprintModule()
  const { permissions } = useSelector((state: any) => state.roles)
  return (
    <Div sx={{ position: "relative" }}>
      <Div
        sx={{ position: "absolute", left: "0px", top: "0px", height: "100%" }}
      >
        <img
          src={`${ASSET_IMAGES}/pages/climate/introduction/nav-menu-open.png`}
          alt="close-sidebar"
          style={{ height: "100%" }}
        />
      </Div>
      <SectionContainer id="page_manual_section7">
        <SplitView
          left={(small && "300px") || (medium && "424px") || "424px"}
          right={(small && "368px") || (medium && "504px") || "648px"}
          gap={(small && "16px") || "24px"}
        >
          <SectionSevenSideMenus
            sx={{
              width: (medium && "151px") || (small && "112px") || "154px",
              height: (medium && "108px") || (small && "78px") || "111.607px",
            }}
          />
          <Stack justifyContent="start">
            <span
              style={{
                letterSpacing: "2px",
                fontWeight: 300,
                lineHeight: "104%",
                marginTop: "80px",
                fontSize: (small && "15px") || "17px",
              }}
            >
              {locales["page_manual_section7_hint"] ?? "HVORDAN NAVIGERER JEG?"}
            </span>
            <Heading2
              sx={{
                marginBottom: "0px",
                fontSize: (small && "26px") || "32px",
              }}
            >
              {locales["page_manual_section7_title"] ??
                "Bruk navigeringsmenyenfor å bevege deg gjennomde 8 stegene."}
            </Heading2>
            <Paragraph
              sx={{
                marginTop: "12px",
                fontWeight: 300,
                fontSize: (small && "15px") || "17px",
              }}
            >
              {locales["page_manual_section7_paragraph"] ??
                `Hold musen din over menyen, så åpner den seg opp.\r\nI navigeringsmenyen ser du alle de 8 stegene og du kan enkelt klikke deg rundt.\r\nDu kan velge å følge prosessen kronologisk, eller du kan klikke deg litt frem og tilbake etter eget ønske.\r\nNår alle kravene i ett steg er besvart, blir steget grønt i navigeringsmenyen.\r\n`}
            </Paragraph>
            {permissions.userRole === SystemRole.Admin && ( // Condition added here
              <>
                <FootprintTertiaryButton
                  style={{ color: "#78B785", textAlign: "start" }}
                  onClick={() => setisResetting(true)}
                >
                  {locales[`${activeModule()}_finishPage_reset_title_btn`] ??
                    "Reset information"}
                </FootprintTertiaryButton>
              </>
            )}
          </Stack>
        </SplitView>
        <FootprintScrollArrow
          nextSelector="#page_manual_section8"
          style={{
            margin:
              (small && "60px auto") || (medium && "60px auto") || "80px auto",
          }}
        />
      </SectionContainer>
      <TransparencyResetConfirmationModal
        open={isResetting}
        onClose={() => {
          setisResetting(false)
        }}
      />
    </Div>
  )
}

import React from "react"
import { Div } from "modules/shared"
import DashboardMakingReport from "./components/DashboardMakingReport/DashboardMakingReport"
import DashboardReportDone from "./components/DashboardReportDone/DashboardReportDone"
import { getGeneratedReportAPI } from "modules/shared"
import { useParams } from "react-router-dom"
import { useQuery } from "react-query"
import useLocalesState from "utils/Localization/useLocalesState"
import MyBreadcrumbs from "modules/shared/components/Breadcrumbs/Breadcrumbs"
const Dashboard = () => {
  // const { firstTimeLogin, reportCreation, reportDone, logoClick } = useSelector(
  //   (state) => state.dashboard
  // );
  const [regeneratedReport, setRegenerate] = React.useState(false)
  const [reportFile, setReportFile] = React.useState(null)
  const { companyId } = useParams()
  const { isLoading, error, data } = useQuery(["list"], () =>
    getGeneratedReportAPI(companyId),
  )
  const [locales] = useLocalesState()

  const breadcrumbs = [
    {
      name: locales["breadcrumb_transparency"] ?? "Åpenhetsloven",
      url: "",
    },
    {
      name: locales["breadcrumb_climate_page_dashboard"] ?? "Dashboard",
      url: "",
    },
  ]
  return (
    <Div data-testid="dashboard-page">
      <MyBreadcrumbs breadcrumbs={breadcrumbs} />
      {isLoading ? (
        <div className="loader">
          <svg className="circular-loader" viewBox="25 25 50 50">
            <circle className="loader-path" cx="50" cy="50" r="20" />
          </svg>
        </div>
      ) : data?.data ? (
        <DashboardReportDone finalReport={data?.data} />
      ) : (
        <DashboardMakingReport />
      )}
    </Div>
  )
}

export default Dashboard

import { Div } from "modules/shared"
import { SplitView } from "modules/layout"
import { SectionContainer } from "modules/shared"
import React from "react"
import { HintTitle, SectionFiveSideMenus } from "../ManualStyles"
import { Heading2, Paragraph } from "modules/shared"
import { FootprintScrollArrow } from "modules/shared"
export const Section5: React.FC<{
  locales?: any
  large?: boolean
  medium?: boolean
  small?: boolean
}> = ({ locales = {}, large, medium, small }) => {
  return (
    <Div>
      <SectionContainer
        id="page_manual_section5"
        sx={{
          p:
            (large && "189px 7.29vw 0px") ||
            (medium && "81px 2.86vw 0px") ||
            (small && "81px 2.604vw 0px ") ||
            "189px 7.29vw 0px",
        }}
      >
        <SplitView
          left="424px"
          right="648px"
          gap="32px"
          sx={{ display: (small && "block") || "grid" }}
        >
          <Div
            sx={{
              mt: (small && "0") || "30px",
              textAlign: (small && "center") || "none",
            }}
          >
            <HintTitle sx={{ fontSize: (small && "15px") || "17px" }}>
              {locales["page_manual_section5_hint"] ??
                "HVOR FYLLER JEG UT SVARENE MINE?"}
            </HintTitle>
            <Heading2 sx={{ m: 0, fontSize: (small && "26px") || "32px" }}>
              {locales["page_manual_section5_title"] ??
                "Du kan fylle inn svaret for hvert rapporteringskrav i nedtrekksboksen."}
            </Heading2>
            <Paragraph sx={{ m: "0px", fontSize: (small && "15px") || "17px" }}>
              {locales["page_manual_section4_paragraph"] ??
                `De ulike rapporteringskravene vil kreve litt ulik type svar og har en maksgrense på antall tegn. Det står beskrevet i hver boks hva slags type svar som forventes av deg.\r\nHvis du sitter fast: Bruk eksempel-svaret som angitt i nedtrekksboksen.\r\nDet er en maksbegrensning på antall tegn fordi svaret ditt skal passe inn i det visuelle oppsettet på sluttrapporten.\r\n`}
            </Paragraph>
          </Div>
          <SectionFiveSideMenus
            sx={{ width: (small && "624px") || (medium && "592px") || "633px" }}
          />
        </SplitView>
        <FootprintScrollArrow
          nextSelector="#page_manual_section6"
          style={{
            margin:
              (small && "60px auto") || (medium && "60px auto") || "80px auto",
          }}
        />
      </SectionContainer>
    </Div>
  )
}

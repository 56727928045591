class HandleLogout {
  #logout = {
    setLogout: () => {},
  }

  setLogout(setLogout: any) {
    this.#logout.setLogout = setLogout
  }

  logout() {
    return this.#logout.setLogout()
  }
}

export default new HandleLogout()

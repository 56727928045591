import VerticalDefault from "../components/vertical-default/VerticalDefault"
import SoloPage from "../components/solo-page/SoloPage"
import WhitePage from "modules/layout/components/white-page/WhitePage"
const LAYOUT_NAMES = {
  VERTICAL_DEFAULT: "vertical-default",
  SOLO_PAGE: "solo-page",
  WHITE_PAGE: "white-page",
  VERTICAL_MINIMAL: "vertical-minimal",
}

const LAYOUTS = [
  {
    name: LAYOUT_NAMES.VERTICAL_DEFAULT,
    label: "Vertical Default",
    component: VerticalDefault,
  },
  {
    name: LAYOUT_NAMES.SOLO_PAGE,
    label: "Solo Page",
    component: SoloPage,
  },
  {
    name: LAYOUT_NAMES.WHITE_PAGE,
    label: "White Page",
    component: WhitePage,
  },
]

export { LAYOUTS, LAYOUT_NAMES }

import React from "react"
import { Typography, Grid, CardMedia } from "@mui/material"
import { Div } from "modules/shared"
import { ASSET_IMAGES } from "utils/constants/paths"
import useLocalesState from "utils/Localization/useLocalesState"
import { FootprintScrollArrow } from "modules/shared"
import "./MainSection.style.scss"
const MainSection = () => {
  const [locales] = useLocalesState()

  return (
    <Div className="MainSection">
      <Div className="section1">
        <CardMedia
          className="image"
          component="img"
          image={`${ASSET_IMAGES}/pages/dashboard/dashboard-first-time.png`}
          alt="dashboard-main-section-img"
          data-testid="dashboard-page-main-section-image"
        />
        <Div className="desc">
          <Div className="title">
            {locales["pages_dashboard_main_section_title_part1"] ??
              "Velkommen til din digitale"}
            <br />
            {locales["pages_dashboard_main_section_title_part2"] ??
              "bærekraftsavdeling"}
          </Div>
          <Div className="content-text">
            {locales["pages_dashboard_main_section_subtitle_part1"] ??
              `Vår misjon er å bidra til å gjøre det enkelt for virksomheter å komme i gang med bærekraftsarbeidet. Denne plattformen sikrer en effektiv prosess gjennom enkle verktøy og rapporteringsløsninger.`}
          </Div>

          <Div className="content-text">
            {locales["pages_dashboard_main_section_subtitle_part2"] ??
              `Plattformen vil bli løpende oppdatert i henhold til nye krav, regelverk og kundebehov.`}
          </Div>
        </Div>
      </Div>
      <Div className="nextSecBtn">
        <Typography variant="h4" color="black" fontWeight={300}>
          {locales["pages_dashboard_main_section_subtitle_start_here"] ??
            "Start her"}
        </Typography>

        <FootprintScrollArrow
          nextSelector="#our_products_section"
          style={{
            alignSelf: "center",
            marginTop: "20px",
            width: "28px",
            height: "13px",
          }}
        />
      </Div>
    </Div>
  )
}

export default MainSection

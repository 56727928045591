import { useRoutes } from "react-router-dom"
import { buildRoutes } from "utils"
import useFootprintApp from "./useFootprintApp"

let generatedRoutes: any = []

const useFootprintRoutes = (routes: any) => {
  const { rebuildRoutes }: any = useFootprintApp()

  if (rebuildRoutes) {
    generatedRoutes = buildRoutes([...routes])
  }
  return useRoutes(generatedRoutes)
}

export default useFootprintRoutes

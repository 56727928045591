import { CompanyStepDTO } from "../types/stepsDTO"
import { FootprintStepStage } from "../types/stepsTypes"
import { useStepsAPI } from "modules/steps/services/useStepsAPI"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import transparencyStepsMock from "../data/transparencySteps.json"
import climateStepsMock from "../data/climateSteps.json"
import _ from "lodash"
import { useParams } from "react-router-dom"
import { FootprintModule, ReactQueryKeys } from "modules/shared"
import { useQuery, useQueryClient } from "react-query"

const useStepsData = (module: FootprintModule) => {
  const queryClient = useQueryClient()
  const { id: companyId } = useSelector((state: any) => state.company)
  const { getSteps } = useStepsAPI()
  const { data, isLoading, error } = useQuery(
    [ReactQueryKeys.steps, module, companyId],
    () => {
      return getSteps(companyId || "", module)
    },
    // { staleTime: 300000 }
  ) // 5 mins

  const [mappedData, setmappedData] = useState<FootprintStepStage[] | null>(
    null,
  )

  useEffect(() => {
    if (!isLoading && data) setmappedData(mapJson(data!))
  }, [data])

  const mapJson = (apiJson: CompanyStepDTO[]) => {
    const mappedJson: FootprintStepStage[] = apiJson.map(
      (stage: CompanyStepDTO) => {
        //fix sections shape
        stage.sections.map((section: any) => {
          section["number"] = section.stepNumber

          section.steps.forEach((step: any) => {
            const ogInputs = { ...step.inputs }
            step.inputs = []
            //fix inputs shape
            for (const key in ogInputs) {
              if (
                typeof ogInputs[key] == "string" ||
                typeof ogInputs[key] == "number"
              ) {
                step.inputs.push({
                  name: key,
                  value: ogInputs[key],
                })
              } else if (
                ogInputs[key] &&
                typeof ogInputs[key] == "object" &&
                ogInputs[key].constructor === Array
              ) {
                // its array
                ;(ogInputs[key] as string[]).forEach((inputVal, idx) => {
                  step.inputs.push({
                    name: `${key}${idx + 1}`,
                    value: inputVal,
                  })
                })
              }
            }
          })
        })

        return stage as any
      },
    )

    //merge mappedJson with mockJson
    let stepsMocks
    switch (module) {
      case FootprintModule.Climate_accounting:
        stepsMocks = _.cloneDeep(climateStepsMock)

        break
      case FootprintModule.Transparency_act:
        stepsMocks = _.cloneDeep(transparencyStepsMock)
        break

      default:
        console.error("No module matches!!")
        break
    }
    const mergedSteps = _.values(
      _.merge(_.keyBy(stepsMocks, "stage"), _.keyBy(mappedJson, "stage")),
    )

    return mergedSteps as FootprintStepStage[]
  }

  const reFetch = () => {
    queryClient.invalidateQueries({ queryKey: [ReactQueryKeys.steps] })
  }

  return {
    isLoading,
    error,
    reFetch,
    data: mappedData,
  }
}
export default useStepsData

import { Div } from "modules/shared"
import React from "react"
import { SplitView } from "modules/layout"
import { SectionSevenSideMenus } from "../IntroductionStyles"
import { SectionContainer } from "modules/shared"
import { Heading2, Paragraph } from "modules/shared"
import { Stack } from "@mui/material"
import { FootprintScrollArrow } from "modules/shared"
import { ASSET_IMAGES } from "utils/constants/paths"
export const Section4: React.FC<{
  locales?: any
  large?: boolean
  medium?: boolean
  small?: boolean
}> = ({ locales = {}, large, medium, small }) => {
  return (
    <Div sx={{ position: "relative" }}>
      <Div
        sx={{ position: "absolute", left: "0px", top: "0px", height: "100%" }}
      >
        <img
          src={`${ASSET_IMAGES}/pages/climate/introduction/nav-menu-open.png`}
          alt="close-sidebar"
          style={{ height: "100%" }}
        />
      </Div>
      <SectionContainer id="page_manual_section4">
        <SplitView
          left={(small && "300px") || (medium && "424px") || "424px"}
          right={(small && "368px") || (medium && "504px") || "648px"}
          gap={(small && "16px") || "24px"}
        >
          <SectionSevenSideMenus
            sx={{
              width: (medium && "151px") || (small && "112px") || "154px",
              height: (medium && "108px") || (small && "78px") || "111.607px",
            }}
          />
          <Stack justifyContent="start">
            <span
              style={{
                letterSpacing: "2px",
                fontWeight: 300,
                lineHeight: "104%",
                marginTop: "80px",
                fontSize: (small && "15px") || "17px",
              }}
            >
              {locales["page_manual_section7_hint"] ?? "HVORDAN NAVIGERER JEG?"}
            </span>
            <Heading2
              sx={{
                marginBottom: "0px",
                fontSize: (small && "26px") || "32px",
              }}
            >
              {locales["climate_page_intro_section4_title"] ??
                `Bruk navigeringsmenyen for å bevegedeg mellom de 2 stegene.`}
            </Heading2>
            <Paragraph
              sx={{
                marginTop: "12px",
                fontWeight: 300,
                fontSize: (small && "15px") || "17px",
              }}
            >
              {locales["climate_page_intro_section4_paragraph"] ??
                `Hold musen din over menyen, så åpner den seg opp.\n\nI navigeringsmenyen ser du de 2 stegene og du kan enkelt klikke deg rundt.\n\nNår alle kravene i ett steg er besvart, blir steget grønt i navigeringsmenyen.\n\nSkulle du stoppe opp, så kan du flagge et rapporteringskrav.  Da  blir både nedtrekksmenyen og steget navigeringsmenyen rødt.\n\nFra navigeringsmenyen kan du også lett trykke deg videre til  dashboardet ditt, dine filer eller tilbake til denne veilederen.`}
            </Paragraph>
          </Stack>
        </SplitView>
        <FootprintScrollArrow
          nextSelector="#page_manual_section5"
          style={{
            margin:
              (small && "60px auto") || (medium && "60px auto") || "80px auto",
          }}
        />
      </SectionContainer>
    </Div>
  )
}

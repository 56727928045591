import { Div } from "modules/shared"
import EarbudsOutlinedIcon from "@mui/icons-material/EarbudsOutlined"
import FolderSharedOutlinedIcon from "@mui/icons-material/FolderSharedOutlined"
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined"
import DeviceHubOutlinedIcon from "@mui/icons-material/DeviceHubOutlined"
import CardOptionAction from "../CardOptionAction/CardOptionAction"
import useLocalesState from "utils/Localization/useLocalesState"
import { useNavigate } from "react-router-dom"
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags"
import { useParams } from "react-router-dom"
import "./ReportWaitDashboard.style.css"

const ReportWaitDashboard = () => {
  const { companyId } = useParams()
  const [locales] = useLocalesState()
  const navigate = useNavigate()

  return (
    <Div className="transparencyActPage">
      <Div className="title">
        {locales["pages_dashboard_Transparency_section_title"] ?? "Hjem"}
      </Div>
      <Div className="container">
        <CardOptionAction
          icon={<EarbudsOutlinedIcon color="secondary" fontSize="large" />}
          bg="#F1F3F3"
          color="secondary"
          title={
            locales["pages_dashboard_Transparency_section_item1"] ??
            "Veilederen"
          }
          onClick={() => navigate(`/${companyId}/transparency/manual`)}
        />
        <CardOptionAction
          icon={<EmojiFlagsIcon color="primary" fontSize="large" />}
          bg="#EDF7EF"
          color="primary"
          title={"Flaggede Krav"}
          hoverSecondary={true}
          onClick={() => {
            navigate(`/${companyId}/transparency/flaggedSteps`)
          }}
        />
        {/* <Grid item xs={12} md={4} {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <CardOptionAction
            icon={<RestoreOutlinedIcon color="secondary" fontSize="large" />}
            bg="#F1F3F3"
            color="secondary"
            title={
              locales["pages_dashboard_Transparency_section_item3"] ??
              "Last opp excel"
            }
          />{" "}
        </Grid> */}
      </Div>
      <Div className="container">
        <CardOptionAction
          icon={<FolderSharedOutlinedIcon color="primary" fontSize="large" />}
          bg="#EDF7EF"
          color="primary"
          title={
            locales["pages_dashboard_Transparency_section_item4"] ??
            "Dine Filer"
          }
          hoverSecondary={true}
          onClick={() =>
            navigate(`/${companyId}/transparency/files`, {
              state: { tabNumber: 0 },
            })
          }
        />
        <CardOptionAction
          icon={<GridViewOutlinedIcon color="secondary" fontSize="large" />}
          bg="#F1F3F3"
          color="secondary"
          title={
            locales["pages_dashboard_Transparency_section_item5"] ?? "Maler"
          }
          onClick={() =>
            navigate(`/${companyId}/transparency/files`, {
              state: { tabNumber: 3 },
            })
          }
        />
        <CardOptionAction
          icon={<DeviceHubOutlinedIcon color="primary" fontSize="large" />}
          bg="#EDF7EF"
          color="primary"
          title={
            locales["pages_dashboard_Transparency_section_item6"] ??
            "Leverandører"
          }
          hoverSecondary={true}
          onClick={() =>
            navigate(`/${companyId}/transparency/files`, {
              state: { tabNumber: 1 },
            })
          }
        />
      </Div>
    </Div>
  )
}
export default ReportWaitDashboard

import React, { useEffect } from "react"
import { Typography } from "@mui/material"
import { Div } from "modules/shared"
import { ASSET_IMAGES } from "utils/constants/paths"
import useLocalesState from "utils/Localization/useLocalesState"
import ImageList from "@mui/material/ImageList"
import { ImageStyleComponent } from "modules/transparencyAct/pages/dashboard/components/ProductImages/ProductImages"
import { useNavigate, useParams } from "react-router-dom"
import "./Products.style.css"
import { useQuery } from "react-query"
import { useCompaniesApi } from "modules/admin"
import { addCompanyReduxData } from "redux/actions/company"
import { useDispatch } from "react-redux"

const Products = () => {
  const [locales] = useLocalesState()
  const navigate = useNavigate()
  const { companyId } = useParams()
  const { getCompany } = useCompaniesApi()
  const dispatch = useDispatch()

  const { isLoading, error, data } = useQuery(["checkModule"], () =>
    getCompany(companyId ?? ""),
  )
  useEffect(() => {
    if (data) {
      dispatch(addCompanyReduxData(data))
    }
  }, [data])
  const hasClimate =
    data && data?.activeModules.length > 0
      ? data?.activeModules.some(
          (activeModule) => activeModule.module.name === "Climate Accounting",
        )
      : false
  const hasTransparency =
    data && data?.activeModules.length > 0
      ? data?.activeModules.some(
          (activeModule) => activeModule.module.name === "The Transparency Act",
        )
      : false
  return (
    <Div id="our_products_section">
      <Typography
        className="title"
        variant="h2"
        textAlign={"center"}
        fontWeight={800}
        p="0px"
        lineHeight={1}
      >
        {locales["pages_dashboard_products_section_title"] ?? "Velg produkt"}
      </Typography>
      <ImageList
        sx={{
          mb: "0px",
          mx: "auto",
          height: "auto",
          width: "auto",
        }}
        cols={3}
        rowHeight={312}
        gap={24}
      >
        <ImageStyleComponent
          img={`${ASSET_IMAGES}/pages/dashboard/product1.png`}
          title={
            locales["pages_dashboard_products_section_title_product1"] ??
            "Åpenhetsloven"
          }
          buttonTitle={
            hasTransparency
              ? locales["pages_dashboard_products_section_button_product1"] ??
                "Lag rapport"
              : locales["pages_dashboard_products_section_button_product3"] ??
                "Book møte"
          }
          openTheProject={() =>
            hasTransparency
              ? navigate(`/${companyId}/transparency/dashboard`)
              : window.open(
                  "https://www.amestoaccounthouse.no/amesto-footprint#form",
                  "_blank",
                )
          }
        />
        <ImageStyleComponent
          img={`${ASSET_IMAGES}/pages/dashboard/product2.png`}
          title={
            locales["pages_dashboard_products_section_title_product2"] ??
            "Klimaregnskap"
          }
          buttonTitle={
            hasClimate
              ? locales["pages_dashboard_products_section_button_product1"] ??
                "Lag rapport"
              : locales["pages_dashboard_products_section_button_product3"] ??
                "Book møte"
          }
          openTheProject={() =>
            hasClimate
              ? navigate(`/${companyId}/climate/dashboard`)
              : window.open(
                  "https://www.amestoaccounthouse.no/amesto-footprint#form",
                  "_blank",
                )
          }
        />
        <ImageStyleComponent
          img={`${ASSET_IMAGES}/pages/dashboard/product3.png`}
          blocked={true}
          title={
            locales["pages_dashboard_products_section_title_product3"] ??
            "Bærekraftsrapport"
          }
          buttonTitle={
            locales["pages_dashboard_products_section_button_product3"] ??
            "Book møte"
          }
          openTheProject={() =>
            window.open(
              "https://www.amestoaccounthouse.no/amesto-footprint#form",
              "_blank",
            )
          }
        />
        <ImageStyleComponent
          img={`${ASSET_IMAGES}/pages/dashboard/product4.png`}
          blocked={true}
          title={
            locales["pages_dashboard_products_section_title_product4"] ??
            "EUs Taksonomi"
          }
          buttonTitle={
            locales["pages_dashboard_products_section_button_product4"] ??
            "Kommer"
          }
          openTheProject={() =>
            window.open(
              "https://www.amestoaccounthouse.no/amesto-footprint#form",
              "_blank",
            )
          }
        />
        <ImageStyleComponent
          img={`${ASSET_IMAGES}/pages/dashboard/product5.png`}
          blocked={true}
          title={
            locales["pages_dashboard_products_section_title_product5"] ??
            "GRI rapport"
          }
          buttonTitle={
            locales["pages_dashboard_products_section_button_product4"] ??
            "Kommer"
          }
        />
        <ImageStyleComponent
          img={`${ASSET_IMAGES}/pages/dashboard/product6.png`}
          blocked={true}
          buttonTitle={
            locales["pages_dashboard_products_section_button_product4"] ??
            "Kommer"
          }
          title={
            locales["pages_dashboard_products_section_title_product6"] ?? "CSRD"
          }
        />
      </ImageList>
    </Div>
  )
}
export default Products

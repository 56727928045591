import React, { FunctionComponent, useState } from "react"
import SearchIcon from "@mui/icons-material/Search"
import DataTable from "modules/admin/components/Table/DataTable"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import useLocalesState from "utils/Localization/useLocalesState"
import { useCompaniesApi } from "modules/admin/services/useCompaniesApi"
import { TextField } from "@mui/material"
import { useLogsApi } from "modules/admin/services/useLogsApi"
import { useUserApi } from "modules/admin/services/usersApi"
import {
  FootprintTextField,
  Div,
  FootprintAutoCompleteFormik,
  FootprintAutoComplete,
  FootprintPrimaryButton,
} from "modules/shared"

interface Company {
  id: string
  companyName: string
}
interface HistoryProps {}
const History: FunctionComponent<HistoryProps> = () => {
  const [locales]: any = useLocalesState()
  const { getCompaniesList } = useCompaniesApi()
  const { getUsersList } = useUserApi()
  const { getHistoryLogs } = useLogsApi()
  const [curPage, setCurPage] = useState(0)
  const [pageCount, setpageCount] = useState(0)
  const [companiesList, setCompaniesList] = React.useState<Company[]>([])
  const [usersList, setUsersList] = React.useState<
    {
      userId: string
      email: string
    }[]
  >([])

  const [searchValues, setSearchValues] = React.useState<{
    user: string | undefined
    company: string | undefined
  }>({ user: undefined, company: undefined })

  const [historyList, setHistoryList] = useState<
    {
      id: string
      date: string | undefined
      time: string | undefined
      action: string
    }[]
  >([])

  React.useEffect(() => {
    getCompaniesList().then((data) => {
      setCompaniesList(data)
    })

    getUsersList().then((data) => {
      setUsersList(data)
    })
  }, [])

  React.useEffect(() => {
    onSearch()
  }, [curPage])

  const onUserChange = (ev: any, val: any) => {
    if (val.length >= 1) {
      const userData = val[0].email
      setSearchValues({ ...searchValues, user: userData })
    } else if (val.length == 0) {
      setSearchValues({ ...searchValues, user: undefined })
    }
  }

  const onCompanyChange = (ev: any, val: any) => {
    if (val.length >= 1) {
      const companyData = val[0].companyName as string
      setSearchValues({ ...searchValues, company: companyData })
    } else if (val.length == 0) {
      setSearchValues({ ...searchValues, company: undefined })
    }
  }

  const onSearch = async () => {
    const pagination = await getHistoryLogs(
      curPage,
      searchValues.company,
      searchValues.user,
    )
    const records = pagination.data.map((l) => {
      const dateTime = extractDateAndTime(new Date(l.createdAt))
      return {
        id: l.id.toString(),
        date: dateTime?.date,
        time: dateTime?.time,
        action: l.message,
      }
    })
    setHistoryList(records)
    setpageCount(pagination.count)
  }

  const extractDateAndTime = (date: Date): { date: string; time: string } => {
    const year = date.getFullYear().toString().padStart(4, "0")
    const month = (date.getMonth() + 1).toString().padStart(2, "0")
    const day = date.getDate().toString().padStart(2, "0")
    const hours = date.getHours().toString().padStart(2, "0")
    const minutes = date.getMinutes().toString().padStart(2, "0")
    const seconds = date.getSeconds().toString().padStart(2, "0")
    const milliseconds = date.getMilliseconds().toString().padStart(3, "0")

    const dateStr = `${year}-${month}-${day}`
    const timeStr = `${hours}:${minutes}:${seconds}.${milliseconds}`

    return { date: dateStr, time: timeStr }
  }

  const columns: GridColDef[] = [
    {
      field: "date",
      headerName: locales["page_admin_historyPage_date"],
      width: 150,
    },
    {
      field: "time",
      headerName: locales["page_admin_historyPage_time"],
      width: 150,
    },
    {
      field: "action",
      headerName: locales["page_admin_historyPage_action"],
      sortable: false,
      width: 600,
    },
  ]

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignContent: "center",
          alignItems: "center",
          height: "100px",
        }}
      >
        <FootprintAutoComplete
          style={{ flexGrow: "1", fontWeight: 300 }}
          fullWidth
          size="small"
          labelTopText={locales["page_admin_historyPage_business"]}
          limitTags={1}
          options={companiesList}
          getOptionLabel={(option) => option.companyName}
          getOptionDisabled={(option) => Boolean(searchValues?.company)}
          onChange={onCompanyChange}
          renderInput={(params) => <TextField {...params} />}
        />
        <FootprintAutoComplete
          style={{ flexGrow: "1", fontWeight: 300 }}
          fullWidth
          size="small"
          labelTopText={locales["page_admin_historyPage_user"]}
          options={usersList}
          getOptionLabel={(option) => option.email}
          getOptionDisabled={(option) => Boolean(searchValues?.user)}
          onChange={onUserChange}
          renderInput={(params) => <TextField {...params} />}
        />
        <FootprintPrimaryButton
          overrideColor="#78B785"
          style={{
            color: "#78B785",
            fontWeight: 500,
            fontSize: "14px",
            marginBottom: "0",
            marginTop: "24px",
            height: "43px",
            backgroundImage: `linear-gradient(to right, #ffffff 50%, #78B785 50%)`,
          }}
          onClick={onSearch}
        >
          {locales["page_admin_historyPage_actionBtn"]}
        </FootprintPrimaryButton>
      </div>
      <Div
        sx={{
          "& .MuiDataGrid-cellContent": {
            fontWeight: 300,
          },
        }}
      >
        <DataTable
          columns={columns}
          rows={historyList}
          rowCount={pageCount}
          setPaginationModel={setCurPage}
        ></DataTable>
      </Div>
    </div>
  )
}

export default History

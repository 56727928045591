import { GET_COMPANY_DATA, RESET_COMPANY_DATA } from "redux/constants/company"

const INIT_STATE = {
  companyName: null,
  activeModules: [],
  id: "",
  invoiceInfo: "",
  organizationNumber: "",
  status: "",
  subscription: 0,
}

const reducerFunc = (
  state = INIT_STATE,
  action: { type: any; payload: any },
) => {
  switch (action.type) {
    case GET_COMPANY_DATA: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case RESET_COMPANY_DATA: {
      return {
        companyName: null,
        activeModules: [],
        id: "",
        invoiceInfo: "",
        organizationNumber: "",
        status: "",
        subscription: 0,
      }
    }
    default: {
      return state
    }
  }
}

export default reducerFunc

import React from "react"

const LoaderPage = () => {
  return (
    <div className="loader">
      <svg className="circular-loader" viewBox="25 25 50 50">
        <circle className="loader-path" cx="50" cy="50" r="20" />
      </svg>
    </div>
  )
}

export default LoaderPage

import { Div, FootprintModule, FootprintPrimaryButton } from "modules/shared"
import { Steps } from "modules/steps"
import React, { FunctionComponent, useState } from "react"
import { If } from "react-if"
import styles from "./TransparencySteps.style.module.scss"
import SnackbarUtils from "utils/SnackbarUtils"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import useLocalesState from "utils/Localization/useLocalesState"

interface TransparencyStepsProps {}

const TransparencySteps: FunctionComponent<TransparencyStepsProps> = () => {
  const [curStep, setcurStep] = useState(0)
  const { id: companyId } = useSelector((state: any) => state.company)
  const [locales]: any = useLocalesState()
  const navigate = useNavigate()
  const onFinishPressed = () => {
    navigate(`/${companyId}/${FootprintModule.Transparency_act}/finishPage`)
  }

  return (
    <Div>
      <Steps
        onStepChange={(newStep) => {
          setcurStep(newStep)
        }}
      />
      <If condition={curStep == 7}>
        <Div className={styles.analysis}>
          <Div className={styles.spacing}></Div>
          <Div className={styles.title}>
            {locales["transparency_steps_endSection_title"]}
          </Div>
          <Div className={styles.header}>
            {locales["transparency_steps_endSection_header"]}
          </Div>
          <Div className={styles.desc}>
            {locales["transparency_steps_endSection_desc"]}
          </Div>
          <FootprintPrimaryButton
            style={{ width: "auto" }}
            onClick={onFinishPressed}
          >
            {locales["transparency_steps_endSection_btn"]}
          </FootprintPrimaryButton>
        </Div>
      </If>
    </Div>
  )
}

export default TransparencySteps

import React from "react"
import { Toolbar } from "@mui/material"
import AppBar from "@mui/material/AppBar"
import {
  SIDEBAR_STYLES,
  SIDEBAR_VARIANTS,
  SIDEBAR_VIEWS,
} from "modules/layout/data/layout"
import ThemeProvider from "@mui/material/styles/ThemeProvider"
import useFootprintLayoutHeader from "modules/layout/services/useFootprintLayoutHeader"
import { useFootprintHeaderTheme } from "modules/shared"
import useMediaQuery from "@mui/material/useMediaQuery"

const FootprintLayoutHeader = ({ children, sx }: any) => {
  const { headerOptions }: any = useFootprintLayoutHeader()
  const { headerTheme } = useFootprintHeaderTheme()
  const matches = useMediaQuery("(min-width:1440px)")

  const extraSx = React.useMemo(() => {
    if (sx) {
      const { width, ml, transition, zIndex, ...restSx } = sx
      return restSx
    }
  }, [sx])

  if (headerOptions?.hide) return null

  return (
    <ThemeProvider theme={headerTheme}>
      <AppBar
        elevation={0}
        sx={{
          width: matches ? "1440px" : "100%",
          left: "unset",
          right: "unset",
          ml: { sm: `0px` },
          transition: (theme) => theme.transitions.create(["width"]),
          zIndex: (theme) => extraSx,
        }}
      >
        <Toolbar sx={{ height: "100%", px: { lg: "60px", xs: 4 } }}>
          {children}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  )
}

export default FootprintLayoutHeader

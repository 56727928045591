import {
  SIDEBAR_ANCHOR_POSITIONS,
  SIDEBAR_SCROLL_TYPES,
  SIDEBAR_STYLES,
  SIDEBAR_VARIANTS,
  SIDEBAR_VIEWS,
} from "modules/layout/data/layout"

const layoutConfig = {
  sidebar: {
    open: true,
    hide: true,
    variant: SIDEBAR_VARIANTS.TEMPORARY,
    style: SIDEBAR_STYLES.CLIPPED_UNDER_HEADER,
    view: SIDEBAR_VIEWS.MINI,
    scrollType: SIDEBAR_SCROLL_TYPES.FIXED,
    anchor: SIDEBAR_ANCHOR_POSITIONS.LEFT,
  },
  header: {
    hide: false,
    fixed: true,
  },
  footer: {
    hide: false,
  },
  root: {
    sx: {
      background: "#F1F3F3",
    },
  },
  content: {
    sx: {
      my: 0,
      mx: "60px",
      px: 0,
      py: "0px",
      background: "#FFFFFF",
    },
  },
}

export default layoutConfig

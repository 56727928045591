import React, { useEffect, useState, useRef } from "react"
import MyFilesTabs from "modules/myFiles/components/tabs/MyFilesTabs"
import { Div, FootprintModule, Span } from "modules/shared"
import MyFilesTabBody from "modules/myFiles/components/tabBody/MyFilesTabBody"
import { useMyfilesApi } from "modules/myFiles/services/useMyFilesApi"
import { useLocation, useParams } from "react-router-dom"
import useLocalesState from "utils/Localization/useLocalesState"
import { useNavigate } from "react-router-dom"
import { ASSET_IMAGES } from "utils/constants/paths"
import "./Myfiles.style.css"
import { useSelector } from "react-redux"
import MyBreadcrumbs from "modules/shared/components/Breadcrumbs/Breadcrumbs"
const MyFiles = () => {
  const [locales] = useLocalesState()
  const { activeModules } = useSelector((state: any) => state.company)

  const climateTabs = [
    {
      name:
        locales[`${FootprintModule.Climate_accounting}_page_files_tab1`] ??
        "Rapport",
      value: "REPORTS",
    },
    {
      name:
        locales[`${FootprintModule.Climate_accounting}_page_files_tab2`] ??
        "Kvartalsvise rapporter",
      value: "QUARTER_REPORT",
    },
    {
      name:
        locales[`${FootprintModule.Climate_accounting}_page_files_tab3`] ??
        "Mine regnskapsfiler",
      value: "ACCOUNTING_FILE",
    },
  ]
  const transparencyTabs = [
    {
      name:
        locales[`${FootprintModule.Transparency_act}_page_files_tab1`] ??
        "Rapport",
      value: "REPORTS",
    },
    {
      name:
        locales[`${FootprintModule.Transparency_act}_page_files_tab2`] ??
        "Leverandører",
      value: "SUPPLIER",
    },
    {
      name:
        locales[`${FootprintModule.Transparency_act}_page_files_tab3`] ??
        "Mine utfylte maler",
      value: "MY_TEMPLATES",
    },
    {
      name:
        locales[`${FootprintModule.Transparency_act}_page_files_tab4`] ??
        "Malverk",
      value: "TEMPLATES",
    },
  ]
  const { state, pathname } = useLocation()
  const hasClimate = pathname.includes("/climate/")
  const hasTransparency = pathname.includes("/transparency/")
  const activeModuleFromPath = () => {
    if (hasClimate) {
      return "2"
    } else if (hasTransparency) {
      return "1"
    } else {
      return "-1"
    }
  }

  const navigate = useNavigate()
  const [activeModule, setActiveModule] = useState(activeModuleFromPath())
  const { userId, companyId } = useParams()

  const breadcrumbs = [
    {
      name:
        activeModule === "2"
          ? locales["breadcrumb_climate"] ?? "Klimaregnskap"
          : activeModule === "1"
            ? locales["breadcrumb_transparency"] ?? "Åpenhetsloven"
            : "",
      url:
        activeModule === "2"
          ? `/${companyId}/climate/dashboard`
          : `/${companyId}/transparency/dashboard`,
    },
    {
      name: locales["breadcrumb_climate_page_files"] ?? "Mine filler",
      url: "",
    },
  ]
  // const tabNumber = state?.tabNumber ?? 0;
  const adminAccessMail = state?.adminAccessMail ?? null
  const { getFileList, uploadReport } = useMyfilesApi()
  const curPage = useRef(0)
  const curTab = useRef(state?.tabNumber ?? 0)

  const [filesList, setfilesList] = useState<any[]>([])
  const [filesCount, setFilesCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const fetchFileList = async (
    tabName: string,
    clearList?: boolean | undefined,
  ) => {
    setIsLoading(true)
    const resp = await getFileList(
      companyId,
      activeModule,
      tabName,
      curPage.current,
      userId ? Number(userId.split("|")[1]) : -1,
    )
    if (resp?.data) {
      setFilesCount(resp.data.count)
      if (clearList) setfilesList(resp.data.data)
      else setfilesList([...filesList, ...resp.data.data])
    }
    setIsLoading(false)
  }

  const onUpload = async (file: any) => {
    await uploadReport(
      companyId,
      activeModule,
      file,
      activeModule === "1"
        ? transparencyTabs[curTab.current].value
        : climateTabs[curTab.current].value,
      userId ? Number(userId.split("|")[1]) : undefined,
    )
    fetchFileList(
      activeModule == "1"
        ? transparencyTabs[curTab.current].value
        : climateTabs[curTab.current].value,
      true,
    )
  }

  useEffect(() => {
    if (companyId) {
      fetchFileList(
        activeModule == "1"
          ? transparencyTabs[curTab.current ?? 0].value
          : climateTabs[curTab.current ?? 0].value,
        true,
      )
    } else {
      navigate("/companies")
    }
  }, [userId, companyId, activeModule])
  return (
    <>
      <MyBreadcrumbs breadcrumbs={breadcrumbs} />
      {userId && (
        <Div className="Myfiles-page-green-header">
          <Span className="text">
            {locales["page_files_header_admin_access_user"] ?? "VISNINGSMODUS"}:
          </Span>
          &nbsp;&nbsp;
          {adminAccessMail && (
            <Span className="text-admin">
              {adminAccessMail}
              <img
                src={`${ASSET_IMAGES}/pages/files/adminfilescloseIcon.svg`}
                alt=""
                onClick={() => {
                  navigate("/admin/users")
                }}
              />{" "}
            </Span>
          )}
        </Div>
      )}
      <Div className="Myfiles-page">
        <h1 className="header">
          {locales["header_label_files"] || "Mine filer"}
        </h1>
        <MyFilesTabs
          tabNames={activeModules.map((t: any) => t.module)}
          initialTab={Number(activeModule)}
          onChange={(idx: number) => {
            curTab.current = 0
            curPage.current = 0
            setActiveModule(idx.toString())
          }}
        />
        <MyFilesTabBody
          tabNames={
            activeModule === "1"
              ? transparencyTabs.map((t) => t.name)
              : activeModule === "2"
                ? climateTabs.map((t) => t.name)
                : []
          }
          curTab={Number(curTab.current)}
          onChange={(idx: number) => {
            curTab.current = idx
            curPage.current = 0
            fetchFileList(
              activeModule === "1"
                ? transparencyTabs[idx].value
                : climateTabs[idx].value,
              true,
            )
          }}
          onDelete={(file: any) => {
            setfilesList(filesList.filter((f) => f.id !== file.id))
          }}
          onUpload={onUpload}
          files={filesList}
          filesCount={filesCount}
          isLoading={isLoading}
          hideDownloadBtn={Number(curTab.current) === 3}
          companyId={companyId}
          fetchNext={async () => {
            if (isLoading) return
            curPage.current++
            await fetchFileList(
              activeModule === "1"
                ? transparencyTabs[curTab.current].value
                : climateTabs[curTab.current].value,
            )
          }}
        />
      </Div>
    </>
  )
}

export default MyFiles

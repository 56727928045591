import React from "react"
import { Div } from "modules/shared"
import Sidebar from "modules/shared/components/Sidebar/Sidebar"
import DashboardFirstTime from "./DashboardFirstTime/DashboardFirstTime"
const MainPage = () => {
  return (
    <Div data-testid="dashboard-page">
      <Sidebar />
      {<DashboardFirstTime />}
    </Div>
  )
}

export default MainPage

import { FunctionComponent } from "react"
import Div from "../Div/Div"
// import { modules } from "./constants/modules";
import styles from "./Sidebar.style.module.scss"
import useLocalesState from "utils/Localization/useLocalesState"
interface Module {
  title: string
  icon: string
  active: boolean
}
interface NeutralSidebarProps {
  onSelectModule: (compName: string) => void
  modules: Module[]
}

const NeutralSidebar: FunctionComponent<NeutralSidebarProps> = (props) => {
  const [locales] = useLocalesState()
  return (
    <Div>
      <div style={{ height: "82px" }}></div>
      {props.modules.map(
        (button: Module) =>
          button.active && (
            <div
              className={styles.title}
              key={button.title}
              onClick={() => {
                props.onSelectModule(button.title)
              }}
            >
              {locales[button.title]}
            </div>
          ),
      )}
    </Div>
  )
}

export default NeutralSidebar

import {
  FAILURE,
  REMOVE_ERROR,
  START_LOADING,
  SUCCESS,
  STOP_LOADING,
} from "redux/constants/status"
import { NOTIFICATION_TYPE } from "utils/constants/Enums"

const INIT_STATE = {
  isLoading: false,
  alertSeverity: null,
  alertMessage: null,
}
const reducerFunc = (
  state = INIT_STATE,
  action: { type: any; payload: { successMessage?: any; errorMessage?: any } },
) => {
  switch (action.type) {
    case START_LOADING: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case SUCCESS: {
      const { successMessage } = action.payload
      return {
        ...state,
        isLoading: false,
        alertSeverity: NOTIFICATION_TYPE.SUCCESS,
        alertMessage: successMessage,
      }
    }
    case FAILURE: {
      const { errorMessage } = action.payload
      return {
        ...state,
        isLoading: false,
        alertSeverity: NOTIFICATION_TYPE.ERROR,
        alertMessage: errorMessage,
      }
    }
    case REMOVE_ERROR: {
      return {
        ...state,
        isLoading: false,
        alertSeverity: null,
      }
    }
    case STOP_LOADING: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default: {
      return state
    }
  }
}
export default reducerFunc

import styled from "@mui/material/styles/styled"
import { ASSET_IMAGES } from "utils/constants/paths"

export const HintTitle = styled("span")({
  fontSize: "17px",
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: "21px" /* 123.529% */,
  letterSpacing: "2px",
  textTransform: "uppercase",
})

export const Intro = styled("p")({
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: "1.18vw",
  lineHeight: "1.458vw",
  marginTop: "132px",
  /* identical to box height, or 124% */
  textAlign: "center",
  letterSpacing: "0.139vw",
  textTransform: "uppercase",
})

export const SectionSixSideMenus = styled("div")({
  width: " 536px",
  height: " 536px",
  flexShrink: 0,
  margin: "auto",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundImage: `url("${ASSET_IMAGES}/pages/climate/introduction/section6.png")`,
})
export const SectionEightSideMenus = styled("div")({
  width: "535px",
  height: "420px",
  flexShrink: 0,
  margin: "auto",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundImage: `url("${ASSET_IMAGES}/pages/climate/introduction/section5.png")`,
})

export const SectionSevenSideMenus = styled("div")({
  width: "154px",
  height: "111.607px",
  flexShrink: 0,
  margin: "auto",
  marginRight: "24px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundImage: `url("${ASSET_IMAGES}/pages/manual/Frame_136.png")`,
})

import React from "react"
import PropTypes from "prop-types"

import { CssBaseline, Toolbar } from "@mui/material"
import {
  SIDEBAR_STYLES,
  SIDEBAR_VARIANTS,
  SIDEBAR_VIEWS,
} from "modules/layout/data/layout"
import FootprintLayoutHeader from "./components/FootprintLayoutHeader/FootprintLayoutHeader"
import FootprintLayoutFooter from "./components/FootprintLayoutFooter/FootprintLayoutFooter"
import { Div } from "modules/shared"
import useFootprintLayoutHeader from "modules/layout/services/useFootprintLayoutHeader"
import useFootprintLayoutRoot from "modules/layout/services/useFootprintLayoutRoot"
import useFootprintLayoutContent from "modules/layout/services/useFootprintLayoutContent"

const FootprintLayout = (props: any) => {
  const { headerOptions }: any = useFootprintLayoutHeader()
  const { rootOptions }: any = useFootprintLayoutRoot()
  const { contentOptions }: any = useFootprintLayoutContent()

  const headerHeightProps = React.useMemo(() => {
    if (props?.headerSx?.height) {
      return { height: props?.headerSx?.height }
    }
    return {}
  }, [props?.headerSx])

  return (
    <Div
      sx={{
        display: "flex",
        flex: 1,
        minWidth: 0,
        minHeight: "100%",
        flexDirection: "column",
        ...rootOptions?.sx,
      }}
      className="CmtLayout-root"
    >
      <CssBaseline />
      <FootprintLayoutHeader sx={props.headerSx}>
        {props.header}
      </FootprintLayoutHeader>

      <Div
        sx={{
          display: "flex",
          flex: 1,
          minWidth: 0,
          position: "relative",
        }}
        className="CmtLayout-wrapper"
      >
        <Div
          sx={{
            display: "flex",
            minWidth: 0,
            flex: 1,
            flexDirection: "column",
            minHeight: "100%",
            marginLeft: {
              sm: `20px`,
            },
            transition: (theme) => theme.transitions.create(["margin-left"]),
          }}
          className="CmtLayout-main"
        >
          {!headerOptions.hide && headerOptions.fixed && (
            <Toolbar sx={{ ...headerHeightProps }} />
          )}
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              flex: 1,
              flexDirection: "column",
              py: 4,
              px: { lg: 6, xs: 4 },
              ...(contentOptions?.sx ?? {}),
            }}
            className="CmtLayout-content"
          >
            {props.children}
          </Div>
          <FootprintLayoutFooter>{props.footer}</FootprintLayoutFooter>
        </Div>
      </Div>
    </Div>
  )
}

FootprintLayout.propTypes = {
  header: PropTypes.node,
  headerSx: PropTypes.object,
  sidebar: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node,
}

export default FootprintLayout

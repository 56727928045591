import { Button, Menu, MenuItem } from "@mui/material"
import React from "react"
import { Div } from "../../../shared"

interface HoverMenuProps {
  title: string
  menuItems: Array<{ label: string; onClick: VoidFunction }>
  menuItemsClass?: string
}
function HoverMenu(props: HoverMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  function handleClick(event: any) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget)
    }
  }

  function handleClose() {
    setAnchorEl(null)
  }

  return (
    <div>
      <Div
        aria-owns={anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onMouseOver={handleClick}
        sx={{
          background: "#f1f3f3",
          cursor: "pointer",
          borderRadius: "none",
          // "&:hover": { background: "#f1f3f3" },
        }}
      >
        {props.title}
      </Div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        sx={{ cursor: "pointer", marginTop: "28px", borderRadius: "none" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {props.menuItems.map((item: any, index: any) => {
          return (
            <MenuItem
              className={props.menuItemsClass}
              sx={{
                background: "#F1F3F3",
                fontSize: "12px",
                borderRadius: "none",
                "&:hover": {
                  textDecorationColor: "#78B785",
                  mozTextDecorationColor: "#78B785",
                  textUnderlineOffset: "5px",
                  textDecorationThickness: "1px",
                  background: "#E9ECED",
                },
              }}
              key={index}
              onClick={() => {
                item.onClick()
                handleClose()
              }}
            >
              {item.label}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}

export default HoverMenu

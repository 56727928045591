import { SnackbarMessage, OptionsObject, SnackbarKey } from "notistack"

/**
 * before using the class first set the snackbar using setSnackBar method and pass the enqueueSnackbar and closeSnackbar method from useSnackbar hook
 */
class SnackbarUtils {
  #snackBar = {
    enqueueSnackbar: () => {},
    closeSnackbar: () => {},
  }

  setSnackBar(
    enqueueSnackbar: {
      (
        message: SnackbarMessage,
        options?: OptionsObject | undefined,
      ): SnackbarKey
      (): void
    },
    closeSnackbar: { (key?: SnackbarKey | undefined): void; (): void },
  ) {
    this.#snackBar.enqueueSnackbar = enqueueSnackbar
    this.#snackBar.closeSnackbar = closeSnackbar
  }

  success(msg: string, options: OptionsObject = {}) {
    return this.toast(msg, {
      autoHideDuration: 5000,
      ...options,
      variant: "success",
    })
  }
  warning(msg: any, options: OptionsObject = {}) {
    return this.toast(msg, { ...options, variant: "warning" })
  }
  info(msg: any, options: OptionsObject = {}) {
    return this.toast(msg, { ...options, variant: "info" })
  }

  error(msg: string, options: OptionsObject = {}) {
    return this.toast(msg, { ...options, variant: "error" })
  }
  toast(msg: string, options: OptionsObject = {}) {
    const finalOptions = {
      variant: "default",
      ...options,
    }
    // @ts-ignore
    return this.#snackBar.enqueueSnackbar(msg, { ...finalOptions })
  }
  closeSnackbar(key: any) {
    // @ts-ignore
    this.#snackBar.closeSnackbar(key)
  }
}

export default new SnackbarUtils()

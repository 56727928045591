import { HistoryLogDTO, Pagination } from "modules/admin/types/adminApiTypes"
import axiosHelper from "../../../utils/axiosHelper"
import { GET_HISTORY_LOGS_URL } from "../../../utils/constants/URLs"

export const useLogsApi = () => {
  return {
    getHistoryLogs: async (
      page = 0,
      companyName?: string,
      email?: string,
    ): Promise<Pagination<HistoryLogDTO[]>> => {
      const { data } = await axiosHelper.get(GET_HISTORY_LOGS_URL, {
        params: { company_name: companyName, page, email: email },
      })
      return data
    },
  }
}

/**
 * View split left and right parts, default 50% 50% relation
 *
 */
import React from "react"
import clsx from "clsx"

const SplitView = (
  props: React.PropsWithChildren<{
    left?: string
    right?: string
    partCnt?: number
    gap?: string
    className?: string
    sx?: React.CSSProperties
  }>,
) => {
  if (props.partCnt) {
    const pros = 100 / props.partCnt
    const parts: string[] = []
    for (let i = 0; i < props.partCnt; i++) {
      parts.push(`${pros.toFixed(0)}%`)
    }
    return (
      <div
        className={clsx(props.className || "")}
        style={{
          gridTemplateColumns: `${parts.join(" ")}`,
          display: "grid",
          gridGap: props.gap,
          width: "100%",
          ...props.sx,
        }}
      >
        {props.children}
      </div>
    )
  }

  const left = props.left || "50%"
  const right = props.right || "50%"
  return (
    <div
      style={{
        gridTemplateColumns: `${left} ${right} `,
        display: "grid",
        width: "100%",
        gridGap: props.gap,
        ...props.sx,
      }}
    >
      {props.children}
    </div>
  )
}

export default SplitView

import React from "react"
import config from "utils/config"
import FootprintThemeContext from "./FootprintThemeContext"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import FootprintThemeHeader from "./FootprintThemeHeader/FootprintThemeHeader"
import FootprintThemeSidebar from "./FootprintThemeSidebar/FootprintThemeSidebar"
import FootprintThemeFooter from "./FootprintThemeFooter/FootprintThemeFooter"

const FootprintTheme: React.FC<{ children: any; init: any }> = ({
  children,
  init,
}) => {
  const [theme, setTheme] = React.useState(init.main)
  const [muiLocale, setMuiLocale] = React.useState(config.locale)

  const updateTheme = React.useCallback(
    (options: any) => {
      const newTheme = createTheme({ ...theme, ...options }, muiLocale)
      setTheme(newTheme)
    },
    [theme, setTheme],
  )

  const themeContextValue = React.useMemo(
    () => ({
      theme: createTheme(theme, muiLocale),
      setTheme: updateTheme,
      setMuiLocale: setMuiLocale,
    }),
    [theme, muiLocale],
  )

  return (
    <FootprintThemeContext.Provider value={themeContextValue}>
      <ThemeProvider theme={themeContextValue.theme}>
        <FootprintThemeHeader init={init.header}>
          <FootprintThemeSidebar init={init.sidebar}>
            <FootprintThemeFooter init={init.footer}>
              {children}
            </FootprintThemeFooter>
          </FootprintThemeSidebar>
        </FootprintThemeHeader>
      </ThemeProvider>
    </FootprintThemeContext.Provider>
  )
}

export default FootprintTheme

import styled from "@emotion/styled"
import { ASSET_IMAGES } from "utils/constants/paths"

export const ArrowImage = styled("div")({
  width: "3.05vw",
  height: "1.736vw",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundImage: `url("${ASSET_IMAGES}/pages/manual/Arrow down.svg")`,
  cursor: "pointer",
})

import * as React from "react"
import Typography from "@mui/material/Typography"
import { Div } from "modules/shared"
import Grid from "@mui/material/Grid"
import FolderSharedOutlinedIcon from "@mui/icons-material/FolderSharedOutlined"
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined"
import DeviceHubOutlinedIcon from "@mui/icons-material/DeviceHubOutlined"
import CardOptionAction from "../CardOptionAction/CardOptionAction"
import useLocalesState from "utils/Localization/useLocalesState"
import { useNavigate, useParams } from "react-router-dom"
import { downloadReportFromDonReport } from "../../../../services/generateReport"
const ReportDoneSection: React.FC<{ finalReport: any }> = ({ finalReport }) => {
  const [locales] = useLocalesState()
  const navigate = useNavigate()
  const { companyId } = useParams()

  return (
    <Div
      sx={{
        flexGrow: 1,
        margin: "auto",
        width: 1000,
        height: "auto",
        mt: "100px",
        mb: "160px",
      }}
    >
      <Typography
        variant="h2"
        textAlign={"center"}
        fontWeight={800}
        fontSize={{ xs: "18px", md: "45px" }}
        mb="40px"
      >
        {locales["pages_dashboard_Transparency_section_title"] ?? "Hjem"}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <CardOptionAction
            icon={
              <FolderSharedOutlinedIcon color="secondary" fontSize="large" />
            }
            bg="#F1F3F3"
            color="secondary"
            title={
              locales["pages_dashboard_report_done_section_item1"] ??
              "Dine Filer"
            }
            onClick={() =>
              navigate(`/${companyId}/transparency/files`, {
                state: { tabNumber: 0 },
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CardOptionAction
            icon={<DeviceHubOutlinedIcon color="primary" fontSize="large" />}
            bg="#EDF7EF"
            color="primary"
            hoverSecondary={true}
            title={
              locales["pages_dashboard_report_done_section_item2"] ??
              "Leverandører"
            }
            onClick={() =>
              navigate(`/${companyId}/transparency/files`, {
                state: { tabNumber: 1 },
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CardOptionAction
            icon={
              <DescriptionOutlinedIcon color="secondary" fontSize="large" />
            }
            bg="#F1F3F3"
            color="secondary"
            title={
              locales["pages_dashboard_report_done_section_item3"] ??
              "Åpenhetsloven 2023"
            }
            onClick={() => {
              finalReport?.blobDownloadUrl &&
                downloadReportFromDonReport(
                  finalReport?.blobDownloadUrl,
                  finalReport?.fileName,
                )
            }}
          />{" "}
        </Grid>
      </Grid>
    </Div>
  )
}
export default ReportDoneSection

import styled from "@mui/material/styles/styled"

export const Column = styled("div")({
  display: "flex",
  flexDirection: "column",
})
export const Row = styled("div")({
  display: "flex",
})

export const SectionContainer = styled(Column)({
  width: "100%",
  // height: "853px",
  alignItems: "center",
  justifyContent: "center",
  padding: "125px 7.29vw 0px",
  gap: "24px",
})
export const ExampleBox = styled("div")({
  backgroundColor: "#F1F3F3",
  width: "100%",
  height: "100%",
  padding: "2.746%",
  fontFamily: "Rubik",
})

export const TipsBox = styled("div")({
  backgroundColor: "#EDF7EF",
  width: "100%",
  height: "100%",
  padding: "2.746%",
  fontFamily: "Rubik",
  fontWeight: 300,
  fontSize: "1.18vw",
  lineHeight: "1.68vw",
})

export const Spacing = styled("div")({
  width: "100%",
  height: "80px",
  backgroundColor: "#F1F3F3",
})

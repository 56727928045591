import { GET_USER, GET_USERS_ROLES } from "redux/constants/admin"

const INIT_STATE = {
  userList: [],
  rolesList: [],
}
const reducerFunc = (
  state = INIT_STATE,
  action: { type: any; payload: any },
) => {
  switch (action.type) {
    case GET_USER: {
      return {
        ...state,
        userList: action.payload,
      }
    }
    case GET_USERS_ROLES: {
      return {
        ...state,
        rolesList: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default reducerFunc

import React, { FunctionComponent, useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"

interface Props {
  style?: React.CSSProperties
  suffixIcon?: React.ReactNode
  label?: string
  placeholder?: string
  value?: string
  maxLength?: number
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const FootprintTextField: FunctionComponent<Props> = (props) => {
  const [value, setValue] = useState(props.value)

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    if (props.onChange) {
      props.onChange(event)
    }
  }
  return (
    <div style={{ ...props.style }}>
      <div>{props.label}</div>
      <TextField
        value={value}
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{props.suffixIcon}</InputAdornment>
          ),
        }}
        inputProps={{
          placeholder: props.placeholder,
          maxLength: props.maxLength,
        }}
        sx={{
          width: "100%",
          height: "48px",
          "& .MuiInputBase-root": {
            height: "48px",
          },
          "& fieldset": {
            borderRadius: "0px",
            boxShadow: "none",
            borderColor: "#DFE3E6",
          },
          input: {
            "&::placeholder": {
              color: "#B0B9C0",
            },
          },
        }}
      />
      <div
        style={{
          display: "flex",
          visibility: props.maxLength ? "visible" : "hidden",
          fontSize: "13px",
          fontWeight: 300,
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <span>Skriv inn maks {props.maxLength} tegn</span>
        <span
          style={{ color: "#BABABA" }}
        >{`${value?.length}/${props.maxLength}`}</span>
      </div>
    </div>
  )
}

export default FootprintTextField

import { useQueryClient } from "react-query"
import {
  ClimateStepInputDTO,
  CompanyStepDTO,
  TransparencyStepInputDTO,
} from "../types/stepsDTO"
import axiosHelper from "utils/axiosHelper"
import {
  DELETE_STEPS_FILE_URL,
  GET_COMPANY_STEPS_URL,
  GET_STEPS_URL,
  RESET_TRANSPARANCY_ACT_STEPS_URL,
  UPDATE_CLIMATE_STEPS_URL,
  UPDATE_STEPS_FILE_URL,
  UPDATE_STEPS_URL as UPDATE_TRANSPARENCY_STEPS_URL,
} from "utils/constants/URLs"
import {
  FootprintModule,
  ReactQueryKeys,
  FootprintModuleData,
} from "modules/shared"

//TODO: rename file to stepsApi
export const useStepsAPI = () => {
  const queryClient = useQueryClient()
  return {
    getSteps: async (companyId: string, module: FootprintModule) => {
      const resp = await axiosHelper.get<CompanyStepDTO[]>(
        GET_COMPANY_STEPS_URL.replace("{companyId}", companyId),
        {
          params: {
            moduleId: FootprintModuleData[module].id,
          },
        },
      )
      return resp.data
    },
    updateStep: async (
      companyId: string,
      stepNumber: string,
      module: FootprintModule,
      data: (TransparencyStepInputDTO | ClimateStepInputDTO) & {
        status: "default" | "finished" | "flagged"
        stepNumber: string
      },
    ) => {
      queryClient.invalidateQueries({ queryKey: [ReactQueryKeys.steps] })
      if (module == FootprintModule.Climate_accounting) {
        return await axiosHelper.patch(
          UPDATE_CLIMATE_STEPS_URL.replace("{companyId}", companyId).replace(
            "{stepNumber}",
            stepNumber,
          ),
          data,
          {
            params: {
              moduleId: FootprintModuleData[module].id,
            },
          },
        )
      } else if (module == FootprintModule.Transparency_act) {
        return await axiosHelper.patch(
          UPDATE_TRANSPARENCY_STEPS_URL.replace(
            "{companyId}",
            companyId,
          ).replace("{stepNumber}", stepNumber),
          data,
          {
            params: {
              moduleId: FootprintModuleData[module].id,
            },
          },
        )
      }
    },
    updateStepFile: async (
      companyId: string,
      stepNumber: string,
      file: File,
      fileName: string,
      status: "default" | "finished" | "flagged",
      module: FootprintModule,
    ) => {
      // queryClient.invalidateQueries({ queryKey: [ReactQueryKeys.steps] });
      const formData: FormData = new FormData()
      formData.append(fileName, file, file.name)
      formData.append("status", status)

      return await axiosHelper.post(
        UPDATE_STEPS_FILE_URL.replace("{companyId}", companyId).replace(
          "{stepNumber}",
          stepNumber,
        ),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            moduleId: FootprintModuleData[module].id,
          },
        },
      )
    },
    deleteStepFile: async (
      companyId: string,
      stepNum: string,
      module: FootprintModule,
    ) => {
      return await axiosHelper.delete(
        DELETE_STEPS_FILE_URL.replace("{stepNumber}", stepNum).replace(
          "{companyId}",
          companyId,
        ),
        {
          params: {
            moduleId: FootprintModuleData[module].id,
          },
        },
      )
    },
    resetTransparencySteps: async (companyId: string) => {
      const resp = await axiosHelper.patch(
        RESET_TRANSPARANCY_ACT_STEPS_URL.replace("{companyId}", companyId),
      )
      queryClient.invalidateQueries()
      return resp
    },
  }
}

import React from "react"

import routes from "modules/app/routes/routes"
import useFootprintRoutes from "./useFootprintRoutes"
import { useSnackbar } from "notistack"
import SnackbarUtils from "utils/SnackbarUtils"
import { HandleLogout } from "modules/authentication"
import { useFootprintAuth } from "modules/authentication"

const AppRoutes = () => {
  const appRoutes = useFootprintRoutes(routes)
  const { setLogout }: any = useFootprintAuth()
  //1. UseHooks to get enqueueSnackbar, closeSnackbar
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const firstUpdate = React.useRef(true)

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      //2. Store both  enqueueSnackbar & closeSnackbar to class variables
      SnackbarUtils.setSnackBar(enqueueSnackbar as any, closeSnackbar)
      HandleLogout.setLogout(setLogout)
    }
  }, [])
  return <React.Fragment>{appRoutes}</React.Fragment>
}
export default AppRoutes

import { useLocation } from "react-router-dom"
import { FootprintModule, FootprintModuleData } from "../types/types"

const useFootprintModule = () => {
  const location = useLocation()
  const allModules = [
    FootprintModule.Climate_accounting,
    FootprintModule.Transparency_act,
  ]
  return {
    activeModule: () => {
      const climateRegex = new RegExp(
        "/" + FootprintModule.Climate_accounting + "/?",
      )
      const transparencyRegex = new RegExp(
        "/" + FootprintModule.Transparency_act + "/?",
      )
      if (location.pathname.match(climateRegex))
        return FootprintModule.Climate_accounting
      else if (location.pathname.match(transparencyRegex))
        return FootprintModule.Transparency_act
      else return null
    },
    allModules,
    getModuleDataFromId: (id: number) => {
      for (const moduleEnum of allModules) {
        const module = FootprintModuleData[moduleEnum]
        if (module.id == id) return module
      }
      return null
    },
  }
}

export default useFootprintModule

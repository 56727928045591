import { AxiosResponse } from "axios"
import { useParams } from "react-router-dom"
import axiosHelper from "utils/axiosHelper"
import {
  GET_CLIMATE_IMPACT_PER_PERSON_URL,
  GET_COMPANY_CAPRO_URL,
  GET_EMISSIONS_TOTAL_SCOPES_YEARLY_URL,
  GET_TOTAL_SCOPES_PER_QUARTER_URL,
  GET_TOTAL_SCOPES_PER_YEAR_URL,
} from "utils/constants/URLs"
import { TotalScopesPerYear } from "../data/chartsApiTypes"

export const useClimateOverviewApi = () => {
  const { companyId } = useParams()
  return {
    //queryKey climateOverviewScopes
    getTotalScopesPerYear: (
      year: number,
    ): Promise<AxiosResponse<TotalScopesPerYear>> => {
      return axiosHelper.get<TotalScopesPerYear>(
        GET_TOTAL_SCOPES_PER_YEAR_URL.replace("{companyId}", companyId!),
        {
          params: {
            year: year,
          },
        },
      )
    },
    //queryKey climateOverviewChart1
    getTotalScopesPerQuarter: (year: number): Promise<AxiosResponse<any>> => {
      return axiosHelper.get<any>(
        GET_TOTAL_SCOPES_PER_QUARTER_URL.replace("{companyId}", companyId!),
        {
          params: {
            year: year,
          },
        },
      )
    },
    //queryKey climateOverviewChart3
    getCompanyCapro: (year: number): Promise<AxiosResponse<any>> => {
      return axiosHelper.get<any>(
        GET_COMPANY_CAPRO_URL.replace("{companyId}", companyId!),
        {
          params: {
            year: year,
          },
        },
      )
    },

    //queryKey climateOverviewChart2
    getTotalScopesYearly: (year: number): Promise<AxiosResponse<any>> => {
      return axiosHelper.get<any>(
        GET_EMISSIONS_TOTAL_SCOPES_YEARLY_URL.replace(
          "{companyId}",
          companyId!,
        ),
        {
          params: {
            year: year,
          },
        },
      )
    },

    //queryKey climateOverviewChart4
    getClimateImpactPerPerson: (year: number): Promise<AxiosResponse<any>> => {
      return axiosHelper.get<any>(
        GET_CLIMATE_IMPACT_PER_PERSON_URL.replace("{companyId}", companyId!),
        {
          params: {
            year: year,
          },
        },
      )
    },
  }
}

import * as React from "react"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Dialog, { DialogProps } from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { Div } from "modules/shared"
import { Heading2 } from "modules/shared"

import "../ViewCompany/ViewCompany.style.css"
import { FootprintPrimaryButton } from "modules/shared"
import { FootprintTertiaryButton } from "modules/shared"

interface DeleteUserModalProps {
  open: boolean
  onClose: (val: boolean) => void
  deleteUserRow: any
  locales: any
}

const DeleteUserModal: React.FunctionComponent<DeleteUserModalProps> = (
  props,
) => {
  return (
    <Div>
      <Dialog
        fullWidth={true}
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ padding: "unset", paddingTop: "80px" }}
        >
          <Heading2 style={{ textAlign: "center" }}>
            {props.locales["label_user_delete_title"] ?? "Please confirm"}
          </Heading2>
        </DialogTitle>
        <DialogContent>
          <Box
            component={Div}
            sx={{
              paddingBottom: "60px !important",

              "& .MuiFormControl-root:not(.MuiTextField-root)": {
                mb: "12px",
                width: { xs: "100%", sm: "50%" },
              },

              "& .MuiFormControl-root.MuiFormControl-fluid": {
                width: "100%",
              },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Div style={{ textAlign: "center", marginBottom: "60px" }}>
                  {props.locales["label_user_delete_description"] ??
                    "Vil du virkelig slette denne bruker?"}
                </Div>
              </Grid>
              <Grid item xs={12}>
                <Div
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <FootprintPrimaryButton onClick={() => props.deleteUserRow()}>
                    {props.locales["label_user_delete_description_confirm"] ??
                      "Yes, I will"}
                  </FootprintPrimaryButton>

                  <FootprintTertiaryButton onClick={() => props.onClose(false)}>
                    {props.locales["label_user_delete_description_refused"] ??
                      "No"}
                  </FootprintTertiaryButton>
                </Div>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </Div>
  )
}

export default DeleteUserModal

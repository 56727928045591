import React from "react"
import { Div } from "modules/shared"
import ContactUs from "modules/transparencyAct/components/ContactUs/ContactUs"
import Products from "../../../../../transparencyAct/pages/dashboard/components/Products/Products"
import ReportDoneSection from "../ReportDoneSection/ReportDoneSection"
import DashboardNewsletter from "../Newsletter/Newsletter"
import ClimateCharts from "../Charts/ClimateCharts"
const DashboardReportDone: React.FC<{}> = () => {
  const Divider = () => (
    <Div sx={{ height: "80px", background: "#F1F3F3", p: 0, m: 0 }}></Div>
  )

  return (
    <Div>
      <ReportDoneSection />
      <Divider />
      <ClimateCharts />
      <Divider />
      <Products />
      <Divider />
      <DashboardNewsletter />
      <Divider />
      <ContactUs />
    </Div>
  )
}

export default DashboardReportDone

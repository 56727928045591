export const downloadFileHelper = async (
  data: RequestInfo | URL,
  name: any,
  fileExtension: any,
) => {
  const t = await fetch(data)
  const blob = await t.blob()
  const a = document.createElement("a")
  a.href = URL.createObjectURL(blob)
  let fileName = `${name}.${fileExtension}`
  a.setAttribute("download", fileName)
  a.click()
}
export const onDownloadFileFromUrl = async (
  templateUrl: RequestInfo | URL,
  fileName: string,
) => {
  const t = await fetch(templateUrl)
  const blob = await t.blob()
  const a = document.createElement("a")
  a.href = URL.createObjectURL(blob)
  a.setAttribute("download", fileName)
  a.click()
}
export const downloadFileBlobHelper = async (res: BlobPart, name: string) => {
  const url = window.URL.createObjectURL(new Blob([res]))

  const a = document.createElement("a")
  a.href = url
  let fileName = name
  a.setAttribute("download", fileName)
  a.click()
}

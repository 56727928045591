import { ASSET_IMAGES } from "utils/constants/paths"
import TransparencyActSidebar from "../TransparencyActSidebar"
import ClimateSidebar from "../ClimateSidebar"
export const modules = [
  {
    title: "sidebar_transparencyModule_title",
    icon: `${ASSET_IMAGES}/widgets/transparency_act_icon.svg`,
    sidebar: <TransparencyActSidebar />,
    active: false,
  },
  {
    title: "sidebar_climateModule_title",
    icon: `${ASSET_IMAGES}/widgets/climate_change_icon.svg`,
    sidebar: <ClimateSidebar />,
    active: false,
  },
  // {
  //   title: "unknown1",
  //   icon: `${ASSET_IMAGES}/widgets/shield.svg`,
  //   sidebar: <TransparencyActSidebar />,
  // },
  // {
  //   title: "unknown2",
  //   icon: `${ASSET_IMAGES}/widgets/request_quote.svg`,
  //   sidebar: <TransparencyActSidebar />,
  // },
]

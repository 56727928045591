import {
  FootprintTextFieldFormik,
  FootprintFileFieldFormik,
  Div,
  FootprintPrimaryButton,
  useFootprintModule,
} from "modules/shared"

import React, { FunctionComponent, useRef, useState } from "react"
import { FootprintStepInput } from "../types/stepsTypes"
import { Else, If, Then } from "react-if"
import { useStepsAPI } from "../services/useStepsAPI"
import SnackbarUtils from "utils/SnackbarUtils"
import { useSnackbar } from "notistack"
import { useParams } from "react-router-dom"
import { FormikProps, FormikValues, useField } from "formik"

interface StepsInputProps {
  fieldData: FootprintStepInput
  stepNum: string
  companyId: string
  onUpdateStep?: (stepNumber: string, stepData: any) => void
}

const StepsInput: FunctionComponent<StepsInputProps> = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [fileField, meta] = useField(props.fieldData.name)
  const [isUploading, setisUploading] = useState(false)
  const { updateStep, updateStepFile } = useStepsAPI()
  const { activeModule } = useFootprintModule()
  const { companyId } = useParams<{
    companyId: string
  }>()
  const fieldData = props.fieldData
  const { deleteStepFile } = useStepsAPI()
  const getFieldType = () => {
    switch (fieldData.type) {
      case "file":
      case "text":
        return "text"
      // case "number":
      //   return "number";
      case "email":
        return "email"
      default:
        return "text"
    }
  }

  const submitFileValues = async () => {
    const keyName = props.fieldData.name
    const file = fileField.value
    setisUploading(true)
    await updateStepFile(
      companyId || "",
      props.stepNum,
      file,
      keyName,
      "finished",
      activeModule()!,
    )
    // if (props.onUpdateStep) props.onUpdateStep(props.stepNum, file);
    setisUploading(false)
    SnackbarUtils.setSnackBar(enqueueSnackbar as any, closeSnackbar)
    SnackbarUtils.success("File uploaded successfully", {
      autoHideDuration: 5000,
    })
  }

  const deleteFile = async () => {
    setisUploading(true)
    await deleteStepFile(props.companyId, props.stepNum, activeModule()!)
    SnackbarUtils.setSnackBar(enqueueSnackbar as any, closeSnackbar)
    SnackbarUtils.success("File deleted successfully", {
      autoHideDuration: 5000,
    })
    setisUploading(false)
  }

  return (
    <If condition={fieldData.type == "file"}>
      <Then>
        <Div sx={{ display: "flex" }}>
          <FootprintFileFieldFormik
            name={fieldData.name}
            accept={{ [fieldData.allowedMimeType ?? "*"]: [] }}
            sx={{ width: "fit-content", margin: "auto" }}
            onDelete={deleteFile}
          />
          <FootprintPrimaryButton
            style={{
              position: "relative",
              top: fileField.value ? "2px" : "8px",
            }}
            disabled={!(fileField.value instanceof File) || isUploading}
            onClick={() => {
              submitFileValues()
            }}
            // disabled={props.isSubmitDisabled}
            // style={{ cursor: props.isSubmitDisabled ? "not-allowed" : "pointer" }}
            // textColor={state === "finished" ? "white !important" : undefined}
          >
            {isUploading ? "uploading..." : "Lagre"}
          </FootprintPrimaryButton>
        </Div>
      </Then>
      <Else>
        <If condition={fieldData.type == "label"}>
          <Then>
            <Div sx={{ textAlign: "center", marginLeft: "-190px" }}>
              {fieldData.value}
            </Div>
          </Then>
          <Else>
            <FootprintTextFieldFormik
              placeholder={fieldData.placeholder}
              maxLength={fieldData.charlimit}
              minRows={Boolean((fieldData.charlimit as number) > 150) ? 10 : 1}
              multiline={Boolean((fieldData.charlimit as number) > 150)}
              sx={{
                height: "auto",
                "& .MuiInputBase-root": {
                  height: "auto",
                },
              }}
              value={fieldData.value}
              name={fieldData.name}
              labelTopText={fieldData.label}
              type={getFieldType()}
            />
          </Else>{" "}
        </If>
      </Else>
    </If>
  )
}

export default StepsInput

import React from "react"
import {
  Div,
  Span,
  Heading1,
  Heading3,
  FootprintTextField,
  ReactQueryKeys,
} from "modules/shared"
import { Divider, Tooltip, useMediaQuery } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import { useCompaniesApi } from "modules/admin"
import { useNavigate } from "react-router-dom"
import useLocalesState from "utils/Localization/useLocalesState"
import {
  addCompanyReduxData,
  resetCompanyReduxData,
} from "redux/actions/company"
import { useDispatch } from "react-redux"
import { useQueryClient } from "react-query"

let debounceTimeout: NodeJS.Timeout

interface CompanyList {
  id: string
  companyName: string
}

export const Companies: React.FunctionComponent = () => {
  const [searchText, setSearchText] = React.useState("")
  const [searchResult, setSearchResult] = React.useState("")
  const [companiesList, setCompaniesList] = React.useState<any>([])
  const { getCompaniesList } = useCompaniesApi()
  const [locales]: any = useLocalesState()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const medium = useMediaQuery("(max-width: 1440px) and (min-width: 1240px)")
  const small = useMediaQuery("(max-width: 904px)")
  const large = useMediaQuery("(min-width: 1440px)")
  React.useEffect(() => {
    dispatch(resetCompanyReduxData())
  }, [])
  React.useEffect(() => {
    getCompaniesList(searchResult).then((data: any) => {
      if (data.length === 1 && !searchResult) {
        dispatch(addCompanyReduxData(data[0]))
        navigate(`/${data[0].id}/main-page`)
      } else {
        setCompaniesList(data)
      }
    })
  }, [searchResult])
  // Sort the companies array by the first character and number in the name
  const sortedCompanies = companiesList.sort((a: any, b: any) => {
    const aKey =
      a.companyName.charAt(0) + parseInt(a.companyName.match(/\d+/)?.[0] || "0")
    const bKey =
      b.companyName.charAt(0) + parseInt(b.companyName.match(/\d+/)?.[0] || "0")

    return aKey.localeCompare(bKey)
  })

  // Create the header label with the character before each list
  const createHeaderLabel = (char: string) => {
    return (
      <Heading3 sx={{ m: 0, p: 0, mt: (small && "32px") || "60px" }}>
        <Span sx={{ color: "var(--B0B9C0, #B0B9C0)" }}>{char}</Span>
      </Heading3>
    )
  }

  const onCompanySelected = (company: any) => {
    dispatch(addCompanyReduxData(company))
    navigate(`/${company.id}/main-page`)
  }

  return (
    <Div
      sx={{
        margin:
          (large && "100px 112px") ||
          (medium && "100px 40px") ||
          (small && "60px 40px") ||
          "60px 40px",
      }}
    >
      <Heading1
        sx={{
          textAlign: "center",
          m: 0,
          mb: (small && "32px") || "60px",
          fontSize: (small && "38px") || "45px",
        }}
      >
        {locales["label_header_companies"] ?? "Velg virksomhet"}
      </Heading1>
      <FootprintTextField
        style={{ flexGrow: "1" }}
        suffixIcon={<SearchIcon />}
        placeholder={"Søke etter et navn"}
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value)
          clearTimeout(debounceTimeout)
          debounceTimeout = setTimeout(() => {
            setSearchResult(e.target.value) // Trigger the search function after the delay
          }, 300)
        }}
      ></FootprintTextField>
      {sortedCompanies.map((company: any, index: any) => {
        const currentChar = company.companyName.charAt(0).toUpperCase()

        // Render the header label if it's the first company with a new character
        if (
          index === 0 ||
          currentChar !==
            sortedCompanies[index - 1].companyName.charAt(0).toUpperCase()
        ) {
          return (
            <Div key={company.companyName} sx={{ display: "contents" }}>
              {createHeaderLabel(currentChar)}
              <Divider color="var(--F1F3F3, #F1F3F3" sx={{ mb: "20px" }} />
              <Div
                sx={{
                  display: "inline-block",
                  width: "auto",
                  height: "44px",
                  padding: "8px 24px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  border: "1px solid var(--78B785, #78B785)",
                  background: "var(--White, #FFF)",
                  color: "var(--78B785, #78B785)",
                  textAlign: "center",
                  fontSize: "14px",
                  marginLeft: "24px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "28px" /* 200% */,
                  letterSpacing: "2.1px",
                  textTransform: "uppercase",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                key={company.id}
                onClick={() => onCompanySelected(company)}
              >
                <Tooltip title={company.companyName} placement="top">
                  <Span>{company.companyName}</Span>
                </Tooltip>
              </Div>
            </Div>
          )
        }

        return (
          <Div
            sx={{
              display: "inline-block",
              width: "auto",
              height: "44px",
              padding: "8px 24px",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              border: "1px solid var(--78B785, #78B785)",
              background: "var(--White, #FFF)",
              color: "var(--78B785, #78B785)",
              textAlign: "center",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "28px" /* 200% */,
              letterSpacing: "2.1px",
              textTransform: "uppercase",
              marginLeft: "24px",
              cursor: "pointer",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            key={company.id}
            onClick={() => onCompanySelected(company)}
          >
            <Tooltip title={company.companyName} placement="top">
              <Span>{company.companyName}</Span>
            </Tooltip>
          </Div>
        )
      })}
    </Div>
  )
}

export default Companies

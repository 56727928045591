import React from "react"
import { useField } from "formik"
import TextField from "@mui/material/TextField"
import Span from "modules/shared/components/Span/Span"
const FootprintTextFiledProfileStyle = (props: any) => {
  const [field, meta] = useField(props)
  const errorText = meta.error && meta.touched ? meta.error : ""
  const helperText = props?.helperText ?? ""
  return (
    <>
      <Span sx={{ fontWeight: 300, fontSize: "17px", lineHeight: "23px" }}>
        {props.label}
      </Span>
      <TextField
        {...props}
        {...field}
        placeholder={props.label ?? ""}
        id="outlined-basic"
        variant="outlined"
        size="small"
        label=""
        inputProps={{
          sx: {
            "&::placeholder": {
              fontSize: "14px",
              fontWeight: 300,
              color: "#BABABA",
              textTransform: "uppercase",
            },
          },
        }}
        sx={{
          "& input": {
            backgroundColor: "#ffffff",
            textTransform: "uppercase",
          },
          ...props.sx,
          "& .MuiFormHelperText-root": {
            margin: "0px",
          },
          "& fieldset": {
            borderRadius: "0px",
            boxShadow: "none",
            color: "#000000",
            fontSize: "14px",
            // border: "none",
            fontWeight: 300,
            textTransform: "uppercase",
          },
          "& label.Mui-focused": {
            color: "green",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "transparent",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "transparent",
            },
            "&:hover fieldset": {
              borderColor: "#B0B9C0",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#B0B9C0",
            },
          },
        }}
        helperText={errorText || helperText}
        error={!!errorText}
      />
    </>
  )
}

export default FootprintTextFiledProfileStyle

import { useDispatch, useSelector } from "react-redux"
import HoverMenu from "./HoverMenu"
import { refreshLocales } from "redux/actions/locales"
import { Locale } from "redux/types/localeTypes"
import { FunctionComponent, useEffect, useState } from "react"
import { SxProps } from "@mui/material"
import { Div } from "modules/shared"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
interface LangButtonProps {}

const LangButton: FunctionComponent<LangButtonProps> = (props) => {
  const allLangs: Array<"en" | "sv" | "no" | "da"> = ["no", "en", "da", "sv"]
  const dispatch = useDispatch()
  const { lang: activeLang } = useSelector(
    (state) => (state as any).locales as Locale,
  )
  const [Languages, setLanguages] =
    useState<Array<"en" | "sv" | "no" | "da">>(allLangs)

  useEffect(() => {
    setLanguages(allLangs.filter((lng) => lng != activeLang))
  }, [activeLang])

  return (
    <Div
      sx={{
        display: "flex",
        alignItems: "center",
        color: "#B0B9C0",
      }}
    >
      <HoverMenu
        title={activeLang}
        menuItems={Languages.map((i: any) => {
          return {
            label: i.toUpperCase(),
            onClick: () => dispatch(refreshLocales(i) as any),
          }
        })}
      />
      <ArrowDropDownIcon />
    </Div>
  )
}

export default LangButton

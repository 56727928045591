import { FunctionComponent, useEffect, useRef, useState } from "react"
import StepsInput from "./StepsInput"
import FlagButtons from "./FlagButtons"
import {
  Form,
  Formik,
  FormikProps,
  FormikValues,
  useFormikContext,
} from "formik"
import * as yup from "yup"
import { Div, FootprintModule, useFootprintModule } from "modules/shared"
import { useStepsAPI } from "modules/steps/services/useStepsAPI"
import { useParams } from "react-router-dom"
import { FootprintStepInput } from "../types/stepsTypes"
import { When } from "react-if"
import { StepNumber } from "modules/transparencyAct/pages/manual/ManualStyles"
interface StepFormProps {
  inputs: FootprintStepInput[]
  stepState: "flagged" | "default" | "finished"
  stepNumber: string
  onUpdateStep?: (stepNumber: string, stepData: any) => void
}

const StepForm: FunctionComponent<StepFormProps> = (props) => {
  const { updateStep, updateStepFile } = useStepsAPI()
  const [isUpdatingStep, setIsSaving] = useState(false)
  const formRef = useRef<FormikProps<FormikValues>>()
  const { companyId } = useParams<{
    companyId: string
  }>()
  const { activeModule } = useFootprintModule()

  const generateInitialValues = () => {
    const initialValues: any = {}
    props.inputs.forEach((input) => {
      initialValues[input.name] = input.value ?? ""
    })
    return initialValues
  }

  const validationType = (input: FootprintStepInput) => {
    if (!input.required) return
    let validation
    switch (input.type) {
      case "text":
        validation = yup.string().required()
        if (input.charlimit) validation = validation.max(input.charlimit)
        break
      case "number": // have issues in validation
        validation = yup
          .number()
          .typeError("This field only accepts number")
          .required()
        if (input.charlimit) {
          validation = validation.test(
            "max-length",
            `must be less than or equal to ${input.charlimit}`,
            (value) => {
              const stringValue = String(value)
              return stringValue.length <= (input as any).charlimit
            },
          )
        }

        break
      case "file":
        // validation = yup.mixed().test("file-val", function (value) {
        //   if (!value) return this.createError({ message: "File is required" });
        //   else if (typeof value == "string" && value.startsWith("http"))
        //     return true;
        //   else if (typeof value != "object")
        //     return this.createError({ message: "" });
        //   return value instanceof File;
        // });
        // return false;
        break
      default:
        validation = yup.string().required()
    }
    return validation
  }

  const generateValidationSchema = () => {
    const validationSchema: any = {}
    props.inputs.forEach((input) => {
      validationSchema[input.name] = validationType(input)
    })
    return yup.object(validationSchema)
  }

  const getFormValue = () => {
    const formValues = formRef.current?.values
    console.log("Formik values", formValues)
    if (!formValues) return null
    const updatedValues = { ...formValues, fileValues: [] } as any

    Object.keys(formValues).forEach((key) => {
      if (formValues[key] instanceof File) {
        updatedValues.fileValues.push({ keyName: key, file: formValues[key] })
        delete updatedValues[key]
      } else if (key == "logo" || key == "file") {
        // only key files on the whole steps
        //if we reached this condition, it means that their is file uploaded before by the user so we just ignore it when submitting the form
        delete updatedValues[key]
      } else if (key == "logo_name" || key == "file_name") {
        delete updatedValues[key]
      }
    })

    return updatedValues
  }

  const handleSubmit = async () => {
    if (!formRef.current) return
    setIsSaving(true)

    const formValue = getFormValue() as any
    // const fileValues = formValue.fileValues as any;
    delete formValue.fileValues

    console.log("textValues", formValue)
    // console.log("files", fileValues);s

    const isTextValuesEmpty = Object.keys(formValue).length === 0
    try {
      if (!isTextValuesEmpty) await submitTextValues(formValue)

      // if (fileValues.length > 0) await submitFileValues(fileValues);
    } catch (e) {}

    setIsSaving(false)
  }

  const submitTextValues = async (values: any) => {
    values.status = "finished"
    values.stepNumber = props.stepNumber
    await updateStep(companyId || "", props.stepNumber, activeModule()!, values)
    if (props.onUpdateStep) props.onUpdateStep(props.stepNumber, values)
  }

  const submitFileValues = async (values: any[]) => {
    return
    const inputObj = values[0]
    const keyName = inputObj.keyName
    const file = inputObj.file
    await updateStepFile(
      companyId || "",
      props.stepNumber,
      file,
      keyName,
      "finished",
      activeModule()!,
    )
    // if (props.onUpdateStep) props.onUpdateStep(props.stepNumber, inputObj);
  }

  const flagStep = async () => {
    setIsSaving(true)

    const updates: any = { status: "flagged", stepNumber: props.stepNumber }
    try {
      await updateStep(
        companyId || "",
        props.stepNumber,
        activeModule()!,
        updates,
      )
    } catch (e) {}
    if (props.onUpdateStep) props.onUpdateStep(props.stepNumber, updates)

    setIsSaving(false)
  }

  const shouldHideBtns = () => {
    const c1 =
      props.stepNumber == "1.3.a" &&
      activeModule()! == FootprintModule.Transparency_act
    const c2 =
      props.stepNumber == "1.1.f" &&
      activeModule()! == FootprintModule.Climate_accounting
    return !(c1 || c2)
  }
  return (
    <Formik
      enableReinitialize
      innerRef={formRef as any}
      initialValues={generateInitialValues()}
      validationSchema={generateValidationSchema()}
      onSubmit={() => {}}
      validateOnMount
    >
      {/* NOTE: if a step contains files and fields at the same time
        files should be before fields otherwise, unknwon behavior will occur
    */}
      {({ isValid }) => (
        <Form>
          <Div sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            {props.inputs.map((input: FootprintStepInput) => (
              <StepsInput
                fieldData={input}
                key={input.name}
                stepNum={props.stepNumber}
                companyId={companyId!}
                onUpdateStep={props.onUpdateStep}
              />
            ))}
          </Div>
          <When condition={shouldHideBtns()}>
            <FlagButtons
              isLoading={isUpdatingStep}
              sx={{ marginTop: "60px" }}
              state={props.stepState}
              onSubmit={handleSubmit}
              isSubmitDisabled={!isValid}
              onFlag={flagStep}
            />
          </When>
        </Form>
      )}
    </Formik>
  )
}

export default StepForm

export const TASK_DISPLAY_MODE = {
  VIEW_TASK: "VIEW_TASK",
  EDIT_TASK: "EDIT_TASK",
  UPLOAD_FILES: "UPLOAD_FILES",
}

export const DISPLAY_MODE = {
  CREATE: "CREATE",
  VIEW: "VIEW",
  EDIT: "EDIT",
}

//region Templates
export const SCHEDULER_TYPE = {
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
  MONTHLY_ORDINAL: "MONTHLY_ORDINAL",
  YEARLY: "YEARLY",
  YEARLY_ORDINAL: "YEARLY_ORDINAL",
}

export const DAYS = {
  MONDAY: { id: 1, value: "Monday", workingDay: true },
  TUESDAY: { id: 2, value: "Tuesday", workingDay: true },
  WEDNESDAY: { id: 3, value: "Wednesday", workingDay: true },
  THURSDAY: { id: 4, value: "Thursday", workingDay: true },
  FRIDAY: { id: 5, value: "Friday", workingDay: true },
  SATURDAY: { id: 6, value: "Saturday", workingDay: false },
  SUNDAY: { id: 7, value: "Sunday", workingDay: false },
}

export const MAP_TO_DAYS = {
  1: DAYS.MONDAY.value,
  2: DAYS.TUESDAY.value,
  3: DAYS.WEDNESDAY.value,
  4: DAYS.THURSDAY.value,
  5: DAYS.FRIDAY.value,
  6: DAYS.SATURDAY.value,
  7: DAYS.SUNDAY.value,
}

export const WEEKS = {
  FIRST: { id: 1, value: "First" },
  SECOND: { id: 2, value: "Second" },
  THIRD: { id: 3, value: "Third" },
  FOURTH: { id: 4, value: "Fourth" },
}

export const MAP_TO_WEEKS = {
  1: WEEKS.FIRST.value,
  2: WEEKS.SECOND.value,
  3: WEEKS.THIRD.value,
  4: WEEKS.FOURTH.value,
}

export const MONTHS = {
  JANUARY: { id: 1, value: "January", numberOfDays: 31 },
  FEBRUARY: { id: 2, value: "February", numberOfDays: 28 },
  MARCH: { id: 3, value: "March", numberOfDays: 31 },
  APRIL: { id: 4, value: "April", numberOfDays: 30 },
  MAY: { id: 5, value: "May", numberOfDays: 31 },
  JUNE: { id: 6, value: "June", numberOfDays: 30 },
  JULY: { id: 7, value: "July", numberOfDays: 31 },
  AUGUST: { id: 8, value: "August", numberOfDays: 31 },
  SEPTEMBER: { id: 9, value: "September", numberOfDays: 30 },
  OCTOBER: { id: 10, value: "October", numberOfDays: 31 },
  NOVEMBER: { id: 11, value: "November", numberOfDays: 30 },
  DECEMBER: { id: 12, value: "December", numberOfDays: 31 },
}

export const MAP_TO_MONTHS = {
  1: MONTHS.JANUARY.value,
  2: MONTHS.FEBRUARY.value,
  3: MONTHS.MARCH.value,
  4: MONTHS.APRIL.value,
  5: MONTHS.MAY.value,
  6: MONTHS.JUNE.value,
  7: MONTHS.JULY.value,
  8: MONTHS.AUGUST.value,
  9: MONTHS.SEPTEMBER.value,
  10: MONTHS.OCTOBER.value,
  11: MONTHS.NOVEMBER.value,
  12: MONTHS.DECEMBER.value,
}
//endregion

//region Files
export const FILES_STATUS = {
  CREATED: "CREATED",
  DELETED: "DELETED",
  ARCHIVED: "ARCHIVED",
}
//endregion

export const NOTIFICATION_TYPE = {
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
  SUCCESS: "success",
}

export const LANGUAGES = {
  ENGLISH: "en",
  DANISH: "da",
  NORWEGIAN: "no",
  SWEDISH: "sv",
}

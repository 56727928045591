import {
  CompanyCreationDto,
  CompanyDto,
  CompanyEntity,
  Pagination,
} from "modules/admin/types/adminApiTypes"
import axiosHelper from "../../../utils/axiosHelper"
import {
  CREATE_COMPANY_URL,
  DELETE_COMPANY_URL,
  GET_ACTIVE_MODULES_LIST_URL,
  GET_ALL_COMPANIES_URL,
  UPDATE_COMPANY_URL,
  GET_ALL_COMPANIES_LIST_URL,
  GET_COMPANY_URL,
  GET_ALL_COMPANIES_STATUS_LIST_URL,
  UPDATE_COMPANY_VISIBILITY,
  SEND_COMPLETE_STATEMENT_NOTIFICATION,
} from "../../../utils/constants/URLs"
import { useSelector } from "react-redux"

export const useCompaniesApi = () => {
  return {
    createCompany: async (company: CompanyCreationDto) => {
      const { data } = await axiosHelper.post(CREATE_COMPANY_URL, company)
      return data
    },
    updateCompany: async (id: string, company: CompanyCreationDto) => {
      const { data } = await axiosHelper.put(
        UPDATE_COMPANY_URL.replace("{id}", id),
        company,
      )
      return data
    },
    getAllCompanies: async (
      page = 0,
      companyName?: string,
    ): Promise<Pagination<CompanyDto[]>> => {
      const { data } = await axiosHelper.get(GET_ALL_COMPANIES_URL, {
        params: { name: companyName, page },
      })
      return data
    },
    getCompany: async (id: string): Promise<CompanyEntity> => {
      const { data } = await axiosHelper.get(
        GET_COMPANY_URL.replace("{id}", id),
      )
      return data
    },
    getActiveModuleList: async () => {
      const { data } = await axiosHelper.get(GET_ACTIVE_MODULES_LIST_URL)
      return data as { id: number; name: string }[]
    },
    deleteCompany: async (id: string) => {
      const { data } = await axiosHelper.put(
        DELETE_COMPANY_URL.replace("{id}", id),
      )
      return data
    },
    getCompaniesList: async (companyName: string = "") => {
      const { data } = await axiosHelper.get(GET_ALL_COMPANIES_LIST_URL, {
        params: {
          companyName,
        },
      })
      return data as { id: string; companyName: string }[]
    },
    getCompaniesStatusList: async () => {
      const { data } = await axiosHelper.get(GET_ALL_COMPANIES_STATUS_LIST_URL)
      return data as { id: string; name: string }[]
    },
    updateCompanyClimateReportVisibilty: async (
      companyId: string,
      moduleId: number,
      isVisible: boolean,
    ) => {
      return await axiosHelper.put(
        UPDATE_COMPANY_VISIBILITY.replace("{companyId}", companyId),
        {
          moduleId,
          isVisible,
        },
      )
    },
    notifyCompanyClimateReportReady: async (companyId: string) => {
      return await axiosHelper.get(
        SEND_COMPLETE_STATEMENT_NOTIFICATION.replace("{companyId}", companyId),
      )
    },
  }
}

import {
  BodyText,
  Heading1,
  Heading2,
  Heading4,
  SectionContainer,
  Spacing,
  Div,
  SimpleLoader,
  FootprintScrollArrow,
  FootprintPrimaryButton,
  useFootprintModule,
} from "modules/shared"
import useStepsData from "./services/useStepsData"
import { Grid, CardMedia } from "@mui/material"
import { ASSET_IMAGES } from "utils/constants/paths"
import _ from "lodash"
import StepsSection from "./components/StepsSection"
import useLocalesState from "utils/Localization/useLocalesState"
import styles from "./styles/FlaggedSteps.style.module.scss"
interface FlaggedStepsProps {}

const FlaggedSteps: React.FunctionComponent<FlaggedStepsProps> = () => {
  const { activeModule } = useFootprintModule()
  const {
    isLoading,
    error,
    reFetch: getStepsData,
    data,
  } = useStepsData(activeModule()!)
  const [locales]: any = useLocalesState()

  const filteredData = () => {
    const flaggedStages = data?.filter((stage) => stage.status == "flagged")
    let cloned = _.cloneDeep(flaggedStages)

    cloned!.forEach((stage) => {
      stage.sections.forEach((section) => {
        //overwrite steps with only flagged steps
        section.steps = section.steps.filter((step) => step.status == "flagged")
      })

      //overwrite sections with only flagged sections
      stage.sections = stage.sections.filter(
        (section) => section.steps.length > 0,
      )
    })

    const sections = cloned!.map((stage) => stage.sections).flat()

    return sections
  }

  const onUpdateStep = async () => {
    await getStepsData()
  }

  if (isLoading || !data) return <SimpleLoader></SimpleLoader>
  else if (error) return <Heading1>Error</Heading1>

  return (
    <>
      <Div className={styles.flaggedSteps}>
        <Div className={styles.firstSection}>
          <CardMedia
            component="img"
            className={styles.imgCard}
            image={`${ASSET_IMAGES}/widgets/flaggedRR.png`}
            alt="flaggedRR-img"
            data-testid="flaggedRR-image"
          />
          <Div className={styles.textDesc}>
            <Heading2>{locales["pages_flagged_title"]}</Heading2>
            <BodyText color="black">{locales["pages_flagged_desc"]}</BodyText>
            <a
              href="https://www.amestoaccounthouse.no/amesto-footprint#form"
              target="_blank"
            >
              <FootprintPrimaryButton version="primary">
                {locales["pages_flagged_booking"]}
              </FootprintPrimaryButton>
            </a>
          </Div>
        </Div>

        <Div className={styles.nextSec}>
          <Heading4>{locales["pages_flagged_nextSec"]}</Heading4>

          <FootprintScrollArrow
            nextSelector="#our_products_section"
            className={styles.nextSecArrow}
          />
        </Div>
      </Div>
      <Spacing />
      <SectionContainer className={styles.stepsContainer}>
        <Heading2>{locales["pages_flagged_flaggedSec"]}:</Heading2>
        {filteredData().length == 0 && (
          <Heading4>{locales["pages_flagged_flaggedSecSub"]}</Heading4>
        )}
        {filteredData().map((section: any, idx: any) => (
          <StepsSection
            className={styles.stepsSections}
            key={section?.number}
            section={section}
            onUpdateStep={(stepNumber, stepData) => onUpdateStep()}
          />
        ))}
      </SectionContainer>
    </>
  )
}

export default FlaggedSteps

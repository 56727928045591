import React from "react"
import FootprintContentLayoutProvider from "./FootprintContentLayout/FootprintContentLayoutProvider"
import useFootprintApp from "./useFootprintApp"
import { LAYOUTS, FootprintLayoutProvider } from "modules/layout"
import config from "utils/config"
import { useFootprintAuth } from "modules/authentication"
import { refreshLocales } from "redux/actions/locales"
import { useDispatch } from "react-redux"
import { addCompanyReduxData } from "redux/actions/company"
import { useCompaniesApi } from "modules/admin"
const AppLayout = (props: any) => {
  const { activeLayout }: any = useFootprintApp()
  const { isLoading } = useFootprintAuth()
  const { getCompany } = useCompaniesApi()
  const firstUpdate = React.useRef(true)
  const url = window.location.href
  const companyIdRegex = /\/([a-f0-9-]+)\/[^/]+$/
  const match = url.match(companyIdRegex)
  const companyId = match ? match[1] : null
  const dispatch = useDispatch()
  if (!activeLayout) {
    throw Error("AppLayout > No activeLayout is set.")
  }

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      //@ts-ignore
      dispatch(refreshLocales(window.localStorage.getItem("language") ?? "no"))
      if (companyId) {
        getCompany(companyId).then((res) => {
          res?.id && dispatch(addCompanyReduxData(res))
        })
      }
    }
  }, [dispatch])
  const LayoutComponent = React.useMemo(() => {
    const layoutIndex = LAYOUTS.findIndex(
      (layout) => layout.name === activeLayout,
    )

    if (layoutIndex >= 0) {
      return LAYOUTS[layoutIndex].component
    }

    throw Error("No activeLayout is set yet.")
  }, [activeLayout])

  return (
    <FootprintLayoutProvider>
      {false ? (
        <div className="loader">
          <svg className="circular-loader" viewBox="25 25 50 50">
            <circle className="loader-path" cx="50" cy="50" r="20" />
          </svg>
        </div>
      ) : (
        <LayoutComponent>
          <FootprintContentLayoutProvider
            layoutOptions={config.defaultContentLayout}
          >
            {props.children}
          </FootprintContentLayoutProvider>
        </LayoutComponent>
      )}
    </FootprintLayoutProvider>
  )
}

export default AppLayout

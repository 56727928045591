import React from "react"
import FootprintThemeFooterContext from "./FootprintThemeFooterContext"
import { createTheme } from "@mui/material/styles"

const FootprintThemeFooter: React.FC<{ children: any; init: any }> = ({
  children,
  init,
}) => {
  const [footerTheme, setFooterTheme] = React.useState(init)

  const themeFooterContextValue = React.useMemo(
    () => ({
      footerTheme: createTheme(footerTheme),
      setFooterTheme: setFooterTheme,
    }),
    [footerTheme, setFooterTheme],
  )

  return (
    <FootprintThemeFooterContext.Provider value={themeFooterContextValue}>
      {children}
    </FootprintThemeFooterContext.Provider>
  )
}

export default FootprintThemeFooter

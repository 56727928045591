import React from "react"
import useLocalesState from "utils/Localization/useLocalesState"
import { Spacing } from "modules/shared"
import useMediaQuery from "@mui/material/useMediaQuery"

import { Section1 } from "./components/Section1"
import { Section2 } from "./components/Section2"
import { Section3 } from "./components/Section3"

import { Section7 } from "./components/Section7"
import { Section5 } from "./components/Section5"
import { Section6 } from "./components/Section6"
import { Section4 } from "./components/Section4"
import MyBreadcrumbs from "modules/shared/components/Breadcrumbs/Breadcrumbs"
import { useSelector } from "react-redux"

const Introduction = () => {
  const { id: companyId } = useSelector((state: any) => state.company)

  const mediumScreenSize = useMediaQuery(
    "(max-width: 1440px) and (min-width: 1240px)",
  )
  const smallScreenSize = useMediaQuery("(max-width: 904px)")
  const LargeScreenSize = useMediaQuery("(min-width: 1440px)")

  const [locales] = useLocalesState()
  const breadcrumbs = [
    {
      name: locales["breadcrumb_climate"] ?? "Klimaregnskap",
      url: `/${companyId}/climate/dashboard`,
    },
    {
      name: locales["breadcrumb_climate_page_intro"] ?? "Les meg først",
      url: "",
    },
  ]
  return (
    <>
      <MyBreadcrumbs breadcrumbs={breadcrumbs} />

      {/* // start Section one */}
      <Section1
        large={LargeScreenSize}
        medium={mediumScreenSize}
        small={smallScreenSize}
        locales={locales}
      />
      {/* // end Section one */}
      {/* //Split sections */}
      <Spacing />
      {/* //End split sections */}
      <Section2
        large={LargeScreenSize}
        medium={mediumScreenSize}
        small={smallScreenSize}
        locales={locales}
      />

      {/* // start Section two */}

      {/* // end Section two */}
      {/* ////////////////////////////////////////////////////////// */}
      <Spacing />
      {/* ////////////////////////////////////////////////////////// */}

      {/* // start Section three */}
      <Section3
        large={LargeScreenSize}
        medium={mediumScreenSize}
        small={smallScreenSize}
        locales={locales}
      />

      <Spacing />

      {/* // start Section 4 */}
      <Section4
        large={LargeScreenSize}
        medium={mediumScreenSize}
        small={smallScreenSize}
        locales={locales}
      />
      {/* // end Section 4 */}
      <Spacing />
      {/* // start Section 5 */}
      <Section5
        large={LargeScreenSize}
        medium={mediumScreenSize}
        small={smallScreenSize}
        locales={locales}
      />
      {/* // end Section5*/}
      <Spacing />
      {/* // start Section 6*/}
      <Section6
        large={LargeScreenSize}
        medium={mediumScreenSize}
        small={smallScreenSize}
        locales={locales}
      />
      {/* // end Section 7 */}

      <Spacing />
      <Section7
        large={LargeScreenSize}
        medium={mediumScreenSize}
        small={smallScreenSize}
        locales={locales}
      />
      <Spacing />
    </>
  )
}

export default Introduction

import Div from "../Div/Div"
import { FunctionComponent, useEffect, useState } from "react"
import { Typography, useMediaQuery } from "@mui/material"
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined"
import useLocalesState from "utils/Localization/useLocalesState"
import { useNavigate, useParams } from "react-router-dom"
import useStepsTitles from "modules/steps/services/useStepsTitles"
import styles from "./TransparencyActSidebar.style.module.scss"
import sidebarStyles from "./Sidebar.style.module.scss"
import { ASSET_IMAGES } from "utils/constants/paths"
import { statusColor } from "./constants/constants"
import {
  FootprintModule,
  FootprintModuleData,
} from "modules/shared/types/types"
import { useSelector } from "react-redux"
interface TransparencyActSidebarProps {}

const TransparencyActSidebar: FunctionComponent<
  TransparencyActSidebarProps
> = () => {
  const [locales] = useLocalesState()
  const navigate = useNavigate()

  const { id: companyId } = useSelector((state: any) => state.company)

  const {
    headers: staticPages,
    isLoading: isHeadersLoading,
    error: isHeadersError,
  } = useStepsTitles(FootprintModule.Transparency_act)

  const links = [
    {
      label: locales["header_label_dashboard"],
      link: `${companyId}/${FootprintModule.Transparency_act}/dashboard`,
      icon: (
        <img
          src={`${ASSET_IMAGES}/widgets/dashboard.svg`}
          className={styles.navigationIcon}
        />
      ),
    },
    {
      label: locales["sidebar_transparencyact_manual"],
      link: `${companyId}/${FootprintModule.Transparency_act}/manual`,
      icon: (
        <img
          src={`${ASSET_IMAGES}/widgets/earbuds.svg`}
          className={styles.navigationIcon}
        />
      ),
    },
    {
      label: locales["sidebar_transparencyact_myfiles"],
      link: `${companyId}/${FootprintModule.Transparency_act}/files`,
      icon: (
        <img
          src={`${ASSET_IMAGES}/widgets/folder_shared.svg`}
          className={styles.navigationIcon}
        />
      ),
    },
    {
      label: locales["sidebar_transparencyact_finish"],
      link: `/${companyId}/${FootprintModule.Transparency_act}/finishPage`,
      icon: (
        <CheckCircleOutlinedIcon
          className={styles.navigationIcon}
          sx={{
            fill: "#ffffff",
          }}
        />
      ),
    },
    {
      label: locales["sidebar_transparencyact_flagged"],
      link: `/${companyId}/${FootprintModule.Transparency_act}/flaggedSteps`,
      color: "#fb6d75",
      icon: (
        <img
          width={28}
          height={28}
          src={`${ASSET_IMAGES}/widgets/flagged.svg`}
          className={styles.navigationIcon}
        />
      ),
    },
  ]

  if (isHeadersLoading) return <></>
  else if (isHeadersError) return <span>Error in status sidebar</span>

  return (
    <Div className={styles.transparencyActSidebar}>
      <Div className={styles.stepsLinks}>
        <Typography className={styles.title}>
          {locales["sidebar_transparencyModule_title"] ?? "Åpenhetsloven"}
        </Typography>
        {staticPages?.map((item, index) => (
          <Div className={styles.step} key={item.title}>
            <Typography
              variant="h4"
              color={statusColor.default}
              className={styles.stepNumber}
              sx={{
                color: `${(statusColor as any)[item.flag]} !important`,
              }}
              onClick={() => navigate(item.url)}
            >
              {item?.labelNumber || ""}
            </Typography>
            <Typography
              className={styles.stepLabel}
              variant="h4"
              color={statusColor.default}
              sx={{
                color: `${(statusColor as any)[item.flag]} !important`,
              }}
              onClick={() => navigate(item.url)}
            >
              {item?.title || ""}
            </Typography>
          </Div>
        ))}
      </Div>
      <Div className={styles.navigationLinks}>
        <div className={sidebarStyles.separator}></div>

        {links.map((link) => (
          <Div
            className={styles.step}
            onClick={() => navigate(link.link)}
            key={link.label}
          >
            {link.icon}
            <Typography
              className={`${styles.stepLabel} ${styles.navigationLabels}`}
              variant="h4"
              color={link.color ?? "white"}
            >
              {link.label}
            </Typography>
          </Div>
        ))}
      </Div>
    </Div>
  )
}

export default TransparencyActSidebar

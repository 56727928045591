import React, { useState } from "react"
import { Typography, Checkbox, FormControlLabel, Grid } from "@mui/material"
import { Form, Formik } from "formik"
import * as yup from "yup"
import FormControl from "@mui/material/FormControl"
import Box from "@mui/material/Box"
import CardMedia from "@mui/material/CardMedia"
import LoadingButton from "@mui/lab/LoadingButton"
import { ASSET_IMAGES } from "utils/constants/paths"
import useLocalesState from "utils/Localization/useLocalesState"
import { useLocation } from "react-router-dom"
import {
  Div,
  FootprintContactTextFieldFormik,
  FootprintModule,
  Heading1,
  Heading4,
  sendEmailAndNewsletter,
  useFootprintModule,
} from "modules/shared"
import "./ContactUs.style.css"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useSelector } from "react-redux"
import MyBreadcrumbs from "modules/shared/components/Breadcrumbs/Breadcrumbs"

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  companyName: yup.string().required("Company Name is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  comment: yup.string().required("Comment is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
})

const formFieldStyle = {
  textTransform: "uppercase",
  color: "black",
}

const ContactUs = () => {
  const [checkItemValue, setCheckItemValue] = useState({
    checkbox1: false,
    checkbox2: false,
  })
  const [locales] = useLocalesState()
  const { id: companyId } = useSelector((state: any) => state.company)

  const { activeModule } = useFootprintModule()
  const location = useLocation()
  const is1240OrLess = useMediaQuery("(max-width:1240px)")
  const is1440OrLess = useMediaQuery("(max-width:1440px)")
  const isOver1440 = useMediaQuery("(min-width:1441px)")
  const inDashboard = location.pathname.includes("dashboard")
  const mutation = { isError: false }
  const getMainImg = () => {
    if (isOver1440) {
      return `${ASSET_IMAGES}/pages/home/large_contact.png`
    } else if (is1240OrLess) {
      return `${ASSET_IMAGES}/pages/home/small_contact.png`
    } else if (is1440OrLess) {
      return `${ASSET_IMAGES}/pages/home/med_contact.png`
    }
  }
  const breadcrumbs = [
    {
      name:
        activeModule() === FootprintModule.Climate_accounting
          ? locales["breadcrumb_climate"] ?? "Klimaregnskap"
          : activeModule() === FootprintModule.Transparency_act
            ? locales["breadcrumb_transparency"] ?? "Åpenhetsloven"
            : "",
      url:
        activeModule() === FootprintModule.Climate_accounting
          ? `/${companyId}/climate/dashboard`
          : FootprintModule.Transparency_act
            ? `/${companyId}/transparency/dashboard`
            : "",
    },
    {
      name: locales["breadcrumb_climate_page_help"] ?? "Hjelp",
      url: "",
    },
  ]
  return (
    <>
      {activeModule() && <MyBreadcrumbs breadcrumbs={breadcrumbs} />}
      <Div className="contactus-container">
        <Heading1 className="mb-0">
          {inDashboard
            ? locales["pages_dashboard_contactUs_section_title"] ??
              "Fant du ikke det du lette etter?"
            : locales["pages_home_contactUs_section_title"] ??
              "Leder og vil ta gode valg?"}
        </Heading1>
        {!inDashboard && (
          <Heading4 className="mb-0">
            {locales["pages_home_contactUs_section_subTitle"] ??
              "FYLL INN SKJEMA OG VI KONTAKTER DEG FOR EN PRAT OM KLIMAREGNSKAP"}
          </Heading4>
        )}
        <Div className="form-container">
          <CardMedia
            component="img"
            className="card"
            image={getMainImg()}
            alt="Contact Us"
          />
          <Div className="form">
            <Box
              component={Div}
              sx={{
                "& .MuiFormControl-root:not(.MuiTextField-root)": {
                  width: { xs: "100%", sm: "50%" },
                },
              }}
            >
              <Formik
                validateOnChange={true}
                initialValues={{
                  email: "",
                  name: "",
                  companyName: "",
                  phoneNumber: "",
                  comment: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting, resetForm }) => {
                  setSubmitting(true)
                  sendEmailAndNewsletter("no", {
                    email: data.email,
                    name: data.name,
                    comment: data.comment,
                    companyName: data.companyName,
                    emailServiceEnum: "CONTACT_FORM",
                    phoneNumber: data.phoneNumber,
                  })
                    .then(() => {
                      setSubmitting(false)
                      resetForm({})
                      setCheckItemValue({ checkbox1: false, checkbox2: false })
                    })
                    .catch(() => setSubmitting(false))
                }}
              >
                {({ isSubmitting }) => (
                  <Form
                    style={{ textAlign: "left" }}
                    noValidate
                    autoComplete="off"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FootprintContactTextFieldFormik
                          fullWidth
                          sx={formFieldStyle}
                          name="name"
                          InputLabelProps={{
                            style: { color: "#000000" },
                          }}
                          label={
                            locales["pages_home_contactUs_section_form_name"] ??
                            "Ditt navn*"
                          }
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FootprintContactTextFieldFormik
                          sx={formFieldStyle}
                          InputLabelProps={{
                            style: { color: "#000000" },
                          }}
                          fullWidth
                          name="companyName"
                          label={
                            locales[
                              "pages_home_contactUs_section_form_companyName"
                            ] ?? "Selskapets navn*"
                          }
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FootprintContactTextFieldFormik
                          sx={formFieldStyle}
                          InputLabelProps={{
                            style: { color: "#000000" },
                          }}
                          fullWidth
                          name="phoneNumber"
                          label={
                            locales[
                              "pages_home_contactUs_section_form_phone"
                            ] ?? "Telefonnummer*"
                          }
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FootprintContactTextFieldFormik
                          sx={formFieldStyle}
                          InputLabelProps={{
                            style: { color: "#000000" },
                          }}
                          fullWidth
                          name="email"
                          label={
                            locales[
                              "pages_home_contactUs_section_form_email"
                            ] ?? "E-post*"
                          }
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FootprintContactTextFieldFormik
                          sx={formFieldStyle}
                          InputLabelProps={{
                            style: { color: "#000000" },
                          }}
                          id="commentField"
                          fullWidth
                          name="comment"
                          multiline
                          rows={is1240OrLess ? 2 : 4}
                          label={
                            locales[
                              "pages_home_contactUs_section_form_comment"
                            ] ?? "Kommentar*"
                          }
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {" "}
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              sx={{
                                "&.MuiFormControlLabel-root": {
                                  marginRight: "0px",
                                },
                              }}
                              checkedIcon={
                                <img
                                  src={`${ASSET_IMAGES}/pages/dashboard/Check-box.svg`}
                                  alt=""
                                />
                              }
                              icon={
                                <img
                                  src={`${ASSET_IMAGES}/pages/dashboard/Check-box-not-selected.svg`}
                                  alt=""
                                />
                              }
                              onChange={(e) =>
                                setCheckItemValue({
                                  ...checkItemValue,
                                  checkbox1: e.target.checked,
                                })
                              }
                              checked={checkItemValue.checkbox1}
                              value={checkItemValue.checkbox1}
                            />
                          }
                          label={
                            locales[
                              "pages_home_contactUs_section_form_checkbox1"
                            ] ??
                            "Ja takk, send meg informasjon om webinar, events og andre relevante nyheter."
                          }
                        />
                        <FormControl className="MuiFormControl-fluid">
                          <FormControlLabel
                            sx={{
                              "&.MuiFormControlLabel-root": {
                                marginRight: "0px",
                              },
                            }}
                            control={
                              <Checkbox
                                checkedIcon={
                                  <img
                                    src={`${ASSET_IMAGES}/pages/dashboard/Check-box.svg`}
                                    alt=""
                                  />
                                }
                                icon={
                                  <img
                                    src={`${ASSET_IMAGES}/pages/dashboard/Check-box-not-selected.svg`}
                                    alt=""
                                  />
                                }
                                size="small"
                                onChange={(e) =>
                                  setCheckItemValue({
                                    ...checkItemValue,
                                    checkbox2: e.target.checked,
                                  })
                                }
                                checked={checkItemValue.checkbox2}
                                value={checkItemValue.checkbox2}
                              />
                            }
                            label={
                              <span>
                                <span>
                                  {locales[
                                    "pages_home_contactUs_section_form_checkbox2"
                                  ] ??
                                    `Jeg samtykker til at Amesto kan behandle persondata
                        i samsvar med`}
                                </span>
                                <span>
                                  {" "}
                                  <a
                                    href="https://www.amesto.no/om-amesto/trust-center/personvernerklaring"
                                    target="_blank"
                                    style={{ color: "black" }}
                                    rel="noreferrer"
                                  >
                                    personvernerklæringen.
                                  </a>
                                  <span style={{ color: "#FF0000" }}>*</span>
                                </span>
                              </span>
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} className="btnItem">
                        <LoadingButton
                          disableElevation
                          disableRipple
                          disableFocusRipple
                          type="submit"
                          variant="contained"
                          size="large"
                          loading={isSubmitting || mutation.isError}
                          disabled={!checkItemValue.checkbox2}
                        >
                          {locales[
                            "pages_home_contactUs_section_form_action_button"
                          ] ?? "Send"}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Box>
          </Div>
        </Div>
      </Div>
    </>
  )
}

export default ContactUs

import styled from "@emotion/styled"
import TabList from "@mui/lab/TabList"
import Tab from "@mui/material/Tab"

export const TabHeader = styled(TabList)({
  background: "#F1F3F3",
  "& .Mui-selected": {
    background: "#ffffff",
    color: "black !important",
    "& h4": {
      fontWeight: 500,
    },
  },
})
export const TabItem = styled(Tab)({
  width: "auto",
  height: "56px",
  lineHeight: "24px",
  color: "black",
  "& img": {
    verticalAlign: "middle",
    marginRight: "9px",
  },
  ":focus": {
    background: "#ffffff",
    color: "black",
  },
})

import React, { useEffect, useState } from "react"
import {
  Div,
  FootprintModule,
  FootprintTertiaryButton,
  Heading2,
  Heading4,
} from "modules/shared"
import { Box } from "@mui/material"
import useLocalesState from "utils/Localization/useLocalesState"
import { TabContext } from "@mui/lab"
import { TabHeader, TabItem } from "./TabStyles/TabHeaderStyle"
import TabPanel from "@mui/lab/TabPanel"
import { ASSET_IMAGES } from "utils/constants/paths"
import Overview from "./components/Overview"
import DetailedOverview from "./components/DetailedOverview"
import Trend from "./components/Trend"
import Budget from "./components/Budget"
import { PDFDocument, rgb } from "pdf-lib"
import html2canvas from "html2canvas"
import { useSelector } from "react-redux"
import { useStepsAPI } from "modules/steps/services/useStepsAPI"
import { useParams } from "react-router-dom"
import { useQuery } from "react-query"
import ChartsContext from "./ChartsContext"
const enum ClimateChartEnum {
  overview,
  detailedOverview,
  trend,
  budget,
}

const ClimateCharts = () => {
  const [locales]: any = useLocalesState()
  const [activeTab, setActiveTab] = React.useState("0")
  const [activePage, setActivePage] = React.useState<ClimateChartEnum>(
    ClimateChartEnum.overview,
  )
  const { activeModules } = useSelector((state: any) => state.company)
  const { getSteps } = useStepsAPI()
  const { companyId } = useParams()
  const isVisible = activeModules.find(
    (targetModule: any) => targetModule?.module?.id === 2,
  )?.isVisible
  const pdfOverviewRef = React.useRef<any>()
  const pdfDetailedOverview = React.useRef<any>()
  const pdfTrend = React.useRef<any>()
  const pdfBudget = React.useRef<any>()
  const [reportingYear, setreportingYear] = useState(2024)
  const [downloadingFullReport, setDownloadingFullReport] = useState(false)
  const { data, isLoading, error } = useQuery(["loadClimateSteps"], () => {
    return getSteps(companyId || "", FootprintModule.Climate_accounting)
  })
  const findReportingYear = (data: any) => {
    for (const stage of data) {
      for (const section of stage.sections) {
        for (const step of section.steps) {
          const isReportingYearFound =
            step.inputs.reportingYear !== undefined &&
            step.inputs.reportingYear !== null
          if (step.inputs && isReportingYearFound) {
            return step.inputs.reportingYear
          }
        }
      }
    }
    return undefined // Return undefined if reportingYear is not found
  }
  // Function to find companyName from the data
  const findCompanyName = (data: any) => {
    if (data === undefined) {
      return undefined // Handle the case where data is undefined
    }

    for (const stage of data) {
      for (const section of stage.sections) {
        for (const step of section.steps) {
          if (step.inputs && step.inputs.companyName !== null) {
            return step.inputs.companyName
          }
        }
      }
    }

    return undefined // Return undefined if companyName is not found
  }
  const handleAppendClick = async () => {
    setDownloadingFullReport(true)
    const existingPdfBytes = await fetch("/files/climate.pdf").then((res) =>
      res.arrayBuffer(),
    )
    // Assuming firstPage and height are defined
    const fontSettings = {
      size: 20,
      lineHeight: 24,
      style: {
        bold: false,
        italic: false,
        underline: false,
        color: rgb(1, 1, 1),
      }, // Adjust style as needed
      color: rgb(1, 1, 1),
      letterSpacing: 0.2, // 20% letter spacing
    }

    const reportingYear = findReportingYear(data).toString()
    // Usage
    const companyName = findCompanyName(data)
    const pdfDoc = await PDFDocument.load(existingPdfBytes)

    // Get the first page
    const firstPage = pdfDoc.getPage(0)
    const { width, height } = firstPage.getSize()
    // Add text to the first page
    firstPage.drawText(reportingYear || "", {
      x: 64, // Adjust the x-coordinate as needed
      y: height - 515, // Adjust the y-coordinate as needed
      size: fontSettings.size,
      lineHeight: fontSettings.lineHeight,
      color: fontSettings.color,
    })
    firstPage.drawText(companyName?.toUpperCase() || "", {
      x: 64, // Adjust the x-coordinate as needed
      y: height - 715, // Adjust the y-coordinate as needed
      size: fontSettings.size,
      lineHeight: fontSettings.lineHeight,
      color: fontSettings.color,
    })
    for (let i = 0; i < 2; i++) {
      const page = pdfDoc.getPage(i + 4)
      const { width, height } = page.getSize()
      for (let j = 0; j < 2; j++) {
        const chartIndex = i * 2 + j
        const canvas = await html2canvas(
          chartIndex === 0
            ? pdfDetailedOverview.current
            : chartIndex === 1
              ? pdfOverviewRef.current
              : chartIndex === 2
                ? pdfBudget.current
                : pdfTrend.current,
        )

        const pdfImgUrl = canvas.toDataURL("image/png")
        const pdfImage = await pdfDoc.embedPng(pdfImgUrl)

        const offsetY = j * 300 // Adjust vertical spacing as needed
        page.drawImage(pdfImage, {
          x: width / 2 - 270,
          y: height / 2 - 250 + offsetY,
          width: 550, // Adjust width as needed
          height: 200, // Adjust height as needed
        })
      }
    }

    // Save the modified PDF
    const modifiedPdfBytes = await pdfDoc.save()
    // Create a Blob from the modified PDF bytes
    const modifiedPdfBlob = new Blob([modifiedPdfBytes], {
      type: "application/pdf",
    })

    // Create a download link
    const downloadLink = document.createElement("a")
    downloadLink.href = URL.createObjectURL(modifiedPdfBlob)
    downloadLink.download = "Last_ned_full_rapport.pdf"

    // Append the download link to the document and trigger the click event
    document.body.appendChild(downloadLink)
    downloadLink.click()

    // Remove the download link from the document
    document.body.removeChild(downloadLink)
    setDownloadingFullReport(false)
  }
  const handleChange = (event: any, newValue: string) => {
    setActiveTab(newValue)
    switch (newValue) {
      case "0":
        setActivePage(ClimateChartEnum.overview)
        break
      case "1":
        setActivePage(ClimateChartEnum.detailedOverview)
        break
      case "2":
        setActivePage(ClimateChartEnum.trend)
        break
      case "3":
        setActivePage(ClimateChartEnum.budget)
        break
      default:
        setActivePage(ClimateChartEnum.overview)
        break
    }
  }

  const getPage = () => {
    switch (activePage) {
      case ClimateChartEnum.overview:
        return (
          <Div>
            <Overview downloadAll />
          </Div>
        )
      case ClimateChartEnum.detailedOverview:
        return (
          <Div>
            <DetailedOverview
              downloadingFullReport={downloadingFullReport}
              setDownloadingFullReport={setDownloadingFullReport}
            />
          </Div>
        )
      case ClimateChartEnum.trend:
        return (
          <Div>
            <Trend />
          </Div>
        )
      case ClimateChartEnum.budget:
        return (
          <Div>
            <Budget />
          </Div>
        )
      default:
        return (
          <Div>
            <Overview downloadAll />
          </Div>
        )
    }
  }

  useEffect(() => {
    if (data) {
      const reportingYear = Number(findReportingYear(data))
      setreportingYear(Number.isNaN(reportingYear) ? 2024 : reportingYear)
    }
  }, [data])

  return (
    <ChartsContext.Provider value={{ reportingYear: reportingYear }}>
      <Div>
        <Box
          sx={{
            width: "100%",
            typography: "body1",
            "& .MuiTabPanel-root": {
              padding: "0px",
            },
            position: "relative",
            height: !isVisible ? "361px" : "auto",
            overflow: "hidden",
          }}
        >
          {!isVisible && (
            <Div
              sx={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "361px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#F1F3F3",
                opacity: "0.8",
                zIndex: 1,
                pointerEvents: "none", // Disable pointer events
              }}
            >
              <Heading2 sx={{ whiteSpace: "pre-line" }}>
                {locales["climate_dashboard_disabled_chart_text"] ??
                  `Dashboardet blir tilgjengelig så snart\n rapporteringen og analysen er fullført.`}
              </Heading2>
            </Div>
          )}
          <TabContext value={activeTab}>
            <Box>
              <TabHeader
                onChange={handleChange}
                TabIndicatorProps={{ style: { display: "none" } }}
              >
                <TabItem
                  label={
                    <Heading4>
                      <img
                        src={`${ASSET_IMAGES}/pages/climate/dashboard/donut_small.svg`}
                        alt=""
                      />
                      {locales["climate_charts_overview"] ?? "Oversikt"}
                    </Heading4>
                  }
                  value={"0"}
                  disableRipple
                  disabled={!isVisible}
                />
                <TabItem
                  label={
                    <Heading4>
                      <img
                        src={`${ASSET_IMAGES}/pages/climate/dashboard/query_stats.svg`}
                        alt=""
                      />
                      {locales["climate_charts_detailedOverview"] ??
                        "Detaljert oversikt"}
                    </Heading4>
                  }
                  value={"1"}
                  disableRipple
                  disabled={!isVisible}
                />
                <TabItem
                  label={
                    <Heading4>
                      <img
                        src={`${ASSET_IMAGES}/pages/climate/dashboard/trending_up.svg`}
                        alt=""
                      />
                      {locales["climate_charts_trend"] ?? "Trend og mål"}
                    </Heading4>
                  }
                  value={"2"}
                  disableRipple
                  disabled={!isVisible}
                />
                <TabItem
                  label={
                    <Heading4>
                      <img
                        src={`${ASSET_IMAGES}/pages/climate/dashboard/track_changes.svg`}
                        alt=""
                      />
                      {locales["climate_charts_budget"] ?? "klimabudsjett"}
                    </Heading4>
                  }
                  value={"3"}
                  disableRipple
                  disabled={!isVisible}
                />
                <Div
                  sx={{
                    margin: "auto",
                    width: "100%",
                    textAlign: "end",
                    "& .foot-print-tertiary-button": {
                      ":hover": {
                        textDecorationColor: " #CDE6D2 !important",
                        transition: "0.3s ease",
                      },
                    },
                  }}
                >
                  <FootprintTertiaryButton
                    style={{ color: "#78B785" }}
                    onClick={() => (!isVisible ? null : handleAppendClick())}
                    disabled={!isVisible}
                  >
                    {locales["climate_charts_downloadAll"] ??
                      "Last ned full rapport"}
                  </FootprintTertiaryButton>
                </Div>
              </TabHeader>
            </Box>
            <TabPanel value={"0"}>{getPage()}</TabPanel>
            <TabPanel value={"1"}>{getPage()}</TabPanel>
            <TabPanel value={"2"}>{getPage()}</TabPanel>
            <TabPanel value={"3"}>{getPage()}</TabPanel>
          </TabContext>
        </Box>
        <Div sx={{ position: "fixed", bottom: "-500" }}>
          <Div ref={pdfOverviewRef}>
            <Overview downloadAll={false} notShowDownloadButton={true} />
          </Div>
          <Div ref={pdfDetailedOverview}>
            <DetailedOverview
              notShowDownloadButton={true}
              downloadingFullReport={downloadingFullReport}
              setDownloadingFullReport={setDownloadingFullReport}
            />
          </Div>
          <Div ref={pdfTrend}>
            <Trend notShowDownloadButton={true} />
          </Div>
          <Div ref={pdfBudget}>
            <Budget notShowDownloadButton={true} />
          </Div>
        </Div>
      </Div>
    </ChartsContext.Provider>
  )
}

export default ClimateCharts

import { Div } from "modules/shared"
import { SplitView } from "modules/layout"
import { SectionContainer } from "modules/shared"
import React from "react"
import { HintTitle, SectionEightSideMenus } from "../ManualStyles"
import { Heading2, Paragraph } from "modules/shared"
import { FootprintScrollArrow } from "modules/shared"
export const Section8: React.FC<{
  locales?: any
  large?: boolean
  medium?: boolean
  small?: boolean
}> = ({ locales = {}, large, medium, small }) => {
  return (
    <Div>
      <Div
        sx={{
          position: "absolute",
          height: "100vh",
          "& .dashboard-status-menu": {
            position: "relative",
            top: 0,
            left: 0,
            marginLeft: "0px",
            height: "100vh",
            zIndex: 0,
          },
        }}
      ></Div>
      <SectionContainer
        id="page_manual_section8"
        sx={{
          p:
            (large && "84px 112px 0px") ||
            (medium && "81px 2.86vw 0px") ||
            (small && "81px 2.604vw 0px ") ||
            "84px 112px 0px",
          ml: (small && "30px") || (medium && "50px") || "50px",
        }}
      >
        <SplitView
          left={(medium && "500px") || (small && "360px") || "542px"}
          right={(medium && "504px") || (small && "368px") || "536px"}
          gap={(small && "16px") || "24px"}
        >
          <SectionEightSideMenus
            sx={{
              width: (medium && "416px") || (small && "304px") || "536px",
              height: (medium && "500px") || (small && "360px") || "500px",
            }}
          />
          <Div>
            <HintTitle sx={{ fontSize: (small && "15px") || "17px" }}>
              {locales["page_manual_section8_hint"] ??
                "HVA GJØR JEG HVIS JEG SITTER FAST?"}
            </HintTitle>
            <Heading2
              sx={{
                m: 0,
                fontSize: (small && "26px") || "32px",
                lineHeight: "normal",
              }}
            >
              {locales["page_manual_section8_title"] ?? "Flaggede krav."}
            </Heading2>
            <Paragraph sx={{ m: "0px", fontSize: (small && "15px") || "17px" }}>
              {locales["page_manual_section8_paragraph"] ??
                `Hvis du sitter fast, kan du markere det ved å flagge rapporteringskravet det gjelder.
Når du flagger et rapporteringskrav, blir både nedtrekksmenyen og steget navigeringsmenyen rødt.

Alle flaggede krav samles på en egen side, så du kan holde oversikten over hvor skoen trykker. Denne oversikten kan også brukes av våre rådgivere for at vi lettere kan hjelpe deg videre i aktsomhetsvurderingen.

Når du har løst et flagget rapporteringskrav, så trykker du på "Ferdig"-knappen nederst til høyre. Da vil rapporteringskravet bli grønt.`}
            </Paragraph>
          </Div>
        </SplitView>
        <FootprintScrollArrow
          nextSelector="#page_manual_section9"
          style={{
            margin:
              (small && "60px auto") || (medium && "60px auto") || "80px auto",
          }}
        />
      </SectionContainer>
    </Div>
  )
}

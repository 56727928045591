import {
  BodyText,
  BodyText2,
  Div,
  FootprintPrimaryButton,
  Heading1,
  Heading4,
} from "modules/shared"
import React, { FunctionComponent, useState } from "react"
import styles from "../ClimateSteps.style.module.scss"
import { ASSET_IMAGES } from "utils/constants/paths"
import { Step, StepBall, Stepper } from "modules/steps"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import useLocalesState from "utils/Localization/useLocalesState"
interface ClimateFinishPageProps {}

const ClimateFinishPage: FunctionComponent<ClimateFinishPageProps> = () => {
  const { id: companyId } = useSelector((state: any) => state.company)
  const [locales]: any = useLocalesState()
  const navigate = useNavigate()
  return (
    <Div className={styles.climateFinishPage}>
      <img src={`${ASSET_IMAGES}/widgets/hourglass_bottom.svg`} alt="" />
      <Heading4>{locales["pages_climateFinshPage_title"]}</Heading4>
      <Heading1>{locales["pages_climateFinshPage_desc"]}</Heading1>
      <Stepper className={styles.stepper}>
        <StepBall
          style={{
            left: 0,
            background: "#1C1C1C",
          }}
        ></StepBall>

        <Step style={{ background: "#1C1C1C" }}>
          <StepBall
            style={{
              right: 0,
              background: "#1C1C1C",
            }}
          ></StepBall>
        </Step>
        <Step>
          <StepBall
            style={{
              right: 0,
              background: "#78B785",
            }}
          ></StepBall>
        </Step>
      </Stepper>
      <BodyText>{locales["pages_climateFinshPage_body"]}</BodyText>
      <FootprintPrimaryButton
        onClick={() => {
          navigate(`/${companyId}/climate/dashboard`)
        }}
      >
        {locales["pages_climateFinshPage_dashboardBtn"]}
      </FootprintPrimaryButton>

      <div className={styles.note}>
        <AccessTimeIcon />
        <strong>{locales["pages_climateFinshPage_merk"]}:</strong>
        <BodyText2>{locales["pages_climateFinshPage_note"]}</BodyText2>
      </div>
    </Div>
  )
}

export default ClimateFinishPage

import {
  AnyAction,
  Dispatch,
  Middleware,
  applyMiddleware,
  compose,
  createStore,
} from "redux"
import thunk from "redux-thunk"
//@ts-ignore
import { createBrowserHistory } from "history"
import { createReduxHistoryContext } from "redux-first-history"
import rootReducer from "../reducers"
import { persistStore } from "redux-persist"
const { routerMiddleware } = createReduxHistoryContext({
  history: createBrowserHistory(),
  //other options if needed
})

const history = createBrowserHistory()
const bindMiddleware = (
  middleware: Middleware<{}, any, Dispatch<AnyAction>>[],
) => {
  //@ts-ignore
  const composeEnhancers =
    (window as any)["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] ?? compose

  return composeEnhancers(applyMiddleware(...middleware))
}

function configureStore(initialState = {}) {
  const store = createStore(
    rootReducer(history),
    initialState,
    bindMiddleware([routerMiddleware, thunk]),
  )
  let persistor = persistStore(store)
  //@ts-ignore
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    //@ts-ignore
    module.hot.accept("../reducers/index", () => {
      const exportReducers = require("../reducers")
      store.replaceReducer(exportReducers)
    })
  }
  return { store, persistor }
}
export default configureStore
export { history }

import { Div } from "modules/shared"
import { FunctionComponent, useState } from "react"
import FootprintTertiaryButton from "../FootprintTertiaryButton/FootprintTertiaryButton"
import { SxProps } from "@mui/material"
import Dropzone, { Accept } from "react-dropzone"
interface FootprintFileFieldProps {
  sx?: SxProps
  onFilesUpload: (files: File[]) => void
  accept?: Accept
}

const FootprintFileField: FunctionComponent<FootprintFileFieldProps> = (
  props,
) => {
  const [isDragActive, setisDragActive] = useState(false)
  return (
    <Dropzone
      onDrop={(acceptedFiles) => {
        setisDragActive(false)
        props.onFilesUpload(acceptedFiles)
      }}
      onDragLeave={() => setisDragActive(false)}
      onDragEnter={() => setisDragActive(true)}
      accept={props.accept}
    >
      {({ getRootProps, getInputProps }) => (
        <Div
          {...getRootProps()}
          sx={{
            border: "1px dashed ",
            display: "flex",
            padding: "12px",
            gap: "10px",
            justifyContent: "center",
            cursor: "pointer",
            borderColor: isDragActive ? "#78B785" : "black",
            ...props.sx,
          }}
        >
          <input {...getInputProps()} />
          <Div
            sx={{
              color: "#BABABA",
              fontSize: "17px",
              fontWeight: 300,
              width: "320px",
            }}
          >
            {isDragActive
              ? "Drop the files here ..."
              : "Drag and drop documents here, or press"}
          </Div>
          <FootprintTertiaryButton style={{ color: "#78B785" }}>
            Upload
          </FootprintTertiaryButton>
        </Div>
      )}
    </Dropzone>
  )
}

export default FootprintFileField

import { combineReducers } from "redux"
import locales from "./locales"
import status from "./status"
import dashboard from "./dashboard"
import roles from "./roles"
import admin from "./admin"
import steps from "./steps"
import company from "./company"
// @ts-ignore
import { createBrowserHistory } from "history"
import { createReduxHistoryContext } from "redux-first-history"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

const companyPersistConfig = {
  key: "company",
  storage: storage,
}
const langConfig = {
  key: "lang",
  storage: storage,
}
const { routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
  //other options if needed
})
const exportReducers = (history: any) => {
  return combineReducers({
    router: routerReducer,
    locales: persistReducer(langConfig, locales),
    status: status,
    dashboard: dashboard,
    roles: roles,
    admin: admin,
    steps: steps,
    company: persistReducer(companyPersistConfig, company),
  })
}

export default exportReducers

export const transparencyGlossary = [
  {
    term: "Handlingsplan",
    description:
      "Det skal utformes en handlingsplan for hvert problem. En handlingsplan skal bestå av tydelige mål, tiltak, KPI(er), tidsfrister og hvem som er ansvarlig for gjennomføring. Målet med handlingsplanen er å løse problemene som bedriften avdekker i kartleggingen (steg 2). Merk: En handlingsplan er ikke det samme som en retningslinje.",
  },
  {
    term: "Forretningsforbindelse",
    description:
      "En aktør som er relevant for bedriftens forretningsvirksomhet. Eks: Leverandører, investorer, samarbeidspartnere ol. Merk: En leverandør er én type forretningsforbindelse.",
  },
  {
    term: "Interessent",
    description:
      "En aktør som har en direkte eller indirekte relasjon til bedriften. Enten ved at bedriften påvirker aktøren, aktøren påvirker bedriften og/eller at de gjensidig påvirker hverandre. Eks: En investor, kunder, turgåere i nærheten av et produksjonsanlegg, ansatte osv.",
  },
  {
    term: "Interessentdialog",
    description:
      "En prosess for å opprette en dialog med en eller flere interessenter. Målet med en interessentdialog er å skape en kommunikasjonskanal mellom bedriften og de(n) aktuelle interessenten(e). Interessentdialogen utformes basert på hvem mottakeren er. Eks.: Invitere til en paneldebatt, organisere et forhandlingsmøte, produsere informasjonsbrosjyrer eller spre rapporten på sosiale medier rettet mot den aktuelle interessentgruppen. Se interessent.",
  },
  {
    term: "Leverandør",
    description:
      "En aktør som leverer produkter eller tjenester til din virksomhet.",
  },
  {
    term: "Negativ påvirkning og/eller skade",
    description:
      "En negativ eksternalitet som følge av forretningsvirksomheten til en bedrift. (Eks.: Underbetalte arbeidere hos en av leverandørene dine er i denne sammenhengen en negativ påvirkning). En av hovedmålene med Åpenhetsloven er å avdekke negativ påvirkning og/eller skade som følge av din forretningsvirksomhet, for deretter å lage planer for hvordan den negative påvirkningen og/eller skaden kan gjenopprettes. Alle risikoområdene som identifiseres gjennom analysen/akstomhetsvurderingen i steg 2, skal ha retningslinjer.",
  },
  {
    term: "Problem",
    description:
      "Når en negativ påvirkning og/eller skade har oppstått og dette blir avdekket, så defineres det som et problem. Alle problem som avdekkes i kartleggingen i steg 2, skal ha en egen handlingsplan.",
  },
  {
    term: "Retningslinje",
    description:
      "En plan for å forebygge og håndtere at en negativ påvirkning og/eller skade skjer. Retningslinjer skal utformes for potensielle risikoområder som avdekkes gjennom kartleggingen og på bedriftsnivå. Merk: En retningslinje er ikke det samme som en handlingsplan.",
  },
  {
    term: "Risikoområde",
    description:
      "Et område hvor det er høy risikjo for at det oppstår problemer (negativ påvirkning og/eller skade) i forbindelse med virksomheten din. (Eks.: Hvis du er i tekstilindustrien og har en fabrikk i India, så er et risikoområde for din virksomhet barnearbeid. Merk: Risikoområde er ikke synonymt med at en negativ påvirkning og/eller skade faktisk har skjedd. Et risikoområde er et område du bør overvåke for å unngå at det oppstår negativ påvirkning og/eller skade. Når en negativ påvirkning og/eller skade har oppstått, blir risikoområdet til et problem.",
  },
]

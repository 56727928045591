import * as React from "react"
import Typography from "@mui/material/Typography"
import { Div } from "modules/shared"
import Grid from "@mui/material/Grid"
import FolderSharedOutlinedIcon from "@mui/icons-material/FolderSharedOutlined"
import RouteOutlinedIcon from "@mui/icons-material/RouteOutlined"
import MapOutlinedIcon from "@mui/icons-material/MapOutlined"
import CardOptionAction from "../CardOptionAction/CardOptionAction"
import useLocalesState from "utils/Localization/useLocalesState"
import { useNavigate, useParams } from "react-router-dom"
import { useMediaQuery } from "@mui/material"
const ReportDoneSection = () => {
  const [locales] = useLocalesState()
  const navigate = useNavigate()
  const { companyId } = useParams()

  const mediumScreen = useMediaQuery(
    "(max-width: 1440px) and (min-width: 1240px)",
  )
  const smallScreen = useMediaQuery("(max-width: 904px)")
  const LargeScreen = useMediaQuery("(min-width: 1440px)")
  return (
    <Div
      sx={{
        flexGrow: 1,
        margin: "auto",
        width:
          (smallScreen && 752) ||
          (mediumScreen && 768) ||
          (LargeScreen && 1000) ||
          768,
        height: "auto",
        mt: "100px",
        mb: "160px",
        "& .cardOptionAction": {
          width:
            (smallScreen && 240) ||
            (mediumScreen && 240) ||
            (LargeScreen && 312) ||
            240,
          height:
            (smallScreen && 240) ||
            (mediumScreen && 240) ||
            (LargeScreen && 312) ||
            240,
        },
      }}
    >
      <Typography
        variant="h2"
        textAlign={"center"}
        fontWeight={800}
        fontSize={{ xs: "18px", md: "45px" }}
        mb="40px"
      >
        {locales["pages_dashboard_climate_section_title"] ??
          "Klimaregnskap dashboard"}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <CardOptionAction
            icon={<RouteOutlinedIcon color="secondary" fontSize="large" />}
            bg="#F1F3F3"
            color="secondary"
            title={
              locales["pages_climate_dashboard_section_item1"] ??
              "Les meg først"
            }
            onClick={() => {
              navigate(`/${companyId}/climate/introduction`)
            }}
          />{" "}
        </Grid>
        <Grid item xs={12} md={4}>
          <CardOptionAction
            icon={<MapOutlinedIcon color="primary" fontSize="large" />}
            bg="#EDF7EF"
            color="primary"
            hoverSecondary={true}
            title={
              locales["pages_climate_dashboard_section_item2"] ?? "Veilederen"
            }
            onClick={() => {
              navigate(`/${companyId}/climate/roadHead`)
            }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <CardOptionAction
            icon={
              <FolderSharedOutlinedIcon color="secondary" fontSize="large" />
            }
            bg="#F1F3F3"
            color="secondary"
            title={
              locales["pages_climate_dashboard_section_item3"] ?? "mine Filer"
            }
            onClick={() =>
              navigate(`/${companyId}/climate/files`, {
                state: { tabNumber: 0 },
              })
            }
          />
        </Grid>
      </Grid>
    </Div>
  )
}
export default ReportDoneSection

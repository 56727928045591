//boilerplate component
import {
  BodyText,
  FootprintModule,
  FootprintTertiaryButton,
  Heading1,
  Heading4,
  Subtitle,
  useFootprintModule,
} from "modules/shared"
import { Div } from "modules/shared"
import React, { Component, useState } from "react"
import { transparencyGlossary } from "./transparencyGlossary"
import { climateGlossary } from "./climateGlossary"
import "./Dictionary.style.scss"
import useLocalesState from "utils/Localization/useLocalesState"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import HoverMenu from "modules/layout/components/vertical-default/HoverMenu"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import MyBreadcrumbs from "modules/shared/components/Breadcrumbs/Breadcrumbs"
const Dictionary = () => {
  const glossaries = {
    [FootprintModule.Transparency_act]: transparencyGlossary,
    [FootprintModule.Climate_accounting]: climateGlossary,
  }

  const [locales]: any = useLocalesState()
  const { activeModule, allModules } = useFootprintModule()
  const { id: companyId } = useSelector((state: any) => state.company)

  const navigate = useNavigate()
  const breadcrumbs = [
    {
      name:
        activeModule() === FootprintModule.Climate_accounting
          ? locales["breadcrumb_climate"] ?? "Klimaregnskap"
          : activeModule() === FootprintModule.Transparency_act
            ? locales["breadcrumb_transparency"] ?? "Åpenhetsloven"
            : "",
      url:
        activeModule() === FootprintModule.Climate_accounting
          ? `/${companyId}/climate/dashboard`
          : FootprintModule.Transparency_act
            ? `/${companyId}/transparency/dashboard`
            : "",
    },
    {
      name: locales["breadcrumb_climate_page_help"] ?? "Hjelp",
      url: "",
    },
  ]
  const dicts = () => {
    const selectableModules = allModules.filter(
      (item) => item != activeModule(),
    )
    return selectableModules.map((mod) => ({
      label: locales[mod],
      onClick: () => {
        navigate(`/dictionary/${mod}`)
      },
    }))
  }
  return (
    <>
      {activeModule() && <MyBreadcrumbs breadcrumbs={breadcrumbs} />}
      <Div id="page" className="dictioanry-page">
        <Div className="module-switch">
          <HoverMenu
            title={locales[activeModule() ?? FootprintModule.Transparency_act]}
            menuItemsClass="dictionaryItems"
            menuItems={dicts()}
          />
          <ArrowDropDownIcon style={{ color: "#78B785" }} />
        </Div>
        <Div className="headers">
          <Heading4 sx={{ textTransform: "uppercase" }}>
            {locales[activeModule() ?? FootprintModule.Transparency_act]}
          </Heading4>
          <Heading1>
            {locales["pages_dictionary_title"] ??
              "Ordbok (alfabetisk rekkefølge)."}
          </Heading1>
        </Div>

        <Div>
          {glossaries[activeModule() ?? FootprintModule.Transparency_act].map(
            (item, idx) => (
              <Div className="glossary-item" key={item.term}>
                <Subtitle>
                  {locales[`${activeModule()}_dicitionary_${idx + 1}_word`] ??
                    item.term}
                </Subtitle>
                <BodyText className="glossary-desc">
                  {locales[
                    `${activeModule()}_dicitionary_${idx + 1}_description`
                  ] ?? item.description}
                </BodyText>
              </Div>
            ),
          )}
        </Div>
      </Div>
    </>
  )
}

export default Dictionary

import { Div } from "modules/shared"
import { SplitView } from "modules/layout"
import { SectionContainer } from "modules/shared"
import React from "react"
import { HintTitle, SectionSixSideMenus } from "../IntroductionStyles"
import { Heading2, Paragraph } from "modules/shared"
import { FootprintScrollArrow } from "modules/shared"
import { ASSET_IMAGES } from "utils/constants/paths"
export const Section6: React.FC<{
  locales?: any
  large?: boolean
  medium?: boolean
  small?: boolean
}> = ({ locales = {}, large, medium, small }) => {
  return (
    <SectionContainer
      id="page_manual_section6"
      sx={{
        p:
          (medium && "82px 48px 0px") ||
          (small && "80px 40px 0px") ||
          "110px 112px 0px",
        position: "relative",
      }}
    >
      <Div
        sx={{ position: "absolute", left: "0px", top: "0px", height: "100%" }}
      >
        <img
          src={`${ASSET_IMAGES}/pages/climate/introduction/nav-menu-close.png`}
          alt="close-sidebar"
          style={{ height: "100%" }}
        />
      </Div>
      <SplitView
        left={(medium && "424px") || (small && "368px") || "424px"}
        right={(medium && "500px") || (small && "304px") || "536px"}
        gap={(small && "16px") || "136px"}
        sx={{ justifyContent: "center" }}
      >
        <Div sx={{ m: "auto" }}>
          <HintTitle sx={{ fontSize: (small && "15px") || "17px" }}>
            {locales["page_manual_section9_hint"] ??
              "HVOR LAGRER JEG ALLE FILENE MINE?"}
          </HintTitle>
          <Heading2
            sx={{
              m: 0,
              fontSize: (small && "26px") || "32px",
              lineHeight: "normal",
            }}
          >
            {locales["page_manual_section9_title"] ??
              "Mine Filer er ditt skylagringssystem."}
          </Heading2>
          <Paragraph sx={{ m: "0px", fontSize: (small && "15px") || "17px" }}>
            {locales["climate_page_intro_section6_paragraph"] ??
              `Plattformen vår tilbyr infrastruktur og trygg skylagring for alt fra regnskapstall til den ferdige rapporten din. Du kan enkelt laste ned, laste opp og slette dokumenter som du vil.\n\nDu finner alle filene dine under Mine Filer i menyen øverst til høyre eller gjennom navigasjonsmenyen.`}
          </Paragraph>
        </Div>
        <SectionSixSideMenus
          sx={{
            width: (medium && "416px") || (small && "304px") || "536px",
            height: (medium && "500px") || (small && "360px") || "500px",
          }}
        />
      </SplitView>
      <FootprintScrollArrow
        nextSelector="#page_manual_section7"
        style={{
          margin:
            (small && "60px auto") || (medium && "60px auto") || "80px auto",
        }}
      />
    </SectionContainer>
  )
}

import React from "react"
import FootprintThemeHeaderContext from "./FootprintThemeHeaderContext"
import { createTheme } from "@mui/material/styles"

const FootprintThemeHeader: React.FC<{ children: any; init: any }> = ({
  children,
  init,
}) => {
  const [headerTheme, setHeaderTheme] = React.useState(init)

  const themeHeaderContextValue = React.useMemo(
    () => ({
      headerTheme: createTheme(headerTheme),
      setHeaderTheme: setHeaderTheme,
    }),
    [headerTheme, setHeaderTheme],
  )

  return (
    <FootprintThemeHeaderContext.Provider value={themeHeaderContextValue}>
      {children}
    </FootprintThemeHeaderContext.Provider>
  )
}

export default FootprintThemeHeader

import React from "react"
import { useFootprintApp } from "modules/app"
import config from "utils/config"

const Page = ({ component, layout, ...restProps }: any) => {
  const { activeLayout, setActiveLayout }: any = useFootprintApp()

  React.useEffect(() => {
    if (layout !== activeLayout) {
      setActiveLayout(layout)
    }
  }, [layout])

  const PageComponent = component

  return <PageComponent {...restProps} />
}

Page.defaultProps = {
  layout: config.defaultLayout,
}

export default Page

import { BodyText, Caption, Div } from "modules/shared"
import { FunctionComponent, useState } from "react"
import FootprintTertiaryButton from "../FootprintTertiaryButton/FootprintTertiaryButton"
import { IconButton, SxProps } from "@mui/material"
import Dropzone, { Accept } from "react-dropzone"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import { FieldInputProps, useField, useFormikContext } from "formik"
import { Else, If, Then, When } from "react-if"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile"
import DoneIcon from "@mui/icons-material/Done"
import CloseIcon from "@mui/icons-material/Close"
interface FootprintFileFieldFormikProps {
  sx?: SxProps
  name: string
  accept?: Accept
  onDelete?: () => void
}

/**
 * expected formik value is {base64: string,name:string}
 */
const FootprintFileFieldFormik: FunctionComponent<
  FootprintFileFieldFormikProps
> = (props) => {
  const [field, meta] = useField(props.name)
  const { setFieldValue } = useFormikContext()
  const [isDragActive, setisDragActive] = useState(false)

  const onDeleteFile = async () => {
    if (props.onDelete) {
      try {
        await props.onDelete()
        setFieldValue(props.name, "")
      } catch (e) {
        return
      }
    } else {
      setFieldValue(props.name, "")
    }
  }

  const onDownloadFile = async () => {
    const url = new URL(field.value)
    const pathname = url.pathname
    const filename = pathname.substring(pathname.lastIndexOf("/") + 1)
    const parts = filename.split(".")
    const extension = parts.pop()
    const name = parts.join(".")

    const t = await fetch(field.value)
    const blob = await t.blob()
    const a = document.createElement("a")
    a.href = URL.createObjectURL(blob)
    let fileName = `${name}.${extension}`
    a.setAttribute("download", fileName)
    a.click()
  }

  const onDrop = (acceptedFiles: File[]) => {
    setisDragActive(false)
    const file = acceptedFiles[0]
    const reader = new FileReader()

    reader.onabort = () => console.log("file reading was aborted")
    reader.onerror = () => (meta.error = "Error reading file")
    reader.onload = () => {
      setFieldValue(props.name, file)
    }
    reader.readAsArrayBuffer(file)
  }

  return (
    <Div
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        alignContent: "center",
        alignItems: "center",
        ...props.sx,
      }}
    >
      <If condition={Boolean(field.value)}>
        <Then>
          <Div
            sx={{
              border: "1px dashed",
              padding: "12px",
              width: "424px",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              fontSize: "17px",
              fontWeight: 300,
              color: "black",
              position: "relative",
              cursor: "pointer",
            }}
          >
            <InsertDriveFileIcon
              sx={{ width: "16px", height: "16px", marginRight: "5px" }}
            ></InsertDriveFileIcon>
            <BodyText
              style={{}}
              onClick={() => onDownloadFile()}
              title={`Download file: ${field.name}`}
            >
              {field.name?.length >= 38
                ? `${field.name.substr(0, 75)}...`
                : `Download file: ${field.name}`}
            </BodyText>
            <IconButton
              sx={{ position: "absolute", right: "-5px", top: "-5px" }}
              aria-label="delete"
              title="Delete file"
              onClick={() => onDeleteFile()}
            >
              <CloseIcon />
            </IconButton>
          </Div>
          <Div
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#78B785",
              gap: "10px",
              marginTop: "-10px",
            }}
          >
            <DoneIcon sx={{ width: "16px", height: "16px" }}></DoneIcon>
            <Caption sx={{ margin: "0" }}>Filen er lastet opp</Caption>
          </Div>
        </Then>
        <Else>
          <Dropzone
            onDragLeave={() => setisDragActive(false)}
            onDragEnter={() => setisDragActive(true)}
            accept={props.accept}
            onDrop={onDrop}
          >
            {({ getRootProps, getInputProps }) => (
              <Div
                {...getRootProps()}
                sx={{
                  border: "1px dashed ",
                  display: "flex",
                  padding: "12px",
                  gap: "10px",
                  justifyContent: "center",
                  cursor: "pointer",
                  borderColor: isDragActive ? "#78B785" : "black",
                  outline: "none",
                }}
              >
                <input {...getInputProps()} />
                <Div
                  sx={{
                    color: "#BABABA",
                    fontSize: "17px",
                    fontWeight: 300,
                    width: "424px",
                  }}
                >
                  {isDragActive
                    ? "Drop the files here ..."
                    : "Drag and drop documents here, or press"}
                </Div>
                <FootprintTertiaryButton
                  style={{ color: isDragActive ? "#E6E6E6" : "#78B785" }}
                >
                  Upload
                </FootprintTertiaryButton>
              </Div>
            )}
          </Dropzone>
        </Else>
      </If>
      <When condition={Boolean(isDragActive)}>
        <Div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#BABABA",
            gap: "10px",
            marginTop: "-10px",
          }}
        >
          <UploadFileIcon
            sx={{ width: "16px", height: "16px" }}
          ></UploadFileIcon>
          <Caption sx={{ margin: "0" }}>Laster opp...</Caption>
        </Div>
      </When>
    </Div>
  )
}

export default FootprintFileFieldFormik

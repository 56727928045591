import {
  BodyText2,
  FootprintDropDown,
  FootprintModule,
  FootprintModuleData,
} from "modules/shared"
import React, { FunctionComponent, useState, useEffect } from "react"
import MenuItem from "@mui/material/MenuItem"
import { DateRangePicker } from "rsuite"
import "rsuite/dist/rsuite-no-reset.min.css"
import "./ViewCompany.style.css"
import { useFormikContext } from "formik"
import { IconButton } from "@mui/material"
import { DateRange } from "rsuite/esm/DateRangePicker"
import { useCompaniesApi } from "modules/admin/services/useCompaniesApi"
import useLocalesState from "utils/Localization/useLocalesState"
import ClearIcon from "@mui/icons-material/Clear"
import { If, Then } from "react-if"
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined"
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined"
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined"
import SnackbarUtils from "utils/SnackbarUtils"

interface ActiveModulesDropdownProps {
  style?: React.CSSProperties
  name: string
  disabled?: boolean
  disableBeforeToday?: boolean
  companyId?: string //if this is found, then this means we are editing. otherwise, we are creating
}
interface Module {
  id: number
  startDate?: Date
  endDate?: Date
  name: string
  isVisible: boolean
}
const ActiveModulesDropdown: FunctionComponent<ActiveModulesDropdownProps> = (
  props,
) => {
  const [locales]: any = useLocalesState()
  const { setFieldValue, initialValues } = useFormikContext()
  const { beforeToday } = DateRangePicker
  const {
    getActiveModuleList,
    updateCompanyClimateReportVisibilty,
    notifyCompanyClimateReportReady,
  } = useCompaniesApi()
  const [modules, setmodules] = useState<Module[]>([])
  const [errorText, seterrorText] = useState("")
  const [allModules, setAllmodules] = useState<Module[]>([])
  const [selectedModules, setselectedModules] = useState<Module[]>(
    (initialValues as any)["activeModule"].map((mod: any) => ({
      ...mod,
      id: mod.moduleId,
    })) || [],
  )
  const checkForErrors = () => {
    const isModuleNoDate = selectedModules.some(
      (module) => !module.startDate || !module.endDate,
    )
    if (isModuleNoDate)
      seterrorText("Please select a date range for all modules")
    else seterrorText("")
  }

  useEffect(() => {
    console.log("init val", initialValues)
    getActiveModuleList().then((res: any[]) => {
      setAllmodules(res)
    })
  }, [])

  useEffect(() => {
    updateDropdownOptions()
  }, [allModules])

  useEffect(() => {
    checkForErrors()
    updateDropdownOptions()
  }, [selectedModules])

  const updateDropdownOptions = () => {
    //remove already selected modules
    const alreadySelectedModules =
      selectedModules.map((mod: any) => mod.moduleId) || []

    let notSelectedModules = allModules.filter(
      (mod) => !alreadySelectedModules.includes(mod.id),
    )

    notSelectedModules = notSelectedModules.map<Module>((mod) => ({
      ...mod,
      moduleId: mod.id,
      startDate: undefined,
      endDate: undefined,
      isVisible: false,
    }))

    setmodules(notSelectedModules as Module[])
    console.log("notSelectedModules", notSelectedModules)
  }
  const formatDate = (date: Date) => {
    // Extract the year, month, and day components
    const year: number = date.getUTCFullYear()
    const month: number = date.getUTCMonth() + 1 // Months are zero-based, so add 1
    const day: number = date.getUTCDate()

    // Format the components into the desired format
    const formattedDate: string = `${year}-${month
      .toString()
      .padStart(2, "0")}-${day.toString().padStart(2, "0")}`

    return formattedDate
  }

  const getModulesForSending = (modules: Module[]) => {
    return modules.map((module) => {
      const startDate =
        module.startDate instanceof Date
          ? formatDate(module.startDate)
          : formatDate(new Date(module.startDate as any))
      const endDate =
        module.endDate instanceof Date
          ? formatDate(module.endDate)
          : formatDate(new Date(module.endDate as any))
      return {
        moduleId: module.id,
        startDate: startDate,
        endDate: endDate,
        id: module.id,
        isVisible: module.isVisible,
      }
    })
  }

  const addModule = (module: Module) => {
    const newModulesList = [...selectedModules, module]
    setselectedModules(newModulesList)
    setFieldValue(props.name, getModulesForSending(newModulesList))
  }

  const removeModule = (moduleId: number) => {
    const newModulesList = selectedModules.filter(
      (module) => module.id !== moduleId,
    )
    setselectedModules(newModulesList)
    setFieldValue(props.name, getModulesForSending(newModulesList))
  }

  const editModule = (moduleId: number, module: Module) => {
    const newModulesList = selectedModules.map((mod) => {
      if (mod.id === moduleId) {
        return module
      }
      return mod
    })
    setselectedModules(newModulesList)
    setFieldValue(props.name, getModulesForSending(newModulesList))
  }

  const isModuleSelected = (moduleId: number) => {
    return selectedModules.findIndex((mod) => mod.id === moduleId) !== -1
  }

  const onDateRangeSelect = (range: DateRange | null, module: Module) => {
    editModule(module.id, {
      ...module,
      startDate: range?.[0],
      endDate: range?.[1],
    })
  }

  const onToggleVisibility = async (module: Module) => {
    await updateCompanyClimateReportVisibilty(
      props.companyId!,
      module.id,
      !module.isVisible,
    )
    SnackbarUtils.success(
      locales["notificaiton_climateReportVisibilityChanged"] ??
        "Visibility updated successfully",
    )
    editModule(module.id, { ...module, isVisible: !module.isVisible })
  }

  const onNotifyClicked = async () => {
    await notifyCompanyClimateReportReady(props.companyId!)
    SnackbarUtils.success(
      locales["notificaiton_copmanyNotifiedClimate"] ??
        "Company notified successfully",
    )
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        fontWeight: 300,
        ...props.style,
        marginBottom: errorText ? "20px" : props.style?.marginBottom,
      }}
    >
      <FootprintDropDown
        label={locales["pages_admin_home_business_activeModules"]}
        selectProps={{
          name: props.name,
          disabled: selectedModules.length == 3 || props.disabled,
          value: 0,
          onChange: (e: any) => {
            const val = e.target.value

            if (val === 0) return
            addModule({
              id: val,
              name: modules.find((module) => module.id === val)?.name || "",
              startDate: undefined,
              endDate: undefined,
              isVisible: false,
            })
          },
        }}
      >
        <MenuItem value={0}></MenuItem>
        {modules.map((module) => (
          <MenuItem
            value={module.id}
            key={module.id}
            style={{ display: isModuleSelected(module.id) ? "none" : "" }}
          >
            {module.name}
          </MenuItem>
        ))}
      </FootprintDropDown>

      {selectedModules.map((module: Module) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            minHeight: "40px",
            maxHeight: "60px",
            fontSize: "10px",
            minWidth: "max-content",
            zIndex: 2,
          }}
          key={module.id + module.name}
        >
          <div
            style={{
              background: "#DFE3E6",
              padding: "8px 12px",
              height: "100%",
              alignItems: "center",
              display: "flex",
            }}
          >
            <span>{module.name}</span>
          </div>
          <div
            style={{
              border: "1px solid #DFE3E6",
              height: "100%",
              alignItems: "center",
              display: "flex",
            }}
          >
            <DateRangePicker
              disabled={props.disabled}
              shouldDisableDate={
                props.disableBeforeToday ? (beforeToday as any)() : null
              }
              value={
                module.endDate && module.startDate
                  ? [
                      new Date(module.startDate || ""),
                      new Date(module.endDate || ""),
                    ]
                  : null
              }
              onChange={(range) => onDateRangeSelect(range, module)}
            />
          </div>
          <IconButton
            disabled={props.disabled}
            onClick={() => removeModule(module.id)}
            title="Remove module"
          >
            <ClearIcon />
          </IconButton>
          <If
            condition={
              module.id ==
                FootprintModuleData[FootprintModule.Climate_accounting].id &&
              props.companyId
            }
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "24px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "2px",
                  cursor: "pointer",
                }}
                title="Toggle visibility"
                onClick={() => {
                  onToggleVisibility(module)
                }}
              >
                <BodyText2 style={{ color: "#BABABA" }}>
                  {locales[
                    "pages_admin_home_business_climateDashboardVisibility"
                  ] ?? "Vis dashboard"}
                  :
                </BodyText2>
                <div
                  style={{ width: "20px", height: "20px", color: "#233E4C" }}
                >
                  {module.isVisible ? (
                    <RemoveRedEyeOutlinedIcon
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  ) : (
                    <VisibilityOffOutlinedIcon
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "2px",
                  cursor: "pointer",
                }}
                onClick={() => onNotifyClicked()}
              >
                <BodyText2 style={{ color: "#BABABA" }}>
                  {locales[
                    "pages_admin_home_business_climateSendNotification"
                  ] ?? "Send varsel"}
                  :
                </BodyText2>
                <NotificationsActiveOutlinedIcon
                  style={{ width: "20px", height: "20px", color: "#233E4C" }}
                />
              </div>
            </div>
          </If>
        </div>
      ))}

      <div style={{ display: errorText ? "block" : "none", color: "red" }}>
        {errorText}
      </div>
    </div>
  )
}

export default ActiveModulesDropdown

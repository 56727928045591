import React from "react"
import { FootprintLayout } from "modules/layout"
import useFootprintLayout from "modules/layout/services/useFootprintLayout"
import layoutConfig from "./layoutConfig"

const WhitePage = ({ children }: any) => {
  const { setFootprintLayoutOptions } = useFootprintLayout()

  React.useEffect(() => {
    setFootprintLayoutOptions(layoutConfig)
  }, [])

  return <FootprintLayout>{children}</FootprintLayout>
}

export default WhitePage

import { Div, Heading2, Heading3 } from "modules/shared"
import { FunctionComponent } from "react"

interface NoDataAvailableProps {}

const NoDataAvailable: FunctionComponent<NoDataAvailableProps> = () => {
  return (
    <Div
      sx={{
        position: "relative",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Heading3>No Data Available</Heading3>
    </Div>
  )
}

export default NoDataAvailable

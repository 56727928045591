import axiosHelper from "utils/axiosHelper"
import {
  UPDATE_USER_FIRST_LOGIN_URL,
  GET_USER_ROLES_AND_PERMISSION_BY_ID_URL,
  GET_USER_BY_ID_URL,
} from "utils/constants/URLs"

//shared
export const getUserDataById = (userId: any) => {
  return axiosHelper.get(GET_USER_BY_ID_URL.replace("{userId}", userId))
}

//shared
export const updateFirstLoginAPI = (userId: any) => {
  return axiosHelper.post(
    UPDATE_USER_FIRST_LOGIN_URL.replace("{userId}", userId),
  )
}

//shared
export const getUserPermissionsAndRolesById = (userId: any) => {
  return axiosHelper.get(
    GET_USER_ROLES_AND_PERMISSION_BY_ID_URL.replace("{userId}", userId),
  )
}

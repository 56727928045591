import {
  FAILURE,
  REMOVE_ERROR,
  START_LOADING,
  SUCCESS,
  STOP_LOADING,
} from "redux/constants/status"
export const startLoading = () => ({ type: START_LOADING })

export const onSuccess = (successMessage: any) => ({
  type: SUCCESS,
  payload: { successMessage },
})

export const onFailure = (errorStatus: any, errorMessage: any) => ({
  type: FAILURE,
  payload: { errorStatus, errorMessage },
})
export const stopLoading = () => ({ type: STOP_LOADING })

export const clearError = () => ({ type: REMOVE_ERROR })

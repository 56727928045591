import axiosHelper from "../../../utils/axiosHelper"
import {
  GENERATE_REPORT_URL,
  DOWNLOAD_GENERATED_SUSTAINABILITY,
} from "../../../utils/constants/URLs"
import { DOWNLOAD_GENERATED_FILE_BY_ID_URL } from "utils/constants/URLs"

export const generateReport = (formData: any) => {
  return axiosHelper.post(GENERATE_REPORT_URL, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}
export const downloadSustainabilityReportFileApi = (companyId: any) => {
  let url = DOWNLOAD_GENERATED_SUSTAINABILITY.replace("{companyId}", companyId)
  return axiosHelper.get(url, {
    responseType: "blob",
  })
}

export const getGeneratedReportAPI = (companyId: any) => {
  let url = DOWNLOAD_GENERATED_FILE_BY_ID_URL.replace("{companyId}", companyId)

  return axiosHelper.get(url)
}

import { Div, Heading2 } from "modules/shared"
import React from "react"
import { SplitView } from "modules/layout"
import { Intro } from "../IntroductionStyles"
import {
  FootprintScrollArrow,
  SectionContainer,
  Paragraph,
} from "modules/shared"
import { ASSET_IMAGES } from "utils/constants/paths"
import { Stack } from "@mui/material"
export const Section1: React.FC<{
  locales?: any
  large?: boolean
  medium?: boolean
  small?: boolean
}> = ({ locales = {}, large, medium, small }) => {
  return (
    <Div>
      {/* //Section one */}
      <SectionContainer
        sx={{
          p:
            (large && "100px 111px 0px") ||
            (medium && "81px 2.86vw 0px") ||
            (small && "81px 2.604vw 0px ") ||
            "100px 111px 0px",
        }}
      >
        <SplitView
          left={(medium && "536px") || (small && "432px") || "536px"}
          right={(medium && "424px") || (small && "304px") || "424px"}
          gap={(small && "16px") || "137.5px"}
          sx={{ justifyContent: "center" }}
        >
          <Div>
            <img
              width={"100%"}
              height={
                (large && "440px") ||
                (medium && "429px") ||
                (small && "313.5px") ||
                "313.5px"
              }
              src={`${ASSET_IMAGES}/pages/climate/introduction/section1.png`}
              alt="climate_report_intro_section1"
            />
          </Div>
          <Div sx={{ margin: "auto" }}>
            <Heading2 sx={{ m: 0, fontSize: (small && "26px") || "32px" }}>
              {locales["climate_page_intro_section1_title"] ??
                "Slik lager du et klimaregnskap med vårt digitale verktøy."}
            </Heading2>
            <Paragraph
              sx={{ m: "0px", fontSize: (small && "15px") || "17px" }}
              dangerouslySetInnerHTML={{
                __html:
                  locales["climate_page_intro_section1_paragraph"] ??
                  `På denne siden forklarer vi hvordan du bruker portalen vår til å lage et klimaregnskap i henhold til den internasjonale gullstandarden for beregning av CO<sub>2</sub>-utslipp (GHG-protokollen).`,
              }}
            ></Paragraph>
          </Div>
        </SplitView>
        <Stack alignItems="center" sx={{ m: 0, p: 0 }}>
          <Intro>
            {locales["page_manual_section1_intro"] ?? "INTRODUKSJON"}
          </Intro>
          <FootprintScrollArrow nextSelector="#page_manual_section2" />
          <span style={{ marginTop: (large && "80px") || "60px" }}></span>
        </Stack>
      </SectionContainer>
    </Div>
  )
}

import { getLocales } from "utils/localesHelper"
import configureStore from "../store/store"
import { GET_LOCALES } from "redux/constants/appStatus"
import { Langs } from "redux/types/localeTypes"

export const refreshLocales =
  (locale: Langs) =>
  (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const { store } = configureStore()
    const locales = store.getState().locales
    if (locales.isEmpty || locales.lang !== locale) {
      getLocales(locale).then((response) => {
        dispatch(getLocalesSuccess(response.data))
        window.localStorage.setItem("language", locale)
      })
    }
  }

const getLocalesSuccess = (locales: any) => ({
  type: GET_LOCALES,
  payload: locales,
})

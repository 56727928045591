import authConfig from "./auth0"
const { AUTH_DOMAIN, AUTH_CLIENT_ID, AUTH_AUDIENCE } = authConfig()
// export const API_URI =
//   process.env.react_app_api_uri || "https://dev-footprint.amesto.no/new-api"
export const API_URI =
  process.env.react_app_api_uri ?? "https://footprint.amesto.no/new-api"

export const REPORT_API_URI =
  process.env.REPORT_API_URI ?? "https://footprint.amesto.no/new-api/report"

export const ACCOUNT_API_URI =
  process.env.ACCOUNT_API_URI ?? "https://footprint.amesto.no/new-api/account"

export const REACT_APP_AUTH0_DOMAIN = AUTH_DOMAIN
export const REACT_APP_AUTH0_CLIENT_ID = AUTH_CLIENT_ID

export const AUDIENCE = AUTH_AUDIENCE

import React from "react"
import { Div } from "modules/shared"
import MainSection from "../../transparencyAct/pages/dashboard/components/MainSection/MainSection"
import ContactUs from "modules/transparencyAct/components/ContactUs/ContactUs"
import Products from "../../transparencyAct/pages/dashboard/components/Products/Products"

const DashboardFirstTime = () => {
  const Divider = () => (
    <Div sx={{ height: "80px", background: "#F1F3F3", p: 0, m: 0 }}></Div>
  )

  return (
    <Div>
      <MainSection />
      <Divider />
      <Products />
      <Divider />
      <ContactUs />
    </Div>
  )
}

export default DashboardFirstTime

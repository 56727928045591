import { Div, FootprintPrimaryButton, Heading2 } from "modules/shared"
import React from "react"
import { SplitView } from "modules/layout"
import { FootprintScrollArrow, Paragraph } from "modules/shared"
import { ASSET_IMAGES } from "utils/constants/paths"
import { Stack } from "@mui/material"
export const Section2: React.FC<{
  locales?: any
  large?: boolean
  medium?: boolean
  small?: boolean
}> = ({ locales = {}, large, medium, small }) => {
  return (
    <Div id="page_road_head_section2">
      {/* //Section two */}
      <Div
        sx={{
          p:
            (large && "120px 112px 0px") ||
            (medium && "113px 40px 0px") ||
            (small && "83px 40px 0px ") ||
            "83px 40px 0px",
        }}
      >
        <SplitView
          left={(medium && "504px") || (small && "368px") || "536px"}
          right={(medium && "416px") || (small && "368px") || "424px"}
          gap={(small && "16px") || (medium && "123px") || "136px"}
        >
          <Div>
            <img
              width={
                (large && "536px") ||
                (medium && "504px") ||
                (small && "368px") ||
                "368px"
              }
              height={
                (large && "480px") ||
                (medium && "480px") ||
                (small && "350px") ||
                "350px"
              }
              src={`${ASSET_IMAGES}/pages/climate/roadHead/roadHeadSection2-img.png`}
              alt="climate_report_roadHead_section2"
            />
          </Div>
          <Div sx={{ margin: "auto" }}>
            <Heading2
              sx={{ m: 0, fontSize: (small && "26px") || "32px" }}
              dangerouslySetInnerHTML={{
                __html:
                  locales["climate_page_roadHead_section2_title"] ??
                  "Klimaregnskapet kan være starten på en reise for å redusere CO<sub>2</sub>-utslipp.",
              }}
            ></Heading2>
            <Paragraph
              sx={{ m: "0px", fontSize: (small && "15px") || "17px" }}
              dangerouslySetInnerHTML={{
                __html:
                  locales["climate_page_roadHead_section2_paragraph"] ??
                  `Vi rådgir alle våre kunder til å sette klimamål og utarbeide tiltaksplaner med de mest effektive og lønnsomme tiltakene deres virksomhet kan gjøre for å redusere CO<sub>2</sub>-utslipp.\nVi har utarbeidet en metode som tar utgangspunkt i klimaregnskapet deres. Ta kontakt for å booke et uforpliktende oppstartsmøte.`,
              }}
            ></Paragraph>
            <FootprintPrimaryButton
              version="secondary"
              onClick={() =>
                window.open(
                  "https://www.amestoaccounthouse.no/amesto-footprint#form",
                  "_blank",
                )
              }
              style={{
                marginTop: "40px",
                backgroundImage: `linear-gradient(to right, #000000 50%, #ffffff 50%)`,
              }}
            >
              {locales["pages_dashboard_products_section_button_product3"] ??
                "Book møte"}
            </FootprintPrimaryButton>
          </Div>
        </SplitView>
        <Stack
          alignItems="center"
          sx={{ my: (large && "80px") || "60px", p: 0 }}
        >
          <FootprintScrollArrow nextSelector="#page_road_head_section3" />
        </Stack>
      </Div>
    </Div>
  )
}

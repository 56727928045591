import { Grid, useMediaQuery } from "@mui/material"
import {
  Caption,
  Div,
  FootprintPrimaryButton,
  Heading2,
  Heading3,
  ReactQueryKeys,
  SimpleLoader,
} from "modules/shared"
import React, { useContext, useEffect, useState } from "react"
import useLocalesState from "utils/Localization/useLocalesState"
import { jsPDF } from "jspdf"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from "chart.js"
import html2canvas from "html2canvas"

import { Bar, Line, Scatter } from "react-chartjs-2"
import ChartsContext from "../ChartsContext"
import { useClimateOverviewApi } from "modules/climateModule/services/useClimateOverviewApi"
import { useQuery } from "react-query"
import { Else, If, Then, When } from "react-if"
import { ChartsColorGenerator } from "modules/climateModule/services/ChartsColorGenerator"
import NoDataAvailable from "./NoDataAvailable"
import { useParams } from "react-router-dom"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
)

interface Scope {
  total: number
  scope: string
}
interface OverviewProps {
  downloadAll: boolean
  notShowDownloadButton?: boolean
}

const Overview = (props: OverviewProps) => {
  const [locales]: any = useLocalesState()
  const pdfRef = React.useRef<any>()
  const chartsContext = useContext(ChartsContext)
  const { companyId } = useParams()
  const LargeScreen = useMediaQuery("(min-width: 1440px)")
  const mediumScreen = useMediaQuery(
    "(max-width: 1440px) and (min-width: 904px)",
  )
  const smallScreen = useMediaQuery("(max-width: 904px)")
  const {
    getTotalScopesYearly,
    getCompanyCapro,
    getTotalScopesPerQuarter,
    getTotalScopesPerYear,
    getClimateImpactPerPerson,
  } = useClimateOverviewApi()
  const [chart1Data, setchart1Data] = useState<any | null>()
  const [chart2Data, setchart2Data] = useState<any | null>()
  const [chart3Data, setchart3Data] = useState<any | null>()
  const [chart4Data, setchart4Data] = useState<any | null>()
  const [scopesData, setScopesData] = useState<any | null>()
  const {
    isLoading: isscopesDataLoading,
    error: scopesDataError,
    data: scopesDataResponse,
  } = useQuery(
    [
      ReactQueryKeys.climateOverviewScopes,
      companyId,
      chartsContext.reportingYear,
    ],
    () => getTotalScopesPerYear(chartsContext.reportingYear),
    { refetchOnMount: false },
  )
  const {
    isLoading: ischart1DataLoading,
    error: chart1DataError,
    data: chart1DataResponse,
  } = useQuery(
    [
      ReactQueryKeys.climateOverviewChart1,
      companyId,
      chartsContext.reportingYear,
    ],
    () => getTotalScopesPerQuarter(chartsContext.reportingYear),
    { refetchOnMount: false },
  )

  const {
    isLoading: ischart2DataLoading,
    error: chart2DataError,
    data: chart2DataResponse,
  } = useQuery(
    [
      ReactQueryKeys.climateOverviewChart2,
      companyId,
      chartsContext.reportingYear,
    ],
    () => getTotalScopesYearly(chartsContext.reportingYear),
    { refetchOnMount: false },
  )

  const {
    isLoading: ischart3DataLoading,
    error: chart3DataError,
    data: chart3DataResponse,
  } = useQuery(
    [
      ReactQueryKeys.climateOverviewChart3,
      companyId,
      chartsContext.reportingYear,
    ],
    () => getCompanyCapro(chartsContext.reportingYear),
    { refetchOnMount: false },
  )

  const {
    isLoading: ischart4DataLoading,
    error: chart4DataError,
    data: chart4DataResponse,
  } = useQuery(
    [
      ReactQueryKeys.climateOverviewChart4,
      companyId,
      chartsContext.reportingYear,
    ],
    () => getClimateImpactPerPerson(chartsContext.reportingYear),
    { refetchOnMount: false },
  )
  const options = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  }

  const lineOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        position: "top" as const,
      },
    },
  }

  const scatterOptions = {
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        display: false, // Hide y-axis values
      },
      x: {
        beginAtZero: true,
      },
    },
  }
  const downloadPdf = async () => {
    const pdf = new jsPDF({
      format: "a4", // Use A4 format
    })

    const pdfElement = pdfRef.current

    // Use html2canvas to capture the HTML content as an image
    const canvas = await html2canvas(pdfElement)

    // Get the dimensions of the A4 page
    const a4Width = 210
    const a4Height = 297

    // Calculate the scaling factor for the captured image
    const scaleFactor = Math.min(
      a4Width / canvas.width,
      a4Height / canvas.height,
    )

    // Calculate the new dimensions of the captured image
    const scaledWidth = canvas.width * scaleFactor
    const scaledHeight = canvas.height * scaleFactor

    // Calculate the center position on the A4 page
    const xPosition = (a4Width - scaledWidth) / 2
    const yPosition = (a4Height - scaledHeight) / 2

    // Add the captured image to the PDF
    pdf.addImage(
      canvas.toDataURL("image/png"),
      "PNG",
      xPosition,
      yPosition,
      scaledWidth,
      scaledHeight,
    )

    // Generate PDF from the HTML content
    pdf.save("Oversikt.pdf")
  }

  const fillChart1Data = () => {
    if (chart1DataResponse?.status == 204) {
      setchart1Data(null)
      return
    }
    const data = chart1DataResponse!.data
    const lineData = data.dataSets
    const lineLabels = data.labels

    const sortedLineData = sortLineDataScopes(lineData)

    const chart1data = {
      labels: lineLabels,
      datasets: [
        ...sortedLineData.map((line: any) => {
          return {
            label: line.label,
            data: line.data,
            backgroundColor: ChartsColorGenerator.instance.getColor(line.label)
              .mainColor,
          }
        }),
      ],
    }

    console.log("overview Chart1Data", chart1data)
    setchart1Data(chart1data)
  }

  const fillChart2Data = () => {
    if (chart2DataResponse?.status == 204) {
      setchart2Data(null)
      return
    }
    const data = chart2DataResponse!.data
    const lineData = data.dataSets
    const lineLabels = data.labels

    const sortedLineData = sortLineDataScopes(lineData)

    const chart2data = {
      labels: lineLabels,
      datasets: [
        ...sortedLineData.map((line: any) => {
          return {
            fill: true,
            label: line.label,
            data: line.data,
            backgroundColor: ChartsColorGenerator.instance.getColor(line.label)
              .backgroundColor,
            borderColor: ChartsColorGenerator.instance.getColor(line.label)
              .mainColor,
          }
        }),
      ],
    }

    console.log("overview Chart2Data", chart2data)
    setchart2Data(chart2data)
  }

  const fillChart3Data = () => {
    if (chart3DataResponse?.status == 204) {
      setchart3Data(null)
      return
    }
    const data = chart3DataResponse!.data
    const scatterData = {
      datasets: [
        ...data.map((scatter: any) => {
          return {
            label: scatter.legendName,
            data: [
              { x: scatter.capro, y: 0 }, // Same y-value for all points

              // Add more data points as needed
            ],
            pointRadius: 7, // Adjust the radius to center the points
            backgroundColor: ChartsColorGenerator.instance.getColor(
              scatter.legendName,
            ).mainColor,
          }
        }),
      ],
    }

    console.log("overview Chart3Data", scatterData)
    setchart3Data(scatterData)
  }

  const fillChart4Data = () => {
    if (chart4DataResponse?.status == 204) {
      setchart4Data(null)
      return
    }
    const data = chart4DataResponse!.data
    const scatterData = {
      datasets: [
        ...data.map((scatter: any) => {
          return {
            label: scatter.legendName,
            data: [
              { x: scatter.totalCo2PerPerson, y: 0 }, // Same y-value for all points

              // Add more data points as needed
            ],
            pointRadius: 7, // Adjust the radius to center the points
            backgroundColor: ChartsColorGenerator.instance.getColor(
              scatter.legendName,
            ).mainColor,
          }
        }),
      ],
    }

    console.log("overview Chart4Data", scatterData)
    setchart4Data(scatterData)
  }

  const fillScopesData = () => {
    if (scopesDataResponse?.status == 204) {
      setScopesData(null)
      return
    }
    const scopesData = scopesDataResponse?.data || []
    setScopesData(scopesData)
  }

  useEffect(() => {
    if (!ischart1DataLoading) fillChart1Data()
    if (!ischart2DataLoading) fillChart2Data()
    if (!ischart3DataLoading) fillChart3Data()
    if (!ischart4DataLoading) fillChart4Data()
    if (!isscopesDataLoading) fillScopesData()
  }, [
    ischart1DataLoading,
    chart1DataResponse,
    ischart2DataLoading,
    chart2DataResponse,
    ischart3DataLoading,
    chart3DataResponse,
    isscopesDataLoading,
    chart4DataResponse,
    ischart4DataLoading,
    scopesDataResponse,
  ])

  return (
    <Div>
      <Div ref={pdfRef} sx={{ canvas: { background: "white" } }}>
        <Div sx={{ textAlign: "center", py: "18px" }}>
          <Heading3
            sx={{ color: "var(--B0B9C0, #B0B9C0)", textTransform: "uppercase" }}
          >
            {locales["climate_charts_reportingYear"] ?? "Rapporteringsår"}{" "}
            {chartsContext.reportingYear}
          </Heading3>
        </Div>
        <Div>
          <If condition={!isscopesDataLoading}>
            <Then>
              <When condition={scopesData}>
                <Div
                  sx={{
                    py: "24px",
                    backgroundColor: "var(--F1F3F3, #F1F3F3)",
                    display: "grid",
                    gridTemplateColumns: "auto auto auto auto",
                    gap: "24px",
                    textAlign: "center",
                  }}
                >
                  <Div sx={{ background: "white", pt: "20px", width: "312px" }}>
                    <Heading2>
                      {scopesData?.totalEmissions?.toFixed(0)}
                    </Heading2>
                    <Caption
                      sx={{
                        // color: "#9f9f9f",
                        fontFamily: "Rubik",
                        fontSize: "13px",
                        fontStyle: "normal",
                        fontWeight: 300,
                        lineHeight: "normal",
                        letterSpacing: "0.26px",
                      }}
                    >
                      Total emissions - tCo<sub>2</sub>e
                    </Caption>
                  </Div>
                  {scopesData &&
                    scopesData!.scopes.map((scope: Scope, index: number) => (
                      <Div
                        sx={{ background: "white", pt: "20px", width: "312px" }}
                        key={index}
                      >
                        <Heading2>{scope.total.toFixed(0)}</Heading2>
                        <Caption
                          sx={{
                            // color: "#9f9f9f",
                            fontFamily: "Rubik",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: 300,
                            lineHeight: "normal",
                            letterSpacing: "0.26px",
                          }}
                        >
                          {scope.scope} - tCo<sub>2</sub>e
                        </Caption>
                      </Div>
                    ))}
                </Div>
              </When>
              <When condition={scopesData == null}>
                <Div
                  sx={{
                    py: "24px",
                    textAlign: "center",
                  }}
                >
                  <NoDataAvailable></NoDataAvailable>
                </Div>
              </When>
            </Then>
            <Else>
              <SimpleLoader></SimpleLoader>
            </Else>
          </If>
        </Div>
        <Div
          sx={{
            py: "24px",
            backgroundColor: "var(--F1F3F3, #F1F3F3)",
            display: "grid",
            gridTemplateColumns: "auto auto ",
            gap: "24px",
          }}
        >
          <Div
            sx={{
              background: "white",
              width: "648px",
              height: "340px",
              padding: "20px",
              textAlign: "center",
              overflow: "hidden",
            }}
          >
            <Heading3>
              {locales["climate_charts_overview_chart1_title"]}
            </Heading3>
            <If condition={!ischart1DataLoading}>
              <Then>
                <When condition={chart1Data}>
                  <Bar
                    options={{
                      ...options,
                    }}
                    data={chart1Data}
                  />
                </When>
                <When condition={chart1Data == null}>
                  <NoDataAvailable></NoDataAvailable>
                </When>
              </Then>
              <Else>
                <SimpleLoader></SimpleLoader>
              </Else>
            </If>
          </Div>
          <Div
            sx={{
              background: "white",
              width: "648px",
              height: "340px",
              overflow: "hidden",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <Heading3>
              {locales["climate_charts_overview_chart2_title"]}
            </Heading3>
            <If condition={!ischart2DataLoading}>
              <Then>
                <When condition={chart2Data}>
                  <Line
                    options={{
                      ...lineOptions,
                      scales: {
                        y: {
                          display: true,
                          title: {
                            display: true,
                            text: "Co\u2082 Emission",
                          },
                        },
                      },
                    }}
                    data={chart2Data}
                  />
                </When>
                <When condition={chart2Data == null}>
                  <NoDataAvailable></NoDataAvailable>
                </When>
              </Then>
              <Else>
                <SimpleLoader></SimpleLoader>
              </Else>
            </If>
          </Div>
        </Div>
        {props.downloadAll && (
          <Div
            sx={{
              display: "grid",
              gridTemplateColumns: "auto auto ",
              gap: "100px",
            }}
          >
            <Div
              sx={{
                background: "white",
                textAlign: "center",
                margin: "auto",
                width: "600px",
                padding: "20px",
              }}
            >
              <Heading3>
                {locales["climate_charts_overview_chart3_title"]}
              </Heading3>
              <Caption>
                {locales["climate_charts_overview_chart3_desc"]}
              </Caption>

              <If condition={!ischart3DataLoading}>
                <Then>
                  <When condition={chart3Data}>
                    <Scatter
                      data={chart3Data}
                      options={scatterOptions}
                      style={{ height: "205px !important" }}
                    />
                  </When>
                  <When condition={chart3Data == null}>
                    <NoDataAvailable></NoDataAvailable>
                  </When>
                </Then>
                <Else>
                  <SimpleLoader></SimpleLoader>
                </Else>
              </If>
            </Div>
            <Div
              sx={{
                background: "white",
                textAlign: "center",
                margin: "auto",
                width: "600px",
                padding: "20px",
              }}
            >
              <Heading3>
                {locales["climate_charts_overview_chart4_title"]}
              </Heading3>
              <Caption>
                {locales["climate_charts_overview_chart4_desc"]}
              </Caption>
              <If condition={!ischart4DataLoading}>
                <Then>
                  <When condition={chart4Data}>
                    <Scatter
                      data={chart4Data}
                      options={scatterOptions}
                      style={{
                        width: "350px !important",
                        height: "205px !important",
                      }}
                    />
                  </When>
                  <When condition={chart4Data == null}>
                    <NoDataAvailable></NoDataAvailable>
                  </When>
                </Then>
                <Else>
                  <SimpleLoader></SimpleLoader>
                </Else>
              </If>
            </Div>
          </Div>
        )}
      </Div>
      {!props.notShowDownloadButton && (
        <Div
          sx={{
            background: "var(--F1F3F3, #F1F3F3)",
            textAlign: "center",
          }}
        >
          <FootprintPrimaryButton
            overrideColor="#78B785"
            style={{
              color: "#78B785",
              fontWeight: 500,
              fontSize: "14px",
              marginTop: "24px",
              backgroundImage: `linear-gradient(to right, #ffffff 50%, #78B785 50%)`,
            }}
            onClick={() => downloadPdf()}
          >
            {locales["climate_charts_downloadTab"] ?? "Last ned utvalg"}
          </FootprintPrimaryButton>
        </Div>
      )}
    </Div>
  )
}
export default Overview

// Sorts the label key ("Scope 1" etc) into numerical order.
function sortLineDataScopes(
  lineData: {
    label: string
    data: any
    backgroundColor: string
  }[],
) {
  return lineData.sort((a, b) => {
    const numA = parseInt(a.label.replace(/\D/g, ""), 10)
    const numB = parseInt(b.label.replace(/\D/g, ""), 10)
    return numA - numB
  })
}

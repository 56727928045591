import * as React from "react"
import { useField, FieldAttributes } from "formik"
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete"
import TextField, { TextFieldProps } from "@mui/material/TextField"
interface Props {
  labelTopText: string
}
const FootprintAutoComplete: React.FC<
  AutocompleteProps<any, boolean, boolean, boolean, any> & Props
> = (props: any) => {
  return (
    <div
      style={{
        ...props.style,
      }}
    >
      <div>{props.labelTopText}</div>
      <Autocomplete
        multiple
        {...props}
        sx={{
          ...props.sx,
          "& fieldset": {
            borderRadius: "0px",
            boxShadow: "none",
            borderColor: "#DFE3E6",
            height: "48px",
          },
          "& .MuiChip-root": {
            borderRadius: "0px",
          },
          "& .MuiFormHelperText-root": {
            ml: 0,
          },
        }}
        id="tags-outlined"
        filterSelectedOptions
        renderInput={(params) => <TextField {...params} />}
      />
    </div>
  )
}
export default FootprintAutoComplete

import React from "react"
import {
  Navigate,
  Link as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom"
import Link from "@mui/material/Link"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import { SIDEBAR_VIEWS } from "modules/layout"
import { Div } from "modules/shared"
import HoverMenu from "modules/layout/components/vertical-default/HoverMenu"
import { useMediaQuery } from "@mui/material"

const FootprintNavHeaderItem = ({
  item,
  handleClick,
  firstTimeLogin,
  logoClick,
}: any) => {
  const location = useLocation()
  const navigate = useNavigate()
  const medium = useMediaQuery("(max-width: 1440px) and (min-width: 1240px)")
  const small = useMediaQuery("(max-width: 904px)")
  const large = useMediaQuery("(min-width: 1440px)")
  const isMiniAndClosed = true

  const label = React.useMemo(() => {
    return item.label
  }, [item])

  if (!item) return null

  const linkStyle = {
    flex: 1,
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    position: "relative",
    color: "inherit",
    justifyContent: "center",
    "&:focus": {
      outline: "none",
    },
  }

  const ListItemTextStyle = {
    m: 0,
    "& .MuiTypography-root": {
      color: "#000000",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: 500,
      fontSize: (small && "12px") || "14px",
    },
    "&:focus": {
      outline: "none",
    },
  }

  const isLinkUnderlined = () => {
    let isLocationMatch = location.pathname === item.uri
    if (item.children)
      isLocationMatch = item.children.some(
        (i: any) => location.pathname === i.uri,
      )

    return isLocationMatch && false
  }
  const ListItemButtonStyle = {
    p: 0,
    overflow: "hidden",
    margin: "0 auto",
    fontSize: (small && "12px") || "14px",

    ...(isLinkUnderlined()
      ? {
          backgroundColor: "#F1F3F3",
          // borderBottom: "1px solid #78B785",
          textDecoration: "underline",
          textDecorationColor: "#78B785",
          mozTextDecorationColor: "#78B785",
          textUnderlineOffset: "5px",
          textDecorationThickness: "1px",
          "&:hover": {
            textDecoration: "underline",
            textUnderlineOffset: "5px",
            textDecorationColor: "#78B785",
            mozTextDecorationColor: "#78B785",
            textDecorationThickness: "1px",
          },
          "&:focus": {
            outline: "none",
          },
        }
      : {}),
  }

  const linkWithSubMenu = () => {
    return (
      <HoverMenu
        title={label}
        menuItems={item.children.map((i: any) => {
          return {
            label: i.label,
            onClick: () => navigate(i.uri),
          }
        })}
      />
    )
  }

  const linkItem = () => {
    return (
      <Link
        underline={"none"}
        component={RouterLink}
        to={item.uri}
        {...(item.target ? { target: item.target } : {})}
        sx={linkStyle}
      >
        <ListItemText primary={label} sx={ListItemTextStyle} />
      </Link>
    )
  }

  const renderItem = () => {
    if (item.children) return linkWithSubMenu()
    else return linkItem()
  }

  return (
    <>
      {item.role && (
        <Div
          sx={
            item.uri == "/profile"
              ? { marginRight: "0px" }
              : {
                  marginRight:
                    (large && "61.67px") ||
                    (medium && "32px") ||
                    (small && "22px") ||
                    "32px",
                }
          }
          onClick={() => {
            item.uri == "/dashboard" && handleClick()
          }}
        >
          <ListItemButton component={"li"} sx={ListItemButtonStyle}>
            {renderItem()}
          </ListItemButton>
        </Div>
      )}
    </>
  )
}

export default FootprintNavHeaderItem

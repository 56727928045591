import {
  Heading2,
  Paragraph,
  Div,
  SectionContainer,
  FootprintScrollArrow,
} from "modules/shared"
import React from "react"
import { SectionTwoDiagram } from "../ManualStyles"
import { Grid, Stack } from "@mui/material"
export const Section2: React.FC<{
  locales?: any
  large?: boolean
  medium?: boolean
  small?: boolean
}> = ({ locales = {}, large, medium, small }) => {
  return (
    <Div>
      <SectionContainer
        id="page_manual_section2"
        sx={{
          p:
            (large && "125px 7.29vw 0px") ||
            (medium && "81px 2.86vw 0px") ||
            (small && "81px 2.604vw 0px ") ||
            "125px 7.29vw 0px",
          paddingBottom: (large && "80px") || "60px",
        }}
      >
        <Stack>
          <Heading2
            sx={{
              textAlign: "center",
              m: 0,
              p: 0,
              fontSize: (small && "26px") || "45px",
            }}
          >
            {locales["page_manual_section2_title"] ??
              "28 rapporteringskrav fordelt på 8 steg."}
          </Heading2>
          <Paragraph>
            <Grid container spacing={2}>
              <Grid item xs={6} sx={{ fontSize: (small && "15px") || "17px" }}>
                {locales["page_manual_section2_sub_title1"] ??
                  "Målet med Åpenhetsloven er å øke ansvarligheten i norske virksomheter. For å oppnå dette, så er det et lovkrav at virksomheten gjennomfører en aktsomhetsvurdering."}
              </Grid>
              <Grid item xs={6} sx={{ fontSize: (small && "15px") || "17px" }}>
                {locales["page_manual_section2_sub_title2"] ??
                  "For å gjennomføre en effektiv og virkningsfull aktsomhetsvurdering har vi laget en veileder med 8 steg. Veilederen leder deg gjennom 33 rapporteringskrav – trinn for trinn."}
              </Grid>
            </Grid>
          </Paragraph>
        </Stack>
        <SectionTwoDiagram
          sx={{
            width: (medium && "1032px") || (small && "752px") || "1096px",
            height: (medium && "780px") || (small && "564px") || "826px",
          }}
        />
        <FootprintScrollArrow nextSelector="#page_manual_section3" />
      </SectionContainer>
    </Div>
  )
}

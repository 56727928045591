import { Div } from "modules/shared"
import React from "react"
import { HintTitle } from "../ManualStyles"
import {
  FootprintScrollArrow,
  SectionContainer,
  Heading1,
  Paragraph,
  FootprintAccordion,
} from "modules/shared"
import { Stack } from "@mui/material"
import { ASSET_IMAGES } from "utils/constants/paths"
import { StepStyle } from "./Step"
export const Section4: React.FC<{
  locales?: any
  large?: boolean
  medium?: boolean
  small?: boolean
}> = ({ locales = {}, large, medium, small }) => {
  return (
    <Div>
      {/* //Section four */}
      <SectionContainer
        id="page_manual_section4"
        sx={{
          padding: "100px 7.29vw 0px",
          alignItems: "start",
          gap: "0px",
          p:
            (large && "100px 7.29vw 0px") ||
            (medium && "81px 2.86vw 0px") ||
            (small && "81px 2.604vw 0px ") ||
            "100px 7.29vw 0px",
        }}
      >
        <Stack sx={{ p: (small && "0px 128px") || "0px 224px" }}>
          <HintTitle
            sx={{
              textAlign: "left",
              p: 0,
              m: 0,
              width: "100%",
              fontSize: (small && "15px") || "17px",
            }}
          >
            {locales["page_manual_section4_hint"] ?? "HVOR FÅR JEG VEILEDNING?"}
          </HintTitle>
          <Heading1
            sx={{
              textAlign: "left",
              p: 0,
              m: 0,
              fontSize: (small && "26px") || "45px",
            }}
          >
            {locales["page_manual_section4_title"] ??
              "Veilederen vår hjelper deg med hvert rapporteringskrav."}
          </Heading1>
          <Paragraph>
            {locales["page_manual_section4_paragraph"] ??
              `I veilederen vår får du både tips, eksempler på hva du kan svare oghvordan du kan gå frem for å finne svar på de ulike rapporteringskravene.\r\nHvert rapporteringskrav har en egen nnedtrekksboks.\r\nDe mer krevende rapporteringskravene har også eget nmalverk i nedtrekksboksen som du kan laste ned for å få ekstra veiledning til hvordan du kan løse det enkelte rapporteringskravet.\r\nKlikk på boksen i eksempelet under for å se hvordan veilederen ser ut:\r\n`}
          </Paragraph>
        </Stack>
        <Div
          sx={{
            "&.MuiAccordionSummary-root": {
              width: "100% !important",
            },
          }}
        >
          <FootprintAccordion
            AccordionDetailsStyle={{}}
            iconStyle={{}}
            withNumbering
            numberSectionStyle={{ background: "#DFE3E6" }}
            number="2.1.c"
            accordionStyle={{
              background: "var(--F1F3F3, #F1F3F3)",
              fontWeight: 500,
              fontSize: "17px",
              marginTop: "14px",
              width: (small && "700px") || "100% !important",
              height: (small && "60px") || "none",
              textAlign: "center",
            }}
            title={
              locales["page_manual_section4_step1"] ??
              "Fordel ansvar for utførelsen av aktsomhetsvurderingen og utformingen av retningslinjer og handlingsplaner."
            }
          >
            <StepStyle locales={locales} />
          </FootprintAccordion>
        </Div>
        <FootprintScrollArrow
          nextSelector="#page_manual_section5"
          style={{
            margin:
              (small && "60px auto") || (medium && "60px auto") || "80px auto",
          }}
        />
      </SectionContainer>
    </Div>
  )
}

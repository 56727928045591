import React from "react"
import { useField, FieldAttributes } from "formik"
import FormHelperText from "@mui/material/FormHelperText"
import Select, { SelectProps } from "@mui/material/Select"
import { SxProps, Theme } from "@mui/system"

interface Props {
  labelTopText: string
  selectStyle?: SxProps<Theme>
}
//TODO: rename to FootprintDropDownFieldFormik
const FootprintDropDownFiledFormik: React.FC<
  FieldAttributes<SelectProps> & Props
> = (props) => {
  const [field, meta] = useField(props.name as string)
  const errorText = meta.error && meta.touched ? meta.error : ""
  return (
    <div
      style={{
        ...props.style,
        marginBottom: errorText ? "20px" : props.style?.marginBottom,
      }}
    >
      <div style={{ fontWeight: 300 }}>{props.labelTopText}</div>
      <Select
        {...props}
        {...field}
        error={!!errorText}
        sx={{
          ...props.selectStyle,
          "&.MuiInputBase-root": {
            height: "48px",
            width: "100%",
            fontWeight: 300,
          },
          "& fieldset": {
            borderRadius: "0px",
            boxShadow: "none",
            borderColor: "#DFE3E6",
            fontWeight: 300,
          },
        }}
      />
      {!props.disabled && (
        <FormHelperText sx={{ color: "#E73145" }}>{errorText}</FormHelperText>
      )}
    </div>
  )
}

export default FootprintDropDownFiledFormik

import {
  generateReport,
  downloadSustainabilityReportFileApi,
} from "modules/shared"
import { LoadingFileSweetAlert, closeSweetAlert } from "utils/sweetAlert"
import { onReportDone, updateGeneratedReportId } from "redux/actions/dashboard"
import {
  downloadFileBlobHelper,
  downloadFileHelper,
  onDownloadFileFromUrl,
} from "utils/DownloadFilesHelper"
export const generateReportFromExcelToWord =
  (acceptedFiles: any[]) =>
  (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let formData = new FormData()
    acceptedFiles.forEach((file: string | Blob) =>
      formData.append("file", file),
    )
    formData.append("locale", "no")
    formData.append("fileType", "REPORTS")
    LoadingFileSweetAlert()
    generateReport(formData)
      .then((response) => {
        // let fileName = response?.data?.fileName;
        // let fileExtension = response?.data?.fileExtension;
        if (response?.data?.id) {
          dispatch(updateGeneratedReportId(response?.data?.id))
          dispatch(onReportDone())
          window.scrollTo(0, 0)
          downloadSustainabilityReportFileApi(response.data.id)
            .then((response) => {
              downloadFileHelper(
                response?.data.blobDownloadUrl,
                response?.data.fileName,
                response?.data.fileExtension,
              )
                .then(() => {
                  closeSweetAlert()
                })
                .catch(() => {
                  closeSweetAlert()
                })
            })
            .catch(() => {
              closeSweetAlert()
            })
        } else {
          closeSweetAlert()
        }
      })
      .catch(() => {
        closeSweetAlert()
      })
  }

export const generateReportWithoutDownload = async (acceptedFiles: any[]) => {
  let formData = new FormData()
  acceptedFiles.forEach((file: string | Blob) => formData.append("file", file))
  formData.append("locale", "no")
  formData.append("fileType", "REPORTS")
  LoadingFileSweetAlert()
  return generateReport(formData)
    .then((response) => {
      closeSweetAlert()
      return response.data
    })
    .catch((error) => {
      closeSweetAlert()
      return error
    })
}

export const downloadReportById = (companyId: string) => {
  LoadingFileSweetAlert()
  downloadSustainabilityReportFileApi(companyId)
    .then((response) => {
      downloadFileBlobHelper(response?.data, "final_report.docx")
        .then(() => {
          closeSweetAlert()
        })
        .catch(() => {
          closeSweetAlert()
        })
    })
    .catch(() => {
      closeSweetAlert()
    })
}

export const downloadReportFromDonReport = (
  fileBlob: RequestInfo | URL,
  name: string,
) => {
  LoadingFileSweetAlert()
  onDownloadFileFromUrl(fileBlob, name)
    .then(() => {
      closeSweetAlert()
    })
    .catch(() => {
      closeSweetAlert()
    })
}

import Div from "../Div/Div"
import { FunctionComponent } from "react"
import { Typography } from "@mui/material"
import useLocalesState from "utils/Localization/useLocalesState"
import { useNavigate } from "react-router-dom"
import useStepsTitles from "modules/steps/services/useStepsTitles"
import styles from "./TransparencyActSidebar.style.module.scss"
import { ASSET_IMAGES } from "utils/constants/paths"
import { statusColor } from "./constants/constants"
import { useSelector } from "react-redux"
import { FootprintModule } from "modules/shared/types/types"
import sidebarStyles from "./Sidebar.style.module.scss"

interface ClimateSidebarProps {}
const ClimateSidebar: FunctionComponent<ClimateSidebarProps> = () => {
  const [locales] = useLocalesState()
  const navigate = useNavigate()

  const { id: companyId } = useSelector((state: any) => state.company)

  const {
    headers: staticPages,
    isLoading: isHeadersLoading,
    error: isHeadersError,
  } = useStepsTitles(FootprintModule.Climate_accounting)

  const links = [
    {
      label: locales["header_label_dashboard"],
      link: `/${companyId}/climate/dashboard`,
      icon: (
        <img
          src={`${ASSET_IMAGES}/widgets/dashboard.svg`}
          className={styles.navigationIcon}
        />
      ),
    },
    {
      label: locales["sidebar_transparencyact_manual"],
      link: `/${companyId}/climate/introduction`,
      icon: (
        <img
          src={`${ASSET_IMAGES}/widgets/earbuds.svg`}
          className={styles.navigationIcon}
        />
      ),
    },
    {
      label: locales["sidebar_transparencyact_myfiles"],
      link: `/${companyId}/climate/files`,
      icon: (
        <img
          src={`${ASSET_IMAGES}/widgets/folder_shared.svg`}
          className={styles.navigationIcon}
        />
      ),
    },
    {
      label: locales["sidebar_transparencyact_flagged"],
      link: `/${companyId}/${FootprintModule.Climate_accounting}/flaggedSteps`,
      color: "#fb6d75",
      icon: (
        <img
          width={28}
          height={28}
          src={`${ASSET_IMAGES}/widgets/flagged.svg`}
          className={styles.navigationIcon}
        />
      ),
    },
  ]

  return (
    <Div className={styles.transparencyActSidebar}>
      <Div className={styles.stepsLinks}>
        <Typography className={styles.title}>
          {locales["sidebar_climateModule_title"] ?? "Klimaregnskap"}
        </Typography>
        {staticPages?.map((item, index) => (
          <Div className={styles.step} key={item.title}>
            <Typography
              variant="h4"
              color={statusColor.default}
              className={styles.stepNumber}
              sx={{
                color: (statusColor as any)[item.flag],
              }}
              onClick={() => navigate(item.url)}
            >
              {item?.labelNumber || ""}
            </Typography>
            <Typography
              className={styles.stepLabel}
              variant="h4"
              color={statusColor.default}
              sx={{
                color: (statusColor as any)[item.flag],
              }}
              onClick={() => navigate(item.url)}
            >
              {item?.title || ""}
            </Typography>
          </Div>
        ))}
      </Div>

      <Div className={styles.navigationLinks}>
        <div className={sidebarStyles.separator}></div>
        {links.map((link) => (
          <Div
            className={styles.step}
            onClick={() => navigate(link.link)}
            key={link.label}
          >
            {link.icon}
            <Typography
              className={`${styles.stepLabel} ${styles.navigationLabels}`}
              variant="h4"
              color={link.color ?? "white"}
            >
              {link.label}
            </Typography>
          </Div>
        ))}
      </Div>
    </Div>
  )
}

export default ClimateSidebar

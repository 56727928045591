import React, { HTMLProps } from "react"

interface FootprintTertiaryButtonProps extends React.PropsWithChildren {
  style?: React.CSSProperties
  onClick?: VoidFunction
  title?: string
}
const FootprintTertiaryButton: React.FC<
  FootprintTertiaryButtonProps & HTMLProps<any>
> = (props) => {
  return (
    <span
      className="foot-print-tertiary-button"
      {...props}
      style={{
        fontFamily: "'Rubik'",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "28px",
        textAlign: "center",
        letterSpacing: "0.15em",
        textTransform: "uppercase",
        textDecoration: "underline",
        textUnderlineOffset: "10px",
        cursor: "pointer",
        ...props.style,
      }}
    >
      {props.children}
    </span>
  )
}

export default FootprintTertiaryButton

import React, { FunctionComponent, useState } from "react"
import { Div, useFootprintModule } from "modules/shared"
import "./MyFilesTabs.style.css"
import useLocalesState from "utils/Localization/useLocalesState"

const MyFilesTabs = (props: {
  tabNames: any[]
  onChange: (selectedIdx: number) => void
  initialTab?: number
}) => {
  const [activeTabIdx, setactiveTabIdx] = useState(props.initialTab)
  const { getModuleDataFromId } = useFootprintModule()
  const [locales] = useLocalesState()

  return (
    <div className="MyFilesTabsList">
      <Div className="container">
        {props.tabNames.map((tab, idx) => (
          <div
            className="tabItem"
            key={idx}
            onClick={() => {
              setactiveTabIdx(tab?.id)
              props.onChange(tab?.id)
            }}
            style={{
              background: activeTabIdx === tab?.id ? "#F1F3F3" : "unset",
              fontWeight: activeTabIdx === tab?.id ? 500 : 300,
            }}
          >
            {locales[getModuleDataFromId(tab?.id)?.translationKey!]}
          </div>
        ))}
      </Div>
      {/* <TabMenu>
        Åpenhetsloven 2023
        <ArrowDropDownIcon />
      </TabMenu> */}
    </div>
  )
}

export default MyFilesTabs

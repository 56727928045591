import {
  Column,
  SectionContainer,
  Heading1,
  Spacing,
  Div,
  SimpleLoader,
  FootprintPrimaryButton,
  FootprintModule,
  useFootprintModule,
} from "modules/shared"
import React, { FunctionComponent, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  Stepper,
  SimpleText,
  SimpleBodyText,
  Step,
  StepBall,
} from "./components/StepsStyles"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import { ASSET_IMAGES } from "utils/constants/paths"
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined"
import { FootprintStepStage } from "./types/stepsTypes"
import "./styles/Steps.styles.scss"
import useStepsData from "./services/useStepsData"
import StepsSection from "./components/StepsSection"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import useStepsTitles from "./services/useStepsTitles"
import { If } from "react-if"
import useLocalesState from "utils/Localization/useLocalesState"
import MyBreadcrumbs from "modules/shared/components/Breadcrumbs/Breadcrumbs"

interface TheStepsProps {
  onStepChange?: (newStep: number) => void
}

const Steps: FunctionComponent<TheStepsProps> = (props) => {
  const { activeModule } = useFootprintModule()
  const { error, reFetch: getStepsData, data } = useStepsData(activeModule()!)
  const { stepNumber: stageNumber } = useParams<{ stepNumber: string }>()
  const myRef = React.useRef<any>(null)
  const [locales] = useLocalesState()
  const { companyId } = useParams()

  const breadcrumbs = [
    {
      name:
        activeModule() === FootprintModule.Climate_accounting
          ? locales["breadcrumb_climate"] ?? "Klimaregnskap"
          : activeModule() === FootprintModule.Transparency_act
            ? locales["breadcrumb_transparency"] ?? "Åpenhetsloven"
            : "",
      url:
        activeModule() === FootprintModule.Climate_accounting
          ? `/${companyId}/climate/dashboard`
          : FootprintModule.Transparency_act
            ? `/${companyId}/transparency/dashboard`
            : "",
    },
    {
      name: locales["breadcrumb_climate_page_reporting"] ?? "Rapportering",
      url: "",
    },
  ]
  const {
    headers,
    isLoading: isHeadersLoading,
    error: isHeadersError,
  } = useStepsTitles(activeModule()!)
  const navigate = useNavigate()
  useEffect(() => {
    if (props.onStepChange) props.onStepChange(Number(stageNumber) - 1)
  }, [])

  const NUMBER_OF_STEPS = () => data?.length ?? 0
  const curStage = () =>
    data ? data[Number(stageNumber) - 1] : ({} as FootprintStepStage)

  const isLastStep = () => {
    return Number(stageNumber) == NUMBER_OF_STEPS()
  }
  const isFirstStep = () => {
    return Number(stageNumber) == 1
  }
  const getStepColor = (n: number) => {
    if (Number(stageNumber) == n + 2 && Number(stageNumber) - 1 != 0)
      return "#78B785"
    else if (Number(stageNumber) >= n + 2) return "#1C1C1C"
    else return "#f1f3f3"
  }

  const stepIcon = () => {
    const state = curStage().status
    if (state == "default") {
      return (
        <PendingOutlinedIcon
          sx={{ color: "#233E4C", fontSize: "60px", marginBottom: "40px" }}
        />
      )
    } else if (state == "finished") {
      return (
        <CheckCircleOutlineIcon
          sx={{ color: "#78B785", fontSize: "60px", marginBottom: "40px" }}
        />
      )
    } else if (state == "flagged") {
      return (
        <img
          src={`${ASSET_IMAGES}/pages/steps/flagged.svg`}
          style={{
            color: "#FB6D75",
            width: "60px",
            height: "60px",
            marginBottom: "40px",
          }}
        />
      )
    }
  }

  const onUpdateStep = async () => {
    await getStepsData()
  }

  const onNextStageClicked = () => {
    const curStageIdx = Number(stageNumber) - 1
    const nextStageIdx = curStageIdx + 1
    if (!isLastStep()) {
      navigate(headers![nextStageIdx].url)
      if (props.onStepChange) props.onStepChange(nextStageIdx)
    }
  }

  const onPreviousStageClicked = () => {
    const curStageIdx = Number(stageNumber) - 1
    const nextStageIdx = curStageIdx - 1
    if (!isFirstStep()) {
      navigate(headers![nextStageIdx].url)
      if (props.onStepChange) props.onStepChange(nextStageIdx)
    }
  }

  if (!data) return <SimpleLoader />
  else if (error) return <Heading1>Error</Heading1>

  return (
    <>
      <MyBreadcrumbs breadcrumbs={breadcrumbs} />
      <SectionContainer className="theSteps-header-container">
        <>
          <Column className="step-header-container">
            {stepIcon()}
            <SimpleText>
              {" "}
              {locales[
                `${activeModule()}_pages_dashboard_status_menu_item${Number(
                  stageNumber,
                )}`
              ] ?? curStage().title}
            </SimpleText>
            <Heading1 className="header">
              {locales["stage"]} {stageNumber}.
            </Heading1>
          </Column>

          <Stepper>
            <StepBall
              style={{
                left: 0,
                background:
                  Number(stageNumber) - 1 == 0 ? "#78B785" : "#1C1C1C",
              }}
            ></StepBall>

            {Array.from(Array(NUMBER_OF_STEPS() - 1).keys()).map((n) => {
              return (
                <Step
                  key={n}
                  style={{
                    background:
                      Number(stageNumber) - 1 > n &&
                      Number(stageNumber) - 1 != 0
                        ? "#1C1C1C"
                        : "#F1F3F3",
                  }}
                >
                  <StepBall
                    style={{
                      right: 0,
                      background: getStepColor(n),
                    }}
                  ></StepBall>
                </Step>
              )
            })}
          </Stepper>

          <SimpleBodyText className="step-desc">
            {locales[
              `${activeModule()}_stage_${Number(stageNumber)}_description`
            ] ?? curStage().description}
          </SimpleBodyText>

          <FootprintPrimaryButton
            onClick={() => {
              myRef.current?.scrollIntoView()
            }}
          >
            {locales["pages_steps_startBtn"]}
          </FootprintPrimaryButton>

          <Div className="footnote" ref={myRef}>
            {locales["pages_steps_footnote1"]}
            <br />
            {locales["pages_steps_footnote2"]}
          </Div>
        </>
      </SectionContainer>

      <Spacing />

      {curStage().sections.map((section) => (
        <StepsSection
          key={section?.number}
          section={section}
          onUpdateStep={(stepNumber, stepData) => onUpdateStep()}
        />
      ))}

      <Div className="navigation-btns">
        <If condition={!isFirstStep()}>
          <Div className="navigation-btn" onClick={onPreviousStageClicked}>
            <ChevronLeftIcon />
            <span>{locales["pages_steps_previousStage"]}</span>
          </Div>
        </If>
        <If condition={!isLastStep()}>
          <Div className="navigation-btn" onClick={onNextStageClicked}>
            <span>{locales["pages_steps_nextStage"]}</span>
            <ChevronRightIcon />
          </Div>
        </If>
      </Div>
    </>
  )
}

export default Steps

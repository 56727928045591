import { FunctionComponent } from "react"
import * as React from "react"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Dialog, { DialogProps } from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"

import {
  FootprintPrimaryButton,
  Div,
  Heading2,
  FootprintTertiaryButton,
  useFootprintModule,
} from "modules/shared"
import { useParams } from "react-router-dom"
import { useStepsAPI } from "modules/steps/services/useStepsAPI"
import useLocalesState from "utils/Localization/useLocalesState"

interface TransparencyResetConfirmationModalProps {
  open: boolean
  onClose?: () => void
}

const TransparencyResetConfirmationModal: FunctionComponent<
  TransparencyResetConfirmationModalProps
> = (props) => {
  const { companyId } = useParams()
  const { resetTransparencySteps } = useStepsAPI()
  const [locales]: any = useLocalesState()
  const { activeModule } = useFootprintModule()

  return (
    <Div>
      <Dialog
        fullWidth={true}
        open={props.open}
        onClose={() => {
          if (props.onClose) props.onClose()
        }}
        aria-labelledby="responsive-dialog-title"
        sx={{
          "& .MuiPaper-root": {
            padding: "0 60px !important",
          },
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ padding: "unset", paddingTop: "80px" }}
        >
          <Heading2 style={{ textAlign: "center" }}>
            {locales[`${activeModule()}_resetDialog_title`] ??
              "Are you sure you want to delete all the data you have filled?"}
          </Heading2>
        </DialogTitle>
        <DialogContent>
          <Box
            component={Div}
            sx={{
              paddingBottom: "60px !important",

              "& .MuiFormControl-root:not(.MuiTextField-root)": {
                mb: "12px",
                width: { xs: "100%", sm: "50%" },
              },

              "& .MuiFormControl-root.MuiFormControl-fluid": {
                width: "100%",
              },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Div
                  style={{
                    textAlign: "center",
                    marginBottom: "60px",
                  }}
                >
                  {locales[`${activeModule()}_resetDialog_desc`] ??
                    `This means that... 
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.  `}
                </Div>
              </Grid>
              <Grid item xs={12}>
                <Div
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <FootprintPrimaryButton
                    onClick={() => {
                      resetTransparencySteps(companyId!)
                      if (props.onClose) props.onClose()
                    }}
                  >
                    {locales["label_user_delete_description_confirm"] ??
                      "Yes, I will"}
                  </FootprintPrimaryButton>

                  <FootprintTertiaryButton
                    onClick={() => {
                      if (props.onClose) props.onClose()
                    }}
                  >
                    {locales["label_user_delete_description_refused"] ?? "No"}
                  </FootprintTertiaryButton>
                </Div>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </Div>
  )
}

export default TransparencyResetConfirmationModal

import { Div, Heading2 } from "modules/shared"
import React from "react"
import { SplitView } from "modules/layout"
import { Intro } from "../IntroductionStyles"
import {
  FootprintScrollArrow,
  SectionContainer,
  Paragraph,
} from "modules/shared"
import { ASSET_IMAGES } from "utils/constants/paths"
import { Stack } from "@mui/material"
export const Section2: React.FC<{
  locales?: any
  large?: boolean
  medium?: boolean
  small?: boolean
}> = ({ locales = {}, large, medium, small }) => {
  return (
    <Div>
      {/* //Section one */}
      <SectionContainer
        id="page_manual_section2"
        sx={{
          p:
            (large && "100px 112px 0px") ||
            (medium && "81px 2.86vw 0px") ||
            (small && "81px 2.604vw 0px ") ||
            "100px 112px 0px",
          justifyContent: "center",
        }}
      >
        <SplitView
          right={(medium && "648px") || (small && "432px") || "648px"}
          left={(medium && "424px") || (small && "304px") || "424px"}
          gap={(small && "16px") || "32px"}
          sx={{ justifyContent: "center" }}
        >
          <Div sx={{ margin: "auto" }}>
            <Heading2 sx={{ m: 0, fontSize: (small && "26px") || "32px" }}>
              {locales["climate_page_intro_section2_title"] ??
                "Ditt klimaregnskap er kun to skritt unna."}
            </Heading2>
            <Paragraph
              sx={{ m: "0px", fontSize: (small && "15px") || "17px" }}
              dangerouslySetInnerHTML={{
                __html:
                  locales["climate_page_intro_section2_paragraph"] ??
                  `Alt vi trenger er at du oppgir grunnleggende informasjon om virksomheten og at vi får tilgang til regnskapsdata for det aktuelle rapporteringsåret. Deretter kalkulerer vi virksomhetens CO<sub>2</sub>-utslipp.
                  \nI klimaregnskapet får du en helhetlig oversikt over hvilke aktiviteter i din virksomhet som skaper mest utslipp, utslippstrend, i tillegg til en oversikt over hvordan du ligger an i forhold til andre virksomheter.
                  \nDermed får man knyttet klimagassutslippene opp mot organisasjonens økonomiske aktiviteter og man får verktøyet man trenger for å måle, forstå og redusere egen klimapåvirkning.`,
              }}
            ></Paragraph>
          </Div>
          <Div>
            <img
              width={
                (large && "648px") ||
                (medium && "416px") ||
                (small && "304px") ||
                "648px"
              }
              height={
                (large && "581px") ||
                (medium && "429px") ||
                (small && "313.5px") ||
                "581px"
              }
              src={`${ASSET_IMAGES}/pages/climate/introduction/section2.png`}
              alt="climate_report_intro_section2"
            />
          </Div>
        </SplitView>
        <Stack alignItems="center" sx={{ m: 0, p: 0 }}>
          <Intro>
            {locales["climate_page_manual_section2_next_section"] ?? "Fortsett"}
          </Intro>
          <FootprintScrollArrow nextSelector="#page_manual_section3" />
          <span style={{ marginTop: (large && "80px") || "60px" }}></span>
        </Stack>
      </SectionContainer>
    </Div>
  )
}

import React, { FunctionComponent, useRef, useState } from "react"
import MenuItem from "@mui/material/MenuItem"
import ActiveModulesDropdown from "./ActiveModulesDropdown"
import {
  Form,
  Formik,
  FormikProps,
  FormikValues,
  useFormikContext,
} from "formik"
import { FootprintTextFieldFormik } from "modules/shared"

import {
  FootprintPrimaryButton,
  FootprintDropDownFiledFormik,
} from "modules/shared"
import { FootprintTertiaryButton } from "modules/shared"
import { useCompaniesApi } from "modules/admin/services/useCompaniesApi"
import {
  AdminPage,
  CompanyCreationDto,
  CompanyDto,
  CompanyEntity,
} from "modules/admin/types/adminApiTypes"
import { SimpleLoader } from "modules/shared"
import * as yup from "yup"
import Admin, { AdminContext } from "modules/admin/Admin"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import { Users } from "../Users/Users"
import useLocalesState from "utils/Localization/useLocalesState"
import { Permissions } from "modules/authentication/types/permissionType"
import { useSelector } from "react-redux"
import { If, Then, Switch, Case } from "react-if"
interface ViewCompanyProps {}

const ViewCompany: FunctionComponent<ViewCompanyProps> = () => {
  const [locales]: any = useLocalesState()
  const adminContext = React.useContext(AdminContext)
  const formRef = useRef<FormikProps<FormikValues>>()
  const { createCompany, updateCompany, getCompany, getCompaniesStatusList } =
    useCompaniesApi()
  const [isSaving, setisSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [company, setcompany] = useState<CompanyEntity>()
  const [companiesStatusList, setCompaniesStatusList] = useState<any[]>([])
  const { permissions } = useSelector((state: any) => state.roles) as {
    permissions: Permissions
  }

  const extractModules = () => {
    if (!company) return []
    const modules = company?.activeModules
    return modules?.map((module) => {
      return {
        moduleId: module?.module.id,
        name: module?.module.name,
        startDate: module?.startDate,
        endDate: module?.endDate,
        isVisible: module?.isVisible,
      }
    })
  }

  const fetchData = async () => {
    setIsLoading(true)
    if (adminContext.activePage !== AdminPage.createBusiness) {
      const theCompany = await getCompany(adminContext.params as string)
      setcompany(theCompany)
    }
    const status = await getCompaniesStatusList()
    setCompaniesStatusList(status)
    setIsLoading(false)
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  const handleSubmit = async () => {
    if (!formRef.current) return
    setisSaving(true)
    formRef.current.handleSubmit()
    try {
      if (adminContext.activePage === AdminPage.createBusiness)
        await createCompany(formRef.current.values as CompanyCreationDto)
      else {
        await updateCompany(
          (company as CompanyEntity).id,
          formRef.current.values as CompanyCreationDto,
        )
      }
      adminContext.changeActivePage(AdminPage.listBusinesses)
    } catch (error) {}
    setisSaving(false)
  }

  const getStatusColor = (status: string) => {
    switch (status) {
      case "ACTIVE":
        return "#78B785"
      case "DISABLED":
        return "#FB6D75"
      case "BREAK":
        return "#BABABA"
      case "UNPAID":
        return "#BABABA"
      default:
        return "#BABABA"
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      <h1>
        {adminContext.activePage === AdminPage.createBusiness
          ? locales["pages_admin_home_business_createTitle"]
          : locales["pages_admin_home_business_updateTitle"]}
      </h1>
      {isLoading ? (
        <SimpleLoader />
      ) : (
        <Formik
          innerRef={formRef as any}
          initialValues={{
            companyName: company?.companyName ?? "",
            organizationNumber: company?.organizationNumber ?? "",
            subscription: company?.subscription ?? "",
            status: company?.status.toUpperCase() ?? "",
            activeModule: extractModules(),
            invoiceInfo: company?.invoiceInfo ?? "",
          }}
          validationSchema={yup.object({
            companyName: yup.string().required("business name is required"),
            organizationNumber: yup
              .string()
              .required("organization number is required"),
            invoiceInfo: yup
              .string()
              .required("invoice information is required"),
            subscription: yup.string().required("subscription is required"),
            status: yup.string().required("status is required"),
            activeModule: yup
              .array()
              .required("At least select 1 active module"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              console.log(JSON.stringify(values, null, 2))
              setSubmitting(false)
            }, 400)
          }}
        >
          <Form>
            <div style={{ display: "flex", gap: "10px" }}>
              <FootprintTextFieldFormik
                disabled={adminContext.activePage === AdminPage.readBusiness}
                placeholder={
                  locales["pages_admin_home_business_namePlaceholder"]
                }
                labelTopText={locales["pages_admin_home_business_name"]}
                name="companyName"
                style={{ width: "37%" }}
              />
              <FootprintTextFieldFormik
                disabled={adminContext.activePage === AdminPage.readBusiness}
                placeholder={locales[
                  "pages_admin_home_business_organizationNumber"
                ]?.toUpperCase()}
                labelTopText={
                  locales["pages_admin_home_business_organizationNumber"]
                }
                name="organizationNumber"
                style={{ flexGrow: "1" }}
              />
              <FootprintDropDownFiledFormik
                disabled={adminContext.activePage === AdminPage.readBusiness}
                name="subscription"
                labelTopText={locales["pages_admin_home_business_subscription"]}
                style={{ flexBasis: "136px" }}
              >
                <MenuItem value={10}>
                  10 {locales["pages_admin_home_business_user"]?.toUpperCase()}
                </MenuItem>
                <MenuItem value={20}>
                  20 {locales["pages_admin_home_business_user"]?.toUpperCase()}
                </MenuItem>
                <MenuItem value={30}>
                  30 {locales["pages_admin_home_business_user"]?.toUpperCase()}
                </MenuItem>
              </FootprintDropDownFiledFormik>

              <FootprintDropDownFiledFormik
                disabled={adminContext.activePage === AdminPage.readBusiness}
                labelTopText="Status"
                name="status"
                style={{ flexBasis: "136px" }}
                selectStyle={{
                  color: () => getStatusColor(formRef.current?.values.status),
                }}
              >
                {companiesStatusList.map((status) => (
                  <MenuItem
                    value={status.name}
                    style={{ color: getStatusColor(status.name) }}
                    key={status.name}
                  >
                    {status.name}
                  </MenuItem>
                ))}
              </FootprintDropDownFiledFormik>
            </div>

            <div style={{ display: "flex", gap: "10px" }}>
              <ActiveModulesDropdown
                disabled={adminContext.activePage === AdminPage.readBusiness}
                style={{ width: "37%" }}
                name="activeModule"
                disableBeforeToday={
                  adminContext.activePage === AdminPage.createBusiness
                }
                companyId={adminContext.params as string}
              />
              <FootprintTextFieldFormik
                disabled={adminContext.activePage === AdminPage.readBusiness}
                placeholder={locales[
                  "pages_admin_home_business_invoiceInfo"
                ]?.toUpperCase()}
                labelTopText={locales["pages_admin_home_business_invoiceInfo"]}
                name="invoiceInfo"
                style={{ flexGrow: "1" }}
              />
            </div>
          </Form>
        </Formik>
      )}
      {adminContext.activePage === AdminPage.createBusiness ? (
        <div></div>
      ) : (
        <Users
          company={{
            id: adminContext.params as string,
            companyName: company?.companyName as string,
          }}
        />
      )}

      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            display: "flex",
            gap: "10px",
            flexGrow: "1",
            cursor: "pointer",
          }}
          onClick={() =>
            adminContext.changeActivePage(AdminPage.listBusinesses)
          }
        >
          <ArrowBackIosIcon />
          <span
            style={{
              fontFamily: "Rubik",
              fontSize: "17px",
              fontStyle: "normal",
              fontWeight: "300",
              lineHeight: "23px",
            }}
          >
            Back to businesses
          </span>
        </div>

        <If condition={adminContext.activePage != AdminPage.readBusiness}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flexGrow: "1",
            }}
          >
            {isSaving ? (
              <SimpleLoader />
            ) : (
              <FootprintPrimaryButton onClick={handleSubmit}>
                Save
              </FootprintPrimaryButton>
            )}
            <FootprintTertiaryButton
              style={{ color: "green" }}
              onClick={() =>
                adminContext.changeActivePage(AdminPage.listBusinesses)
              }
            >
              Cancel
            </FootprintTertiaryButton>
          </div>
        </If>

        <div style={{ flexGrow: "2.1" }}></div>
      </div>
    </div>
  )
}

export default ViewCompany

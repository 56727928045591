import {
  FIRSTTIMELOGIN,
  REPORTCREATION,
  REPORTDONE,
  SETREPORTID,
  LOGOCLICK,
} from "redux/constants/dashboard"

const INIT_STATE = {
  firstTimeLogin: false,
  reportCreation: true,
  reportDone: false,
  generatedReportFileId: null,
  logoClick: false,
}

const reducerFunc = (
  state = INIT_STATE,
  action: { type: any; payload: any },
) => {
  switch (action.type) {
    case FIRSTTIMELOGIN: {
      return {
        ...state,
        firstTimeLogin: true,
        reportCreation: false,
        reportDone: false,
        logoClick: false,
      }
    }
    case REPORTCREATION: {
      return {
        ...state,
        firstTimeLogin: false,
        reportCreation: true,
        logoClick: false,
        reportDone: false,
      }
    }
    case REPORTDONE: {
      return {
        ...state,
        firstTimeLogin: false,
        reportCreation: false,
        logoClick: false,
        reportDone: true,
      }
    }
    case LOGOCLICK: {
      return {
        ...state,
        logoClick: action.payload,
      }
    }
    case SETREPORTID: {
      return {
        ...state,
        generatedReportFileId: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default reducerFunc

/* eslint-disable react-hooks/rules-of-hooks */
import baseAxios from "axios"
import configureStore from "../redux/store/store"
import {
  clearError,
  onFailure,
  onSuccess,
  startLoading,
} from "../redux/actions/status"
import SnackbarUtils from "utils/SnackbarUtils"
import { HandleLogout } from "modules/authentication"
const axiosHelper = baseAxios.create({
  headers: { "content-type": "application/json" },
})
const { store } = configureStore()
//Axios Interceptors
//Request
axiosHelper.interceptors.request.use(
  (config: any) => {
    if (!config?.disableLoading) {
      store.dispatch(startLoading())
    }
    const token = localStorage.getItem("token")
    if (token) config.headers.Authorization = `Bearer ${token}`
    return config
  },
  (error) => {
    store.dispatch(onFailure(error, error.message))
    return Promise.reject(error)
  },
)

//Response
axiosHelper.interceptors.response.use(
  (response: any) => {
    if (response?.config?.showNotificationOnSuccess) {
    } else store.dispatch(clearError())
    return response
  },
  (error) => {
    // const { messages } = store.getState().locales;
    const status = error.response && error.response.status
    let message = error?.response?.data?.message
    if (status === 400 && message) {
      SnackbarUtils.error(message)
    } else if (status === 401) {
      HandleLogout.logout()
    } else if (status === 500) {
      SnackbarUtils.error("something went wrong")
    }
    return Promise.reject(error)
  },
)

export default axiosHelper

import React from "react"
import { Div, ExampleBox, Paragraph, Subtitle } from "modules/shared"
import FlagButtons from "modules/steps/components/FlagButtons"
import TextField from "@mui/material/TextField"

export const StepStyle = (props: any) => {
  return (
    <Div sx={{ p: "60px 35px" }}>
      <div>
        <Subtitle>
          {props.locales["climate_page_intro_section3_step1_title"] ??
            "Forklaring:"}
        </Subtitle>
        <Paragraph>
          {props.locales["transparency_page_intro_section4_step1_subTitle"] ??
            "Skriv navnet på avdelingen eller rollen som er ansvarlig for implementering og oppfølging av Åpenhetsloven. Skriv i tillegg inn e-postadressen dere skal bruke for henvendelser knyttet til Åpenhetsloven."}
        </Paragraph>
      </div>

      <ExampleBox className="exampleBox">
        <Subtitle className="subtitle">
          {props.locales["climate_page_intro_section3_step1_exm_title"] ??
            "Eksempel:"}
        </Subtitle>
        <Paragraph className="exampleBox-text">
          {props.locales[
            "transparency_page_intro_section4_step1_exm_subTitle"
          ] ??
            `Delt ansvar mellom\nHR-sjef og kvalitetssjef,\napenhetsloven@virksomhetsensmail.no`}
        </Paragraph>
      </ExampleBox>
      <br />
      <Div sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <TextField
          placeholder="Avdelingsnavn/stillingstittel"
          helperText={
            props.locales[
              "transparency_page_intro_section4_step1_exm_helper_text"
            ] ?? "Skriv inn maks 150 tegn"
          }
          sx={{
            "& .MuiInputBase-root": {
              height: "48px",
              fontWeight: 300,
            },
            "& fieldset": {
              borderRadius: "0px",
              boxShadow: "none",
              borderColor: "#DFE3E6",
              fontWeight: 300,
            },
          }}
        />
        <TextField
          placeholder="E-post for henvendelser knyttet til Åpenhetsloven"
          helperText={
            props.locales[
              "transparency_page_intro_section4_step2_exm_helper_text"
            ] ?? "Skriv inn maks 150 tegn"
          }
          sx={{
            "& .MuiInputBase-root": {
              height: "48px",
              fontWeight: 300,
            },
            "& fieldset": {
              borderRadius: "0px",
              boxShadow: "none",
              borderColor: "#DFE3E6",
              fontWeight: 300,
            },
          }}
        />
      </Div>
      <FlagButtons
        sx={{ marginTop: "60px" }}
        state={"default"}
        onSubmit={() => {}}
        onFlag={() => {}}
      />
    </Div>
  )
}

import React, { FunctionComponent, useState, useEffect } from "react"
import Div from "../Div/Div"
import styles from "./Sidebar.style.module.scss"
import { useLocation, useNavigate } from "react-router-dom"
import LogoutIcon from "@mui/icons-material/Logout"
import { modules } from "./constants/modules"
import { ASSET_IMAGES } from "utils/constants/paths"
import NeutralSidebar from "./NeutralSidebar"
import { useSelector } from "react-redux"
import { ActiveModule } from "modules/admin/types/adminApiTypes"
import { useQuery, useQueryClient } from "react-query"
import { useCompaniesApi } from "modules/admin"
import useFootprintModule from "modules/shared/services/useFootprintModule"
import useLocalesState from "utils/Localization/useLocalesState"
import ReactQueryKeys from "modules/shared/types/reactQueryKeys"

interface SidebarProps {}
const Sidebar: FunctionComponent<SidebarProps> = () => {
  const [locales] = useLocalesState()
  const navigate = useNavigate()
  const { getCompany } = useCompaniesApi()
  //should be moved to redux
  const { activeModule } = useFootprintModule()
  const [selectedModule, setselectedModule] = useState(
    activeModule() ? modules[1].title : modules[0].title,
  )
  const queryClient = useQueryClient()

  const [isOpen, setisOpen] = useState(false)
  const { companyName, id } = useSelector((state: any) => state.company)
  const { isLoading, error, data } = useQuery(["checkModule"], () =>
    getCompany(id ?? ""),
  )
  const hasClimate =
    data &&
    data?.activeModules.length > 0 &&
    data?.activeModules.some(
      (activeModule: ActiveModule) =>
        activeModule.module.name === "Climate Accounting",
    )
  const hasTransparency =
    data &&
    data?.activeModules.length > 0 &&
    data?.activeModules.some(
      (activeModule: ActiveModule) =>
        activeModule.module.name === "The Transparency Act",
    )
  modules[0].active = hasTransparency || false
  modules[1].active = hasClimate || false

  const onSelectCompany = (compName: string) => {
    if (compName === selectedModule && isOpen) {
      setisOpen(false)
      return
    }
    setselectedModule(compName)
    setisOpen(true)
  }
  const onLogoutCompany = () => {
    navigate("/companies")
  }
  const activeSidebar = () => {
    if (selectedModule === "company") {
      return (
        <NeutralSidebar modules={modules} onSelectModule={onSelectCompany} />
      )
    }

    const activeModule = modules.find(
      (button) => button.title == selectedModule,
    )

    return activeModule!.sidebar
  }
  return (
    <Div
      id="sidebar"
      className={styles.sidebar}
      tabIndex={0}
      onBlur={() => {
        setisOpen(false)
      }}
    >
      <Div className={styles.previewBar}>
        <img
          className={styles.companyButton}
          src={`${ASSET_IMAGES}/widgets/company_icon.svg`}
          alt="company_logo"
          onClick={() => {
            onSelectCompany("company")
          }}
        />
        <div style={{ height: "86px" }}></div>
        {modules.map(
          (button) =>
            button.active && (
              <div
                key={button.title}
                className={styles.moduleButton}
                onClick={() => {
                  onSelectCompany(button.title)
                }}
              >
                <img
                  src={
                    selectedModule == button.title
                      ? `${button.icon.slice(0, -4)}-white.svg`
                      : button.icon
                  }
                  alt="company_logo"
                  title={locales[button.title]}
                />
                <div
                  style={{
                    height: "100%",
                    position: "absolute",
                    right: "-16px",
                    width: "2px",
                    backgroundColor: "white",
                    top: "0px",
                    visibility:
                      selectedModule == button.title && isOpen
                        ? "visible"
                        : "hidden",
                  }}
                />
              </div>
            ),
        )}
      </Div>

      <Div
        className={styles.expandedBar}
        style={{ display: isOpen ? "flex" : "none" }}
      >
        <div className={styles.companyName}>
          <span className={styles.title}>{companyName}</span>
          <LogoutIcon
            htmlColor="#B0B9C0"
            style={{ cursor: "pointer" }}
            onClick={onLogoutCompany}
          />
        </div>
        <div className={styles.separator}></div>
        {activeSidebar() as any}
      </Div>
    </Div>
  )
}

export default Sidebar

//TODO:
// add links to nav buttons
// make icon highlight only when selected module is open
// add climate steps
// show modules based on active modules in company
// memoize steps

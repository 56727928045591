import React from "react"
import * as yup from "yup"
import {
  Div,
  FootprintContactTextFieldFormik,
  sendEmailAndNewsletter,
} from "modules/shared"
import { Form, Formik } from "formik"
import LoadingButton from "@mui/lab/LoadingButton"
import useLocalesState from "utils/Localization/useLocalesState"
const validationSchema = yup.object({
  email: yup.string().email().required("Email is required"),
})
const NewsLetterForm = () => {
  const mutation = { isError: false }
  const [locales] = useLocalesState()
  return (
    <Formik
      validateOnChange={true}
      initialValues={{
        email: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(data, { setSubmitting }) => {
        setSubmitting(true)
        sendEmailAndNewsletter("no", {
          email: data.email,
          emailServiceEnum: "NEWS_LETTER",
        })
          .then(() => setSubmitting(false))
          .catch(() => setSubmitting(false))
      }}
    >
      {({ isSubmitting }) => (
        <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
          <Div sx={{ mb: 3, mt: 1 }}>
            <FootprintContactTextFieldFormik
              fullWidth
              name="email"
              label={
                locales["pages_home_newsLetter_section_email"] ??
                "E-post adresse*"
              }
              sx={{
                width: { xs: "100%", md: "70%" },
                ".MuiFormHelperText-root": { marginLeft: "0px" },
              }}
              helperText={
                locales["pages_home_newsLetter_section_email_helper_text"] ??
                "Ved å trykke på knappen nedenfor godtar du at Amesto kan lagre og behandle dine personopplysninger."
              }
            />
          </Div>

          <LoadingButton
            disableElevation
            disableRipple
            disableFocusRipple
            type="submit"
            variant="contained"
            size="large"
            loading={isSubmitting || mutation.isError}
          >
            {locales["pages_home_newsLetter_section_email_action_button"] ??
              "Send"}
          </LoadingButton>
        </Form>
      )}
    </Formik>
  )
}
export default NewsLetterForm

import React, { useRef, useEffect } from "react"
import { ArrowImage } from "./styles"

/**
 * Arrow component to scroll to the specified element
 * based on a provided selector.
 *
 * @param {string} nextSelector - The selector for the next element to scroll to (can be and #id or .class). This
 * selector should match an element in the DOM.
 *
 * @returns {JSX.Element} - Arrow Component.
 */

const FootprintScrollArrow = ({ nextSelector, style }: any): JSX.Element => {
  const nextElementRef = useRef<any>(null)

  useEffect(() => {
    const nextElement = document.querySelector(nextSelector)
    if (nextElement) {
      nextElementRef.current = nextElement
    }
  }, [nextSelector])

  const handleNextClick = () => {
    if (nextElementRef.current) {
      nextElementRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  return <ArrowImage style={style} onClick={handleNextClick} />
}
export default FootprintScrollArrow

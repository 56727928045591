import React from "react"
import { Typography, Button } from "@mui/material"
import { BodyText, Div } from "modules/shared"
import ImageListItem from "@mui/material/ImageListItem"
import useLocalesState from "utils/Localization/useLocalesState"

export const ImageStyleComponent: React.FC<{
  img?: any
  title?: any
  blocked?: boolean
  openTheProject?: any
  buttonTitle?: string
}> = ({ img, title, blocked = false, openTheProject, buttonTitle = "" }) => {
  const [locales] = useLocalesState()
  return (
    <ImageListItem
      key={img}
      // bgcolor="#000000"
      sx={{
        backgroundImage: `url(${img})`,
        width: 312,
        position: "relative",
      }}
      data-testid="dashboard-first-time-product-image"
    >
      <Div
        sx={{
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, .36)",
          "&:hover": {
            background: "rgba(0, 0, 0, .36)",
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h2"
          fontWeight={800}
          fontSize={{ xs: "18px", md: "32px" }}
          color="white"
        >
          {title}
        </Typography>
        <Button
          disableElevation
          disableRipple
          disableFocusRipple
          variant={"contained"}
          size="large"
          data-testid="dashboard-first-time-product-button"
          onClick={() => {
            window.scrollTo(0, 0)
            openTheProject()
          }}
        >
          {buttonTitle}
        </Button>
        {blocked && (
          <BodyText
            style={{
              color: "white",
              position: "absolute",
              bottom: "16px",
            }}
          >
            {locales["pages_productsSection_blockedText"]}
          </BodyText>
        )}
      </Div>
    </ImageListItem>
  )
}

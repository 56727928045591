import styled from "@emotion/styled"
import { Column } from "modules/shared"
import { ASSET_IMAGES } from "utils/constants/paths"

const mediaQueries = {
  smallestRange: `screen and (min-width: 904px) and (max-width: 1240px)`,
  mediumRange: `screen and (min-width: 1240px) and (max-width: 1440px)`,
  largestRange: `(min-width: 1441px)`,
}

export const AccordionDetailsContainer = styled.div`
  padding: 5.35vw 7.77vw;
  gap: 24px;
  display: flex;
  flex-direction: column;
  @media ${mediaQueries.smallestRange} {
  }
  @media ${mediaQueries.mediumRange} {
  }
  @media ${mediaQueries.largestRange} {
    padding: 60px 135px;
  }
`

export const SimpleText = styled.div`
  font-family: "Rubik";
  font-style: light;
  font-weight: 300;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  @media ${mediaQueries.smallestRange} {
  }
  @media ${mediaQueries.mediumRange} {
  }
  @media ${mediaQueries.largestRange} {
  }
`

export const SimpleBodyText = styled.div`
  font-family: "Rubik";
  font-style: light;
  font-weight: 300;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0px;
  @media ${mediaQueries.smallestRange} {
  }
  @media ${mediaQueries.mediumRange} {
  }
  @media ${mediaQueries.largestRange} {
  }
`
export const Stepper = styled.div`
  display: flex;
  position: relative;
  alignitems: center;
  aligncontent: center;
  @media ${mediaQueries.smallestRange} {
  }
  @media ${mediaQueries.mediumRange} {
  }
  @media ${mediaQueries.largestRange} {
  }
`

export const Step = styled.div`
  height: 2px;
  width: 100px;
  background: #233e4c;
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  @media ${mediaQueries.smallestRange} {
  }
  @media ${mediaQueries.mediumRange} {
  }
  @media ${mediaQueries.largestRange} {
  }
`

export const StepBall = styled.div`
  background: #233e4c;
  border-radius: 30px;
  position: absolute;
  height: 8px;
  width: 8px;
  z-index: 3;
  top: -3px !important;
  @media ${mediaQueries.smallestRange} {
  }
  @media ${mediaQueries.mediumRange} {
  }
  @media ${mediaQueries.largestRange} {
  }
`

export const DownloadButton = styled(Column)`
  background-color: #f1f3f3;
  width: 14.28vw;
  height: 14.28vw;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  @media ${mediaQueries.smallestRange} {
  }
  @media ${mediaQueries.mediumRange} {
  }
  @media ${mediaQueries.largestRange} {
  }
`
export const DownloadImage = styled.div`
  background-image: url(${ASSET_IMAGES}/pages/steps/download.svg);
  background-repeat: no-repeat;
  background-position: center;
  height: 12.5%;
  width: 12.5%;
  @media ${mediaQueries.smallestRange} {
  }
  @media ${mediaQueries.mediumRange} {
  }
  @media ${mediaQueries.largestRange} {
  }
`
export const DownloadText = styled.p`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #78b785;
  @media ${mediaQueries.smallestRange} {
  }
  @media ${mediaQueries.mediumRange} {
  }
  @media ${mediaQueries.largestRange} {
  }
`

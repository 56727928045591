import useStepsData from "./useStepsData"
import { FootprintStepHeaderOld, FootprintStepStage } from "../types/stepsTypes"
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import useLocalesState from "utils/Localization/useLocalesState"
import { FootprintModule, FootprintModuleData } from "modules/shared"

const useStepsTitles = (module: FootprintModule) => {
  const { isLoading: isLoadingData, error, data } = useStepsData(module)
  const [headers, setHeaders] = useState<FootprintStepHeaderOld[] | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [locales]: any = useLocalesState()
  const { id: companyId } = useSelector((state: any) => state.company)
  useEffect(() => {
    setIsLoading(isLoadingData || !data)
    if (data && locales && companyId) {
      setHeaders(generateHeaders())
    }
  }, [data, isLoadingData, locales, companyId])

  const generateHeaders = () => {
    const headers: FootprintStepHeaderOld[] = []
    data!.forEach((stage: FootprintStepStage) => {
      const header = {
        title:
          locales[`${module}_pages_dashboard_status_menu_item${stage.stage}`],
        url: `/${companyId}/${module}/steps/${stage.stage}`,
        labelNumber: stage.stage,
        flag: stage.status as string,
      }
      headers.push(header)
    })
    setIsLoading(false && !isLoadingData)
    return headers
  }

  return { headers, isLoading, error }
}
export default useStepsTitles

import React from "react"
import { Div } from "modules/shared"
import { Typography } from "@mui/material"
import "./CardOptionAction.style.css"
const CardOption: React.FC<{
  title?: any
  icon?: any
  bg?: any
  color?: any
  url?: any
  onClick?: any
  hoverSecondary?: boolean
}> = ({ title, icon, bg, color, url, onClick, hoverSecondary = false }) => {
  return (
    <Div
      className="cardOptionAction"
      sx={{
        backgroundColor: bg,
        "&:hover": {
          background: !hoverSecondary ? "#e1e5e6" : "#e1f1e4",
        },
      }}
      onClick={onClick}
    >
      {icon}
      <Typography
        letterSpacing={"2px"}
        color={color}
        variant="h4"
        fontWeight={500}
        fontSize={"17px"}
        mt={"19.5px"}
      >
        {title}
      </Typography>
    </Div>
  )
}
export default CardOption

import { Div, Heading2 } from "modules/shared"
import React from "react"
import { SplitView } from "modules/layout"
import { FootprintScrollArrow, Paragraph } from "modules/shared"
import { ASSET_IMAGES } from "utils/constants/paths"
import { Stack } from "@mui/material"
import { HintTitle } from "../RoadHeadStyles"
export const Section4: React.FC<{
  locales?: any
  large?: boolean
  medium?: boolean
  small?: boolean
}> = ({ locales = {}, large, medium, small }) => {
  return (
    <Div id="page_road_head_section4">
      {/* //Section one */}
      <Div
        sx={{
          p:
            (large && "100px 112px 0px") ||
            (medium && "80px 21px 0px") ||
            (small && "80px 40px 0px ") ||
            "80px 40px 0px",
        }}
      >
        <SplitView
          left={(medium && "416px") || (small && "368px") || "535px"}
          right={(medium && "504px") || (small && "368px") || "536px"}
          gap={(small && "16px") || (medium && "103px") || "24px"}
        >
          <Div sx={{ margin: "auto" }}>
            <HintTitle>
              {locales["climate_page_roadHead_section4_hint"] ??
                "Praktisk Informasjon"}
            </HintTitle>
            <Heading2 sx={{ m: 0, fontSize: (small && "26px") || "32px" }}>
              {locales["climate_page_roadHead_section4_title"] ??
                "Hva er et klimaregnskap?"}
            </Heading2>
            <Paragraph
              sx={{ m: "0px", fontSize: (small && "15px") || "17px" }}
              dangerouslySetInnerHTML={{
                __html:
                  locales["climate_page_roadHead_section4_paragraph"] ??
                  `Et klimaregnskap gir en helhetlig oversikt av klimagassutslippene knyttet til en organisasjon, produkt eller tjeneste.\n\nVår klimaregnskapsløsning tar utgangspunkt i den finansielle dataen, berikes med manuell input og knyttes opp mot oppdaterte utslippsfaktorer.\n\nKlimaregnskap oppgis ofte i CO<sub>2</sub>-ekvivalenter (CO<sub>2</sub>-e) for å gi en standardisert måleenhet som tar hensyn til effekten av forskjellige klimagasser på global oppvarming. Dette gjør det enklere å sammenligne utslipp fra ulike kilder, da alle utslipp konverteres til en CO2-ekvivalent for å vurdere deres samlede klimapåvirkning.`,
              }}
            ></Paragraph>
          </Div>
          <Div>
            <img
              width={
                (large && "536px") ||
                (medium && "504px") ||
                (small && "368px") ||
                "368px"
              }
              height={
                (large && "500px") ||
                (medium && "440px") ||
                (small && "360px") ||
                "360px"
              }
              src={`${ASSET_IMAGES}/pages/climate/roadHead/roadHeadSection4-img.png`}
              alt="climate_report_roadHead_section4"
            />
          </Div>
        </SplitView>
        <Stack
          alignItems="center"
          sx={{ my: (large && "80px") || "60px", p: 0 }}
        >
          <FootprintScrollArrow nextSelector="#page_road_head_section5" />
        </Stack>
      </Div>
    </Div>
  )
}

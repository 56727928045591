import * as React from "react"
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid"
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined"
import { FootprintPrimaryButton } from "modules/shared"
import { FootprintTextField } from "modules/shared"
import SearchIcon from "@mui/icons-material/Search"
import DataTable from "modules/admin/components/Table/DataTable"
import { Div } from "modules/shared"
import { UserForm } from "modules/admin/components/Users/UserForm"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { useUserApi } from "modules/admin/services/usersApi"
import { useCompaniesApi } from "modules/admin/services/useCompaniesApi"
import { UserDto } from "modules/admin/types/adminApiTypes"
import DeleteUserModal from "./DeleteUserModal"
import useLocalesState from "utils/Localization/useLocalesState"
import { useSelector } from "react-redux"
import { SystemRole } from "modules/authentication"

type Row = UserDto
interface CompanyList {
  id: string
  companyName: string
}
interface RolesList {
  description: string
  id: string
  name: string
}
interface Props {
  companyId?: string
  company?: {
    id: string
    companyName: string
  }
}
export const Users: React.FunctionComponent<Props> = (props) => {
  const [rows, setRows] = React.useState<Row[]>([])
  const [open, setOpen] = React.useState<boolean>(false)
  const [userData, setUserData] = React.useState<UserDto | null>(null)
  const [paginationModel, setPaginationModel] = React.useState(0)
  const [rowCount, setRowCount] = React.useState(10)
  const [roleList, setRolesList] = React.useState<RolesList[]>([])
  const [companiesList, setCompaniesList] = React.useState<CompanyList[]>([])
  const [isDeleting, setisDeleting] = React.useState(false)
  const [userDeleteId, setUserDeleteId] = React.useState("")
  const [searchText, setsearchText] = React.useState("")
  const [searchResult, setsearchResult] = React.useState("")
  const [locales]: any = useLocalesState()
  const { permissions } = useSelector((state: any) => state.roles)
  let debounceTimeout: NodeJS.Timeout
  const {
    getAllUsers,
    getRolesPerUser,
    deleteUser,
    getUsersByCompanyId,
    deleteUserCompany,
  } = useUserApi()
  const { getCompaniesList } = useCompaniesApi()

  React.useEffect(() => {
    //Get companies
    getCompaniesList().then((data) => {
      setCompaniesList(data)
    })
    //get roles
    getRolesPerUser().then((data) => {
      setRolesList(data)
    })
    return () => {
      clearTimeout(debounceTimeout)
    }
  }, [])
  React.useEffect(() => {
    //Get AllUser
    if (props?.company?.id) {
      getUsersByCompanyId(
        paginationModel,
        10,
        props?.company?.id,
        searchResult,
      ).then((res) => {
        setRowCount(res.count)
        setRows(res.data)
      })
    } else {
      handleGetAllUsers()
    }
  }, [paginationModel, searchResult])

  const canUpdateUser = (user: UserDto) => {
    if (!permissions?.canUpdateUser) return false

    if (permissions.userRole === SystemRole.Advisor) {
      if (user.roleName === "advisor") return false
      else return true
    }

    if (permissions.userRole === SystemRole.CompanyAdmin) {
      if (user.roleName === "advisor") return false
      else return true
    }

    if (permissions.userRole === SystemRole.CompanyUser) {
      if (user.roleName === "company_user") return true
      else return false
    }

    return true
  }

  const canDeleteUser = (user: UserDto) => {
    if (!permissions?.canDeleteUser) return false

    if (permissions.userRole === SystemRole.Advisor) {
      if (user.roleName === "advisor") return false
      else return true
    }

    if (permissions.userRole === SystemRole.CompanyAdmin) {
      if (user.roleName === "advisor") return false
      else return true
    }

    if (permissions.userRole === SystemRole.CompanyUser) {
      if (user.roleName === "company_user") return true
      else return false
    }

    return true
  }

  const handleGetAllUsers = () => {
    if (props?.company?.id) {
      getUsersByCompanyId(
        paginationModel,
        10,
        props?.company?.id,
        searchResult,
      ).then((res) => {
        setRowCount(res.count)
        setRows(res.data)
      })
    } else {
      getAllUsers(paginationModel, 10, searchResult).then((res) => {
        setRowCount(res.count)
        setRows(res.data)
      })
    }
  }
  const handleClickOpenUserDialog = () => {
    setOpen(true)
  }
  const editUser = (data: any) => {
    setUserData(data.row)
    setOpen(true)
  }

  const openDleteModel = (id: string) => {
    setUserDeleteId(id)
    setisDeleting(true)
  }
  const deleteUserRow = () => {
    props?.company?.id
      ? deleteUserCompany(userDeleteId, props?.company?.id).then(() => {
          getUsersByCompanyId(
            paginationModel,
            10,
            props?.company?.id ?? "",
          ).then((res) => {
            setRowCount(res.count)
            setRows(res.data)
          })
          setisDeleting(false)
        })
      : deleteUser(userDeleteId).then(() => {
          handleGetAllUsers()
          setisDeleting(false)
        })
  }

  const columns = React.useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: "userName",
        type: "string",
        headerName: locales["page_admin_users_table_header_name"] ?? "Navn",
        width: 250,
        renderCell: (params) => {
          const handleClick = () => {
            canUpdateUser(params?.row) && editUser(params)
          }

          return (
            <Div sx={{ cursor: "pointer" }} onClick={handleClick}>
              {params?.row?.userName}
            </Div>
          )
        },
      },
      {
        field: "email",
        type: "string",
        headerName: locales["page_admin_users_table_header_email"] ?? "E-post",
        width: 300,
      },
      {
        field: "companyEntity",
        headerName:
          locales["page_admin_users_table_header_business"] ?? "Virksomheter",
        width: 300,
        renderCell: (params) => {
          let companyResult = params?.row?.companyEntity[0]?.companyName || "-"
          let companyLength = params?.row?.companyEntity.length
          return (
            <span
              title={params?.row?.companyEntity
                .map((c) => c.companyName)
                .join(",")}
            >
              {companyResult +
                (companyLength > 1 ? ` (+${companyLength - 1} more)` : "")}
            </span>
          )
        },
      },
      {
        field: "roleName",
        headerName: locales["page_admin_users_table_header_role"] ?? "Roller",
        sortable: false,
        cellClassName: "super-app-theme--cell",
        width: 300,
      },

      {
        field: "actions",
        headerName:
          locales["page_admin_users_table_header_action"] ?? "Handlinger",
        type: "actions",
        width: 80,
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={
              <ModeEditOutlineOutlinedIcon
                sx={{
                  color: canUpdateUser(params?.row)
                    ? "#233E4C"
                    : "rgba(0, 0, 0, 0.26)",
                }}
              />
            }
            label="edit"
            onClick={() => editUser(params)}
            disabled={!canUpdateUser(params?.row)}
          />,
          <GridActionsCellItem
            icon={
              <DeleteOutlineIcon
                sx={{
                  color: canDeleteUser(params?.row)
                    ? "#FF0000"
                    : "rgba(0, 0, 0, 0.26)",
                }}
              />
            }
            label="Delete"
            onClick={() => openDleteModel(params.id)}
            disabled={!canDeleteUser(params?.row)}
          />,
        ],
      },
    ],
    [deleteUserRow],
  )

  const columnsUSerCompany = React.useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: "userName",
        type: "string",
        headerName: locales["page_admin_users_table_header_name"] ?? "Navn",
        width: 250,
        renderCell: (params) => {
          const handleClick = () => {
            canUpdateUser(params?.row) && editUser(params)
          }

          return (
            <Div sx={{ cursor: "pointer" }} onClick={handleClick}>
              {params?.row?.userName}
            </Div>
          )
        },
      },
      {
        field: "email",
        type: "string",
        headerName: locales["page_admin_users_table_header_email"] ?? "E-post",
        width: 300,
      },

      {
        field: "roleName",
        headerName: locales["page_admin_users_table_header_role"] ?? "Roller",
        sortable: false,
        cellClassName: "super-app-theme--cell",
        width: 300,
      },
      {
        field: "daterange",
        headerName:
          locales["page_admin_users_table_header_tilgangsperiode"] ??
          "Tilgangsperiode",
        width: 300,
        renderCell: (params) => {
          return (
            <>
              {(params?.row?.startDate ?? "") +
                " - " +
                (params?.row?.endDate ?? "")}
            </>
          )
        },
      },
      {
        field: "actions",
        headerName:
          locales["page_admin_users_table_header_action"] ?? "Handlinger",
        type: "actions",
        width: 80,
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={
              <ModeEditOutlineOutlinedIcon
                sx={{
                  color: canUpdateUser(params?.row)
                    ? "#233E4C"
                    : "rgba(0, 0, 0, 0.26)",
                }}
              />
            }
            label="Edit"
            disabled={!canUpdateUser(params?.row)}
            onClick={() => editUser(params)}
          />,
          <GridActionsCellItem
            icon={
              <DeleteOutlineIcon
                sx={{
                  color: canDeleteUser(params?.row)
                    ? "#FF0000"
                    : "rgba(0, 0, 0, 0.26)",
                }}
              />
            }
            label="Delete"
            disabled={!canDeleteUser(params?.row)}
            onClick={() => openDleteModel(params.id)}
          />,
        ],
      },
    ],
    [deleteUserRow],
  )
  return (
    <Div>
      <Div sx={{ display: "flex", gap: "10px" }}>
        <FootprintTextField
          style={{ flexGrow: "1" }}
          suffixIcon={<SearchIcon />}
          placeholder={
            locales["page_admin_users_search_placeholder"] ??
            "Søke etter en bruker"
          }
          value={searchText}
          onChange={(e) => {
            setPaginationModel(0)
            setsearchText(e.target.value)
            clearTimeout(debounceTimeout)
            debounceTimeout = setTimeout(() => {
              setsearchResult(e.target.value) // Trigger the search function after the delay
            }, 300)
          }}
        ></FootprintTextField>
        {open && (
          <UserForm
            open={open}
            setOpen={setOpen}
            setUserData={setUserData}
            userData={userData as UserDto}
            roleList={roleList}
            companiesList={companiesList}
            company={props.company}
            handleGetAllUsers={handleGetAllUsers}
            locales={locales}
          />
        )}
        {permissions?.canCreateUser && (
          <FootprintPrimaryButton
            overrideColor="#78B785"
            style={{
              color: "#78B785",
              fontWeight: 500,
              fontSize: "14px",
              backgroundImage: `linear-gradient(to right, #ffffff 50%, #78B785 50%)`,
            }}
            onClick={handleClickOpenUserDialog}
          >
            {locales["page_admin_users_create_user_button"] ?? "ny bruker"}
          </FootprintPrimaryButton>
        )}
      </Div>
      <Div
        sx={{
          "& .super-app-theme--cell": {
            "& .MuiDataGrid-cellContent": {
              backgroundColor: "var(--EDF7EF, #EDF7EF)",
              color: "#00000",
              padding: "2px 12px",
              fontWeight: 300,
              letterSpacing: "2px",
              textTransform: "uppercase",
            },
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-cell": {
            fontWeight: 300,
            fontSize: "14px",
            color: "black",
          },
        }}
      >
        {permissions?.canReadUser && (
          <DataTable
            columns={props?.company?.id ? columnsUSerCompany : columns}
            rows={rows}
            setPaginationModel={setPaginationModel}
            rowCount={rowCount}
          />
        )}
      </Div>
      {isDeleting && permissions?.canDeleteUser && (
        <DeleteUserModal
          open={isDeleting}
          locales={locales}
          deleteUserRow={deleteUserRow}
          onClose={() => setisDeleting(false)}
        />
      )}
    </Div>
  )
}

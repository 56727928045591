import React from "react"
import { useField } from "formik"
import TextField from "@mui/material/TextField"

const FootprintContactTextFieldFormik = (props: any) => {
  const [field, meta] = useField(props)
  const errorText = meta.error && meta.touched ? meta.error : ""
  const helperText = props?.helperText ?? ""
  return (
    <TextField
      {...props}
      {...field}
      sx={{
        "& fieldset": {
          borderRadius: "0px",
          boxShadow: "none",
          borderColor: "#000000",
        },
      }}
      helperText={errorText || helperText}
      error={!!errorText}
    />
  )
}

export default FootprintContactTextFieldFormik

import { UPDATE_ROLES, UPDATE_PERMISSIONS } from "redux/constants/roles"
import { SystemRole } from "modules/authentication"
const INIT_STATE = {
  roles: null,
  permissions: {
    canAccessAdminPage: false,
    canCreateBusiness: false,
    canCreateUser: false,
    canDeleteBusiness: false,
    canDeleteUser: false,
    isFirstLogin: false,
    canReadBusiness: false,
    canReadSustainability: false,
    canReadUser: false,
    canUpdateBusiness: false,
    canUpdateUser: false,
    userRole: SystemRole.UNKNOWN,
  },
}
const reducerFunc = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case UPDATE_ROLES: {
      return {
        ...state,
        roles: action.payload,
      }
    }
    case UPDATE_PERMISSIONS: {
      const payload = action.payload.map((p: any) => p.permission_name)

      for (let i = 0; i < payload.length; i++) {
        const p = payload[i]
        for (let i = 0; i < payload.length; i++) {
          const p = payload[i]
          if (p === "admin" || p === "advisor" || p === "company_admin") {
            state.permissions.canAccessAdminPage = true
            switch (p) {
              case "admin":
                state.permissions.userRole = SystemRole.Admin
                break
              case "advisor":
                state.permissions.userRole = SystemRole.Advisor
                break
              case "company_admin":
                state.permissions.userRole = SystemRole.CompanyAdmin
                break

              default:
                state.permissions.userRole = SystemRole.CompanyUser
                break
            }
          } else if (p === "create:business") {
            state.permissions.canCreateBusiness = true
          } else if (p === "create:user") {
            state.permissions.canCreateUser = true
          } else if (p === "delete:business") {
            state.permissions.canDeleteBusiness = true
          } else if (p === "delete:user") {
            state.permissions.canDeleteUser = true
          } else if (p === "first_login") {
            state.permissions.isFirstLogin = true
          } else if (p === "read:business") {
            state.permissions.canReadBusiness = true
          } else if (p === "read:sustainability") {
            state.permissions.canReadSustainability = true
          } else if (p === "read:user") {
            state.permissions.canReadUser = true
          } else if (p === "update:business") {
            state.permissions.canUpdateBusiness = true
          } else if (p === "update:user") {
            state.permissions.canUpdateUser = true
          }
        }
      }
      return {
        ...state,
      }
    }
    default: {
      return state
    }
  }
}

export default reducerFunc

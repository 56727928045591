import React from "react"
import { CircularProgress } from "@mui/material"

const SimpleLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <CircularProgress />
    </div>
  )
}

export default SimpleLoader

import React from "react"
import { Div } from "modules/shared"
import DashboardReportDone from "./components/DashboardReportDone/DashboardReportDone"
import MyBreadcrumbs from "modules/shared/components/Breadcrumbs/Breadcrumbs"
import useLocalesState from "utils/Localization/useLocalesState"
const ClimateDashboard = () => {
  const [locales] = useLocalesState()

  const breadcrumbs = [
    {
      name: locales["breadcrumb_climate"] ?? "Klimaregnskap",
      url: "",
    },
    {
      name: locales["breadcrumb_climate_page_dashboard"] ?? "Dashboard",
      url: "",
    },
  ]
  return (
    <Div>
      <MyBreadcrumbs breadcrumbs={breadcrumbs} />
      <DashboardReportDone />
    </Div>
  )
}

export default ClimateDashboard

import React, { FunctionComponent, useEffect, useState } from "react"
import DataTable from "modules/admin/components/Table/DataTable"
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined"
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid"
import LoginIcon from "@mui/icons-material/Login"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { useCompaniesApi } from "modules/admin/services/useCompaniesApi"
import { AdminPage, CompanyDto } from "modules/admin/types/adminApiTypes"
import { AdminContext } from "modules/admin/Admin"
import DeleteCompanyModal from "./DeleteCompanyModal"
import useLocalesState from "utils/Localization/useLocalesState"
import { useDispatch, useSelector } from "react-redux"
import { Permissions } from "modules/authentication/types/permissionType"
import { If, Then, Switch, Case } from "react-if"
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined"
import { useNavigate } from "react-router-dom"
import { addCompanyReduxData } from "redux/actions/company"
import { Div } from "modules/shared"
interface CompaniestableProps {
  searchText?: string
}

const CompaniesTable: FunctionComponent<CompaniestableProps> = (props) => {
  const [locales]: any = useLocalesState()
  const adminContext = React.useContext(AdminContext)
  const { getAllCompanies, deleteCompany } = useCompaniesApi()
  const [rows, setrows] = useState<CompanyDto[]>([])
  const [curPage, setcurPage] = useState(0)
  const [Count, setCount] = useState(0)
  const [isDeleting, setisDeleting] = useState(false)
  const [compDeleteId, setcompDeleteId] = useState("")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { permissions } = useSelector((state: any) => state.roles) as {
    permissions: Permissions
  }

  useEffect(() => {
    getAllCompanies(0, props.searchText).then((pagination) => {
      setrows(pagination.data)
      setCount(pagination.count)
    })
  }, [props.searchText])

  useEffect(() => {
    getAllCompanies(curPage, props.searchText).then((pagination) => {
      setrows(pagination.data)
      setCount(pagination.count)
    })
  }, [curPage])

  const columns: GridColDef[] = [
    {
      field: "companyName",
      headerName: locales["pages_admin_home_business_businesses"],
      width: 250,
      renderCell: (params) => {
        return (
          <Div
            sx={{ cursor: "pointer" }}
            onClick={(e) => {
              adminContext.changeActivePage(
                AdminPage.updateBusiness,
                params.row.id,
              )
              e.stopPropagation()
            }}
          >
            {params?.row?.companyName}
          </Div>
        )
      },
    },
    {
      field: "organizationNumber",
      headerName: locales["pages_admin_home_business_organizationNumber"],
      width: 250,
    },
    {
      field: "activeModules",
      headerName: locales["pages_admin_home_business_activeModules"],
      cellClassName: "activeModulesCell",
      width: 320,
    },
    {
      field: "subscription",
      headerName: locales["pages_admin_home_business_subscription"],
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return `${params.value} users`
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      cellClassName: "statusCell",
      renderCell: (params: GridRenderCellParams) => {
        const value = params.value
        var label = value
        var color = ""
        switch (value) {
          case "ACTIVE":
            color = "#78B785"
            label = locales["word.active"] ?? "Aktiv"
            break
          case "DISABLED":
            color = "#FB6D75"
            label = locales["word.disabled"] ?? "Ufør"
            break
          case "BREAK":
            color = "#BABABA"
            label = locales["word.break"] ?? "Pause"
            break
          case "UNPAID":
            color = "#BABABA"
            label = locales["word.unpaid"] ?? "Ubetalt"
            break
          default:
            color = "black"
            break
        }
        return <div style={{ color: color }}>{label}</div>
      },
    },
    {
      field: "actions",
      headerName: locales["pages_admin_home_business_actions"],
      type: "actions",
      headerAlign: "left",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div style={{ display: "flex", gap: "14px", justifyContent: "end" }}>
            <LoginIcon
              sx={{ cursor: "pointer", color: "#78B785" }}
              onClick={(e) => {
                e.stopPropagation()
                dispatch(addCompanyReduxData(params.row))
                navigate(`/${params.row.id}/main-page`)
              }}
            ></LoginIcon>

            <Switch>
              <Case condition={permissions.canUpdateBusiness}>
                <ModeEditOutlineOutlinedIcon
                  sx={{ cursor: "pointer" }}
                  onClick={(e) => {
                    adminContext.changeActivePage(
                      AdminPage.updateBusiness,
                      params.row.id,
                    )
                    e.stopPropagation()
                  }}
                />
              </Case>
              <Case condition={permissions.canReadBusiness}>
                <RemoveRedEyeOutlinedIcon
                  sx={{ cursor: "pointer" }}
                  onClick={(e) => {
                    adminContext.changeActivePage(
                      AdminPage.readBusiness,
                      params.row.id,
                    )
                    e.stopPropagation()
                  }}
                />
              </Case>
            </Switch>

            <DeleteOutlineIcon
              sx={{
                cursor: permissions.canDeleteBusiness ? "pointer" : "default",
                color: permissions.canDeleteBusiness ? "#FF0000" : "#BABABA",
              }}
              onClick={(e) => {
                if (!permissions.canDeleteBusiness) return
                setisDeleting(true)
                setcompDeleteId(params.row.id)
                e.stopPropagation()
              }}
            ></DeleteOutlineIcon>
          </div>
        )
      },
    },
  ]
  const isCellDisabled = (params: GridRenderCellParams) => {
    const rowStatus = params.row.status
    // Define your condition here to disable specific cells
    return rowStatus === "BREAK" || rowStatus === "DISABLED"
  }

  const getCellClassName = (params: GridRenderCellParams) => {
    if (isCellDisabled(params)) {
      return "disabled-cell"
    }
    return ""
  }
  return (
    <>
      <DataTable
        rowCount={Count}
        setPaginationModel={setcurPage}
        getCellClassName={getCellClassName}
        sx={{
          "& .MuiDataGrid-cellContent": {
            fontSize: "13px",
            fontStyle: "normal",
            fontWeight: "300",
          },
          "& .disabled-cell": {
            color: "#BABABA",
          },

          "& .activeModulesCell": {
            "& .MuiDataGrid-cellContent": {
              background: "#DFE3E6",
              padding: "4px 12px",
            },
          },
        }}
        columns={columns}
        rows={rows}
      ></DataTable>
      <DeleteCompanyModal
        open={isDeleting}
        onClose={(isDelete) => {
          setisDeleting(false)
          if (!isDelete) setcompDeleteId("")
          else {
            deleteCompany(compDeleteId).then((res) => {
              setrows(rows.filter((x) => x.id !== compDeleteId))
            })
          }
        }}
      />
    </>
  )
}

export default CompaniesTable

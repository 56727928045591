import { GET_LOCALES } from "redux/constants/appStatus"
import { Locale } from "redux/types/localeTypes"

const INIT_STATE: Locale = {
  isEmpty: true,
  lang: "en",
  messages: [],
}

const reducerFunc = (
  state = INIT_STATE,
  action: { type: any; payload: any },
) => {
  switch (action.type) {
    case GET_LOCALES: {
      return {
        ...state,
        ...action.payload,
        isEmpty: false,
      }
    }

    default: {
      return state
    }
  }
}

export default reducerFunc

import { Div } from "modules/shared"
import { SectionContainer } from "modules/shared"
import React from "react"
import { Heading1, Paragraph } from "modules/shared"
import { useNavigate, useParams } from "react-router-dom"
import { FootprintPrimaryButton } from "modules/shared"
export const Section10: React.FC<{
  locales?: any
  large?: boolean
  medium?: boolean
  small?: boolean
}> = ({ locales = {}, large, medium, small }) => {
  const navigate = useNavigate()
  const { companyId } = useParams()

  return (
    <Div>
      <SectionContainer
        id="page_manual_section10"
        sx={{
          p:
            (large && "239px 112px") ||
            (medium && "239px 112px") ||
            (small && "160px 168px") ||
            "239px 112px",

          textAlign: "center",
        }}
      >
        <Div>
          <Heading1 sx={{ m: 0, p: 0, fontSize: (small && "32px") || "45px" }}>
            {locales["page_manual_section10_title"] ?? "Lykke til!"}
          </Heading1>
          <Paragraph
            sx={{
              letterSpacing: "2px",
              m: 0,
              p: 0,
              fontSize: (small && "17px") || "15px",
              textTransform: "uppercase",
            }}
          >
            {locales["page_manual_section10_paragraph"] ??
              "Vi er her hvis du trenger help."}
          </Paragraph>
          <FootprintPrimaryButton
            style={{ width: "268px", height: "48px", marginTop: "60px" }}
            onClick={() => {
              navigate(`/${companyId}/transparency/steps/1`)
            }}
          >
            {locales["page_manual_section10_button"] ?? "START RAPPORTERINGEN"}
          </FootprintPrimaryButton>
        </Div>
      </SectionContainer>
    </Div>
  )
}

import {
  CreateUserDto,
  UserDto,
  Pagination,
  UpdateUserAdminDto,
  UpdateUserDto,
} from "modules/admin/types/adminApiTypes"
import axiosHelper from "../../../utils/axiosHelper"
import {
  CREATE_USER_URL,
  GET_ALL_USERS_URL,
  GET_ROLES_PER_USER_URL,
  DELETE_USER_URL,
  DELETE_USER_COMPANY_URL,
  SEND_USER_INVITATION_URL,
  GET_USERS_BY_COMPANY_URL,
  UPDATE_USER_URL,
  UPDATE_USER_ADMIN_URL,
  GET_USERS_LIST_URL,
} from "../../../utils/constants/URLs"

export const useUserApi = () => {
  return {
    createUser: async (user: CreateUserDto) => {
      const { data } = await axiosHelper.post(CREATE_USER_URL, user)
      return data
    },

    getAllUsers: async (
      page = 0,
      size = 10,
      username = "",
    ): Promise<Pagination<UserDto[]>> => {
      const { data: pagination } = await axiosHelper.get<Pagination<UserDto[]>>(
        GET_ALL_USERS_URL,
        {
          params: {
            page,
            size,
            username: username == "" ? null : username,
          },
        },
      )

      pagination.data = pagination.data.map((d) => {
        d.id = d.userId
        return d
      })
      return pagination
    },

    getUsersList: async (): Promise<
      {
        userId: string
        email: string
      }[]
    > => {
      const data =
        await axiosHelper.get<{ userId: string; email: string }[]>(
          GET_USERS_LIST_URL,
        )
      return data.data
    },

    getUsersByCompanyId: async (
      page = 0,
      size = 10,
      id: string,
      username = "",
    ): Promise<Pagination<UserDto[]>> => {
      const { data: pagination } = await axiosHelper.get<Pagination<UserDto[]>>(
        GET_USERS_BY_COMPANY_URL.replace("{companyId}", id),
        {
          params: {
            page,
            size,
            username: username == "" ? null : username,
          },
        },
      )

      pagination.data = pagination.data.map((d) => {
        d.id = d.userId
        return d
      })
      return pagination
    },

    getRolesPerUser: async () => {
      const { data } = await axiosHelper.get(GET_ROLES_PER_USER_URL)
      return data as { description: string; id: string; name: string }[]
    },
    deleteUser: async (userId: string) => {
      const { data } = await axiosHelper.delete(
        DELETE_USER_URL.replace("{userId}", userId),
      )
      return data
    },
    deleteUserCompany: async (userId: string, companyId: string) => {
      const { data } = await axiosHelper.delete(
        DELETE_USER_COMPANY_URL.replace("{userId}", userId).replace(
          "{companyId}",
          companyId,
        ),
      )
      return data
    },
    updateAdminUser: async (userId: string, user: UpdateUserAdminDto) => {
      const { data } = await axiosHelper.put(
        UPDATE_USER_ADMIN_URL.replace("{userId}", userId),
        user,
      )
      return data
    },
    updateUser: async (userId: string, user: UpdateUserDto) => {
      const { data } = await axiosHelper.put(
        UPDATE_USER_URL.replace("{userId}", userId),
        user,
      )
      return data
    },
    sendInvitation: async (userId: string, email: string) => {
      const { data } = await axiosHelper.post(
        SEND_USER_INVITATION_URL.replace("{userId}", userId),
        null,
        { params: { email } },
      )
      return data
    },
  }
}

import React, { FunctionComponent, PropsWithChildren } from "react"
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select"

interface FootprintDropDownProps {
  label?: string
  style?: React.CSSProperties
  selectProps?: SelectProps
}

const FootprintDropDown: FunctionComponent<
  PropsWithChildren<FootprintDropDownProps>
> = (props) => {
  return (
    <div style={{ ...props.style }}>
      <div>{props.label}</div>
      <Select
        labelId="demo-simple-select-helper-label"
        sx={{
          "&.MuiInputBase-root": {
            height: "48px",
            width: "100%",
          },
          "& fieldset": {
            borderRadius: "0px",
            boxShadow: "none",
            borderColor: "#DFE3E6",
          },
        }}
        {...props.selectProps}
      >
        {props.children}
      </Select>
    </div>
  )
}

export default FootprintDropDown

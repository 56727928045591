enum ReactQueryKeys {
  steps = "steps",
  climateBudgetChart1 = "climateBudgetChart1",
  climateBudgetTable = "climateBudgetTable",
  climateTrendChart1 = "climateTrendChart1",
  climateDetailedOverviewPieChart = "climateDetailedOverviewPieChart",
  climateDetailedOverviewBarChart = "climateDetailedOverviewBarChart",
  climateDetailedOverviewLegends = "climateDetailedOverviewLegends",
  climateOverviewChart1 = "climateOverviewChart1",
  climateOverviewChart2 = "climateOverviewChart2",
  climateOverviewChart3 = "climateOverviewChart3",
  climateOverviewChart4 = "climateOverviewChart4",
  climateOverviewScopes = "climateOverviewScopes",
}
export default ReactQueryKeys
